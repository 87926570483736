import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import $ from 'jquery';

import './semantic.css';
import './sidebar.css';

function CommonSidebar() {
  const location = useLocation();
  const menuItem = location.pathname;

  const [activeMyTasks, setActiveMyTasks] = useState('InActive');
  const [activeDashboard, setActiveDashboard] = useState('InActive');
  const [activeClientLogo, setActiveClientLogo] = useState('InActive');

  const [activePatients, setActivePatients] = useState('InActive');
  const [activeUsers, setActiveUsers] = useState('InActive');

  const [activeReports, setActiveReports] = useState('InActive');
  const [activeEvents, setActiveEvents] = useState('InActive');
  const [activeClinical, setActiveClinical] = useState('InActive');

  const [activeTechnician, setActiveTechnician] = useState('InActive');
  const [activeMetrics, setActiveMetrics] = useState('InActive');
  const [activeOutcomes, setActiveOutcomes] = useState('InActive');
  const [activeAccreditation, setActiveAccreditation] = useState('InActive');
  

  useEffect(() => {
    if (menuItem === '/clientlogo') {
      $("#dropDownMenu").addClass('show1111');
      setActiveClientLogo('active');
      setActiveClinical('InActive');
      setActiveMyTasks('InActive');

      setActiveDashboard('InActive');
      setActiveTechnician('InActive');

      setActiveReports('InActive');
      setActiveMetrics('InActive');

      setActiveOutcomes('InActive');
      setActiveAccreditation('InActive');
      setActiveEvents('InActive');
    } else if (menuItem === '/tasks' || menuItem === '/patientprofile' || menuItem === '/scheduledelivery' || menuItem === '/pastmedicalhistory'
    || menuItem === '/currentrxotcmedications'|| menuItem === '/patientsprofile' || menuItem === '/logintervention' || menuItem === '/refillremindercall' || menuItem === '/patientcliprofile' 
    || menuItem === '/reports') {
      setActiveMyTasks('active');
      setActiveClinical('InActive');

      setActiveDashboard('InActive');
      setActiveTechnician('InActive');

      setActiveReports('InActive');
      setActiveMetrics('InActive');

      setActiveOutcomes('InActive');
      setActiveAccreditation('InActive');
    } else if (menuItem === '/' || menuItem === '/outcomesreport' || menuItem === '/outcomes' || menuItem === '/adverseevents' 
    || menuItem === '/advinitialkarnofsky' || menuItem === '/durrates') {
      setActiveDashboard('active');
      setActiveMyTasks('InActive');

      setActiveClinical('InActive');
      setActiveTechnician('InActive');

      setActiveReports('InActive');
      setActiveMetrics('InActive');

      setActiveOutcomes('InActive');
      setActiveAccreditation('InActive');
    } else if (menuItem === '/users') {
      $("#dropDownMenu").addClass('show');
      setActiveUsers('active');
      setActiveClinical('InActive');

      setActiveDashboard('InActive');
      setActiveMyTasks('InActive');

      setActiveReports('InActive');
      setActiveMetrics('InActive');

      setActiveOutcomes('InActive');
      setActiveAccreditation('InActive');
      setActiveEvents('InActive');
    } else if (menuItem === '/patientslists') {
      setActivePatients('active');
      setActiveTechnician('InActive');

      setActiveClinical('InActive');
      setActiveDashboard('InActive');

      setActiveMyTasks('InActive');
      setActiveReports('InActive');

      setActiveMetrics('InActive');
      setActiveOutcomes('InActive');
      setActiveAccreditation('InActive');
    } else if (menuItem === '/reports') {
      setActiveReports('active');
      setActiveTechnician('InActive');

      setActiveClinical('InActive');
      setActiveDashboard('InActive');

      setActiveMyTasks('InActive');
      setActiveEvents('InActive');

      setActiveMetrics('InActive');
      setActiveOutcomes('InActive');
      setActiveAccreditation('InActive');
    } else if (menuItem === '/metrics') {
      setActiveMetrics('active');
      setActiveTechnician('InActive');

      setActiveClinical('InActive');
      setActiveDashboard('InActive');

      setActiveMyTasks('InActive');
      setActiveEvents('InActive');

      setActiveReports('InActive');
      setActiveOutcomes('InActive');
      setActiveAccreditation('InActive');
    } else if (menuItem === '/outcomes') {
      setActiveOutcomes('active');
      setActiveTechnician('InActive');

      setActiveClinical('InActive');
      setActiveDashboard('InActive');

      setActiveMyTasks('InActive');
      setActiveEvents('InActive');

      setActiveReports('InActive');
      setActiveMetrics('InAactive');
      setActiveAccreditation('InActive');
    } else if (menuItem === '/accreditation') {
      setActiveAccreditation('active');
      setActiveTechnician('InActive');

      setActiveClinical('InActive');
      setActiveDashboard('InActive');

      setActiveMyTasks('InActive');
      setActiveEvents('InActive');

      setActiveReports('InActive');
      setActiveMetrics('InAactive');
      setActiveOutcomes('InActive');
    }
  }, [menuItem]);

  return (
    <div className="sidebar-wrapper">
      <div className="sidebar-nav">
        <ul>
          <Link to="/">
            <li>
              <Link to="/" className={activeDashboard}>
                <span className="fa fa-th-large"> </span>
                Dashboard
              </Link>
            </li>
          </Link>
          <Link to="/tasks">
            <li>
              <Link to="/tasks" className={activeMyTasks}>
                <span className="fa fa-heartbeat"> </span>
                My Task
              </Link>
            </li>
          </Link>
          <Link to="/patientslists">
            <li>
              <Link to="/patientslists" className={activePatients}>
                <span className="fa fa-heartbeat"> </span>
                Patients
              </Link>
            </li>
          </Link>
          {localStorage.getItem('adminCheck') === "true" ? (
            <li id="dropDownMenu" className="dropdown">
              <Link className="dropdown-toggle poniter-event" data-toggle="dropdown">
                <span className="fa fa-heartbeat"> </span>
                  D2 Admin
              </Link>
                <div class="dropdown-menu subMenu">
                  <Link class="dropdown-item" class={activeUsers}  to="/users">Manage Users</Link>
                  <Link class="dropdown-item" class={activeClientLogo} to="/clientlogo">Manage Logo</Link>
                  <Link class="dropdown-item" to="#">Manage Assessments</Link>
                </div>  
            </li>
          ) : ''}
        </ul>
      </div>
    </div>
  );
}

export default CommonSidebar;
