/*
 * Patients Lists Page
 *
 *
 */

import React from 'react';

import CommonHeader from '../../components/CommonHeader';
import CommonSidebar from '../../components/CommonSidebar';
import CommonFooter from '../../components/CommonFooter';

import axios from 'axios';
import { Redirect, Link } from 'react-router-dom';
import { MDBDataTable } from 'mdbreact';

import { ToastContainer, toast } from 'react-toastify';
import Button from 'react-bootstrap/Button';
import Moment from 'moment';

import {
  API_URL,
} from '../../config';

import {
  TOKEN_EXPIRED,
  INTERNAL_SERVER_ERROR,
} from './constants';

import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.css';

import 'mdbreact/dist/css/mdb.css';
import 'bootstrap-css-only/css/bootstrap.min.css';

import '../../containers/css/style_semantic.css';
import './style.css';

export default class PatientsLists extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [
        {
          label: 'Patient Name',
          field: 'PatientName',
          sort: 'asc',
          width: 100,
        },
        {
          label: 'MRN',
          field: 'MRN',
          sort: 'asc',
          width: 100,
        },
        {
          label: 'Date of Birth',
          field: 'DateOfBirth',
          sort: 'asc',
          width: 270,
        },
        {
          label: 'Phone',
          field: 'Phone',
          sort: 'asc',
          width: 200,
        },
        {
          label: 'Mobile',
          label: 'Mobile',
          field: 'Mobile',
          sort: 'asc',
          width: 200,
        },
        {
          label: 'EmailId',
          label: 'Email',
          field: 'Email',
          sort: 'asc',
          width: 200,
        },
        {
          label: 'Gender',
          field: 'Gender',
          sort: 'asc',
          width: 150,
        },
        {
          label: 'Age',
          field: 'Age',
          sort: 'asc',
          width: 150,
        },
        {
          label: 'Action',
          field: 'Action',
          sort: 'asc',
          width: 100,
        }
      ],
      rowData: [],
    };
  }

  componentDidMount() {
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    axios
      .get(`${API_URL}/api/api-patient/`, config)
      .then(response => {
        if(response.status == 200) {
          if(response.data.status == 200) {
            let responseData;
            if(response.data.data !== null) { 
              responseData = response.data.data.map((data, index)=> ({
                  PatientName: data.patient_first_name + ' ' + data.patient_last_name,
                  MRN: data.mrn,
                  DateOfBirth: Moment(data.dob).format('MM/DD/YYYY'),
                  Mobile: data.patient_cell,
                  Phone: data.patient_phone,
                  Email: data.patient_email,
                  Gender: data.gender == 'M'? 'Male' : 'Female',
                  Age: this.calculate_age(data.dob),
                  Action: <Link to={{'pathname': '/patientcliprofile','state':{'patient_id':data.id, 
                          'patient_name': data.patient_first_name +' '+ data.patient_last_name, 'data_added' : Moment(data.data_added).format('MM/DD/YYYY') }}} className="btn btn-primary">
                            Clinical Profile
                          </Link>,
                }));
            } else {
              responseData = [];
            }
            this.setState({"rowData": responseData});
          } else if(response.data.status_code == 406) {
            localStorage.removeItem('token');
            localStorage.removeItem('user_name');

            localStorage.removeItem('adminCheck');
            localStorage.setItem('tokenExpired', true);

            const tokenStatus = localStorage.getItem('tokenExpired');
            if(tokenStatus) {
              localStorage.removeItem('tokenExpired');
              toast.error(TOKEN_EXPIRED, {
                position: 'top-right',
                autoClose: 2500,
              });
            }

            const isLogged = !!localStorage.getItem('token');
            if (!isLogged) {
             setTimeout(() => { 
              this.props.history.push("/login");
              }, 2500);
            }
          }
        } else {
          toast.error(INTERNAL_SERVER_ERROR, {
            position: 'top-right',
            autoClose: 2500,
          });
        }
      })
      .catch(err=> console.log(err));
  };

  /* Method For Calculating Age Of Patient Dynamically On The Basis Of Date Of Birth */
  calculate_age = (dob) => {
    var today = new Date();
    var birthDate = new Date(dob);  // create a date object directly from `dob1` argument
    var age_now = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) 
    {
        age_now--;
    }
    return age_now;
  }

  render() {
    const isLogged = !!localStorage.getItem('token');
    if (!isLogged) {
      return <Redirect to="/login" />;
    }

    let mbdDatas = {
      columns: this.state.columns,
      rows: this.state.rowData,
    }

    return (
      <>
        <ToastContainer />
          <CommonHeader />
          <section id="wrapper">
            <CommonSidebar />
              <div className="ui grid">
                <div className="column">
                  <div className="tabs-outer tabsouterCustom mb-5">
                    <div className="ui tab active" data-tab="edit">
                      <div className="ui form customSelectIcon">
                        <div className="table-responsive">
                          <MDBDataTable
                            striped
                            bordered
                            small
                            hover
                            searching={true}
                            data={mbdDatas}
                            disableRetreatAfterSorting={true}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          </section>
        <CommonFooter />
      </>
    );
  }
}
