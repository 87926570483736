/*
 * TaskPage
 *
 *
 */

import React from 'react';
import { MDBDataTable, MDBInput } from 'mdbreact';

import CommonHeader from '../../components/CommonHeader';
import CommonSidebar from '../../components/CommonSidebar';
import CommonFooter from '../../components/CommonFooter';
import axios from 'axios';
import {
  API_URL,
} from '../../config';
import Moment from 'moment';
import {
  SUCCESS_SCHDEULE_DELIVERY, TOKEN_EXPIRED,
  ERROR_DATA_SAVE, INTERNAL_SERVER_ERROR, UPDATE_SCHDEULE_DELIVERY,
} from './constants';
import { Redirect, Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'mdbreact/dist/css/mdb.css';

import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';

import '../../css/style_semantic.css';
import './style.css';

export default class TasksPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      columns: [
        {
          label: 'Sr No.',
          field: 'Sno',
          sort: 'asc',
          width: 150,
        },
        {
          label: 'Call Done',
          field: 'CallDone',
          sort: 'asc',
          width: 150,
        },
        {
          label: 'Call Date',
          field: 'CallDate',
          sort: 'asc',
          width: 150,
        },
        {
          label: 'Days',
          field: 'Days',
          sort: 'asc',
          width: 150,
        },
        {
          label: 'Expected Call Date',
          field: 'ExpectedCallDate',
          sort: 'asc',
          width: 150,
        },
        {
          label: 'RxNum',
          field: 'RxNum',
          sort: 'asc',
          width: 150,
        },
        {
          label: 'MRN',
          field: 'MRN',
          sort: 'asc',
          width: 150,
        },
        {
          label: 'Patient',
          field: 'Patient',
          sort: 'asc',
          width: 150,
        },
      ],
      rowData: [],
    }
  }


  componentDidMount() {
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    axios
      .get(`${API_URL}/api/refill_too_early_report/`, config)
      .then(response => {
        if (response.status == 200) {
          if (response.data.status == 200) {
            let responseData = response.data.data != null ? response.data.data.map((data, index) => ({
              Sno: index + 1,
              CallDone: <input type='checkbox' id='call_done' checked={data.call_done ? true : false} readOnly />,
              CallDate: Moment(data.call_date).format('MM/DD/YYYY'),
              Days: data.days,
              ExpectedCallDate: Moment(data.expected_call_date).format('MM/DD/YYYY'),
              RxNum: data.rx_number,
              MRN: data.mrn,
              Patient: data.patient
            })) : []
            //console.log('data',responseData)
            this.setState({ rowData: responseData });
          } else if (response.data.status_code == 406) {
            localStorage.removeItem('token');
            localStorage.removeItem('user_name');

            localStorage.removeItem('adminCheck');
            localStorage.setItem('tokenExpired', true);

            const tokenStatus = localStorage.getItem('tokenExpired');
            if (tokenStatus) {
              localStorage.removeItem('tokenExpired');
              toast.error(TOKEN_EXPIRED, {
                position: 'top-right',
                autoClose: 2500,
              });
            }

            const isLogged = !!localStorage.getItem('token');
            if (!isLogged) {
              setTimeout(() => {
                this.props.history.push("/login");
              }, 2500);
            }
          }
        } else {
          toast.error(INTERNAL_SERVER_ERROR, {
            position: 'top-right',
            autoClose: 2500,
          });
        }
      })
      .catch(error => {
        console.log(error);
      });
  }

  Logout = () => {
    localStorage.removeItem('token');
  };

  render() {
    const isLogged = !!localStorage.getItem('token');
    let mbdDatas = {
      columns: this.state.columns,
      rows: this.state.rowData
    }
    //console.log(this.state.rowData)
    if (!isLogged) {
      return <Redirect to="/login" />;
    }

    return (
      <React.Fragment>
        <ToastContainer />
        <CommonHeader />
        <section id="wrapper">
          <CommonSidebar />
          <div className="queue-block">
            <h1>Refill Reminder Too Early Report</h1>
            <div className="ui tab active" data-tab="edit">
              <div className="ui form customSelectIcon">
                <div className="table-responsive">
                  <MDBDataTable
                    striped
                    bordered
                    small
                    hover
                    searching={true}
                    data={mbdDatas}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <CommonFooter />
      </React.Fragment>
    );
  }
}