export const GET_TASK_LIST = 'GET_TASK_LIST';
export const API_URL = 'http://pioneer.d2api.local:8001';

export const UPLOAD_TASK_LIST = 'Excel sheet uploaded successfully.';
export const UPLOAD_ERROR_TASK_LIST = 'Something went wrong while uploading.';

export const VALIDATE_UPLOAD_TASK_LIST = 'Please upload file.';
export const VALIDATE_UPLOAD_FILE = 'Please upload valid Excel(xls, xlsx) file.';

export const UPLOAD_INTERNAL_ERROR = 'Something went wrong !.';
export const ASSESSMENT_COUNT_ERROR = 'Something went wrong with assessment count!.';
export const TOKEN_EXPIRED = 'Please login again. Your token has expired.';
