/*
 * Current Rx Medications
 *
 *
*/

import React from 'react';

import CommonHeader from '../../components/CommonHeader';
import CommonSidebar from '../../components/CommonSidebar';
import CommonFooter from '../../components/CommonFooter';
import dateFormat from 'dateformat';

import axios from 'axios';
import { Redirect, Link } from 'react-router-dom';

import { ToastContainer, toast } from 'react-toastify';
import $ from 'jquery';

import {
  VALID_RX_MED_DRUG,
  VALID_RX_MED_NOTES,
  VALID_RX_OTC_TYPE,
  SUCCESS_RX_OTC_MED,
  UPDATE_RX_OTC_DATA,
  DELETE_CURR_RX_OTC_DATA,
  TOKEN_EXPIRED,
  INTERNAL_SERVER_ERROR,
} from './constants';

import {
  API_URL,
} from '../../config';

import PlusIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import Moment from 'moment';

import IconWithTooltip from 'icon-with-tooltip';
import CheckIcon from '@material-ui/icons/Check';
import InfoIcon from '@material-ui/icons/Info';

import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.css';

import '../../containers/css/style_semantic.css';
import './style.css';
import { ButtonBase } from '@material-ui/core';

export default class CurrentRxMedications extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      trackCurrRxOtcMed: [],
      rxMedInputList: [
        {
          currRxOtcMedId: "",
          patient: "",
          is_active: true,
          type: "",
          medication_name: "",
          notes: "",
        },
      ],
      rxOtcData: {
        patient: "",
        is_active: true,
        type: "",
        medication_name: "",
        notes: "",
      },
      patient_id: this.props.location.state !== undefined ? this.props.location.state.patient_id : null,
      patient_name: this.props.location.state !== undefined ? this.props.location.state.patient_name : null,
      patient_name: this.props.location.state !== undefined ? this.props.location.state.patient_name : null,
      data_added: this.props.location.state !== undefined ? this.props.location.state.data_added : null,

    }
  }

  /**General method to get latest RX/OTC data */
  getRxOtcData(config){
    axios
    .get(`${API_URL}/api/current_rx_otc_med/?patient_id=${this.state.patient_id}`, config)
    .then(response => {
      if (response.status == 200) {
        if (response.data.status == 200 || response.data.status == 204) {
          let responseData, trackResponseData;
          if (response.data.data !== null) {
            responseData = response.data.data.map((data, index) => ({
              currRxOtcMedId: data.id,
              patient: data.patient,
              is_active: data.is_active,
              type: data.type,
              medication_name: data.medication_name,
              notes: data.notes,
              created: data.created,
              modified: data.modified,
              updated_by_user: data.updated_by_user,
            }));

            trackResponseData = response.data.data.map((data, index) => ({
              currRxOtcMedId: data.id,
              patient: data.patient,
              is_active: data.is_active,
              type: data.type,
              medication_name: data.medication_name,
              notes: data.notes,
              created: data.created,
              modified: data.modified,
              updated_by_user: data.updated_by_user,
            }));
          } else {
            responseData = [];
            trackResponseData = [];
          }

          this.setState({ "rxMedInputList": responseData });
          /* @TODO: If this is only used to track original response data then 
          Change this to "trackCurrRxOtcMed" : JSON.parse(JSON.stringify(responseData))
          */
          this.setState({ "trackCurrRxOtcMed": trackResponseData });
        } else if (response.data.status_code == 406) {
          localStorage.removeItem('token');
          localStorage.removeItem('user_name');

          localStorage.removeItem('adminCheck');
          localStorage.setItem('tokenExpired', true);

          const tokenStatus = localStorage.getItem('tokenExpired');
          if (tokenStatus) {
            localStorage.removeItem('tokenExpired');
            toast.error(TOKEN_EXPIRED, {
              position: 'top-right',
              autoClose: 2500,
            });
          }

          const isLogged = !!localStorage.getItem('token');
          if (!isLogged) {
            setTimeout(() => {
              this.props.history.push("/login");
            }, 2500);
          }
        }
      } else {
        toast.error(INTERNAL_SERVER_ERROR, {
          position: 'top-right',
          autoClose: 2500,
        });
      }
    })
    .catch(err => console.log(err));
  }

  /* Method For Current Rx/Otc Medications After Component Rendering */
  componentDidMount() {
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    this.getRxOtcData(config)
  }

  // Handle Data Input Change Of Current RX/OTC Medications
  async handleRxOtcDataChange(e) {
    const { name, value } = e.target;
    const list = { ...this.state.rxOtcData };

    if (name == 'is_active') {
      list[name] = !this.state.rxOtcData.is_active;
    } else {
      list[name] = value;
    }

    await this.setState({ rxOtcData: list, });
  };

  /* Save Patient Current RX/OTC Medications Data */
  handleCurrRxOtcMedSubmit = () => {
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    this.state.rxOtcData.patient = this.state.patient_id;
    if (this.state.rxOtcData.type == '') {
      toast.error(VALID_RX_OTC_TYPE, {
        position: 'top-right',
        autoClose: 3000,
      });
      return false;
    } else if (this.state.rxOtcData.medication_name == '') {
      toast.error(VALID_RX_MED_DRUG, {
        position: 'top-right',
        autoClose: 3000,
      });
      return false;
    } else if (this.state.rxOtcData.notes == '') {
      toast.error(VALID_RX_MED_NOTES, {
        position: 'top-right',
        autoClose: 3000,
      });
      return false;
    }

    axios
      .post(`${API_URL}/api/current_rx_otc_med/`, this.state.rxOtcData, config)
      .then(response => {
        if (response.status === 200) {
          if (response.data.status == 201) {
            toast.success(SUCCESS_RX_OTC_MED, {
              position: 'top-right',
              autoClose: 3000,
            });

            this.setState({
              rxOtcData: {
                patient: "",
                is_active: true,
                type: "",
                medication_name: "",
                notes: "",
              }
            });

            axios
              .get(`${API_URL}/api/current_rx_otc_med/?patient_id=${this.state.patient_id}`, config)
              .then(response => {
                if (response.status === 200) {
                  let responseData;
                  if (response.data.data !== null) {
                    responseData = response.data.data.map((data, index) => ({
                      currRxOtcMedId: data.id,
                      patient: data.patient,
                      is_active: data.is_active,
                      type: data.type,
                      medication_name: data.medication_name,
                      notes: data.notes,
                      created: data.created,
                      modified: data.modified,
                      updated_by_user: data.updated_by_user
                    }));
                  } else {
                    responseData = [];
                  }
                  this.setState({ "rxMedInputList": responseData });
                  this.setState({ trackCurrRxOtcMed: JSON.parse(JSON.stringify(responseData)) });
                  //console.log('rxMedInputList', this.state.rxMedInputList);
                }
              })
              .catch(err => console.log(err));
          } else if (response.data.status_code == 406) {
            localStorage.removeItem('token');
            localStorage.removeItem('user_name');

            localStorage.removeItem('adminCheck');
            localStorage.setItem('tokenExpired', true);

            const tokenStatus = localStorage.getItem('tokenExpired');
            if (tokenStatus) {
              localStorage.removeItem('tokenExpired');
              toast.error(TOKEN_EXPIRED, {
                position: 'top-right',
                autoClose: 2500,
              });
            }

            const isLogged = !!localStorage.getItem('token');
            if (!isLogged) {
              setTimeout(() => {
                this.props.history.push("/login");
              }, 2500);
            }
          }
        } else {
          toast.error(INTERNAL_SERVER_ERROR, {
            position: 'top-right',
            autoClose: 2500,
          });
        }
      })
      .catch(err => console.log(err));
  };


  // Handle Input Change Of Current RX Medications
  async handleRxOtcMedInputChange(e, index, active) {
    const { name, value } = e.target;
    const list = [...this.state.rxMedInputList];
    const button_element = document.getElementsByClassName("update_button");
    button_element[index].removeAttribute("style");
    if (name == 'is_active') {
      list[index][name] = !this.state.rxMedInputList[index][name];
    } else {
      list[index][name] = value;
    }

    this.setState({ rxMedInputList: list, });
    if (active === true) {
      this.updCurrRxOtcMedSubmit(index);
    }

    for (var i = 0; i < this.state.rxMedInputList.length; i++) {
      if ((this.state.trackCurrRxOtcMed[index].type !== this.state.rxMedInputList[index]['type'])
        || (this.state.trackCurrRxOtcMed[index].medication_name !== this.state.rxMedInputList[index]['medication_name'])
        || (this.state.trackCurrRxOtcMed[index].notes !== this.state.rxMedInputList[index]['notes'])) {
        $("#currRxOtcMed_" + index).prop('disabled', false);
        break;
      } else {
        $("#currRxOtcMed_" + index).prop('disabled', true);
        break;
      }
    }
  };

  /* Update Patient Current RX/OTC Medications */
  updCurrRxOtcMedSubmit = (index) => {
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const list = [...this.state.rxMedInputList];
    const currRxOtcMedDet = {
      patient: this.state.patient_id,
      is_active: list[index].is_active,
      type: list[index].type,
      medication_name: list[index].medication_name,
      notes: list[index].notes,
    };

    if (list[index].type == '' || list[index].type == null) {
      toast.error(VALID_RX_OTC_TYPE, {
        position: 'top-right',
        autoClose: 3000,
      });
      return false;
    } else if (list[index].medication_name == '' || list[index].medication_name == null) {
      toast.error(VALID_RX_MED_DRUG, {
        position: 'top-right',
        autoClose: 3000,
      });
      return false;
    } else if (list[index].notes == '' || list[index].notes == null) {
      toast.error(VALID_RX_MED_NOTES, {
        position: 'top-right',
        autoClose: 3000,
      });
      return false;
    }

    axios
      .put(`${API_URL}/api/current_rx_otc_med/${list[index].currRxOtcMedId}`, currRxOtcMedDet, config)
      .then(response => {
        if (response.status === 200) {
          if (response.data.status == 200) {
            toast.success(UPDATE_RX_OTC_DATA, {
              position: 'top-right',
              autoClose: 3000,
            });
            /* Refresh data with latest modified date
            * TODO: This is an excessive call try to update the updated through other means if possible
            */
            this.getRxOtcData(config);
            return false;
          } else if (response.data.status_code == 406) {
            localStorage.removeItem('token');
            localStorage.removeItem('user_name');

            localStorage.removeItem('adminCheck');
            localStorage.setItem('tokenExpired', true);

            const tokenStatus = localStorage.getItem('tokenExpired');
            if (tokenStatus) {
              localStorage.removeItem('tokenExpired');
              toast.error(TOKEN_EXPIRED, {
                position: 'top-right',
                autoClose: 2500,
              });
            }

            const isLogged = !!localStorage.getItem('token');
            if (!isLogged) {
              setTimeout(() => {
                this.props.history.push("/login");
              }, 2500);
            }
            return false;
          }
        } else {
          toast.error(INTERNAL_SERVER_ERROR, {
            position: 'top-right',
            autoClose: 2500,
          });
        }
      })
      .catch(err => console.log(err));
  };

  /* Delete Patient Delivery Address Data */
  delCurrRxOtcMedData = (index) => {
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    axios
      .delete(`${API_URL}/api/current_rx_otc_med/${index}`, config)
      .then(response => {
        if (response.status === 200) {
          if (response.data.status == 200) {
            toast.error(DELETE_CURR_RX_OTC_DATA, {
              position: 'top-right',
              autoClose: 3000,
            });

            axios
              .get(`${API_URL}/api/current_rx_otc_med/?patient_id=${this.state.patient_id}`, config)
              .then(response => {
                if (response.status === 200) {
                  let responseData;
                  if (response.data.data !== null) {
                    responseData = response.data.data.map((data, index) => ({
                      currRxOtcMedId: data.id,
                      patient: data.patient,
                      is_active: data.is_active,
                      type: data.type,
                      medication_name: data.medication_name,
                      notes: data.notes,
                      created: data.created,
                      modified: data.modified,
                      updated_by_user: data.updated_by_user
                    }));
                  } else {
                    responseData = [];
                  }
                  this.setState({ "rxMedInputList": responseData });
                }
              })
              .catch(err => console.log(err));
            return false;
          } else if (response.data.status_code == 406) {
            localStorage.removeItem('token');
            localStorage.removeItem('user_name');

            localStorage.removeItem('adminCheck');
            localStorage.setItem('tokenExpired', true);

            const tokenStatus = localStorage.getItem('tokenExpired');
            if (tokenStatus) {
              localStorage.removeItem('tokenExpired');
              toast.error(TOKEN_EXPIRED, {
                position: 'top-right',
                autoClose: 2500,
              });
            }

            const isLogged = !!localStorage.getItem('token');
            if (!isLogged) {
              setTimeout(() => {
                this.props.history.push("/login");
              }, 2500);
            }
          }
        } else {
          toast.error(INTERNAL_SERVER_ERROR, {
            position: 'top-right',
            autoClose: 2500,
          });
        }
      })
      .catch(err => console.log(err));
  };

  /* Filter Patient Current RX/OTC Medications */
  filterRxOtcPatientData = (event) => {
    var type = event.target.value;
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    axios
      .get(`${API_URL}/api/filter_current_rx_otc_med/?patient_id=${this.state.patient_id}&filter_type=${type}`, config)
      .then(response => {
        if (response.status === 200) {
          if (response.data.status == 200 || response.data.status == 204) {
            let responseData;
            if (response.data.data !== null) {
              responseData = response.data.data.map((data, index) => ({
                currRxOtcMedId: data.id,
                patient: data.patient,
                is_active: data.is_active,
                type: data.type,
                medication_name: data.medication_name,
                notes: data.notes,
              }));
            } else {
              responseData = [];
            }
            this.setState({ "rxMedInputList": responseData });
          } else if (response.data.status_code == 406) {
            localStorage.removeItem('token');
            localStorage.removeItem('user_name');

            localStorage.removeItem('adminCheck');
            localStorage.setItem('tokenExpired', true);

            const tokenStatus = localStorage.getItem('tokenExpired');
            if (tokenStatus) {
              localStorage.removeItem('tokenExpired');
              toast.error(TOKEN_EXPIRED, {
                position: 'top-right',
                autoClose: 2500,
              });
            }

            const isLogged = !!localStorage.getItem('token');
            if (!isLogged) {
              setTimeout(() => {
                this.props.history.push("/login");
              }, 2500);
            }
          }
        } else {
          toast.error(INTERNAL_SERVER_ERROR, {
            position: 'top-right',
            autoClose: 2500,
          });
        }
      })
      .catch(err => console.log(err));
  };

  // Handle Click event Of The Remove Button Of Current RX Medications
  handleRxMedRemoveClick = index => {
    const list = [...this.state.rxMedInputList];
    list.splice(index, 1);
    this.setState({
      rxMedInputList: list,
    });
  };

  // Handle Click Event Of The Add Button Of Current RX Medications
  handleRxMedAddClick = () => {
    const list = [...this.state.rxMedInputList];
    list.push({
      active: "",
      rexMedDrug: "",
      rexMedNotes: "",
      lastUpdated: "",
    });
    this.setState({
      rxMedInputList: list,
    });
  };

  handleCurrRxMedicationsRow = index => {
    const list = [...this.state.rxMedInputList];
    //console.log('Rx Medication Row' + index, list);
    if (list[index].rexMedDrug === '') {
      toast.error(VALID_RX_MED_DRUG, {
        position: 'top-right',
        autoClose: 3000,
      });
    } else if (list[index].rexMedNotes === '') {
      toast.error(VALID_RX_MED_NOTES, {
        position: 'top-right',
        autoClose: 3000,
      });
    }
    return false;
  };

  render() {
    const isLogged = !!localStorage.getItem('token');
    if (!isLogged) {
      return <Redirect to="/login" />;
    }

    return (
      <>
        <ToastContainer />
        <CommonHeader />
        <section id="wrapper">
          <CommonSidebar />
          <div className="row">
            <div className="col-sm-12 mb-4">
              <h3 className="text-uppercase font-weight-bold">
                Current RX/OTC Medications Taken Maintanenace Form
              </h3>
            </div>
            <div className="col-sm-12">
              <h3 className="text-blue mb-1">
                <i className="fa fa-user fa-fw user-icon mr-2"></i>
                <Link to={{
                  'pathname': '/patientcliprofile', 'state': {
                    'transaction_id': this.state.transaction_id,
                    'patient_id': this.state.patient_id, 'patient_name': this.state.patient_name,'data_added': this.state.data_added
                  }
                }}>
                  {this.state.patient_name}
                </Link>
              </h3>
              <hr />
            </div>
            <div className="col-sm-12">
              <div className="col-sm-3"></div>
              <div className="col-sm-6"></div>
              <div className="col-sm-3 custom-control">
                <select id="filterType" className="form-control" onChange={e => this.filterRxOtcPatientData(e)} name="filterType">
                  <option value="all">Please Choose To Filter</option>
                  <option value="OTC">Otc</option>
                  <option value="Rx">Rx</option>
                </select>
              </div>
              <div className="table-responsive">
                <table className="table RX-table">
                  <thead>
                    <tr>
                      <th>Active</th>
                      <th>Type<span className='error'>*</span></th>
                      <th>Drug Name and Strength<span className='error'>*</span></th>
                      <th>Notes<span className='error'>*</span></th>
                      <th>Created On<span className='error'></span></th>
                      <th>Last Updated<span className='error'></span></th>
                      <th>Updated By<span className='error'></span></th>
                      <th style={{ width: '50px' }}></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <div class="custom-control custom-checkbox pt-2">
                          <input defaultChecked type="checkbox" checked={this.state.rxOtcData.is_active} onChange={e => this.handleRxOtcDataChange(e)}
                            name="is_active" className="custom-control-input" id="rxOtcMediActive" />
                          <label class="custom-control-label font-weight-normal" for="rxOtcMediActive" ></label>
                        </div>
                      </td>
                      <td>
                        <div className="custom-control">
                          <select id="type" onChange={e => this.handleRxOtcDataChange(e)} value={this.state.rxOtcData.type}
                            className="form-control" name="type">
                            <option>Please Choose</option>
                            <option value="OTC">Otc</option>
                            <option value="Rx">Rx</option>
                          </select>
                        </div>
                      </td>
                      <td>
                        <input type="text" id="medication_name" name="medication_name" value={this.state.rxOtcData.medication_name} className="form-control"
                          onChange={e => this.handleRxOtcDataChange(e)} />
                      </td>
                      <td>
                        <input type="text" id="notes" name="notes" value={this.state.rxOtcData.notes} className="form-control"
                          onChange={e => this.handleRxOtcDataChange(e)} />
                      </td>
                      <td>
                        <input disabled type="text" className="form-control" readOnly />
                      </td>
                      <td>
                        <input type="text" className="form-control" readOnly />
                      </td>
                      <td>
                        <input type="text" className="form-control" readOnly />
                      </td>

                      <td className="d-flex justify-content-end">
                        <button type="button" className="btn btn-primary mt-1 btnControl btnsv"
                          onClick={() => this.handleCurrRxOtcMedSubmit()}>
                          <IconWithTooltip Icon={CheckIcon} text="Save Changes" />
                        </button>
                      </td>
                    </tr>
                    {this.state.rxMedInputList.length > 0 ? this.state.rxMedInputList.map((x, i) => {
                      return (
                        <tr key={i}>
                          <td>
                            <div class="custom-control custom-checkbox pt-2">
                              <input type="checkbox" onChange={(e, active) => this.handleRxOtcMedInputChange(e, i, active = true)} name="is_active"
                                checked={x.is_active} className="custom-control-input" id={"rxOtcMediActive_" + i} />
                              <label class="custom-control-label font-weight-normal" for={"rxOtcMediActive_" + i}></label>
                            </div>
                          </td>
                          <td>
                            <div className="custom-control">
                              <select id="type" onChange={e => this.handleRxOtcMedInputChange(e, i)} value={x.type} class="form-control" name="type">
                                <option>Please Choose</option>
                                <option value="OTC">Otc</option>
                                <option value="Rx">Rx</option>
                              </select>
                            </div>
                          </td>
                          <td>
                            <input type="text" name="medication_name" value={x.medication_name} className="form-control"
                              onChange={e => this.handleRxOtcMedInputChange(e, i)} />
                          </td>
                          <td>
                            <input type="text" name="notes" value={x.notes} className="form-control"
                              onChange={e => this.handleRxOtcMedInputChange(e, i)} />
                          </td>
                          <td>
                            <input type="text" name="created_on" value={dateFormat(x.created, "mm/dd/yyyy  HH:MM:ss")} className="form-control"
                              onChange={e => this.handleRxOtcMedInputChange(e, i)} readOnly />
                          </td>
                          <td>
                            <input type="text" name="modified_on" value={dateFormat(x.modified, "mm/dd/yyyy  HH:MM:ss")} className="form-control"
                              onChange={e => this.handleRxOtcMedInputChange(e, i)} readOnly />
                          </td>
                          <td>
                            <input type="text" name="updated_by" value={x.updated_by_user} className="form-control"
                              onChange={e => this.handleRxOtcMedInputChange(e, i)} readOnly />
                          </td>
                          <td className="d-flex justify-content-end">
                            <button id={"currRxOtcMed_" + i} style={{ "pointer-events": "none", "cursor": "not-allowed" }} type="button" className="btn btn-primary mt-1 btnControl btnsv update_button"
                              onClick={() => this.updCurrRxOtcMedSubmit(i)}>
                              <IconWithTooltip Icon={CheckIcon} text="Update Changes" />
                            </button>
                            {localStorage.getItem('adminCheck') === "true" ? (
                              <button type="button" className="btn btn-primary mt-1 btnControl btndel"
                                onClick={() => this.delCurrRxOtcMedData(x.currRxOtcMedId)}>
                                <IconWithTooltip Icon={DeleteIcon} text="Remove" />
                              </button>
                            ) : ''}
                          </td>
                        </tr>
                      );
                    }) : (
                      <tr>
                        <td colSpan="9">No records found</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </section>
        <CommonFooter />
      </>
    );
  }
}
