/*
 * TaskPage
 *
 *
 */

import React from 'react';

import CommonHeader from '../../components/CommonHeader';
import CommonSidebar from '../../components/CommonSidebar';
import CommonFooter from '../../components/CommonFooter';
import axios from 'axios';
import { Redirect,Link } from 'react-router-dom';

import 'bootstrap/dist/css/bootstrap.css';
import '../../containers/css/style_semantic.css';
import './style.css';


import { ToastContainer, toast } from 'react-toastify';
import { VALID_EDUCATION_DATA } from './constants';

import {
  API_URL,
} from '../../config';

export default class PastMedicalHistory extends React.Component {
 
  token = localStorage.getItem('token');
  config = {
      headers: { Authorization: `Bearer ${this.token}` },
  };  

  constructor(props) {
    super(props);
    this.state = {
      medicalHistory:'',
      flag:'',
      patient_id: this.props.location.state !== undefined ? this.props.location.state.patient_id: null,
      
      patient_name : this.props.location.state.patient_name,
      data_added : this.props.location.state !== undefined ? this.props.location.state.data_added : null,

    }
    
  }
  
   /* get past medical history method*/
   componentDidMount() {
    axios
      .get(`${API_URL}/api/past_medication_history/`+this.state.patient_id, this.config)
      .then(response => {
        if (response.data.status === 200 && response.status === 200) {  
          this.setState({
            medicalHistory: response.data.data.medical_history,
          });
        } 
        else if(response.data.status===204){
          this.setState({
            flag:'create',
          });
          
        }
        else if(response.status !== 200) {
          toast.error(VALID_EDUCATION_DATA, {
            position: 'top-right',
            autoClose: 3500,
          });
          
          return false;
        }
      })
      .catch(error => {
        console.log(error);
      });
  }

   /* create  past medical history method*/
   createPastMedicalHistory = () => {
    axios
      .post(`${API_URL}/api/past_medication_history/`,{'patient': this.state.patient_id,'medical_history':document.getElementById('payment_history').value}, this.config)
      .then(response => {
        if (response.data.status === 201 && response.status === 200) {
          toast.success('Successfully Created',{
            position:'top-right',
            autoClose:3500,
          });
          window.setTimeout(function(){
            window.location.reload();
          }, 3500);
          
          
        } else if(response.status !== 200) {
          toast.error(VALID_EDUCATION_DATA, {
            position: 'top-right',
            autoClose: 3500,
          });
          return false;
        }
      })
      .catch(error => {
        console.log(error);
      });
  }


   /* update  past medical history method*/
   updatePastMedicalHistory = () => {
    axios
      .put(`${API_URL}/api/past_medication_history/`+this.state.patient_id,{'patient': this.state.patient_id,'medical_history':document.getElementById('payment_history').value}, this.config)
      .then(response => {
        if (response.data.status === 200 && response.status === 200) {  
          toast.success('Successfully Updated', {
            position: 'top-right',
            autoClose: 3500,
          });
          window.setTimeout(function () {
            window.location.reload();
          }, 3500);
        } else if(response.status !== 200) {
          toast.error(VALID_EDUCATION_DATA, {
            position: 'top-right',
            autoClose: 3500,
          });
          return false;
        }
      })
      .catch(error => {
        console.log(error);
      });
  }


  

  
  render() {
    
    const isLogged = !!localStorage.getItem('token');
    if (!isLogged) {
      return <Redirect to="/login" />;
    }
    const flag=this.state.flag==='create'

    return (
      <>
        <ToastContainer />
        <CommonHeader />
        <section id="wrapper">
          <CommonSidebar />
          <div className="row">
            <div className="col-sm-12 mb-4">
                  <h3 className="text-uppercase font-weight-bold">
                  Past Medical History
                  </h3>
                </div>
                  <div className="col-sm-12">
                    <h3 className="text-blue mb-1">
                    <i className="fa fa-user fa-fw user-icon mr-2"></i>
                        <Link to={{
                      'pathname': '/patientcliprofile', 'state': {
                      'transaction_id': this.state.transaction_id,
                      'patient_id': this.state.patient_id, 'patient_name': this.state.patient_name,'data_added' : this.state.data_added
                    }
                     
                  }}>
                  {this.state.patient_name}
                  </Link>
                    </h3>
                    <hr/>
                  </div>
            <div className="col-sm-12">
              <div className="card">
                <div className="card-body bg-gray">
                  <textarea  rows="5" cols="145" class="form-control text-left " name="payment_history" id="payment_history" placeholder="Please Enter Patient Past Medical History"
                  defaultValue={this.state.medicalHistory}>
                    
                  </textarea>
                  
                </div>
                <div class="col-sm-14 text-right">
                {flag
                 
                 ?<button type="submit" class="btn btn-primary" onClick={ (evt) => this.createPastMedicalHistory() } > save </button> 
                 :<button type="submit" class="btn btn-primary" onClick={ (evt) => this.updatePastMedicalHistory() } > update </button>
                }
                  
                </div>
              </div>
            </div>
          </div>
        </section>
        
        <CommonFooter />
      </>
    );
  }
}
