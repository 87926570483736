/*
 * Deliveries
 *
 *
 */

import React from 'react';

import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import { Redirect, Link } from 'react-router-dom';


import {
  VALID_DEL_STREET_ADD,
  VALID_DEL_CITY,
  VALID_DEL_STATE,
  VALID_DEL_ZIPCODE,
  INVALID_DATA,
  SAVE_ADDRESS_DATA,
  STREET_ADDRESS_DATA,
  LOCATION_ADDRESS_DATA,
  CITY_ADDRESS_DATA,
  STATE_ADDRESS_DATA,
  ZIPCODE_ADDRESS_DATA,
  UPDATE_ADDRESS_DATA,
  DELETE_ADDRESS_DATA,

} from './constants';

import {
  API_URL,
} from '../../config';


import $ from 'jquery';

import { Accordion, Card, Button, Modal } from 'react-bootstrap';
import DeleteIcon from '@material-ui/icons/Delete';
import Moment from 'moment';

import IconWithTooltip from 'icon-with-tooltip';
import CheckIcon from '@material-ui/icons/Check';

import 'react-toastify/dist/ReactToastify.css';
import 'react-datepicker/dist/react-datepicker.css';

import 'bootstrap/dist/css/bootstrap.css';
import '../../containers/css/style_semantic.css';

import './style.css';
import './bootstrap.min.css'
import 'react-confirm-alert/src/react-confirm-alert.css';
import PastDeliveries from './PastDeliveries';

export default class Deliveries extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

      trackDeliveryInputList: [{
        deliveryStreetAddress: "",
        deliveryCity: "",
        deliveryState: "",
        deliveryZipCode: "",
        deliveryLocation: "",
      }],

      show: false,
      isFetchData: false,
      activeMenu: true,
      activePlus: false,
      activeMinus: true,
      showHideOpt: true,

      patient_id: this.props.patient_id !== undefined ? this.props.patient_id : null,

      scheduleDelivery: {
        shipping_date: '',
        delivery_date: '',
        delivery_method: '',
        shipping_method: '',
        shipping_location: '',
        new_patient: false,
        delivery_notes_shipment: '',
      },
      deliveryInputList: [
        {
          deliveryId: "",
          deliveryActive: "",
          deliveryPrimary: "",
          deliveryStreetAddress: "",
          deliveryCity: "",
          deliveryState: "",
          deliveryZipCode: "",
          deliveryLocation: "",
        },
      ],
      delAddress: {
        is_primary: false,
        is_active: false,
        street: '',
        city: '',
        state: '',
        zip_code: '',
        location: '',
        patient: '',
      },
    };

    this.myInitObject = null;

  }

  // today date
  todayDate = new Date().toISOString().slice(0, 10)

  // get token from loclastorage
  token = localStorage.getItem('token');
  config = {
    headers: { Authorization: `Bearer ${this.token}` },
  };

    
  
    handleAddDelAddUnSaved = () => {
      localStorage.removeItem('isPatientAddDelChanged');
      this.setState({
        'delAddress': {
          is_primary: false,
          is_active: false,
          street: '',
          city: '',
          state: '',
          zip_code: '',
          location: '',
          patient: '',
        }
      });
    };

    /* Handling Patient Delivery Address Data Changes */
    async handleAddDelAddChange(event) {
      let list = { ...this.state.delAddress };
      const { name, value } = event.target;
  
      if (name == 'is_active') {
        list[name] = !this.state.delAddress.is_active;
      } else if (name == 'is_primary') {
        list[name] = !this.state.delAddress.is_primary;
      } else {
        list[name] = value;
      }
  
      await this.setState({
        delAddress: list,
      });
  
      if (list['street'] != '' || list['city'] != '' || list['state'] != '' || list['zip_code'] != '' ||
        list['location'] != '' || list['is_primary'] == true || list['is_active'] == true) {
        localStorage.setItem('isPatientAddDelChanged', true);
      } else {
        localStorage.removeItem('isPatientAddDelChanged');
      }
    };


  /* Set Deliveries Tab After Switching */
  setDeliveryTab = () => {
    if (localStorage.getItem('currTab') == 'AllFillsHis') {
      $("#allFilesHis").attr('href', '#allFilesHistory');
      $("#refCallNotes").removeAttr('href');
      $("#eduAssessment").removeAttr('href');

      $("#schDelivery").removeAttr('href');
      $("#assCommunications").removeAttr('href');
      $("#medTherapy").removeAttr('href');

      $("#allFilesHis").addClass("active");
      $("#schDelivery").removeClass("active");
      $("#eduAssessment").removeClass("active");

      $("#refCallNotes").removeClass("active");
      $("#assCommunications").removeClass('active');
      $("#medTherapy").removeClass('active');
    } else if (localStorage.getItem('currTab') == 'refillCalNotes') {
      $("#refCallNotes").attr('href', '#refillCallNotes');
      $("#allFilesHis").removeAttr('href');
      $("#eduAssessment").removeAttr('href');

      $("#schDelivery").removeAttr('href');
      $("#assCommunications").removeAttr('href');
      $("#medTherapy").removeAttr('href');

      $("#refCallNotes").addClass("active");
      $("#medTherapy").removeClass("active");
      $("#schDelivery").removeClass("active");

      $("#eduAssessment").removeClass("active");
      $("#allFilesHis").removeClass("active");
      $("#assCommunications").removeClass('active');
    } else if (localStorage.getItem('currTab') == 'comm') {
      $("#assCommunications").attr('href', '#communications');
      $("#allFilesHis").removeAttr('href');
      $("#eduAssessment").removeAttr('href');

      $("#refCallNotes").removeAttr('href');
      $("#medTherapy").removeAttr('href');
      $("#schDelivery").removeAttr('href');

      $("#assCommunications").addClass("active");
      $("#schDelivery").removeClass("active");
      $("#allFilesHis").removeClass("active");

      $("#eduAssessment").removeClass("active");
      $("#medTherapy").removeClass("active");
      $("#refCallNotes").removeClass("active");
    } else if (localStorage.getItem('currTab') == 'therapy') {
      $("#medTherapy").attr('href', '#therapy');
      $("#allFilesHis").removeAttr('href');
      $("#assCommunications").removeAttr('href');

      $("#eduAssessment").removeAttr('href');
      $("#refCallNotes").removeAttr('href');
      $("#schDelivery").removeAttr('href');

      $("#medTherapy").addClass("active");
      $("#schDelivery").removeClass("active");
      $("#allFilesHis").removeClass("active");

      $("#assCommunications").removeClass("active");
      $("#eduAssessment").removeClass("active");
      $("#refCallNotes").removeClass("active");
    } else if (localStorage.getItem('currTab') == 'education') {
      $("#eduAssessment").attr('href', '#education');
      $("#allFilesHis").removeAttr('href');
      $("#assCommunications").removeAttr('href');

      $("#schDelivery").removeAttr('href');
      $("#refCallNotes").removeAttr('href');
      $("#medTherapy").removeAttr('href');

      $("#eduAssessment").addClass("active");
      $("#schDelivery").removeClass("active");
      $("#assCommunications").removeClass('active');

      $("#allFilesHis").removeClass("active");
      $("#refCallNotes").removeClass('active');
      $("#medTherapy").removeClass('active');
    }
  };

  /* Get Deliveries data */
  getDelivery = () => {
    if (localStorage.getItem('patAllFillsHisChanged') || localStorage.getItem('patRefillCallNotesChanged')
      || localStorage.getItem('patCommunicationsChanged') || localStorage.getItem('patTherapyInputChanged')
      || localStorage.getItem('isPatientEduAssChanged') || localStorage.getItem('isAssChanged')) {
      const confirmBox = window.confirm("You have unsaved changes! Do you really want to move to another tab?");
      if (confirmBox === true) {
        localStorage.setItem('prevTab', localStorage.getItem('currTab'));
        localStorage.setItem('currTab', 'Del');

        localStorage.removeItem('patAllFillsHisChanged');
        localStorage.removeItem('patRefillCallNotesChanged');
        localStorage.removeItem('patCommunicationsChanged');
        localStorage.removeItem('patTherapyInputChanged');
        localStorage.removeItem('isPatientEduAssChanged');
        localStorage.removeItem('isAssChanged')

        $("#schDelivery").attr('href', '#deliveries');
        $("#allFilesHis").removeAttr('href');
        $("#refCallNotes").removeAttr('href');
        $("#assCommunications").removeAttr('href');
        $("#medTherapy").removeAttr('href');
        $("#eduAssessment").removeAttr('href');
        $("#archivesAss").removeAttr('href');
        $("#oncoAssessments").removeAttr('href');
      } else {
        setTimeout(() => this.props.setTab(), 250);
      }
    } else {
      localStorage.setItem('prevTab', localStorage.getItem('currTab'));
      localStorage.setItem('currTab', 'Del');
      $("#schDelivery").attr('href', '#deliveries');
      $("#allFilesHis").removeAttr('href');
      $("#refCallNotes").removeAttr('href');
      $("#assCommunications").removeAttr('href');
      $("#medTherapy").removeAttr('href');
      $("#eduAssessment").removeAttr('href');
      $("#archivesAss").removeAttr('href');
      $("#oncoAssessments").removeAttr('href');
    }
    this.clearinput();
    axios
      .get(`${API_URL}/api/delivery_address/?patient_id=${this.props.patient_id}`, this.config)
      .then(response => {
        //console.log('List response Data', response.data.data);
        if (response.status == 200) {
          let responseData;
          let trackDeliveryData;

          if (response.data.data !== null) {
            responseData = response.data.data.map((data, index) => ({
              deliveryId: data.id,
              deliveryActive: data.is_active,
              deliveryPrimary: data.is_primary,
              deliveryStreetAddress: data.street,
              deliveryCity: data.city,
              deliveryState: data.state,
              deliveryZipCode: data.zip_code,
              deliveryLocation: data.location,
            }));

            trackDeliveryData = response.data.data.map((data, index) => ({
              deliveryStreetAddress: data.street,
              deliveryCity: data.city,
              deliveryState: data.state,
              deliveryZipCode: data.zip_code,
              deliveryLocation: data.location,
            }));
          } else {
            responseData = [];
            trackDeliveryData = [];
          }

          this.setState({ "deliveryInputList": responseData });
          this.setState({ "trackDeliveryInputList": trackDeliveryData });

        }
      })
      .catch(err => console.log(err));
  }

  clearinput = () => {
    this.setState({
      delAddress: {
        is_primary: false,
        is_active: false,
        street: '',
        city: '',
        state: '',
        zip_code: '',
        location: '',
        patient: '',
      }
    });
  }

  /* Method For Assessment Count After Component Rendering */
  componentDidMount() {
    
    localStorage.setItem('currTab', 'Del');
    localStorage.setItem('prevTab', 'Del');

    localStorage.removeItem('patAllFillsHisChanged');
    localStorage.removeItem('isPatientAddDelChanged');
    localStorage.removeItem('patRefillCallNotesChanged');

    localStorage.removeItem('patCommunicationsChanged');
    localStorage.removeItem('patTherapyInputChanged');

    localStorage.removeItem('isPatientEduAssChanged');
    localStorage.removeItem('refillReminderData');
    localStorage.removeItem('schDeliveryDataChanges');

     this.getDelivery();
     
  }

  
  /* Method For Logout */
  Logout = () => {
    localStorage.removeItem('token');
  };

  // Handle Click Event Of The Add Button Of Delivery
  handleDeliveryAddClick = () => {this.therapy = React.createRef();
    const list = [...this.state.deliveryInputList];
    list.push({
      deliveryPrimary: "",
      deliveryStreetAddress: "",
      deliveryCity: "",
      deliveryState: "",
      deliveryZipCode: "",
      deliveryLocation: "",
    });
    this.setState({
      deliveryInputList: list,
    });
  };
  
  /* Save Patient Delivery Address Data */
  handlePatDelAddrSave = () => {
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    this.state.delAddress.patient = this.state.patient_id;
    if (this.state.delAddress.street == '') {
      toast.error(STREET_ADDRESS_DATA, {
        position: 'top-right',
        autoClose: 3000,
      });
      return false;
    } else if (this.state.delAddress.location == '') {
      toast.error(LOCATION_ADDRESS_DATA, {
        position: 'top-right',
        autoClose: 3000,
      });
      return false;
    } else if (this.state.delAddress.city == '') {
      toast.error(CITY_ADDRESS_DATA, {
        position: 'top-right',
        autoClose: 3000,
      });
      return false;
    } else if (this.state.delAddress.state == '') {
      toast.error(STATE_ADDRESS_DATA, {
        position: 'top-right',
        autoClose: 3000,
      });
      return false;
    } else if (this.state.delAddress.zip_code == '') {
      toast.error(ZIPCODE_ADDRESS_DATA, {
        position: 'top-right',
        autoClose: 3000,
      });
      return false;
    }

    axios
      .post(`${API_URL}/api/delivery_address/`, this.state.delAddress, config)
      .then(response => {
        if (response.status === 200) {
          toast.success(SAVE_ADDRESS_DATA, {
            position: 'top-right',
            autoClose: 3000,
          });

          this.setState({
            delAddress: {
              is_primary: false,
              is_active: false,
              street: '',
              city: '',
              state: '',
              zip_code: '',
              location: '',
              patient: '',
            }
          });

          axios
            .get(`${API_URL}/api/delivery_address/?patient_id=${this.state.patient_id}`, config)
            .then(response => {
              if (response.status === 200) {
                let responseData = response.data.data.map((data, index) => ({
                  deliveryId: data.id,
                  deliveryActive: data.is_active,
                  deliveryPrimary: data.is_primary,
                  deliveryStreetAddress: data.street,
                  deliveryCity: data.city,
                  deliveryState: data.state,
                  deliveryZipCode: data.zip_code,
                  deliveryLocation: data.location,
                }));
                this.setState({ "deliveryInputList": responseData });
              }
            })
            .catch(err => console.log(err));
          return false;
        }
      })
      .catch(err => console.log(err));
  };

  /* Update Patient Delivery Address Data */
  updPatientDelAdd = (index) => {
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const list = [...this.state.deliveryInputList];
    const delPatDelAddr = {
      'is_primary': list[index].deliveryPrimary,
      'is_active': list[index].deliveryActive,
      'street': list[index].deliveryStreetAddress,
      'city': list[index].deliveryCity,
      'state': list[index].deliveryState,
      'zip_code': list[index].deliveryZipCode,
      'location': list[index].deliveryLocation,
      'patient': this.state.patient_id,
    };
    //console.log('List Values', list[index]);
    if (list[index].deliveryStreetAddress == '' || list[index].deliveryStreetAddress == null) {
      toast.error(STREET_ADDRESS_DATA, {
        position: 'top-right',
        autoClose: 3000,
      });
      return false;
    } else if (list[index].deliveryLocation == '' || list[index].deliveryLocation == null) {
      toast.error(LOCATION_ADDRESS_DATA, {
        position: 'top-right',
        autoClose: 3000,
      });
      return false;
    } else if (list[index].deliveryCity == '' || list[index].deliveryCity == null) {
      toast.error(CITY_ADDRESS_DATA, {
        position: 'top-right',
        autoClose: 3000,
      });
      return false;
    } else if (list[index].deliveryState == '' || list[index].deliveryState == null) {
      toast.error(STATE_ADDRESS_DATA, {
        position: 'top-right',
        autoClose: 3000,
      });
      return false;
    } else if (list[index].deliveryZipCode == '' || list[index].deliveryZipCode == null) {
      toast.error(ZIPCODE_ADDRESS_DATA, {
        position: 'top-right',
        autoClose: 3000,
      });
      return false;
    }

    axios
      .put(`${API_URL}/api/delivery_address/${list[index].deliveryId}`, delPatDelAddr, config)
      .then(response => {
        if (response.status === 200) {
          localStorage.removeItem('isPatientAddDelChanged');
          toast.success(UPDATE_ADDRESS_DATA, {
            position: 'top-right',
            autoClose: 3000,
          });
          return false;
        }
      })
      .catch(err => console.log(err));
  };

  /* Delete Patient Delivery Address Data */
  delPatientDelAdd = (index) => {
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    axios
      .delete(`${API_URL}/api/delivery_address/${index}`, config)
      .then(response => {
        if (response.status === 200) {
          toast.error(DELETE_ADDRESS_DATA, {
            position: 'top-right',
            autoClose: 3000,
          });

          axios
            .get(`${API_URL}/api/delivery_address/?patient_id=${this.state.patient_id}`, this.config)
            .then(response => {
              if (response.status === 200) {
                let responseData;
                if (response.data.data !== null) {
                  responseData = response.data.data.map((data, index) => ({
                    deliveryId: data.id,
                    deliveryActive: data.is_active,
                    deliveryPrimary: data.is_primary,
                    deliveryStreetAddress: data.street,
                    deliveryCity: data.city,
                    deliveryState: data.state,
                    deliveryZipCode: data.zip_code,
                    deliveryLocation: data.location,
                  }));
                } else {
                  responseData = [];
                }

                //console.log('Response Data', responseData);
                this.setState({ "deliveryInputList": responseData });
              }
            })
            .catch(err => console.log(err));
          return false;
        }
      })
      .catch(err => console.log(err));
  };


  // Handle Click Event Of The Remove Button Of Delivery
  handleDeliveryRemoveClick = index => {
    const list = [...this.state.deliveryInputList];
    list.splice(index, 1);
    this.setState({
      deliveryInputList: list,
    });
  };

  // Handle On Change Event Of Patient Address Delivery List
  async handleDeliveryInputChange(e, index) {
    const { name, value } = e.target;
    const list = [...this.state.deliveryInputList];

    if (name == "deliveryPrimary") {
      list[index][name] = !this.state.deliveryInputList[index].deliveryPrimary;
    } else if (name == "deliveryActive") {
      list[index][name] = !this.state.deliveryInputList[index].deliveryActive;
    } else {
      list[index][name] = value;
    }

    await this.setState({ deliveryInputList: list, });

    for (var i = 0; i < list.length; i++) {
      if ((name !== 'deliveryPrimary' && name !== 'deliveryActive') && ((this.state.trackDeliveryInputList[i].deliveryStreetAddress !== list[i]['deliveryStreetAddress'])
        || (this.state.trackDeliveryInputList[i].deliveryCity !== list[i]['deliveryCity']) ||
        (this.state.trackDeliveryInputList[i].deliveryState !== list[i]['deliveryState'])
        || (list[i].deliveryZipCode) || (list[i].deliveryLocation))) {
        localStorage.setItem('isPatientAddDelChanged', true);
        break;
      } else {
        localStorage.removeItem('isPatientAddDelChanged');
      }
    }
  };

  handleChangePrimaryAddress(e, id) {
    axios
      .post(`${API_URL}/api/toggle_delivery_address_primary/`, { 'delivery_address_id': id }, this.config)
      .then(response => {
        if (response.data.status === 200 && response.status === 200) {
          this.getDelivery();
          toast.success('Successfully Updated', {
            position: 'top-right',
            autoClose: 3500,
          });
        } else if (response.status !== 200) {
          toast.error(INVALID_DATA, {
            position: 'top-right',
            autoClose: 3500,
          });
          return false;
        }
      })
      .catch(error => {
        console.log(error);
      });

  }

  handleChangeActive(e, id) {
    axios
      .post(`${API_URL}/api/toggle_delivery_address_primary/`, { 'is_active': id }, this.config)
      .then(response => {
        if (response.data.status === 200 && response.status === 200) {
          this.getDelivery();
          toast.success(response.data.message, {
            position: 'top-right',
            autoClose: 3500,
          });
        } else if (response.status !== 200) {
          toast.error(INVALID_DATA, {
            position: 'top-right',
            autoClose: 3500,
          });
          return false;
        }
      })
      .catch(error => {
        console.log(error);
      });

  }

  // Handle Validations For Deliveries Rows Input Fields
  handleCurrDeliveriesRow = index => {
    const list = [...this.state.deliveryInputList];
    //console.log('Communication Rows', list[index]);
    if (list[index].deliveryStreetAddress === '') {
      toast.error(VALID_DEL_STREET_ADD, {
        position: 'top-right',
        autoClose: 3000,
      });
      return false;
    } else if (list[index].deliveryCity === '') {
      toast.error(VALID_DEL_CITY, {
        position: 'top-right',
        autoClose: 3000,
      });
      list[index].textInput.focus();
      return false;
    } else if (list[index].deliveryState === '') {
      toast.error(VALID_DEL_STATE, {
        position: 'top-right',
        autoClose: 3000,
      });
      return false;
    } else if (list[index].deliveryZipCode === '') {
      toast.error(VALID_DEL_ZIPCODE, {
        position: 'top-right',
        autoClose: 3000,
      });
      return false;
    }
  };

  render() {
    const isLogged = !!localStorage.getItem('token');
    if (!isLogged) {
      return <Redirect to="/login" />;
    }
    let mbdDatas = {
      columns: this.state.columns,
      rows: this.state.rowData
    }

    const { errors } = this.state;
    return (
      <>
        
          <div id="deliveries" className="container-fluid tab-pane fade">
                    <br />
                    <div className="row">
                      <div className="col-md-6">
                        <h3>Manage Patient Address Delivery Options</h3>
                      </div>
                    </div>
                    <div className="table-responsive mt-3">
                      <table className="table">
                        <thead>
                          <tr>
                            <th style={{ width: '10%' }}>Primary</th>
                            <th style={{ width: '10%' }}>Active</th>
                            <th style={{ width: '25%' }}>Street Address<span className='error'>*</span></th>
                            <th style={{ width: '10%' }}>City<span className='error'>*</span></th>
                            <th style={{ width: '10%' }}>State<span className='error'>*</span></th>
                            <th style={{ width: '10%' }}>Zip Code<span className='error'>*</span></th>
                            <th>Location<span className='error'>*</span></th>
                            <th style={{ width: '10%' }}></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="text-left" style={{ width: '10%' }}>
                              <div className="custom-control custom-checkbox">
                                <input type="checkbox" className="custom-control-input" id="isPrimary" checked={this.state.delAddress.is_primary}
                                  name="is_primary" value={this.state.delAddress.is_primary} onChange={e => this.handleAddDelAddChange(e)} />
                                <label className="custom-control-label font-weight-normal" htmlFor="isPrimary"></label>
                              </div>
                            </td>
                            <td className="text-left" style={{ width: '10%' }}>
                              <div className="custom-control custom-checkbox">
                                <input type="checkbox" className="custom-control-input" id="isDelAddrActive" checked={this.state.delAddress.is_active}
                                  name="is_active" value={this.state.delAddress.is_active} onChange={e => this.handleAddDelAddChange(e)} />
                                <label className="custom-control-label font-weight-normal" htmlFor="isDelAddrActive"></label>
                              </div>
                            </td>
                            <td style={{ width: '25%' }}>
                              <input type="text" className="form-control" name="street"
                                value={this.state.delAddress.street} onChange={e => this.handleAddDelAddChange(e)} />
                            </td>
                            <td style={{ width: '10%' }}>
                              <input type="text" className="form-control" name="city" value={this.state.delAddress.city}
                                onChange={e => this.handleAddDelAddChange(e)} />
                            </td>
                            <td style={{ width: '10%' }}>
                              <input type="text" className="form-control" name="state" value={this.state.delAddress.state}
                                onChange={e => this.handleAddDelAddChange(e)} />
                            </td>
                            <td style={{ width: '10%' }}>
                              <input type="text" className="form-control" name="zip_code" value={this.state.delAddress.zip_code}
                                onChange={e => this.handleAddDelAddChange(e)} />
                            </td>
                            <td>
                              <select className="form-control" name="location" value={this.state.delAddress.location}
                                onChange={e => this.handleAddDelAddChange(e)}>
                                <option value="">Please Choose</option>
                                <option value="Home">Home</option>
                                <option value="Caregiver">Caregiver</option>
                                <option value="Work">Work</option>
                                <option value="Other">Other</option>
                              </select>
                            </td>
                            <td className="d-flex justify-content-end">
                              <button type="button" className="btn btn-primary mt-1 btnControl btnsv"
                                onClick={() => this.handlePatDelAddrSave()}>
                                <IconWithTooltip
                                  Icon={CheckIcon}
                                  text="Save Changes"
                                />
                              </button>
                            </td>
                          </tr>
                          {this.state.deliveryInputList.length > 0 ? this.state.deliveryInputList.map((x, i) => {
                            return (
                              <tr key={i}>
                                <td className="text-left" style={{ width: '10%' }}>
                                  <div className="custom-control custom-checkbox">
                                    <input type="checkbox" className="custom-control-input" id={"Primary_" + i} checked={x.deliveryPrimary}
                                      name="deliveryPrimary" value={x.deliveryPrimary} onChange={e => this.handleDeliveryInputChange(e, i)} onClick={e => this.handleChangePrimaryAddress(e, x.deliveryId)} />
                                    <label className="custom-control-label font-weight-normal" htmlFor={"Primary_" + i}></label>
                                  </div>
                                </td>
                                <td className="text-left" style={{ width: '10%' }}>
                                  <div className="custom-control custom-checkbox">
                                    <input type="checkbox" className="custom-control-input" id={"isDActive_" + i} checked={x.deliveryActive}
                                      name="deliveryActive" value={x.deliveryActive} onChange={e => this.handleDeliveryInputChange(e, i)} onClick={e => this.handleChangeActive(e, x.deliveryId)} />
                                    <label className="custom-control-label font-weight-normal" htmlFor={"isDActive_" + i}></label>
                                  </div>
                                </td>
                                <td style={{ width: '25%' }}>
                                  <input type="text" className="form-control"
                                    name="deliveryStreetAddress" value={x.deliveryStreetAddress} onChange={e => this.handleDeliveryInputChange(e, i)} />
                                </td>
                                <td style={{ width: '10%' }}>
                                  <input type="text" className="form-control"
                                    name="deliveryCity" value={x.deliveryCity} onChange={e => this.handleDeliveryInputChange(e, i)} />
                                </td>
                                <td style={{ width: '10%' }}>
                                  <input type="text" className="form-control"
                                    name="deliveryState" value={x.deliveryState} onChange={e => this.handleDeliveryInputChange(e, i)} />
                                </td>
                                <td style={{ width: '10%' }}>
                                  <input type="text" className="form-control"
                                    name="deliveryZipCode" value={x.deliveryZipCode} onChange={e => this.handleDeliveryInputChange(e, i)} />
                                </td>
                                <td>
                                  <select className="form-control" name="deliveryLocation" value={x.deliveryLocation} onChange={e => this.handleDeliveryInputChange(e, i)}>
                                    <option value="">Please Choose</option>
                                    <option value="Home">Home</option>
                                    <option value="Caregiver">Caregiver</option>
                                    <option value="Work">Work</option>
                                    <option value="Other">Other</option>
                                  </select>
                                </td>
                                <td className="d-flex justify-content-end">
                                  <button type="button" className="btn btn-primary mt-1 btnControl btnsv"
                                    onClick={() => this.updPatientDelAdd(i)}>
                                    <IconWithTooltip
                                      Icon={CheckIcon}
                                      text="Save Changes"
                                    />
                                  </button>
                                  {localStorage.getItem('adminCheck') === "true" ? (
                                    <button type="button" className="btn btn-primary mt-1 btnControl btndel"
                                      onClick={() => this.delPatientDelAdd(x.deliveryId)}>
                                      <IconWithTooltip
                                        Icon={DeleteIcon}
                                        text="Remove"
                                      />
                                    </button>) : ''}
                                </td>
                              </tr>
                            );
                          }) : (
                            <tr>
                              <td colSpan="6"><b>No Patient Delivery Address Found</b></td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                    <br></br>
                    <div>
                      <PastDeliveries patient_id = {this.state.patient_id}/>
                    </div>
                    
          </div>
                            
      </>
    );
  }
}