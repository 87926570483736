/*
 * TaskPage
 *
 *
 */

import React from 'react';

import CommonHeader from '../../components/CommonHeader';
import CommonSidebar from '../../components/CommonSidebar';
import CommonFooter from '../../components/CommonFooter';

import axios from 'axios';
import { Redirect } from 'react-router-dom';

import { ToastContainer, toast } from 'react-toastify';
import Moment from 'moment';

import { TOKEN_EXPIRED, INTERNAL_SERVER_ERROR, } from './constants';
import {
	API_URL,
} from '../../config';

import 'bootstrap/dist/css/bootstrap.css';
import '../../containers/css/style_semantic.css';
import './style.css';

export default class PatientsProfile extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			patientProData: {},
			patient_id: this.props.location.state !== undefined ? this.props.location.state.patient_id : null,
			patient_name: this.props.location.state !== undefined ? this.props.location.state.patient_name : null,
		}
	}

	/* Method For Patient Details After Component Rendering */
	componentDidMount() {
		const token = localStorage.getItem('token');
		const config = {
			headers: { Authorization: `Bearer ${token}` },
		};

		axios
			.get(`${API_URL}/api/api-patient/${this.state.patient_id}`, config)
			.then(response => {
				//console.log('Res Status', response.status);
				//console.log('Res Data Status', response.data.status);
				if (response.status == 200) {
					if (response.data.status == 200) {
						this.setState({ "patientProData": response.data.data });
					} else if (response.data.status_code == 406) {
						localStorage.removeItem('token');
						localStorage.removeItem('user_name');

						localStorage.removeItem('adminCheck');
						localStorage.setItem('tokenExpired', true);

						const tokenStatus = localStorage.getItem('tokenExpired');
						if (tokenStatus) {
							localStorage.removeItem('tokenExpired');
							toast.error(TOKEN_EXPIRED, {
								position: 'top-right',
								autoClose: 2500,
							});
						}

						const isLogged = !!localStorage.getItem('token');
						if (!isLogged) {
							setTimeout(() => {
								this.props.history.push("/login");
							}, 2500);
						}
					}
				} else {
					toast.error(INTERNAL_SERVER_ERROR, {
						position: 'top-right',
						autoClose: 2500,
					});
				}

				//console.log('Res Data Data', this.state.patientProData);
			})
			.catch(error => {
				console.log(error);
			});
	};

	render() {
		const isLogged = !!localStorage.getItem('token');
		if (!isLogged) {
			return <Redirect to="/login" />;
		}

		return (
			<>
				<ToastContainer />
				<CommonHeader />
				<section id="wrapper">
					<CommonSidebar />
					<div className="row">
						<div className="col-sm-12 mb-4">
							<h2 class="text-uppercase">
								Patient Profile
							</h2>
						</div>
						<div className="col-sm-12">
							<h3 className="text-blue mb-1">
								<i className="fa fa-user fa-fw user-icon mr-2"></i>
								{this.state.patient_name}
							</h3>
							<hr />
						</div>
						<div className="card w-100">
							<div className="card-body row">
								<div className="col-sm-6">
									<div className="mb-4">
										<span className="font-weight-bold">MRN : </span>
										<span>
											{this.state.patientProData.mrn != '' ? this.state.patientProData.mrn : ''}
										</span>
									</div>
									<div className="mb-4">
										<span className="font-weight-bold"> Date Imported :</span>
										<span>
											{this.state.patientProData.data_added != '' ? Moment(this.state.patientProData.data_added).format('MM/DD/YYYY  H:m:s') : ''}
										</span>
									</div>
									<div className="mb-4">
										<span className="font-weight-bold">DOB : </span>
										<span>
											{this.state.patientProData.dob != '' ? Moment(this.state.patientProData.dob).format('MM/DD/YYYY') : ''}
										</span>
									</div>
									<div className="mb-4">
										<span className="font-weight-bold">Age : </span>
										<span>36 </span>
									</div>
									<div className="mb-4">
										<span className="font-weight-bold">Deceased : </span>
										<span>
											{this.state.patientProData.deceased == true ? "True" : "False"}
										</span>
									</div>
									<div className="mb-4">
										<span className="font-weight-bold">Active : </span>
										<span>
											{this.state.patientProData.active == true ? "True" : "False"}
										</span>
									</div>
								</div>

								<div className="col-sm-6">
									<div className="mb-4">
										<span className="font-weight-bold">Height : </span>
										<span>
											{this.state.patientProData.patient_height != '' ? this.state.patientProData.patient_height : ''}
										</span>
									</div>
									<div className="mb-4">
										<span className="font-weight-bold">Weight : </span>
										<span>
											{this.state.patientProData.patient_weight != '' ? this.state.patientProData.patient_weight : ''}
										</span>
									</div>
									<div className="mb-4">
										<span className="font-weight-bold">Gender : </span>
										<span>
											{this.state.patientProData.gender != '' ? this.state.patientProData.gender : ''}
										</span>
									</div>
									<div className="mb-4">
										<span className="font-weight-bold">Phone : </span>
										<span>
											{this.state.patientProData.patient_phone != '' ? this.state.patientProData.patient_phone : ''}
										</span>
									</div>
									<div className="mb-4">
										<span className="font-weight-bold">Cell : </span>
										<span>
											{this.state.patientProData.patient_cell != '' ? this.state.patientProData.patient_cell : ''}
										</span>
									</div>
									<div className="mb-4">
										<span className="font-weight-bold">Patient Email : </span>
										<span>
											{this.state.patientProData.patient_email != '' ? this.state.patientProData.patient_email : ''}
										</span>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="col-sm-12 mt-4 section-sepration">
						<div class="table-responsive">
							<table class="table RX-table">
								<thead>
									<tr>
										<th>High Risk Conditions</th>
										<th>Notes</th>
										<th>Last Updated</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>
											<select novalidate="" class="form-control" name="patient_output">
												<option value="0">Please Select Status</option>
												<option value="Patient Agrees To Counselling And Program">Patient Agrees To Counselling And Program</option>
												<option value="Patient OPTS out If The Program">Patient OPTS out If The Program</option>
												<option value="Patient Agrees To Counselling Only">Patient Agrees To Counselling Only</option>
											</select>
										</td>
										<td>
											<input type="text" class="form-control"
												name="rxTherapy" />
										</td>
										<td>
											<input type="text" class="form-control"
												name="rxTherapy" />
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>

					<hr />

					<div className="col-sm-12 mt-4 section-sepration">
						<div class="table-responsive">
							<table class="table RX-table">
								<thead>
									<tr>
										<th>Allergies Drug/Food</th>
										<th>Notes</th>
										<th>Last Updated</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>
											<select novalidate="" class="form-control" name="patient_output">
												<option value="0">Please Select Status</option>
												<option value="Patient Agrees To Counselling And Program">Patient Agrees To Counselling And Program</option>
												<option value="Patient OPTS out If The Program">Patient OPTS out If The Program</option>
												<option value="Patient Agrees To Counselling Only">Patient Agrees To Counselling Only</option>
											</select>
										</td>
										<td>
											<input type="text" class="form-control"
												name="rxTherapy" />
										</td>
										<td>
											<input type="text" class="form-control"
												name="rxTherapy" />
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>


					<div className="col-sm-12 mt-4 section-sepration">
						<div class="table-responsive">
							<table class="table RX-table">
								<thead>
									<tr>
										<th>Comborbid Condition</th>
										<th>Notes</th>
										<th>Last Updated</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>
											<select novalidate="" class="form-control" name="patient_output">
												<option value="0">Please Select Status</option>
												<option value="Patient Agrees To Counselling And Program">Patient Agrees To Counselling And Program</option>
												<option value="Patient OPTS out If The Program">Patient OPTS out If The Program</option>
												<option value="Patient Agrees To Counselling Only">Patient Agrees To Counselling Only</option>
											</select>
										</td>
										<td>
											<input type="text" class="form-control"
												name="rxTherapy" />
										</td>
										<td>
											<input type="text" class="form-control"
												name="rxTherapy" />
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
					{/* <div className="col-sm-12">
			  <div class="table-responsive">
				  <table class="table RX-table">
					<thead>
					  <tr>
						<th>High Risks Conditions</th>
						<th>Notes</th>
						<th>Last Updated</th>
					  </tr>
					</thead>
					<tbody>
					  <tr>
						<td>
						  <div class="custom-control custom- text-left">
							<select noValidate class="form-control" name="patient_output">
							  <option value="0">Please Select Status</option>
							  <option value="Elderly > 65">Elderly > 65</option>
							  <option value="Inappropriate Medication Usage">Inappropriate Medication Usage</option>
							  <option value="Narrow Therapeutic List Medication">Narrow Therapeutic List Medication</option>
							  <option value="None Identified">None Identified</option>
							  <option value="Patients With Positive Hepatitis">Patients With Positive Hepatitis</option>
							  <option value="Patients With Positive TB">Patients With Positive TB</option>
							  <option value="Pediatric">Pediatric</option>
							  <option value="Pregnant Of BreastFeeding">Pregnant Of BreastFeeding</option>
							  <option value="Taking Rems Medication">Taking Rems Medication</option>
							</select>
						  </div>
						</td>
						<td>
						<textarea class="form-control" cols="50" name="rxTherapy"></textarea>
						</td>
						<td>
						  <input type="text" class="form-control"
						name="orgTherapy"/>
						</td>
					  </tr>
					</tbody>
				  </table>
				</div>

				<div class="table-responsive">
				  <table class="table RX-table">
					<thead>
					  <tr>
						<th>Allergies Drug/Food</th>
						<th>Notes</th>
						<th>Last Updated</th>
					  </tr>
					</thead>
					<tbody>
					  <tr>
						<td>
						  <div class="custom-control custom- text-left">
							<input type="text" class="form-control" name="rxTherapy" />
						  </div>
						</td>
						<td>
							<textarea class="form-control" cols="50" name="rxTherapy"></textarea>
						</td>
						<td>
						  <input type="text" class="form-control"
						name="orgTherapy"/>
						</td>
					  </tr>
					</tbody>
				  </table>
				</div>

			<div class="table-responsive">
			  <table class="table RX-table">
				<thead>
				  <tr>
					<th>Comborid Condition</th>
					<th>Notes</th>
					<th>Last Updated</th>
				  </tr>
				</thead>
				<tbody>
				  <tr>
					<td>
					  <div class="custom-control custom- text-left">
						<input type="text" class="form-control" name="rxTherapy" />
					  </div>
					</td>
					<td>
						<textarea class="form-control" cols="50" name="rxTherapy"></textarea>
					</td>
					<td>
					  <input type="text" class="form-control"
					name="orgTherapy"/>
					</td>
				  </tr>
				</tbody>
			  </table>
			</div>
		  </div> */ }
				</section>
				<CommonFooter />
			</>
		);
	}
}
