/*
 * Past Deliveries
 *
 *
 */

import React from 'react';


import { MDBDataTable } from 'mdbreact';
import axios from 'axios';
import { Redirect, Link } from 'react-router-dom';
import { Accordion, Card, Button, Modal } from 'react-bootstrap';
import Moment from 'moment';

import {
  API_URL,
} from '../../config';



import 'bootstrap/dist/css/bootstrap.css';
import '../../containers/css/style_semantic.css';

import './style.css';
import './bootstrap.min.css'
import 'react-confirm-alert/src/react-confirm-alert.css';

export default class Deliveries extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [
        {
          label: 'Shipping Date',
          field: 'ShippingDate',
          sort: 'asc',
          width: 150,
        },
        {
          label: 'Delivery Date',
          field: 'DeliveryDate',
          sort: 'asc',
          width: 270,
        },
        {
          label: 'Delivery Method',
          field: 'DeliveryMethod',
          sort: 'asc',
          width: 200,
        },
        {
          label: 'Shipping Method',
          field: 'ShippingMethod',
          sort: 'asc',
          width: 200,
        },
        {
          label: 'New Patient',
          field: 'NewPatient',
          sort: 'asc',
          width: 100,
        },
        {
          label: 'Shipping Notes',
          field: 'ShippingNotes',
          sort: 'asc',
          width: 150,
        },
        {
          label: 'Shipping Location',
          field: 'ShippingLocation',
          sort: 'asc',
          width: 150,
        },
        {
          label: 'Action',
          field: 'Action',
          sort: 'asc',
          width: 150,
        },
      ],

      showPastDelModal: false,
      rowData: [],
     
      trackDeliveryInputList: [{
        deliveryStreetAddress: "",
        deliveryCity: "",
        deliveryState: "",
        deliveryZipCode: "",
        deliveryLocation: "",
      }],

      show: false,
      isFetchData: false,
      activeMenu: true,
      activePlus: false,
      activeMinus: true,
      showHideOpt: true,


      patient_id: this.props.patient_id !== undefined ? this.props.patient_id : null,

      scheduleDelivery: {
        shipping_date: '',
        delivery_date: '',
        delivery_method: '',
        shipping_method: '',
        shipping_location: '',
        new_patient: false,
        delivery_notes_shipment: '',
      },
      deliveryInputList: [
        {
          deliveryId: "",
          deliveryActive: "",
          deliveryPrimary: "",
          deliveryStreetAddress: "",
          deliveryCity: "",
          deliveryState: "",
          deliveryZipCode: "",
          deliveryLocation: "",
        },
      ],
      delAddress: {
        is_primary: false,
        is_active: false,
        street: '',
        city: '',
        state: '',
        zip_code: '',
        location: '',
        patient: '',
      },
    };

    this.myInitObject = null;
    this.getAllPastScheduleDelivery = this.getAllPastScheduleDelivery.bind(this)
  }

  // today date
  todayDate = new Date().toISOString().slice(0, 10)

  // get token from loclastorage
  token = localStorage.getItem('token');
  config = {
    headers: { Authorization: `Bearer ${this.token}` },
  };

    /*Get All past Schedule Delivery*/
    getAllPastScheduleDelivery = () => {
      axios
        .get(`${API_URL}/api/schedule_delivery/?patient_id=${this.props.patient_id}`, this.config)
        .then(response => {
          //console.log('Response Status Data 1', response.data.data);
          if (response.status === 200) {
            let responseData = response.data.data;
            //console.log('Res Data', responseData);
            for (var i = 0; i < responseData.length; i++) {
  
              if (responseData[i].delivery_method == 1) {
                responseData[i].delivery_method = 'Pickup';
              } else if (responseData[i].delivery_method == 5) {
                responseData[i].delivery_method = 'Delivery';
              } else {
                responseData[i].delivery_method = 'Same Day Delivery';
              }
  
              if (responseData[i].shipping_method == 10) {
                responseData[i].shipping_method = 'Standard Shipping';
              } else if (responseData[i].shipping_method == 20) {
                responseData[i].shipping_method = 'Saturday';
              } else {
                responseData[i].shipping_method = 'Priority Overnight';
              }
  
              // if (responseData[i].shipping_location == 1) {
              //   responseData[i].shipping_location = 'Home';
              // } else if (responseData[i].shipping_location == 5) {
              //   responseData[i].shipping_location = 'Work';
              // } else if (responseData[i].shipping_location == 10) {
              //   responseData[i].shipping_location = 'Caregiver';
              // } else {
              //   responseData[i].shipping_location = 'Other';
              // }
            }
  
            let res = responseData.filter(past_delivery_date => new Date(past_delivery_date.delivery_date).setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0)).map((data, index) => (
              {
                Sno: index + 1,
                ShippingDate: Moment(data.shipping_date).format('MM/DD/YYYY'),
                DeliveryDate: Moment(data.delivery_date).format('MM/DD/YYYY'),
                ShippingNotes: data.delivery_notes_shipment,
                DeliveryMethod: data.delivery_method,
                ShippingMethod: data.shipping_method,
                ShippingLocation: data.shipping_location,
                NewPatient: data.new_patient == true ? 'Yes' : 'No',
                Action: <Button onClick={() => { this.showPastDeliveryAddrModal(data.id) }} className="f-12 nowrap">
                  <i className="fa fa-eye" aria-hidden="true"></i>
                </Button>,
              }));
            this.setState({ rowData: res });
          }
        })
        .catch(error => {
          console.log(error);
        });
    }
  
    /* Close Modal Pop Up Of Past Delivery Patient Address Details */
    handlePastDelModalClose = () => {
      this.setState({ showPastDelModal: false, });
    };
  
    /* Get Past Delivery Address Details */
    showPastDeliveryAddrModal = id => {
      this.setState({ showPastDelModal: true, });
      axios
        .get(`${API_URL}/api/schedule_delivery_get_update/${id}`, this.config)
        .then(res => {
          res.data.data[0].shipping_date = Moment(res.data.data[0].shipping_date).format('MM/DD/YYYY');
          res.data.data[0].delivery_date = Moment(res.data.data[0].delivery_date).format('MM/DD/YYYY');
  
          if (res.data.data[0].delivery_method == '1') {
            res.data.data[0].delivery_method = 'Pickup';
          } else if (res.data.data[0].delivery_method == '5') {
            res.data.data[0].delivery_method = 'Delivery';
          } else if (res.data.data[0].delivery_method == '10') {
            res.data.data[0].delivery_method = 'Same Day Delivery';
          }
  
          if (res.data.data[0].shipping_method == '10') {
            res.data.data[0].shipping_method = 'Standard Shipping';
          } else if (res.data.data[0].shipping_method == '20') {
            res.data.data[0].shipping_method = 'Saturday';
          } else if (res.data.data[0].shipping_method == '30') {
            res.data.data[0].shipping_method = 'Priority Overnight';
          }
  
          if (res.data.data[0].shipping_location == '1') {
            res.data.data[0].shipping_location = 'Home';
          } else if (res.data.data[0].shipping_location == '5') {
            res.data.data[0].shipping_location = 'Work';
          } else if (res.data.data[0].shipping_location == '10') {
            res.data.data[0].shipping_location = 'Caregiver';
          } else if (res.data.data[0].shipping_location == '15') {
            res.data.data[0].shipping_location = 'Other';
          }
  
          if (res.data.data[0].new_patient == true) {
            res.data.data[0].new_patient = 'Yes';
          } else if (res.data.data[0].new_patient == false) {
            res.data.data[0].new_patient = 'No';
          }
  
          this.setState({ scheduleDelivery: res.data.data[0] });
          //console.log('Schedule Delivery', this.state.scheduleDelivery);
        })
        .catch(error => {
          console.log(error);
        });
    };
 

  /* Method For Assessment Count After Component Rendering */
  componentDidMount() {
     this.getAllPastScheduleDelivery();
  }

  
  /* Method For Logout */
  Logout = () => {
    localStorage.removeItem('token');
  };


  render() {
    const isLogged = !!localStorage.getItem('token');
    if (!isLogged) {
      return <Redirect to="/login" />;
    }
    let mbdDatas = {
      columns: this.state.columns,
      rows: this.state.rowData
    }

    const { errors } = this.state;
    return (
      <>
        
          <div>

                    <br></br>
                    <div>
                      <h3>Past Schedule Delivery Patient Address</h3>
                    </div>
                    <div className="tab-content">
                      <div id="Archives" className="container-fluid tab-pane fade active in px-0">
                        <div className="table-responsive">
                          <MDBDataTable
                            striped
                            bordered
                            small
                            hover
                            searching={true}
                            data={mbdDatas}
                          />
                        </div>
                      </div>
                      <Modal show={this.state.showPastDelModal} onHide={this.handlePastDelModalClose} backdrop="static">
                        <Modal.Header closeButton>
                          <Modal.Title> Past Schedule Delivery Patient Address </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <table className="table">
                            <thead>
                              <tr>
                                <th>Shipping Date</th>
                                <th>Delivery Date</th>
                                <th>Delivery Methods</th>
                                <th>Shipping Methods</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>
                                  {this.state.scheduleDelivery.shipping_date}
                                </td>
                                <td>
                                  {this.state.scheduleDelivery.delivery_date}
                                </td>
                                <td>
                                  {this.state.scheduleDelivery.delivery_method}
                                </td>
                                <td>
                                  {this.state.scheduleDelivery.shipping_method}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <table className="table">
                            <thead>
                              <tr>
                                <th>New Patient</th>
                                <th>Delivery Notes Shipment</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>
                                  {this.state.scheduleDelivery.new_patient}
                                </td>
                                <td>
                                  {this.state.scheduleDelivery.delivery_notes_shipment}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </Modal.Body>
                        <Modal.Footer>
                          <Button variant="secondary" onClick={this.handlePastDelModalClose}>
                            Close
                          </Button>
                        </Modal.Footer>
                      </Modal>
                    </div>
                  </div>
                            
      </>
    );
  }
}