/*
 * Day FollowUp
 */

import React from 'react';

import CommonHeader from '../../components/CommonHeader';
import CommonSidebar from '../../components/CommonSidebar';
import CommonFooter from '../../components/CommonFooter';
import dateFormat from 'dateformat';
import { Modal, Button } from 'react-bootstrap';
import Moment from 'moment';
import AdversePopup from './AdverseEvents';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { Redirect, Link } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.css';
import '../../containers/css/style_semantic.css';
import './style.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { TOKEN_EXPIRED, ALL_FIELDS_REQUIRED, SUCCESS_DATA, VALID_DATA, UPDATE_DATA } from './constants';
import { API_URL } from '../../config';


export default class DayFollowUp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sevendayfollowupId: '',
      assessment_id: '',//this.props.location.state !== undefined ? this.props.location.state.assessment_id : null,
      transaction_id: this.props.location.state !== undefined ? this.props.location.state.transaction_id : null,
      patient_id: this.props.location.state !== undefined ? this.props.location.state.patient_id : null,
      patient_name: this.props.location.state !== undefined ? this.props.location.state.patient_name : null,
      data_added: this.props.location.state !== undefined ? this.props.location.state.data_added : null,
      isDisabled: false,

      followup_date: new Date().toISOString().slice(0, 10),
      medication_date: '', //new Date().toISOString().slice(0, 10),
      wayofmedication: '',
      receivedmedication: '',
      forgotmedication: '',
      dosesmissed: '',
      anysideeffects: '',
      sedescription: '',
      sideeffectapproach: '',
      aegrade: '',
      mdcontacted: '',
      clinicalintervation: '',
      aedescription: null,
      rphrecommendations: '',
      additionalrphnotes: '',
      isinformedpatient: false,
      iscompleted: false,
      completedby: localStorage.getItem('user_name'),
      goaloftherapylst: [],
      createddt: dateFormat(new Date().toISOString(), "mm/dd/yyyy HH:mm"),
      createdby: localStorage.getItem('user_name'),
      patienttimespend: '',
      edumedication: [],
      aeGradelst: [],
      goalTherapyList: [],
      categories: [],
      barrierTherapy: [],
      aegradeData: [],
      careList: [],
      origCareList: [],
      updateCareList: [],
      modifieddt: '',
      modifiedby: '',
      errors: {
        followup_date: null,
        medication_date: null,
        wayofmedication: '',
        receivedmedication: '',
        forgotmedication: '',
        dosesmissed: '',
        anysideeffects: '',
        sedescription: '',
        sideeffectapproach: '',
        aegrade: '',
        mdcontacted: '',
        clinicalintervation: '',
        aedescription: null,
        rphrecommendations: '',
        additionalrphnotes: '',
        isinformedpatient: '',
        iscompleted: false,
        completedby: '',
        goaloftherapylst: [],
        createddt: null,
        createdby: '',
        edumedication: [],
        patienttimespend: ''
      }
    };
    this.handleChanges = this.handleChanges.bind(this);

  }

  token = localStorage.getItem('token');
  config = {
    headers: { Authorization: `Bearer ${this.token}` },
  };
  // today date
  todayDate = new Date().toISOString().slice(0,10)
  
  // Get All medication Details 
  getMedication = () => {
    /* axios
      .get(`${API_URL}/api/get_therapy_info/?patient_id=${this.state.patient_id}`, this.config)
      .then(response => {
        if (response.status === 200) {
          let edumedication; 
          edumedication = response.data.data.map((data, index) => ({
            trans_med_id: data.trans_med_id,
            name: data.name
            
          }));
          this.setState({ edumedication :edumedication }); 

        }
      })
      .catch(err => console.log(err)) */
    axios
      .get(`${API_URL}/api/educational_assessment/?patient_id=` + this.state.patient_id, this.config)
      .then(response => {
        if (response.data.status === 200 && response.status === 200) {
          if (response.data.data != null) {
            let getdata = response.data.data.filter(x => x.is_active === true).map(gd => gd.medications)[0];
            //console.log(getdata);
            let edumedication = getdata.map((data, index) => ({
              trans_med_id: data.medication_id,
              name: data.medication_name
            }));
            //console.log(edumedication);
            this.setState({ edumedication: edumedication });
          }
        }
      })
      .catch(error => {
        console.log(error);
      });
  }

  sortCareLists = (list) => {
    let uneditAble = [];
    let editAble = [];
    list.map((eachGoal) => {
      eachGoal.achieved_date === null ? editAble.push(eachGoal) : uneditAble.push(eachGoal)
    })
    uneditAble = this.insertionSort(uneditAble)
    editAble = this.insertionSort(editAble)
    let arraySorted = editAble.concat(uneditAble)
    return arraySorted
  }

  insertionSort = (array) => {
    let n = array.length;
    for (let i = 1; i < n; i++) {
      // Choosing the first element in our unsorted subarray
      let current = array[i];
      // The last element of our sorted subarray
      let j = i - 1;
      while ((j > -1) && (current.identified_date > array[j].identified_date)) {
        array[j + 1] = array[j];
        j--;
      }
      array[j + 1] = current;
    }
    return array;
  }

  getTherapy = () => {
    axios
      .get(`${API_URL}/api/log_intervention/?patient_id=` + this.state.patient_id, this.config)
      .then(response => {
        if (response.data.status === 200 && response.status === 200) {
          this.setState({
            careList: this.sortCareLists(response.data.data),
            origCareList: this.sortCareLists(JSON.parse(JSON.stringify(response.data.data))),
          });
          //console.log(response.data.data);
        }
        else if (response.status !== 200) {
          toast.error(VALID_DATA, {
            position: 'top-right',
            autoClose: 3500,
          });

          return false;
        }
      })
      .catch(error => {
        console.log(error);
      });
  }

  getCategories = () => {
    axios
      .get(`${API_URL}/api/get_all_categories/`, this.config)
      .then(response => {
        if (response.status === 200) {
          let plainArray = response.data.data.map((data, index) =>
          ({
            id: data.id,
            category: data.category,
          }));
          this.setState({ categories: plainArray });
        }
      })
      .catch(err => console.log(err))
  };

  // Get All Barriers Therapy
  getBarriersTherapy = () => {
    axios
      .get(`${API_URL}/api/get_all_barriers_to_therapy/`, this.config)
      .then(response => {
        if (response.status === 200) {
          let plainArray = response.data.data.map((data, index) => ({
            id: data.id,
            barrier: data.therapy_problem,
          }));
          this.setState({ barrierTherapy: plainArray });
        }
      })
      .catch(err => console.log(err))
  };

  componentDidMount() {
    this.checksevendaycondition();
    this.getAllAEGrade();
    this.getMedication();
    this.getCategories();
    this.getBarriersTherapy();
    this.getTherapy();

  }

  handlePopup = (e) => {
    this.setState({ show: true })
  }

  closeModal = event => {
    this.setState({
      show: false
    })
  }

  /* Handling Followup Data */
  handleChanges = (e) => {
    /* this.setState({
      [e.target.name]: e.target.value
    }); */

    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    const name = e.target.name;
    let errors = this.state.errors;
    this.setState({
      [name]: value
    });

    if (e.target.name === 'aegrade') {
      if (e.target.value !== '') {
        this.setState({ aedescription: this.state.aegradeData.filter(g => g.id === Number(value)).map(gd => gd.description)[0] });
      }
      else {
        this.setState({ aedescription: '' });
      }
    }

    switch (name) {
      case 'forgotmedication':
        errors.forgotmedication =
          (value === null || value === "") ? 'Please select are you forgot to take your medication(s).' : '';
        break;
      case 'dosesmissed':
        errors.dosesmissed =
          (this.state.forgotmedication === 'true' && value === "") ? 'Please enter missed doses.' : '';
        break;
      case 'anysideeffects':
        errors.anysideeffects =
          (value === null || value === "") ? 'Please select are you facing any side effect' : '';
        break;
      case 'sideeffectapproach':
        errors.sideeffectapproach =
          (value === null || value === "") ? 'Please enter what do you do when you have these side effects.' : '';
        break;
      case 'followup_date':
        errors.followup_date =
          (value === null || value === "") ? 'Please enter followup date.' : '';
        break;
      case 'medication_date':
        errors.medication_date =
          (value === null || value === "") ? 'Please enter medication date.' : '';
        break;
      case 'wayofmedication':
        errors.wayofmedication =
          (value === null || value === "") ? 'Please enter how are you taking your medication(s).' : '';
        break;
      case 'patienttimespend':
        errors.patienttimespend =
          (value === null || value === "") ? 'Please enter time spend with patient.' : '';
        break;
      case 'iscompleted':
        errors.iscompleted =
          (value === null || value === "" || value === false) ? 'Please select Completed By' : '';
      default:
        break;
    }

  }

  /* Methods For Handling Data Form Submit */
  handleFormSubmit(event) {
    event.preventDefault();
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    let valid = true;
    let errors = this.state.errors;

    if (this.state.forgotmedication === "" || this.state.anysideeffects === '' || this.state.sideeffectapproach === ''
      || this.state.followup_date === '' || this.state.medication_date === '' || this.state.wayofmedication === ''
      || this.state.patienttimespend === '' || (this.state.forgotmedication === 'true' && this.state.dosesmissed === '') || this.state.iscompleted === false) {
      toast.error(ALL_FIELDS_REQUIRED, {
        position: 'top-right',
        autoClose: 3500,
      });
      valid = false;
    }

    if (this.state.forgotmedication === null || this.state.forgotmedication === '') {
      errors.forgotmedication = 'Please select are you forgot to take your medication(s).';
    } else {
      errors.forgotmedication = '';
    }

    if (this.state.forgotmedication === 'true' && (this.state.dosesmissed === null || this.state.dosesmissed === '')) {
      errors.dosesmissed = 'Please enter missed doses.';
    } else {
      errors.dosesmissed = '';
    }

    if (this.state.anysideeffects === null || this.state.anysideeffects === '') {
      errors.anysideeffects = 'Please select are you facing any side effect.';
    } else {
      errors.anysideeffects = '';
    }

    if (this.state.sideeffectapproach === null || this.state.sideeffectapproach === '') {
      errors.sideeffectapproach = 'Please enter what do you do when you have these side effects.';
    } else {
      errors.sideeffectapproach = '';
    }

    if (this.state.followup_date === null || this.state.followup_date === '') {
      errors.followup_date = 'Please select follow-up date.';
    } else {
      errors.followup_date = '';
    }

    if (this.state.medication_date === null || this.state.medication_date === '') {
      errors.medication_date = 'Please select medication date.';
    } else {
      errors.medication_date = '';
    }

    if (this.state.wayofmedication === null || this.state.wayofmedication === '') {
      errors.wayofmedication = 'Please enter how are you taking your medication(s).';
    } else {
      errors.wayofmedication = '';
    }

    if (this.state.patienttimespend === null || this.state.patienttimespend === '') {
      errors.patienttimespend = 'Please enter time spend.';
    } else {
      errors.patienttimespend = '';
    }
    if (this.state.iscompleted === false) {
      errors.iscompleted = 'Please select completed by.';
    } else {
      errors.iscompleted = '';
    }

    this.setState({ errors: errors });

    if (valid) {
      //console.log(this.state.assessment_id);
      const formData = {
        'patient': this.state.patient_id,
        'assessment_id': this.state.assessment_id,
        'fu_date': this.state.followup_date,
        'medication_start_date': this.state.medication_date,
        'medication_from': this.state.receivedmedication,
        'way_of_medication': this.state.wayofmedication,
        'missed_days': this.state.forgotmedication !== '' ? this.state.forgotmedication : false,
        'side_effects': this.state.anysideeffects !== '' ? this.state.anysideeffects : false,
        'doses_missed': this.state.dosesmissed,
        'side_effect_description': this.state.sedescription,
        'ae_grade': this.state.aegrade,
        'md_contacted': this.state.mdcontacted !== '' ? this.state.mdcontacted : '',
        'care_plan': this.state.clinicalintervation !== '' ? this.state.clinicalintervation : '',
        'ae_desc': this.state.aedescription,
        'action_on_sideeffect': this.state.sideeffectapproach,
        'rph_recommendation': this.state.rphrecommendations,
        'rph_notes': this.state.additionalrphnotes,
        'informed_7day': this.state.isinformedpatient,
        'completed_by': this.state.iscompleted === true ? this.state.createdby : null,
        'time_spent_patient': this.state.patienttimespend,
        //'updated_by' :  this.state.createdby,   
        //'created_by' : this.state.createdby,  
        //'created' : this.state.createddt
      }

      if (this.state.sevendayfollowupId === '') {
        return axios.post(`${API_URL}/api/seven_day_follow_up/`, formData, config)
          .then((response) => {
            if (response.status === 200) {
              if (response.data.status === 201) {
                const updategoal = this.state.updateCareList;
                if (updategoal != null && updategoal.length > 0) {
                  axios.post(`${API_URL}/api/log_intervation_bulk/`, updategoal, config)
                    .then((response) => {
                      if (response.status === 200) {
                        toast.success(SUCCESS_DATA, {
                          position: 'top-right',
                          autoClose: 3000,
                        });
                        this.setState({ formData: [] });
                        setTimeout(() => {
                          this.props.history.push({
                            pathname: '/patientcliprofile',
                            state: {
                              'transaction_id': this.state.transaction_id,
                              'patient_id': this.state.patient_id, 'patient_name': this.state.patient_name,
                              'data_added': this.state.data_added
                            }
                          })
                        }, 2500);
                      }
                    })
                    .catch((error) => {
                      return false;
                    });
                }
                else {
                  toast.success(SUCCESS_DATA, {
                    position: 'top-right',
                    autoClose: 3000,
                  });
                  this.setState({ formData: [] });
                  setTimeout(() => {
                    this.props.history.push({
                      pathname: '/patientcliprofile',
                      state: {
                        'transaction_id': this.state.transaction_id,
                        'patient_id': this.state.patient_id, 'patient_name': this.state.patient_name,
                        'data_added': this.state.data_added
                      }
                    })
                  }, 2500);
                }
              }
              else if (response.data.status === 400) {
                toast.error(VALID_DATA, {
                  position: 'top-right',
                  autoClose: 3000,
                });
              }
            }
          })
          .catch((error) => {
            toast.error(VALID_DATA, {
              position: 'top-right',
              autoClose: 3500,
            });
            return false;
          });
      }
      else {
        axios
          .put(`${API_URL}/api/seven_day_update_data/${this.state.sevendayfollowupId}`, formData, config)
          .then(response => {
            if (response.status === 200) {
              const updategoal = this.state.updateCareList;
              if (updategoal != null && updategoal.length > 0) {
                axios.post(`${API_URL}/api/log_intervation_bulk/`, updategoal, config)
                  .then((response) => {
                    if (response.status === 200) {
                      toast.success(UPDATE_DATA, {
                        position: 'top-right',
                        autoClose: 3000,
                      });
                      this.setState({ formData: [] });
                      setTimeout(() => {
                        this.props.history.push({
                          pathname: '/patientcliprofile',
                          state: {
                            'transaction_id': this.state.transaction_id,
                            'patient_id': this.state.patient_id, 'patient_name': this.state.patient_name,
                            'data_added': this.state.data_added
                          }
                        })
                      }, 2500);
                    }
                  })
                  .catch((error) => {
                    return false;
                  });
              }
              else {
                toast.success(UPDATE_DATA, {
                  position: 'top-right',
                  autoClose: 3000,
                });
                this.setState({ formData: [] });
                setTimeout(() => {
                  this.props.history.push({
                    pathname: '/patientcliprofile',
                    state: {
                      'transaction_id': this.state.transaction_id,
                      'patient_id': this.state.patient_id, 'patient_name': this.state.patient_name,
                      'data_added': this.state.data_added
                    }
                  })
                }, 2500);
              }
            }
          })
          .catch(err => console.log(err));
      }
    }

  }

  // Get All AE Grade
  getAllAEGrade = () => {
    axios
      .get(`${API_URL}/api/get_all_ae_grades/`, this.config)
      .then(response => {
        if (response.status === 200) {
          let plainArray = response.data.data.map((planet) =>
            <option value={planet.id}>{planet.name}</option>
          );
          this.setState({ aeGradelst: plainArray });

          let aeGradeArray = response.data.data.map((data, index) => ({
            id: data.id,
            description: data.description,
            name: data.name
          }));
          this.setState({ aegradeData: aeGradeArray });
          //this.setState({ aedescription:  this.state.aegradeData.filter(g => g.id === Number(1)).map(gd => gd.description)[0]});
          //this.setState({ aegrade:  this.state.aegradeData.filter(g => g.id === Number(1)).map(gd => gd.id)[0]}); 
        }
      })
      .catch(err => console.log(err))
  };

  updateCarePlanChanges = (event, index, id) => {
    const name = event.target.name;
    const value = event.target.value;
    let list = [...this.state.careList];
    list[index][event.target.name] = event.target.value;
    
      //list[index]['achieved_date'] = dateFormat(new Date().toISOString().slice(0, 10), "yyyy-mm-dd");
      if (!list[index]["goal_acheived"] !== list[index]["goal_not_acheived"]) {
        list[index][name] = list[index][name];
        this.state.updateCareList.push(list[index]);
      }
      
    this.setState({careList: list,});
    
    
  }

  updateCarePlanCheckboxes = (event, index, id) => {
    const name = event.target.name;
    const value = event.target.checked ? true : false;
    let list = [...this.state.careList];
    let origAchieved = this.state.origCareList[index].achieved_date
    if (origAchieved !== null) {
      return
    }
    else {
      if (value) {
        list[index]['achieved_date'] = dateFormat(new Date().toISOString().slice(0, 10), "yyyy-mm-dd");
        if (!list[index]["goal_acheived"] !== list[index]["goal_not_acheived"]) {
          list[index][name] = !list[index][name];
          this.state.updateCareList.push(list[index]);
        }
      }
      else {
        list[index]['achieved_date'] = null;
        list[index][name] = !list[index][name]
        const filterlist = this.state.updateCareList.filter((item) => item.id !== list[index]['id']);
        this.setState({
          updateCareList: filterlist,
        });
      }
    }
    this.setState({
      careList: list,
    });
  }

  checksevendaycondition = () => {
    axios
      .get(`${API_URL}/api/seven_day_condition/?patient_id=` + this.state.patient_id, this.config)
      .then(response => {
        if (response.status === 200) {
          //console.log(JSON.stringify(response.data.data));
          this.setState({ assessment_id: response.data.data.assessment_id })
          if (response.data.data.startdate_condition === true && response.data.data.education_condition === true) {
            axios
              .get(`${API_URL}/api/seven_day_get_data/?patient_id=` + this.state.patient_id + '&assessment_id=' + this.state.assessment_id, this.config)
              .then(response => {
                if (response.status === 200 && response.data.status === 200) {
                  //console.log('followup' + JSON.stringify(response.data.data));
                  if (response.data.data !== null && response.data.data.length > 0) {
                    this.setState({ isDisabled: false })
                    let getdata = response.data.data[0];
                    console.log(response.data.data);
                    this.setState({
                      sevendayfollowupId: getdata.id,
                      followup_date: getdata.fu_date,
                      medication_date: getdata.medication_start_date,
                      receivedmedication: getdata.medication_from,
                      wayofmedication: getdata.way_of_medication,
                      forgotmedication: getdata.missed_days,
                      anysideeffects: getdata.side_effects,
                      dosesmissed: getdata.doses_missed,
                      sedescription: getdata.side_effect_description,
                      sideeffectapproach: getdata.action_on_sideeffect,
                      aegrade: getdata.ae_grade,
                      mdcontacted: getdata.md_contacted,
                      clinicalintervation: getdata.care_plan,
                      aedescription: getdata.ae_desc,
                      rphrecommendations: getdata.rph_recommendation,
                      additionalrphnotes: getdata.rph_notes,
                      isinformedpatient: getdata.informed_7day,
                      createddt: Moment(getdata.created).format('MM/DD/YYYY HH:mm'),
                      createdby: getdata.created_by_user,
                      modifieddt: Moment(getdata.modified).format('MM/DD/YYYY HH:mm'),
                      modifiedby: getdata.updated_by_user,
                      patienttimespend: getdata.time_spent_patient,
                      completedby: getdata.completed_by,
                      iscompleted: getdata.completed_by != null ? true : false
                    })
                  }
                  else
                    this.setState({ isDisabled: false })
                }
                else
                  this.setState({ isDisabled: false })
              })
              .catch(err => console.log(err))
          }
          else {
            this.setState({ isDisabled: true })
          }
        }
        else
          this.setState({ isDisabled: true })
        //console.log(this.state.isDisabled)
      })
      .catch(err => console.log(err))
  };

  render() {
    const isLogged = !!localStorage.getItem('token');
    if (!isLogged) {
      return <Redirect to="/login" />;
    }

    const { errors } = this.state;

    return (
      <>
        <ToastContainer />
        <CommonHeader />
        <section id="wrapper">
          <CommonSidebar />
          <div className="row">
            <div className="col-sm-12 mb-4">
              <h2 className="text-uppercase">
                7 Day Follow Up
              </h2>
            </div>
            <div className="col-sm-12">
              <h3 className="text-blue mb-1">
                <i className="fa fa-user fa-fw user-icon mr-2"></i>
                <Link to={{
                  'pathname': '/patientcliprofile', 'state': {
                    'transaction_id': this.state.transaction_id,
                    'patient_id': this.state.patient_id, 'patient_name': this.state.patient_name, 'data_added': this.state.data_added
                  }
                }}>
                  {this.state.patient_name}
                </Link>
              </h3>
              <hr />
            </div>
          </div>
          <div className="card">
            <div className="card-body">
              <div className="row">
                {/*------------------------------*/}
                <div className="col-md-6">
                  {this.state.edumedication.map(med => (
                    <p>{med.name}</p>
                  ))}
                </div>
                <div className="col-md-6">
                  <div className="form-inline justify-content-end">
                    <label for="followup_date" className="align-items-end end col-md-4">  Follow Up Date : </label>
                    <input type="date" className="form-control col-md-3" name="followup_date" min = {this.todayDate} onKeyDown={(event)=>{event.preventDefault()}} value={this.state.followup_date} onChange={this.handleChanges} disabled={this.state.isDisabled}/>
                    <br />
                    {(errors.followup_date !== null || errors.followup_date !== "") && <span className='error col-md-12 text-right' style={{ color: 'red' }}>{errors.followup_date}</span>}
                  </div>
                </div>
              </div>
              <hr class="mt-4 hr-blue"></hr>
              <div className="row">
                <div className="form-group  form-inline col-md-12">
                  <div className="col-md-6 form-inline paddingleft">
                    <div className="col-md-6 paddingzero">
                      <label for="" className="col-md-12 paddingzero"> When did you first start taking your medication(s)?
                        <span class="text-danger">*</span>
                      </label>
                      {(errors.medication_date !== null || errors.medication_date !== "") && <span className='error col-md-12 paddingzero' style={{ color: 'red' }}>{errors.medication_date}</span>}
                    </div>
                    <input type="date" className="form-control col-md-6" max = {this.todayDate} onKeyDown={(event)=>{event.preventDefault()}}  name="medication_date" value={this.state.medication_date} onChange={this.handleChanges} disabled={this.state.isDisabled} />
                  </div>
                  <div className="col-md-6 form-inline paddingzero">
                    <label for="" className="col-md-4 end">Receiving medication from:  </label>
                    <input type="text" className="form-control col-md-8 " name="receivedmedication" value={this.state.receivedmedication} onChange={this.handleChanges} disabled={this.state.isDisabled}/>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="form-group  form-inline col-md-6">
                  <div className="col-md-6 paddingzero">
                    <label for="" className="col-md-12 paddingzero">How are you taking your medication(s)? <span class="text-danger">*</span> </label>
                    {errors.wayofmedication.length > 0 && <span className='error col-md-12 paddingzero' style={{ color: 'red' }}>{errors.wayofmedication}</span>}
                  </div>
                  <input type="text" className="form-control col-md-6" name="wayofmedication" value={this.state.wayofmedication} onChange={this.handleChanges} disabled={this.state.isDisabled}/>
                </div>
              </div>
              <div className="row">
                <div className="form-group  form-inline col-md-12">
                  <div className="form-group form-inline paddingzero col-md-6">
                    <label for="" className="col-md-12 paddingzero">
                      Were there any days when you forgot to take your medication(s) or took it later than you should have?
                      <span class="text-danger">*</span>
                    </label>
                    {errors.forgotmedication.length > 0 && <span className='error col-md-12 paddingzero' style={{ color: 'red' }}>{errors.forgotmedication}</span>}
                  </div>
                  <select class="form-control ml-2" value={this.state.forgotmedication} name="forgotmedication" onChange={this.handleChanges} disabled={this.state.isDisabled}>
                    <option value="">Please Select</option>
                    <option value="true">Yes</option>
                    <option value="false">No</option>
                  </select>
                </div>
              </div>
              {this.state.forgotmedication === 'true' || this.state.forgotmedication === true ? (
                <div className="row">
                  <div className="form-group  form-inline col-md-8">
                    <div className="col-md-4">
                      <label for="" className="col-md-12">How many doses were missed?<span class="text-danger">*</span></label>
                      {errors.dosesmissed.length > 0 && <span className='error col-md-12' style={{ color: 'red' }}>{errors.dosesmissed}</span>}
                    </div>
                    <input type="text" class="form-control col-md-1 " name="dosesmissed" value={this.state.dosesmissed} onChange={this.handleChanges} disabled={this.state.isDisabled} />
                  </div>
                </div>
              ) : null}
              <div className="row">
                <div className="form-group form-inline col-md-12">
                  <div className="mr-2">
                    <label for="mr-2">Are you experiencing any new side effects? <span class="text-danger">*</span></label>
                    {errors.anysideeffects.length > 0 && <span className='error' style={{ color: 'red' }}>{errors.anysideeffects}</span>}
                  </div>
                  <select class="form-control ml-2" name="anysideeffects" onChange={this.handleChanges} value={this.state.anysideeffects} disabled={this.state.isDisabled}>
                    <option value="">Please Select</option>
                    <option value="true">Yes</option>
                    <option value="false">No</option>
                  </select>

                </div>
              </div>
              <div className="row">
                <div className="form-group form-inline col-md-12">
                  <label for="mr-2" class="col-md-1 paddingleft">SE Description:</label>
                  <input type="text" class="form-control col-md-11" name="sedescription" value={this.state.sedescription} onChange={this.handleChanges} disabled={this.state.isDisabled}/>
                </div>
              </div>

              <div className="row">
                <div className="form-group form-inline col-md-12">
                  <label for="mr-2" className="col-md-3 paddingleft"> What do you do when you have these side effects ? <span class="text-danger">*</span></label>
                  <input type="text" class="form-control col-md-9" value={this.state.sideeffectapproach} onChange={this.handleChanges} name="sideeffectapproach" disabled={this.state.isDisabled} />
                  {errors.sideeffectapproach.length > 0 && <span className='error' style={{ color: 'red' }}>{errors.sideeffectapproach}</span>}
                </div>
              </div>

              <hr class="mt-4 hr-blue"></hr>
              <div className="row">
                <div className="form-group form-inline col-md-12">
                  <div className="form-inline col-md-4 paddingleft">
                    <label for="mr-2" onClick={this.handlePopup} title="AE Grade Description"><u style={{ cursor: "pointer", color: "blue" }}> Adverse Event Grade: </u> </label>
                    <select class="form-control ml-2 dropdown" onChange={this.handleChanges} value={this.state.aegrade} name="aegrade" disabled={this.state.isDisabled}>
                      <option value="">Please Select</option>
                      {this.state.aeGradelst}
                    </select>
                  </div>

                  <div className="form-inline col-md-4">
                    <label for="mr-2">MD Contacted: </label>
                    <select class="form-control ml-2" value={this.state.mdcontacted} onChange={this.handleChanges} name="mdcontacted" disabled={this.state.isDisabled}>
                      <option value="">Please Select</option>
                      <option value="true">Yes</option>
                      <option value="false">No</option>
                    </select>
                  </div>
                  <div className="form-inline col-md-4">
                    <label for="mr-2">Clinical Intervention Created: </label>
                    <select class="form-control ml-2" value={this.state.clinicalintervation} onChange={this.handleChanges} name="clinicalintervation" disabled={this.state.isDisabled}>
                      <option value="">Please Select</option>
                      <option value="true">Yes</option>
                      <option value="false">No</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="form-group form-inline col-md-12">
                  <label for="mr-2" class="col-md-2 paddingleft">AE Grade Description:</label>
                  <input type="text" class="form-control col-md-10" value={this.state.aedescription} name="aedescription" readOnly onChange={this.handleChanges} />
                </div>
              </div>

              <div className="row">
                <div className="form-group form-inline col-md-12">
                  <label for="mr-2" className="col-md-2 paddingleft"> RPh Recommendations: </label>
                  <textarea className="form-control col-md-10 text-left" rows="3" value={this.state.rphrecommendations} name="rphrecommendations" onChange={this.handleChanges} disabled={this.state.isDisabled}></textarea>
                </div>
              </div>
              <div className="row">
                <div className="form-group form-inline col-md-12">
                  <label for="mr-2" className="col-md-2 paddingleft">Additional RPh Notes: </label>
                  <textarea className="form-control col-md-10 text-left" rows="3" value={this.state.additionalrphnotes} name="additionalrphnotes" onChange={this.handleChanges} disabled={this.state.isDisabled}></textarea>
                </div>
              </div>
              <div className="row">
                <div className="form-group form-inline col-md-12">
                  <div class="custom-control custom-checkbox">
                    <input type="checkbox" class="custom-control-input" id="defaultUnchecked"
                      name="isinformedpatient" onChange={this.handleChanges} value={this.state.isinformedpatient} checked={this.state.isinformedpatient} disabled={this.state.isDisabled}/>
                    <label class="custom-control-label form-check-label" for="defaultUnchecked">Informed patient we will follow up with them
                      7 days prior to their next refill </label>
                  </div>
                </div>
              </div>
              <hr class="mt-4 hr-blue"></hr>
              <div className="row">
                <div className="col-md-12">
                  <span className="tittle">GOALS OF THERAPY</span>
                </div>
                <div className="col-md-12 table-responsive">
                  <table className="table col-md-12 customtable paddingzero">
                    <thead className="col-md-12">
                      <tr className="col-md-12">
                        <th className="col-md-1">Goal Identified </th>
                        <th className="col-md-1">Category</th>
                        <th className="col-md-1">Goal or Barrier</th>
                        <th className="col-md-7">Progress Update</th>
                        <th className="col-md-1">Achieved</th>
                        <th className="col-md-1">Not Achieved</th>
                      </tr>
                    </thead>
                    <tbody className="col-md-12">
                      {this.state.careList.length > 0 ? this.state.careList.map((x, i) => {
                        let origList = this.state.origCareList[i]
                        let disable = origList.achieved_date !== null;
                        return (
                          <tr className="col-md-12" key={i} style={disable ? { backgroundColor: "#F0F0F0" } : {}}>
                            <td className="col-md-1 text-left">
                              {Moment(x.identified_date).format('MM/DD/YYYY')}
                            </td>
                            <td className="col-md-1 text-left">
                              {this.state.categories.filter(cat => cat.id === x.category).map(cat => cat.category)}
                            </td>
                            <td className="col-md-1 text-left">
                              {this.state.barrierTherapy.filter(cat => cat.id === x.barrier_therapy).map(cat => cat.barrier)}
                            </td>
                            <td className="col-md-7 text-left">
                              <input type="text" className="form-control col-md-12"
                                name="goal_7days" value={x.goal_7days} onChange={(e) => this.updateCarePlanChanges(e, i)} disabled={this.state.isDisabled ? this.state.isDisabled : disable} />
                            </td>
                            <td className="col-md-1 text-center">
                              <div className="custom-control custom-checkbox">
                                <input type="checkbox" className="custom-control-input" id={"goal_acheived_" + i} disabled ={this.state.isDisabled ? this.state.isDisabled : disable}
                                  name="goal_acheived" checked={x.goal_acheived} onChange={(e) => this.updateCarePlanCheckboxes(e, i)} />
                                <label className="custom-control-label" htmlFor={"goal_acheived_" + i}></label>
                              </div>
                            </td>
                            <td className="col-md-1 text-center">
                              <div className="custom-control custom-checkbox">
                                <input type="checkbox" className="custom-control-input" id={"goal_not_acheived_" + i} disabled ={this.state.isDisabled ? this.state.isDisabled : disable}
                                  name="goal_not_acheived" checked={x.goal_not_acheived} onChange={(e) => this.updateCarePlanCheckboxes(e, i)} />
                                <label className="custom-control-label" htmlFor={"goal_not_acheived_" + i}></label>
                              </div>
                            </td>
                          </tr>
                        );
                      }) : (
                        <tr>
                          <td colSpan="6">No records found</td>
                        </tr>
                      )}
                    </tbody>
                  </table>

                </div>

              </div>

              <div className="row">
                <div class="form-inline col-md-7">
                  <div class="custom-control custom-checkbox col-md-2 paddingright">
                    <input type="checkbox" class="custom-control-input form-check-label" id="iscompleted" name="iscompleted" disabled={this.state.isDisabled}
                      value={this.state.iscompleted} onChange={this.handleChanges}
                      checked={this.state.iscompleted} />
                    <label class="custom-control-label" for="iscompleted">Completed By: <span class="text-danger">*</span></label>
                  </div>
                  {errors.iscompleted.length > 0 && <span className='error' style={{ color: 'red' }}>{errors.iscompleted}</span>}
                  {this.state.iscompleted === true ? (
                    <label class="col-md-4">
                      {this.state.createddt} | {this.state.completedby}
                    </label>
                  ) : null
                  }
                  {this.state.sevendayfollowupId != '' ? (
                    <div class="form-inline col-md-12 paddingzero">
                      <label className="col-md-2">Updated By:</label>
                      <label className="col-md-4">
                        {this.state.modifieddt} | {this.state.modifiedby}
                      </label>
                    </div>
                  ) : null
                  }

                </div>


                <div className="form-inline justify-content-end col-md-5">
                  <div className="mr-2">
                    <label for="FollowUpDate mr-2"> Time Spend with Patient  <span class="text-danger">*</span> </label>
                    {errors.patienttimespend.length > 0 && <span className='error mr-2' style={{ color: 'red' }}>{errors.patienttimespend}</span>}
                  </div>
                  <input type="text" class="form-control ml-2 col-sm-3" name="patienttimespend" value={this.state.patienttimespend} onChange={this.handleChanges} disabled={this.state.isDisabled}/>
                </div>
              </div>
              { this.state.isDisabled === false ? (
              <div className="row">
                <div className="form-group form-inline col-md-12 bottomset">
                  <div className="col-md-12 text-right paddingzero">
                    <Link to={{
                      'pathname': '/logintervention', 'state': {
                        'transaction_id': this.state.transaction_id,
                        'patient_id': this.state.patient_id, 'patient_name': this.state.patient_name,'data_added' : this.state.data_added
                      }
                    }}>
                      <button className="btn btn-primary mr-2">Therapy Goals</button>
                    </Link>

                    <button className="btn btn-primary mr-2">MS Word Export</button>
                    <button className="btn btn-primary mr-2" onClick={(evt) => this.handleFormSubmit(evt)}>Save</button>
                  </div>
                </div>
              </div>
              ): null }
            </div>
          </div>
          <Modal show={this.state.show} onHide={this.closeModal} backdrop="static">
            <Modal.Header closeButton>
              <Modal.Title className="text-center col-md-11 paddingzero"> Common Terminology Criteria for Adverse Events v4.0 (CTCAE) </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <AdversePopup closeModal={this.closeModal} />
            </Modal.Body>
          </Modal>

        </section>
        <CommonFooter />
      </>
    );
  }
}
