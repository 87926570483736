import React from 'react';
import { Redirect, Link } from 'react-router-dom';
import IconWithTooltip from 'icon-with-tooltip';
import CheckIcon from '@material-ui/icons/Check';
import 'react-toastify/dist/ReactToastify.css';
import 'react-datepicker/dist/react-datepicker.css';
import $ from 'jquery';
import 'bootstrap/dist/css/bootstrap.css';
import '../../containers/css/style_semantic.css';
import {API_URL,} from '../../config';
import { ToastContainer, toast } from 'react-toastify';
import dateFormat from 'dateformat';
import axios from 'axios';
import 'react-toastify/dist/ReactToastify.css';
import 'react-confirm-alert/src/react-confirm-alert.css';


import {
    
    VALID_EDUCATION_DATA,
    DATA_UPDATED,
    TOKEN_EXPIRED,
    
  } from './constants';

export default class Communication extends React.Component {
  constructor(props) {
    super(props);
    this.myInitObject = null;
    this.state = {
      patient_id: this.props !== undefined ? this.props.patient_id : null,
      commInputList: [
        {
          comId: "",
          typeOfCom: "",
          comDate: "",
          comNotes: "",
          comStatus: "",
          comCheck: "",
          comFollowUpDate: "",
        },
      ],
      trackCommInputList: [],
    
    }
    this.handleCommInputChange = this.handleCommInputChange.bind(this);
  }

    //Set Communication Tab After Switching 
    setAssessComm = () => {
      if (localStorage.getItem('currTab') == 'Del') {
        $("#schDelivery").attr('href', '#deliveries');
        $("#allFilesHis").removeAttr('href');
        $("#medTherapy").removeAttr('href');
  
        $("#eduAssessment").removeAttr('href');
        $("#refCallNotes").removeAttr('href');
        $("#communications").removeAttr('href');
  
        $("#schDelivery").addClass("active");
        $("#allFilesHis").removeClass("active");
        $("#eduAssessment").removeClass("active");
        
        $("#refCallNotes").removeClass("active");
        $("#assCommunications").removeClass('active');
        $("#medTherapy").removeClass('active');
      } else if (localStorage.getItem('currTab') == 'AllFillsHis') {
        $("#allFilesHis").attr('href', '#allFilesHistory');
        $("#schDelivery").removeAttr('href');
        $("#medTherapy").removeAttr('href');
  
        $("#eduAssessment").removeAttr('href');
        $("#refCallNotes").removeAttr('href');
        $("#assCommunications").removeAttr('href');
  
        $("#allFilesHis").addClass("active");
        $("#schDelivery").removeClass("active");
        $("#eduAssessment").removeClass("active");
  
        $("#refCallNotes").removeClass("active");
        $("#assCommunications").removeClass('active');
        $("#medTherapy").removeClass('active');
      } else if (localStorage.getItem('currTab') == 'refillCalNotes') {
        $("#refCallNotes").attr('href', '#refillCalNotes');
        $("#allFilesHis").removeAttr('href');
        $("#medTherapy").removeAttr('href');
  
        $("#eduAssessment").removeAttr('href');
        $("#schDelivery").removeAttr('href');
        $("#assCommunications").removeAttr('href');
  
        $("#refCallNotes").addClass("active");
        $("#schDelivery").removeClass("active");
        $("#eduAssessment").removeClass("active");
  
        $("#allFilesHis").removeClass("active");
        $("#assCommunications").removeClass('active');
        $("#medTherapy").removeClass('active');
      } else if (localStorage.getItem('currTab') == 'therapy') {
        $("#medTherapy").attr('href', '#therapy');
        $("#allFilesHis").removeAttr('href');
        $("#refCallNotes").removeAttr('href');
  
        $("#eduAssessment").removeAttr('href');
        $("#schDelivery").removeAttr('href');
        $("#assCommunications").removeAttr('href');
  
        $("#medTherapy").addClass("active");
        $("#schDelivery").removeClass("active");
        $("#eduAssessment").removeClass("active");
  
        $("#allFilesHis").removeClass("active");
        $("#assCommunications").removeClass('active');
        $("#refCallNotes").removeClass('active');
      } else if (localStorage.getItem('currTab') == 'education') {
        $("#eduAssessment").attr('href', '#education');
        $("#allFilesHis").removeAttr('href');
        $("#assCommunications").removeAttr('href');
  
        $("#schDelivery").removeAttr('href');
        $("#refCallNotes").removeAttr('href');
        $("#medTherapy").removeAttr('href');
  
        $("#eduAssessment").addClass("active");
        $("#schDelivery").removeClass("active");
        $("#assCommunications").removeClass('active');
  
        $("#allFilesHis").removeClass("active");
        $("#refCallNotes").removeClass('active');
        $("#medTherapy").removeClass('active');
      }
    };
    
    // today date
    todayDate = new Date().toISOString().slice(0,10)
    // get token from loclastorage
  token = localStorage.getItem('token');
  config = {
    headers: { Authorization: `Bearer ${this.token}` },
  };

    getAssessComm = () => {
      if (localStorage.getItem('patAllFillsHisChanged') || localStorage.getItem('isPatientAddDelChanged') ||
        localStorage.getItem('patRefillCallNotesChanged') || localStorage.getItem('patTherapyInputChanged')
        || localStorage.getItem('isPatientEduAssChanged') || localStorage.getItem('isAssChanged')) {
        const confirmBox = window.confirm("You have unsaved changes! Do you really want to move to another tab?");
        if (confirmBox === true) {
          localStorage.setItem('prevTab', localStorage.getItem('currTab'));
          localStorage.setItem('currTab', 'comm');
  
          localStorage.removeItem('isPatientAddDelChanged');
          localStorage.removeItem('patAllFillsHisChanged');
          localStorage.removeItem('patRefillCallNotesChanged');
          localStorage.removeItem('patTherapyInputChanged');
          localStorage.removeItem('isPatientEduAssChanged');
          localStorage.removeItem('isAssChanged')
  
          $("#assCommunications").attr('href', '#communications');
          $("#allFilesHis").removeAttr('href');
          $("#schDelivery").removeAttr('href');
          $("#refCallNotes").removeAttr('href');
          $("#medTherapy").removeAttr('href');
          $("#eduAssessment").removeAttr('href');
          $("#archivesAss").removeAttr('href');
          $("#oncoAssessments").removeAttr('href');
        } else {
          setTimeout(() => this.props.setTab(), 250);
        }
      } else {
        localStorage.setItem('prevTab', localStorage.getItem('currTab'));
        localStorage.setItem('currTab', 'comm');
  
        $("#assCommunications").attr('href', '#communications');
        $("#allFilesHis").removeAttr('href');
        $("#schDelivery").removeAttr('href');
        $("#refCallNotes").removeAttr('href');
        $("#medTherapy").removeAttr('href');
        $("#eduAssessment").removeAttr('href');
        $("#archivesAss").removeAttr('href');
        $("#oncoAssessments").removeAttr('href');
      }
  
      
        this.setState({
          "commInputList": []   
         });
      
    

      axios
        .get(`${API_URL}/api/assessment_communication/?patient_id=` + this.state.patient_id, this.config)
        .then(response => {
          if (response.status === 200) {
            if (response.data.status === 200) {
              let allCommData;
              let trackAllCommData;
  
              if (response.data.data !== null) {
                allCommData = response.data.data.map((data, index) => ({
                  typeOfCom: data.method,
                  comDate: data.created,
                  comNotes: data.notes,
                  comStatus: data.status,
                  comCheck: null,
                  comId: data.id,
                  comFollowUpDate: data.followup_date,
                }));
  
                trackAllCommData = response.data.data.map((data, index) => ({
                  typeOfCom: data.method,
                  comNotes: data.notes,
                  comStatus: data.status,
                  comCheck: null,
                  comFollowUpDate: data.followup_date,
                }));
              } else {
                allCommData = [];
                trackAllCommData = [];
              }
  
              this.setState({ commInputList: allCommData, });
              this.setState({ trackCommInputList: trackAllCommData, });
  
            } else if (response.data.status_code == 406) {
              localStorage.removeItem('token');
              localStorage.removeItem('user_name');
  
              localStorage.removeItem('adminCheck');
              localStorage.setItem('tokenExpired', true);
  
              const tokenStatus = localStorage.getItem('tokenExpired');
              if (tokenStatus) {
                localStorage.removeItem('tokenExpired');
                toast.error(TOKEN_EXPIRED, {
                  position: 'top-right',
                  autoClose: 2500,
                });
              }
  
              const isLogged = !!localStorage.getItem('token');
              if (!isLogged) {
                setTimeout(() => {
                  this.props.history.push("/login");
                }, 2500);
              }
            }
          } else if (response.status !== 200) {
            toast.error(VALID_EDUCATION_DATA, {
              position: 'top-right',
              autoClose: 3500,
            });
            return false;
          }
        })
        .catch(error => {
          console.log(error);
        });
    }
  
  handleCommAddClick = () => {
    const list = [...this.state.commInputList];
    list.push({
      typeOfCom: "",
      comDate: "",
      comNotes: "",
      comStatus: "",
      comCheck: "",
      comFollowUpDate: "",
    });
    this.setState({
      commInputList: list,
    });
  }; 

  typeOfCom = (e, index) => {
    const { name, value } = e.target;
    const list = [...this.state.commInputList];
    list[index][name] = value;
    this.setState({ commInputList: list, });

    for (var i = 0; i < list.length; i++) {
      if ((this.state.trackCommInputList[i].typeOfCom !== list[i]['typeOfCom']) || (this.state.trackCommInputList[i].comStatus !== list[i]['comStatus'])
        || (this.state.trackCommInputList[i].comNotes !== list[i]['comNotes']) || (this.state.trackCommInputList[i].comFollowUpDate !== list[i]['comFollowUpDate'])) {
        localStorage.setItem('patCommunicationsChanged', true);
        break;
      } else {
        localStorage.removeItem('patCommunicationsChanged');
      }
    }
  };
  handleCommInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...this.state.commInputList];
    list[index][name] = value;
    this.setState({ commInputList: list, });

    for (var i = 0; i < list.length; i++) {
      if ((this.state.trackCommInputList[i].typeOfCom !== list[i]['typeOfCom']) || (this.state.trackCommInputList[i].comStatus !== list[i]['comStatus'])
        || (this.state.trackCommInputList[i].comNotes !== list[i]['comNotes']) || (this.state.trackCommInputList[i].comFollowUpDate !== list[i]['comFollowUpDate'])) {
        localStorage.setItem('patCommunicationsChanged', true);
        break;
      } else {
        localStorage.removeItem('patCommunicationsChanged');
      }
    }
  };

  handleCommRemoveClick = index => {
    const list = [...this.state.commInputList];
    list.splice(index, 1);
    this.setState({
      commInputList: list,
    });
  };


  handleCurrCommRow = index => {
    const list = [...this.state.commInputList];
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const formData = {
      patient: this.state.patient_id,
      method: list[index].typeOfCom,
      status: list[index].comStatus,
      followup_date: list[index].comFollowUpDate,
      notes: list[index].comNotes
    }
    this.setState({ isFetchData: true, });
    axios
      .put(`${API_URL}/api/assessment_communication/${list[index].comId}`, formData, config)
      .then(response => {
        if (response.data.status === 200 && response.status === 200) {
          localStorage.removeItem('patCommunicationsChanged');
          toast.success(DATA_UPDATED, {
            position: 'top-right',
            autoClose: 3500,
          })
          this.setState({
            isFetchData: false
          })
        } else {
          toast.error(VALID_EDUCATION_DATA, {
            position: 'top-right',
            autoClose: 3500,
          });
          return false;
        }
      })
      .catch(err => {
        console.log(err, 'err')
      })
  };
  

  render() {
      const isLogged = !!localStorage.getItem('token');
		if (!isLogged) {
			return <Redirect to="/login" />;
		}
    return (
        <>
        
          <div id="communications" className="container-fluid tab-pane fade">
            
                    <div className="table-responsive">
                      <table className="table">
                      <thead>
                          <tr>
                            <th>Methods</th>
                            <th className="w-145">Created Date</th>
                            <th>Communication Notes</th>
                            <th>Status</th>
                            <th className="w-145">Follow Up Date</th>
                            <th style={{ width: '100px' }}></th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.commInputList.length > 0 ? this.state.commInputList.map((x, i) => {
                            return (
                              <tr key={x.id}>
                                <td>
                                  <select className="form-control" id="typeOfCom" name="typeOfCom" value={x.typeOfCom} onChange={e => this.handleCommInputChange(e, i)}>
                                    <option value="phone_call">Phone Call</option>
                                    <option value="email">Email</option>
                                    <option value="other">Other</option>
                                  </select>
                                </td>
                                <td>
                                  <input type="date" disabled className="form-control" name="comDate" value={dateFormat(x.comDate, "yyyy-mm-dd")}
                                    onChange={e => this.handleCommInputChange(e, i)} />
                                </td>
                                <td style={{ width: '28%' }}>
                                  <textarea rows="1" className="form-control" name="comNotes" defaultValue={x.comNotes}
                                    onChange={e => this.handleCommInputChange(e, i)}></textarea>
                                </td>
                                <td>
                                  <select className="form-control" name="comStatus" value={x.comStatus} onChange={e => this.handleCommInputChange(e, i)}>
                                    <option value="in_process">In Process</option>
                                    <option value="closed">Closed</option>
                                  </select>
                                </td>
                                <td>
                                  <input type="date" className="form-control" name="comFollowUpDate" value={x.comFollowUpDate} 
                                  min ={this.todayDate}
                                  onChange={e => this.handleCommInputChange(e, i)} />
                                </td>
                                <td className="d-flex justify-content-end">
                                  {this.state.isFetchData ? (
                                    <span>Updating</span>
                                  ) : (
                                    <button type="button" className="btn btn-primary mt-1 btnControl btnsv" onClick={() => this.handleCurrCommRow(i)}>
                                      <IconWithTooltip
                                        Icon={CheckIcon}
                                        text="Save Changes"
                                      />
                                    </button>)}
                                </td>
                              </tr>
                            );
                          }) :
                            (
                              <tr>
                                <td colSpan="6">No records found</td>
                              </tr>
                            )}
                        </tbody>
                        
                      </table>
                    </div>
                  </div>
                                                          
      </>
    );

  }

}
