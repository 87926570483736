import React, { useState } from 'react';
import { Redirect, Link } from 'react-router-dom';

import $ from 'jquery';
import logo2 from '../../images/logo2.png';
import hamburgIcon from '../../images/hamburger-icon.png';

import axios from 'axios';
import { API_URL, } from '../../config';

import './semantic.css';
import './header.css';


function Logout() {
  localStorage.removeItem('token');
  localStorage.removeItem('user_name');
  const isLogged = !!localStorage.getItem('token');
  if (!isLogged) {
    return <Redirect to="/login" />;
  }
}

function CommonHeader() {
  const [visible, setVisible] = useState(false);
  const [image, setImage]= useState(logo2);


  function toggleMenu() {
    setVisible(!visible);
  }

  function toggleSidebarMenu() {
    $('body').toggleClass('expandBody');
  }

  // Get Token From localstorage
  const user_name = localStorage.getItem('user_name'); 
  const token = localStorage.getItem('token');
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  function getClientLogo () {
    axios
      .get(`${API_URL}/api/client_logo/`, config)
      .then(response => {
        if (response.status == 200 && response.data.data != null) {
          setImage(response.data.data.logo_image);
        } else {}
      })
  }

  getClientLogo();

  return (
    <header>
      <div className="header-inner">
        <div className="header-logo">
          <img src={image} alt="" />
        </div>
        <div className="tgl-btn">
          <Link to="#">
            <img onClick={toggleSidebarMenu} src={hamburgIcon} alt="" />
          </Link>
        </div>
        <div className="header-right ui form">
          <div className="user-profile">
            <div onClick={toggleMenu} className="ui dropdown">
              <div className="text ddclass"> {user_name} </div>
              <i className="dropdown icon ddclass"> </i>
              <div
                className="menu menud"
                style={{ display: visible ? 'block' : 'none' }}>
                <div className="item">
                  <Link to="#" onClick={Logout}> Logout </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

export default CommonHeader;
