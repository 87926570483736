import { Component } from "react";
import {
    API_CALL_FAIL, INTERNAL_FAILURE,
} from './constants';
import {Button, Form, Row, Col } from 'react-bootstrap';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';


export default class DisplayModal extends Component{
    constructor(props){
        super(props);
        this.state = {
            username:'',
            first_name:'',
            last_name:'',
            email:'',
            mobile:'',
            role:'',
            errors: {
                username: '',
                first_name: '',
                last_name: '',
                email: '',
                mobile: '',
                role: '',
            },
            roleOptions:[],
        }

        this.handleChange = this.handleChange.bind(this)
    };

    handleChange (event){
        this.setState({[event.target.name]: event.target.value})
    }

    handleActiveInactive(username) {
      const token = this.props.token;
      const config = this.props.config;
      const resetToken = {
        username,
        token
      };
      axios.put(`${this.props.API_URL}/api/active_inactive/`, resetToken, config)
      .then((response) => {
        if (response.data.status === 200) {
          toast.success(response.data.message,{
            position:'top-right',
            autoClose:3000,
          });
          window.setTimeout(function(){
            window.location.reload();
          }, 3000);
        }
        else if (response.data.status !== 200){
          toast.error(response.data.message, {
            position: 'top-right',
            autoClose: 3500,
          });
          return false;
        }})
        .catch(error => {
          console.log(error);
        });
    }
    //get_user_groups
    getAllPermissions = () =>{
        axios
          .get(`${this.props.API_URL}/api/get_user_groups/`, this.props.config)
          .then(response => {
            if (response.status === 200) {
              let allOptions = response.data.data.map((eachRole)=>
                <option value={eachRole.id}>{eachRole.name}</option>)
              this.setState({roleOptions: allOptions})
            }else if(response.data.status_code == 406) {
              toast.error(API_CALL_FAIL, {
                position: 'top-right',
                autoClose: 3500,
              });
              return false;
            }
          })
          .catch(err => console.log(err))
    };

    validateEmail = (email) => {
      var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    }

    create_user = (event) => {
    let valid = true;
    let allErrors = {};  
    let create_user_data = {
        username: this.state.username,
        first_name: this.state.first_name,
        last_name: this.state.last_name,
        email: this.state.email,
        mobile: this.state.mobile,
        role: this.state.role
      }
      // Vaildations for empty
      if(create_user_data.username==='' || create_user_data.username===undefined){
        allErrors['username'] = 'Please enter Username';
        valid = false;
       }else{allErrors['username'] = '';}
       if(create_user_data.email==='' || create_user_data.email===undefined){
        allErrors["email"] = "Please enter Email";
        valid = false;
       }
       else if (this.validateEmail(create_user_data.email) === false){
         allErrors['email'] = 'Please enter valid Email';
         valid = false;
        }else{allErrors['email'] = '';}
       if(create_user_data.first_name==='' || create_user_data.first_name===undefined){
        allErrors["first_name"] = "Please enter First Name";
        valid = false;
       }else{allErrors['first_name'] = '';}
       if(create_user_data.last_name==='' || create_user_data.last_name===undefined){
        allErrors["last_name"] = "Please enter Last Name";
        valid = false;
       }else{allErrors['last_name'] = '';}
       if(create_user_data.mobile==='' || create_user_data.mobile===undefined){
        allErrors["mobile"] = "Please enter Mobile number";
        valid = false;
       }
       else if(create_user_data.mobile.length!=10){
        toast.error('Mobile Number Length Invalid', {
          position: 'top-right',
          autoClose: 2500,
        });
        allErrors["mobile"] = "Please enter 10 digits in Mobile number";
        valid = false;
       }else{allErrors['mobile'] = '';}
       if(create_user_data.role==='' || create_user_data.role===undefined){
        allErrors["role"] = "Please choose a Role";
        valid = false;
       }else{allErrors['role'] = '';}

       this.setState({errors: allErrors})
       if (valid){
          axios
          .post(`${this.props.API_URL}/api/create_update_admin_users/`,create_user_data, this.props.config)
          .then(response => {
           if (response.status === 200 ) {
             if (response.data.status === 200 || response.data.status === 201){
              //Change the modal display
              this.props.changeAction();
              toast.success(response.data.message,{
                position:'top-right',
                autoClose:3000,
              });
              // window.setTimeout(function(){
              //   window.location.reload();
              // }, 3000);
              return false;
             }
             else if (response.data.status === 406 || response.data.status === 500){
              if (response.data.message.includes("password") && response.data.message.includes("reset"))
              {
                this.props.closeModal() 
              } 
              toast.error(INTERNAL_FAILURE + response.data.message,{
                position:'top-right',
                autoClose:4000,
              });
              return false;
             }
           }
         }) 
         .catch(error => {
          console.log(error);
        });
       }
    }   

    componentDidMount(){
      this.getAllPermissions();
    }
    
    render(){
        let display;
        if (this.props.action ==='NewUser'){
            display = <Form>
            <Form.Group>
              <Row>
                <Col>
                  <div>
                      <Form.Label>User Name <span className='error'>*</span></Form.Label>
                      <Form.Control
                          required
                          name="username"
                          placeholder='Enter User Name'
                          onChange={this.handleChange} />
                  </div>
                  {this.state.errors.username === ""? '': 
                  <span className='error' style={{ color: 'red' }}>{this.state.errors.username}</span>}
                </Col>
                <Col>
                  <div>
                      <Form.Label>Email ID <span className='error'>*</span></Form.Label>
                      <Form.Control
                          required
                          name="email"
                          placeholder='Enter Email ID'
                          onChange={this.handleChange} />
                  </div>
                  {this.state.errors.email === ""? '': 
                  <span className='error' style={{ color: 'red' }}>{this.state.errors.email}</span>}
                </Col>
              </Row>
              </Form.Group>
              <Form.Group>
              <Row>
                  <Col>
                  <div>
                      <Form.Label>First Name <span className='error'>*</span></Form.Label>
                      <Form.Control
                          required
                          type="text"
                          name="first_name"
                          placeholder='Enter First Name' 
                          onChange={this.handleChange}/>
                  </div>
                  {this.state.errors.first_name === ""? '': 
                  <span className='error' style={{ color: 'red' }}>{this.state.errors.first_name}</span>}
                  </Col>
                  <Col>
                  <div >
                      <Form.Label>Last Name <span className='error'>*</span></Form.Label>
                      <Form.Control
                          required
                          type="text"
                          name="last_name"
                          placeholder='Enter Last Name'
                          onChange={this.handleChange}
                      />
                  </div>
                  {this.state.errors.last_name === ""? '': 
                  <span className='error' style={{ color: 'red' }}>{this.state.errors.last_name}</span>}
                  </Col>
              </Row>
              </Form.Group>
              <Form.Group>
              <Row>
                  <Col>
                  <div>
                      <Form.Label>Mobile Number <span className='error'>*</span></Form.Label>
                      <Form.Control
                          required
                          type="text"
                          name="mobile"
                          placeholder='Enter Mobile Number' 
                          onChange={this.handleChange}/>
                  </div>
                  {this.state.errors.mobile === ""? '': 
                  <span className='error' style={{ color: 'red' }}>{this.state.errors.mobile}</span>}
                  </Col>
                  <Col>
                  <div >
                      <Form.Label>Role <span className='error'>*</span></Form.Label>
                      <Form.Control
                        as="select"
                        className="my-1 mr-sm-2"
                        id="role"
                        name="role"
                        required
                        onChange={this.handleChange}
                      >
                      <option value="">Please Select Role</option>
                      {this.state.roleOptions}
                      </Form.Control>
                  </div>
                  {this.state.errors.role === ""? '': 
                  <span className='error' style={{ color: 'red' }}>{this.state.errors.role}</span>}
                  </Col>
              </Row>
              </Form.Group>
              <Form.Group>
                  <Button variant="success" onClick={this.create_user}>
                    Create
                  </Button>
                  <Button variant="danger" onClick={this.props.closeModal}>
                    Cancel
                  </Button>
              </Form.Group> 
            </Form>
        }
        else if (this.props.action === 'EmailPopUp'){
            display = <div>
              <h1>Password Reset Email sent to user {this.state.username}</h1>
              <Button variant="danger" onClick={this.props.closeModal}>
                Close
              </Button>
            </div>
        }
        else if (this.props.action === 'Activate'){
          display = <div>
            <div>
              <h1 className="mb-2">Warning!</h1>
              <h2 className="m-2">Clicking this button will enable the user’s access. Proceed?</h2>
            </div>
            <div>
                <Button variant="success" onClick ={() =>{this.handleActiveInactive(this.props.activationUsername)}}>
                  Yes
                </Button>
                <Button variant="danger" onClick={this.props.closeModal}>
                  No
                </Button>
            </div>
        </div>
        }
        else if (this.props.action === 'Deactivate'){
          display = <div>
            <div>
              <h1 className="mb-2">Warning!</h1>
              <h2 className="m-2">Clicking this button will disable the user’s access. Proceed?</h2>
            </div>
            <div>
                <Button variant="success" onClick ={() =>{this.handleActiveInactive(this.props.activationUsername)}}>
                  Yes
                </Button>
                <Button variant="danger" onClick={this.props.closeModal}>
                  No
                </Button>
            </div>
        </div>
        }
        return(
          <div>
              {display}
          </div>
        )
    }
}