import React from 'react';
import axios from 'axios';
import { Redirect, Link } from 'react-router-dom';
import { Accordion, Card, Button, Modal } from 'react-bootstrap';
import dateFormat from 'dateformat';
import { ToastContainer, toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert';
import $ from 'jquery';
import PlusIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import DeleteIcon from '@material-ui/icons/Delete';
import Moment from 'moment';
import Select from 'react-select';
import IconWithTooltip from 'icon-with-tooltip';
import CheckIcon from '@material-ui/icons/Check';
import InfoIcon from '@material-ui/icons/Info';
import 'react-toastify/dist/ReactToastify.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'bootstrap/dist/css/bootstrap.css';
import '../../containers/css/style_semantic.css';
import './style.css';
import './bootstrap.min.css';
import 'react-confirm-alert/src/react-confirm-alert.css';

import {
    VALID_EDUCATION_DATA,
    SUCCESS_EDUCATION_DATA,
    UPDATE_EDUCATION_DATA,
    INTERNAL_SERVER_ERROR,
    ASSESSMENT_ACTIVE,
    TOKEN_EXPIRED,
    ALL_FIELDS_REQUIRED,
    MEDICATION_MISSING
  } from './constants';
  
  import {
    API_URL,
  } from '../../config';

  var prescribingMD;
  var officePhone;

export default class EducationAssessment extends React.Component {
    constructor(props) {
      super(props);
      this.RefillCodeNotes = React.createRef();
      this.Archives = React.createRef();
      this.AllFillHistory = React.createRef();
      this.therapy = React.createRef();
      this.state = {
        currentDate: new Date('07/01/2021'), 
        isgotshow: false,
        updDiagnosisCodes: [],
        diagnosisCodes: [],
        selDiagnosisCodeValue: [],
        selPriDiagnosisCodeValue: [],
        selDiagCodeIdsArr: [],
        selPriDiagCodeIdsArr: [],
        eduAssessmentForms: [],
        trackEduAssessmentForms: [],
        eduAssErrors: [],
        eduMedErrors: [],
        selectedDiagnosisCodes: [],
        priSelDiagnosisCodes: [],
        medicationsArr: [],
       
        show: false,
        isFetchData: false,
        activeMenu: true,
        activePlus: false,
        activeMinus: true,
        showHideOpt: true,
        activeAddAssessment: false,
        minusAddAssessment: false,
        activeAssPlus: false,
        activeAssMinus: true,
        
        errors: {
          is_active: '',
          participation_status: '',
          opt_out_reason: '',
          emergency_contact: '',
          emergency_contact_phone: '',
          relationship_to_patient: '',
          person_spoken_to: '',
          welcome_packet: '',
          diagnosis_codes: '',
          primary_diagnosis_codes: '',
          physical_edu_complete: '',
          self_admin_meds: '',
          administration_route: '',
          direction: '',
          missed_dose_instructions: '',
          reproductive_implications: '',
          storage: '',
          disposal: '',
          appropriateness: '',
          goals_of_therapy: '',
          drug_drug: '',
          drug_disease: '',
          drug_condition: '',
          dur_other: '',
          common_side_effects: '',
          other_important_info: '',
          quality_of_life: '',
          how_to_contact: '',
          time_with_patient: '',
        },
        assessment_id: this.props.assessment_id !== undefined ? this.props.assessment_id : null,
        transaction_id: this.props.transaction_id !== undefined ? this.props.transaction_id : null,
        patient_id: this.props.patient_id !== undefined ? this.props.patient_id : null,
        patient_name: this.props.patient_name !== undefined ? this.props.patient_name : null,
        data_added: this.props.data_added !== undefined ? this.props.data_added : null,
        eduData: {
          transaction: 205,
          is_active: true,
          is_completed: false,
          participation_status: '',
          opt_out_reason: '',
          patient_output: 0,
          person_spoken_to: '',
          person_spoken_to_comments: '',
          relationship_to_patient: 0,
          relationship_to_patient_comments: '',
          emergency_contact: '',
          emergency_contact_comments: '',
          emergency_contact_phone: '',
          emergency_contact_phone_comments: '',
          emergency_contact_declined: false,
          emergency_contact_declined_comments: '',
          previous_therapy: '',
          previous_therapy_comments: '',
          previous_pharmacy: '',
          previous_pharmacy_comments: '',
          welcome_packet: false,
          welcome_packet_comments: '',
          hipaa_signature: true,
          hipaa_form: '',
          hipaa_signature_comments: null,
          hipaa_form_comments: false,
          physical_edu_complete: '',
          physical_edu_complete_comments: '',
          self_admin_meds: '',
          self_admin_meds_comments: '',
          administration_route: '',
          administration_route_comments: '',
          direction: '',
          direction_comments: '',
          missed_dose_instructions: '',
          mdi_comments: '',
          reproductive_implications: '',
          storage: '',
          storage_comments: '',
          disposal: '',
          disposal_comments: '',
          appropriateness: '',
          appropriateness_comments: '',
          goals_of_therapy: '',
          goals_of_therapy_comments: '',
          drug_drug: '',
          drug_drug_comment: '',
          drug_disease: '',
          drug_disease_comment: '',
          drug_condition: '',
          drug_condition_comment: '',
          dur_other: '',
          dur_other_comment: '',
          common_side_effects: '',
          cse_comments: '',
          other_important_info: '',
          other_imp_info_comments: '',
          quality_of_life: 0,
          quality_of_life_comments: '',
          how_to_contact: '',
          how_to_contact_comments: '',
          time_with_patient: '',
          time_with_patient_comments: '',
          therapy_others: '',
          therapy_others_comments: ''
        },
        
        medicationsInputList: [
          {
            id: "",
            refrigerate: "",
            prescribingMD: "",
            officePhone: "",
          },
        ],
        updMedInputList: [
          {
            id: "",
            refrigerate: "",
            prescribingMD: "",
            officePhone: "",
          },
        ],
    }
      this.myInitObject = null;
    }

    // get token from loclastorage
    token = localStorage.getItem('token');
    config = {
        headers: { Authorization: `Bearer ${this.token}` },
    };

    /* Method For Assessment Count After Component Rendering */
    componentDidMount() {
        if(new Date(this.state.data_added) < this.state.currentDate){
          this.setState({ 'isgotshow': true});
        }
        const eduMedErr = [];
        for (var i = 0; i < (this.state.medicationsInputList.length); i++) {
          eduMedErr.push({
            id: "",
            refrigerate: "",
            prescribingMD: "",
            officePhone: "",
          });
        }
        this.setState({ eduMedErrors: eduMedErr });
    
         this.getEducationData();
         this.getMedication();
      }  


      plusMinusAddAssessmentToggle = event => {
        if (event === 'plus') {
          this.setState({ 'activeAssPlus': true });
          this.setState({ 'activeAddAssessment': false });
          this.setState({ 'activeAssMinus': false });
        } else {
          this.setState({ 'activeAssPlus': false });
          this.setState({ 'activeAddAssessment': true });
          this.setState({ 'activeAssMinus': true });
        }
      };
    
      hideShow = event => {
        //console.log('Event', event);
        if (event === 'plus') {
          this.setState({ 'activePlus': true });
          this.setState({ 'activeMinus': false });
        } else {
          this.setState({ 'activeMinus': true });
          this.setState({ 'activePlus': false });
        }
      };
   getEducationData = () =>{
    axios
    .get(`${API_URL}/api/educational_assessment/?patient_id=` + this.state.patient_id, this.config)
    .then(response => {
      //console.log('Response Status', response.status);

      if (response.status === 200) {
        if (response.data.status === 200) {
          if (response.data.data.length > 0) {
            this.setState({
              'activeAddAssessment': true,
              'activeAssPlus': false,
            });
            
            for (var index = 0; index < response.data.data.length; index++) {
              if (response.data.data[index]['participation_status'] == 1) {
                response.data.data[index]['showHideOpt'] = false;
              } else {
                response.data.data[index]['showHideOpt'] = true;
              }
              //opts out select and then page refresh so not a mantatory field
              if (response.data.data[index]['participation_status'] == 1) {
                this.setState({ 'showHideOpt': false });
              } else if (response.data.data[index]['participation_status'] != 1 ) {
                this.setState({ 'showHideOpt': true });
              }

              const arr = [];

              if (response.data.data[index].diagnosis_code != null) {
                for (var i = 0; i < response.data.data[index].diagnosis_code.length; i++) {
                  arr.push({ label: response.data.data[index].diagnosis_code[i].code_with_short_desc, value: response.data.data[index].diagnosis_code[i].id });
                }
              }

              this.state.selectedDiagnosisCodes[index] = arr;

              if (response.data.data[index].primary_diagnosis_code != null) {
                this.state.priSelDiagnosisCodes[index] = [{ label: response.data.data[index].primary_diagnosis_code.code_with_short_desc, value: response.data.data[index].primary_diagnosis_code.id }];
              } else {
                this.state.priSelDiagnosisCodes[index] = null;
              }
            }

            this.setState({
              eduAssessmentForms: response.data.data,
            });
            let trackAsseData = response.data.data;
            this.setState({
              trackEduAssessmentForms: trackAsseData,
            });

            const eduErrors = [];
            for (var i = 0; i < response.data.data.length; i++) {
              eduErrors.push(this.state.errors);
            }

            this.setState({ eduAssErrors: eduErrors });
          } else {
            this.setState({
              eduAssessmentForms: [],
            });
          }
        } else if (response.data.status_code == 406) {
          localStorage.removeItem('token');
          localStorage.removeItem('user_name');

          localStorage.removeItem('adminCheck');
          localStorage.setItem('tokenExpired', true);

          const tokenStatus = localStorage.getItem('tokenExpired');
          if (tokenStatus) {
            localStorage.removeItem('tokenExpired');
            toast.error(TOKEN_EXPIRED, {
              position: 'top-right',
              autoClose: 2500,
            });
          }

          const isLogged = !!localStorage.getItem('token');
          if (!isLogged) {
            setTimeout(() => {
              this.props.history.push("/login");
            }, 2500);
          }
        }
        else if (response.data.status === 204) {
          this.setState({
            'activeAddAssessment': false,
            'activeAssPlus': true,
          });
          this.setState({
            eduAssessmentForms: [],       
          });
           return response;
        }
      } 
    })
    .catch(error => {
      console.log(error);
    });
   }   

  /* Get Education Listing Common Method*/
  getEducation = () => {
    if(new Date(this.state.data_added) < this.state.currentDate){
      this.setState({ 'isgotshow': true});
    }

    if (localStorage.getItem('patAllFillsHisChanged') || localStorage.getItem('isPatientAddDelChanged') ||
      localStorage.getItem('patRefillCallNotesChanged') || localStorage.getItem('patCommunicationsChanged')
      || localStorage.getItem('patTherapyInputChanged') || localStorage.getItem('isAssChanged')) {
      const confirmBox = window.confirm("You have unsaved changes! Do you really want to move to another tab?");
      if (confirmBox === true) {
        localStorage.setItem('prevTab', localStorage.getItem('currTab'));
        localStorage.setItem('currTab', 'education');

        localStorage.removeItem('isPatientAddDelChanged');
        localStorage.removeItem('patAllFillsHisChanged');
        localStorage.removeItem('patRefillCallNotesChanged');
        localStorage.removeItem('patCommunicationsChanged');
        localStorage.removeItem('patTherapyInputChanged');
        localStorage.removeItem('isAssChanged');

        $("#eduAssessment").attr('href', '#education');
        $("#medTherapy").removeAttr('href');
        $("#assCommunications").removeAttr('href');
        $("#allFilesHis").removeAttr('href');
        $("#schDelivery").removeAttr('href');
        $("#refCallNotes").removeAttr('href');
        $("#archivesAss").removeAttr('href');
        $("#oncoAssessments").removeAttr('href');

      } else {
        setTimeout(() => this.props.setTab(), 250);
      }
    } else {
      localStorage.setItem('prevTab', localStorage.getItem('currTab'));
      localStorage.setItem('currTab', 'education');

      $("#eduAssessment").attr('href', '#education');
      $("#medTherapy").removeAttr('href');
      $("#assCommunications").removeAttr('href');
      $("#allFilesHis").removeAttr('href');
      $("#schDelivery").removeAttr('href');
      $("#refCallNotes").removeAttr('href');
      $("#archivesAss").removeAttr('href');
      $("#oncoAssessments").removeAttr('href');

    }
    this.setState({
      eduAssessmentForms: [],
    });
    
    this.getEducationData();
  };


  /* Get Medications Listing Common Method*/
  getMedication = () => {
    axios
      .get(`${API_URL}/api/get_therapy_info/?patient_id=${this.state.patient_id}`, this.config)
      .then(response => {
        const arr = [];
        const arrIds = [];

        if (response.status === 200) {
          for (var i = 0; i < response.data.data.length; i++) {
            arr.push({ name: response.data.data[i].name, id: response.data.data[i].trans_med_id });
            arrIds.push(response.data.data[i].trans_med_id);
          }

          this.setState({ medicationsArr: arr });
          this.setState({ medicationIdsArr: arrIds });
          //console.log('Array', arr);
        }
      })
      .catch(error => {
        console.log(error);
      });
  }

   /* Method For Handling File Upload For Hipaa Form */
   onFileChange = event => {
    this.myInitObject = event.target.files[0];
    //console.log('My Init Object', this.myInitObject);
  };

  /* Methods For Handling Education Data Form Submit */
  handleEduFormSubmit(event) {
    event.preventDefault();
    const { assessment_id } = this.state;

    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    let list = { ...this.state.eduData };
    let medicationsList = { ...this.state.medicationsInputList };
    let errors = { ...this.state.errors };

    let isDraft;

    if (list.participation_status == 0 || list.is_active == false || list.relationship_to_patient == ''
      || list.person_spoken_to == '' || list.welcome_packet == false || list.physical_edu_complete == ''
      || list.self_admin_meds == '' || list.administration_route == '' || list.direction == '' ||
      list.missed_dose_instructions == '' || list.reproductive_implications == '' || list.storage == ''
      || list.disposal == '' || list.appropriateness == '' || (this.state.isgotshow == true && list.goals_of_therapy == false) || list.drug_drug == ''
      || list.drug_disease == '' || list.drug_condition == '' || list.common_side_effects == false
      || list.quality_of_life == 0 || list.how_to_contact == false
      || list.time_with_patient == '' || this.state.selDiagCodeIdsArr.length == 0 || typeof(this.state.selPriDiagCodeIdsArr) != 'number') {
      isDraft = true;
    } else {
      isDraft = false;
    }
    // Check if conditions are met for OPT OUT and make isDraft = False
    if (list.participation_status == 1){
      if(list.is_active != false && list.opt_out_reason != '' && list.relationship_to_patient != '' && list.relationship_to_patient != '0' && list.person_spoken_to != '' && list.how_to_contact != false && list.time_with_patient != '' &&
      this.state.selDiagCodeIdsArr.length != 0 && typeof(this.state.selPriDiagCodeIdsArr) == 'number'){
      isDraft = false
      }
      else{
      isDraft = true
      }
    }
    // If is completed is ticked with isDraft being true
    if (list.is_completed == true && isDraft == true ) {
      toast.error(ALL_FIELDS_REQUIRED, {
        position: 'top-right',
        autoClose: 3500,
      });
    }
    if (list.participation_status === '' || list.participation_status === 0) {
      errors.participation_status = 'Please Select The Participation Status.';
    } else {
      errors.participation_status = '';
    }

    if (list.is_active === false) {
      errors.is_active = 'Please Check The Assessment Active.';
      toast.error(ASSESSMENT_ACTIVE, {
        position: 'top-right',
        autoClose: 3000,
      });
    } else {
      errors.is_active = '';
    }

    if (list.participation_status == 1 && list.opt_out_reason === '') {
      errors.opt_out_reason = 'Please Enter Reason for Opt Out.';
    } else {
      errors.opt_out_reason = '';
    }

    if (list.relationship_to_patient == 0) {
      errors.relationship_to_patient = 'Please Enter Relationship To Patient.';
    } else {
      errors.relationship_to_patient = '';
    }

    if (list.person_spoken_to === '') {
      errors.person_spoken_to = 'Please Enter Person Spoken To.';
    } else {
      errors.person_spoken_to = '';
    }
    if (list.participation_status != 1){
      if (list.welcome_packet === false) {
      errors.welcome_packet = 'Please Enter Welcome Packet.';
    } else {
      errors.welcome_packet = '';
    }
    }else{
      errors.welcome_packet = '';
    }
   
    if (list.participation_status != 1){
      if (list.physical_edu_complete === '') {
      errors.physical_edu_complete = 'Please Enter Physical Education Complete.';
    } else {
      errors.physical_edu_complete = '';
    }
    }else{
      errors.physical_edu_complete = '';
    }

    if (list.participation_status != 1){
      if (list.self_admin_meds === '') {
      errors.self_admin_meds = 'Please Enter Self Admin Medications.';
    } else {
      errors.self_admin_meds = '';
    }
    }else{
      errors.self_admin_meds = '';
    }

    if (list.participation_status != 1){
      if (list.administration_route === '') {
      errors.administration_route = 'Please Enter Administration Route.';
    } else {
      errors.administration_route = '';
    }
    }else{
      errors.administration_route = '';
    }
    
    if (list.participation_status != 1){
      if (list.direction === '') {
      errors.direction = 'Please Enter Directions.';
    } else {
      errors.direction = '';
    }
    }else{
      errors.direction = '';
    }
    
    if (list.participation_status != 1){
      if (list.missed_dose_instructions === '') {
      errors.missed_dose_instructions = 'Please Enter Missed Dose Instructions.';
    } else {
      errors.missed_dose_instructions = '';
    }
    }else{
      errors.missed_dose_instructions = '';
    }
    
    if (list.participation_status != 1){
      if (list.reproductive_implications === '') {
      errors.reproductive_implications = 'Please Enter Reproductive Implications.';
    } else {
      errors.reproductive_implications = '';
    }
    }else{
      errors.reproductive_implications = '';
    }
    
    if (list.participation_status != 1){
      if (list.storage === '') {
      errors.storage = 'Please Enter Storage.';
    } else {
      errors.storage = '';
    }
    }else{
      errors.storage = '';
    }
    
    if (list.participation_status != 1){
      if (list.disposal === '') {
      errors.disposal = 'Please Enter Disposal.';
    } else {
      errors.disposal = '';
    }
    }else{
      errors.disposal = '';
    }
   
    if (list.participation_status != 1){
      if (list.appropriateness === '') {
      errors.appropriateness = 'Please Enter Appropriateness.';
    } else {
      errors.appropriateness = '';
    }
    }else{
      errors.appropriateness = '';
    }

    if(this.state.isgofshow)
    {
      if (list.goals_of_therapy == false) {
        errors.goals_of_therapy = 'Please Enter Goals Of Therapy.';
      } else {
        errors.goals_of_therapy = '';
      }
    }
    if (list.participation_status != 1){
      if (list.drug_drug === '') {
      errors.drug_drug = 'Please Enter Drug Drug.';
    } else {
      errors.drug_drug = '';
    }
    }else{
      errors.drug_drug = '';
    }
    
    if (list.participation_status != 1){
      if (list.drug_disease === '') {
      errors.drug_disease = 'Please Enter Drug Disease.';
    } else {
      errors.drug_disease = '';
    }
    }else{
      errors.drug_disease = '';
    }
    
    if (list.participation_status != 1){
      if (list.drug_condition === '') {
      errors.drug_condition = 'Please Enter Drug Condition.';
    } else {
      errors.drug_condition = '';
    }
    }else{
      errors.drug_condition = '';
    }
    
    if (list.participation_status != 1){
      if (list.common_side_effects == false) {
      errors.common_side_effects = 'Please Enter Common Side Effects.';
    } else {
      errors.common_side_effects = '';
    }
    }else{
      errors.common_side_effects = '';
    }
    if (list.participation_status != 1){
      if (list.quality_of_life == false) {
      errors.quality_of_life = 'Please Enter Quality Of Life.';
    } else {
      errors.quality_of_life = '';
    }
    }else{
      errors.quality_of_life = '';
    }
    if (list.how_to_contact == false) {
      errors.how_to_contact = 'Please Enter How To Contact.';
    } else {
      errors.how_to_contact = '';
    }

    if (list.time_with_patient == '') {
      errors.time_with_patient = 'Please Enter Time With Patient.';
    } else {
      errors.time_with_patient = '';
    }
    if (this.state.selDiagCodeIdsArr.length == 0) {
      errors.diagnosis_codes = 'Please Enter Diagnosis Codes.';
    } else {
      errors.diagnosis_codes = '';
    }

    if (this.state.selPriDiagCodeIdsArr.length == 0) {
      errors.primary_diagnosis_codes = 'Please Enter Primary Diagnosis Codes.';
    } else if (this.state.selPriDiagCodeIdsArr.length > 1) {
      errors.primary_diagnosis_codes = 'Please Enter Only One Primary Diagnosis Codes.';
    } else {
      errors.primary_diagnosis_codes = '';
    }

    let medList = [...this.state.medicationsInputList];
    let errMedEdu = [...this.state.eduMedErrors];

    //Checks if medication list is empty
    if (medList[0].id == '') {
      medList = [];
    } else { }

    if (this.state.selPriDiagCodeIdsArr.length == 0) {
      this.state.selPriDiagCodeIdsArr = '';
    } else { }

    for (var j = 0; j < medList.length; j++) {
      if (medList[j].id == '') {
        medList.splice(j, 1);
      }
    }
    if (medList.length == 0){
      errMedEdu[0].id = 'Please Enter The Medications.'
    }

    
    else{for (var z = 0; z < medList.length; z++) {
      if (medList[z].id == '') {
        errMedEdu[z].id = 'Please Enter The Medications';
      } else {
        errMedEdu[z].id = '';
      }

      if (medList[z].refrigerate == '') {
        errMedEdu[z].refrigerate = 'Please Enter The Refrigerate.';
      } else {
        errMedEdu[z].refrigerate = '';
      }

      if (medList[z].prescribingMD == '') {
        errMedEdu[z].prescribingMD = 'Please Enter The Prescribing MD.';
      } else {
        errMedEdu[z].prescribingMD = '';
      }

      if (medList[z].officePhone == '') {
        errMedEdu[z].officePhone = 'Please Enter The Office Phone.';
      } else {
        errMedEdu[z].officePhone = '';
      }

      
    }}
    
    this.setState({ eduMedErrors: errMedEdu });
    // Resets the error list if this is a draft
    if (!list.is_completed) {
      errors.participation_status = '';
      errors.relationship_to_patient = '';
      errors.person_spoken_to = '';
      errors.welcome_packet = '';
      
      errors.physical_edu_complete = '';
      errors.self_admin_meds = '';
      errors.administration_route = '';
      errors.direction = '';
      errors.missed_dose_instructions = '';
      errors.reproductive_implications = '';

      errors.storage = '';
      errors.disposal = '';
      errors.appropriateness = '';
      errors.goals_of_therapy = '';
      errors.drug_drug = '';

      errors.drug_disease = '';
      errors.drug_condition = '';
      errors.dur_other = '';
      errors.common_side_effects = '';
      errors.other_important_info = '';

      errors.quality_of_life = '';
      errors.how_to_contact = '';
      errors.time_with_patient = '';

      errors.diagnosis_codes = '';
      errors.primary_diagnosis_codes = '';
      this.setState({ errors: errors });

      for (var z = 0; z < medList.length; z++) {
          if (z > 0){
            errMedEdu[z].id = '';
          }
          errMedEdu[z].refrigerate = '';
          errMedEdu[z].prescribingMD = '';
          errMedEdu[z].officePhone = '';
      }
      this.setState({ eduMedErrors: errMedEdu });
    }

    if (errMedEdu[0].id !== ''){
      toast.error(MEDICATION_MISSING, {
        position: 'top-right',
        autoClose: 3500,
      });
    }

    // Checks the first if the first is True the rest aren't checked.
    if (errMedEdu[0].id !== '' || errors.participation_status !== '' || errors.is_active !== '' || errors.opt_out_reason !== '' || errors.relationship_to_patient !== '' || errors.person_spoken_to !== '' ||
      errors.welcome_packet !== '' || errors.physical_edu_complete !== '' || errors.self_admin_meds !== '' || errors.administration_route !== '' || errors.direction !== '' ||
      errors.missed_dose_instructions !== '' || errors.reproductive_implications !== '' || errors.storage !== '' || errors.disposal !== '' || errors.appropriateness !== '' ||
      errors.goals_of_therapy !== '' || errors.drug_drug !== '' || errors.drug_disease !== '' || errors.drug_condition !== '' || errors.common_side_effects !== ''
      || errors.other_important_info !== '' || errors.quality_of_life !== '' || errors.how_to_contact !== '' || errors.time_with_patient !== '' || errors.diagnosis_codes !== '' || errors.primary_diagnosis_codes !== '') {
      this.setState({ eduMedErrors: errMedEdu });
      this.setState({ errors: errors });
      return false;
    }

    var isActive;
    if ($('#isActive').is(":checked")) {
      isActive = true;
    } else {
      isActive = false;
    }

    var emeContactDeclined;
    if ($('#emeContactDeclined').is(":checked")) {
      emeContactDeclined = true;
    } else {
      emeContactDeclined = false;
    }

    var welcomePacket;
    if ($('#welcomePacket').is(":checked")) {
      welcomePacket = true;
    } else {
      welcomePacket = false;
    }

    var diafnosisCodes;
    if($('#diafnosisCodes').is(":checked")){
      diafnosisCodes = true;
    }else {
      diafnosisCodes = false;
    }


    var hipaaSignature;
    if ($('#hipaaSignature').is(":checked")) {
      hipaaSignature = true;
    } else {
      hipaaSignature = false;
    }

    var goalsOfTherapy;
    if ($('#goalsOfTherapy').is(":checked")) {
      goalsOfTherapy = true;
    } else {
      goalsOfTherapy = false;
    }

    var howToContact;
    if ($('#howToContact').is(":checked")) {
      howToContact = true;
    } else {
      howToContact = false;
    }

    var otherImpInfo;
    if ($('#otherImpInfo').is(":checked")) {
      otherImpInfo = true;
    } else {
      otherImpInfo = false;
    }

    var CommonSideEffects;
    if ($('#CommonSideEffects').is(":checked")) {
      CommonSideEffects = true;
    } else {
      CommonSideEffects = false;
    }

    var isCompleted;
    if ($('#isCompleted').is(":checked")) {
      isCompleted = true;
    } else {
      isCompleted = false;
    }

    const formData = {
      patient: this.state.patient_id,
      is_active: isActive,
      participation_status: document.getElementById("participation_status").value,
      opt_out_reason: document.getElementById("optOutReason").value,
      person_spoken_to: document.getElementById("personSpokenTo").value,
      person_spoken_to_comments: document.getElementById("personSpokenToComments").value,
      relationship_to_patient: document.getElementById("relationshipToPatient").value,
      relationship_to_patient_comments: document.getElementById("relationshipToPatientComments").value,
      emergency_contact: document.getElementById("emergencyContact").value,
      emergency_contact_comments: document.getElementById("emergencyContactComments").value,
      emergency_contact_phone: document.getElementById("emergencyContactPhone").value,
      emergency_contact_phone_comments: document.getElementById("emergencyContactPhoneComments").value,
      emergency_contact_declined: emeContactDeclined,
      previous_therapy: document.getElementById("previousTherapy").value,
      previous_therapy_comments: document.getElementById("previousTherapyComments").value,
      previous_pharmacy: document.getElementById("previousPharmacy").value,
      previous_pharmacy_comments: document.getElementById("previousPharmacyComments").value,
      welcome_packet: welcomePacket,
      welcome_packet_comments: document.getElementById("welcomePacketComments").value,
      hipaa_signature: hipaaSignature,
      hipaa_form: this.myInitObject,
      hipaa_signature_comments: 'Present',
      hipaa_form_comments: 'Screenshot',
      physical_edu_complete: document.getElementById("physicalEduComplete").value,
      physical_edu_complete_comments: document.getElementById("physicalEduCompleteComments").value,
      self_admin_meds: document.getElementById("selfAdminMeds").value,
      self_admin_meds_comments: document.getElementById("selfAdminMedsComments").value,
      administration_route: document.getElementById("administrationRoute").value,
      administration_route_comments: document.getElementById("administrationRouteComments").value,
      direction: document.getElementById("direction").value,
      direction_comments: document.getElementById("directionComments").value,
      missed_dose_instructions: document.getElementById("missedDoseInstructions").value,
      mdi_comments: document.getElementById("mdiComments").value,
      reproductive_implications: document.getElementById("reproductiveImplications").value,
      rprdi_comments: document.getElementById("rprdiComments").value,
      storage: document.getElementById("storage").value,
      storage_comments: document.getElementById("storageComments").value,
      disposal: document.getElementById("disposal").value,
      disposal_comments: document.getElementById("disposalComments").value,
      appropriateness: document.getElementById("appropriateness").value,
      appropriateness_comments: document.getElementById("appropriatenessComments").value,
      goals_of_therapy: goalsOfTherapy,
      goals_of_therapy_comments: document.getElementById("goalsOfTherapyComments") ? document.getElementById("goalsOfTherapyComments").value : null,
      drug_drug: document.getElementById("drugDrug").value,
      drug_drug_comment: document.getElementById("drugDrugComment").value,
      drug_disease: document.getElementById("drugDisease").value,
      drug_disease_comment: document.getElementById("drugDiseaseComment").value,
      drug_condition: document.getElementById("drugCondition").value,
      drug_condition_comment: document.getElementById("drugConditionComment").value,
      dur_other: document.getElementById("durOther").value,
      dur_other_comment: document.getElementById("durOtherComment").value,
      common_side_effects: CommonSideEffects,
      cse_comments: document.getElementById("cseComments").value,
      other_important_info: otherImpInfo,
      other_imp_info_comments: document.getElementById("otherImpInfoComments").value,
      quality_of_life: document.getElementById("qualityOfLife").value,
      quality_of_life_comments: document.getElementById("qualityOfLifeComments").value,
      how_to_contact: howToContact,
      how_to_contact_comments: 'Provided',
      time_with_patient: document.getElementById("timeWithPatient").value,
      therapy_others: 0,
      therapy_others_comments: 'N/A',
      is_completed: isCompleted,
      is_draft: isCompleted ? false : true,
      medication: medList,
      diagnosis_code: this.state.selDiagCodeIdsArr,
      
      primary_diagnosis_code: this.state.selPriDiagCodeIdsArr,
    }

    return axios.post(`${API_URL}/api/educational_assessment/`, formData, config)
      .then((response) => {
        if (response.status == 200) {
          if (response.data.status == 200 || response.data.status == 201) {
            localStorage.removeItem('isPatientEduAssChanged');
            toast.success(SUCCESS_EDUCATION_DATA, {
              position: 'top-right',
              autoClose: 3000,
            });

            axios
              .get(`${API_URL}/api/educational_assessment/?patient_id=` + this.state.patient_id, this.config)
              .then(response => {
                let trackAsseData = response.data.data;
                this.setState({
                  trackEduAssessmentForms: trackAsseData,
                });
              })
              .catch(error => {
                console.log(error);
              });

            this.setState({
              eduData: {
                is_active: true,
                is_completed: false,
                participation_status: '',
                opt_out_reason: '',
                patient_output: 0,
                person_spoken_to: '',
                person_spoken_to_comments: '',
                relationship_to_patient: 0,
                relationship_to_patient_comments: '',
                emergency_contact: '',
                emergency_contact_comments: '',
                emergency_contact_phone: '',
                emergency_contact_phone_comments: '',
                emergency_contact_declined: false,
                emergency_contact_declined_comments: '',
                previous_therapy: '',
                previous_therapy_comments: '',
                previous_pharmacy: '',
                previous_pharmacy_comments: '',
                welcome_packet: false,
                welcome_packet_comments: '',
                hipaa_signature: true,
                hipaa_form: '',
                hipaa_signature_comments: null,
                hipaa_form_comments: false,
                physical_edu_complete: '',
                physical_edu_complete_comments: '',
                self_admin_meds: '',
                self_admin_meds_comments: '',
                administration_route: '',
                administration_route_comments: '',
                direction: '',
                direction_comments: '',
                missed_dose_instructions: '',
                mdi_comments: '',
                reproductive_implications: '',
                storage: '',
                storage_comments: '',
                disposal: '',
                disposal_comments: '',
                appropriateness: '',
                appropriateness_comments: '',
                goals_of_therapy: '',
                goals_of_therapy_comments: '',
                drug_drug: '',
                drug_drug_comment: '',
                drug_disease: '',
                drug_disease_comment: '',
                drug_condition: '',
                drug_condition_comment: '',
                dur_other: '',
                dur_other_comment: '',
                common_side_effects: '',
                cse_comments: '',
                other_important_info: '',
                other_imp_info_comments: '',
                quality_of_life: 0,
                quality_of_life_comments: '',
                how_to_contact: '',
                how_to_contact_comments: '',
                time_with_patient: '',
                time_with_patient_comments: '',
                therapy_others: '',
                therapy_others_comments: ''
              },
            });
          } else if (response.data.status_code == 406) {
            localStorage.removeItem('token');
            localStorage.removeItem('user_name');

            localStorage.removeItem('adminCheck');
            localStorage.setItem('tokenExpired', true);

            const tokenStatus = localStorage.getItem('tokenExpired');
            if (tokenStatus) {
              localStorage.removeItem('tokenExpired');
              toast.error(TOKEN_EXPIRED, {
                position: 'top-right',
                autoClose: 2500,
              });
            }

            const isLogged = !!localStorage.getItem('token');
            if (!isLogged) {
              setTimeout(() => {
                this.props.history.push("/login");
              }, 2500);
            }
          } else if (response.data.status == 400) {
            toast.error(response.data.message, {
              position: 'top-right',
              autoClose: 2500,
            });
            return false;
          }

          axios
            .get(`${API_URL}/api/educational_assessment/?patient_id=` + this.state.patient_id, this.config)
            .then(response => {

              if (response.status === 200) {
                if (response.data.status === 200) {
                  if (response.data.data.length > 0) {
                    this.setState({
                      'activeAddAssessment': true,
                      'activeAssPlus': false,
                      'activeAssMinus': true,
                    });

                    for (var index = 0; index < response.data.data.length; index++) {
                      if (response.data.data[index]['participation_status'] == 1) {
                        response.data.data[index]['showHideOpt'] = false;
                      } else {
                        response.data.data[index]['showHideOpt'] = true;
                      }

                      const arr = [];

                      if (response.data.data[index].diagnosis_code != null) {
                        for (var i = 0; i < response.data.data[index].diagnosis_code.length; i++) {
                          arr.push({ label: response.data.data[index].diagnosis_code[i].code_with_short_desc, value: response.data.data[index].diagnosis_code[i].id });
                        }
                      }

                      this.state.selectedDiagnosisCodes[index] = arr;

                      if (response.data.data[index].primary_diagnosis_code != null) {
                        this.state.priSelDiagnosisCodes[index] = [{ label: response.data.data[index].primary_diagnosis_code.code_with_short_desc, value: response.data.data[index].primary_diagnosis_code.id }];
                      } else {
                        this.state.priSelDiagnosisCodes[index] = null;
                      }
                    }

                    this.setState({
                      eduAssessmentForms: response.data.data,
                    });

                    const eduErrors = [];
                    for (var i = 0; i < response.data.data.length; i++) {
                      eduErrors.push(this.state.errors);
                    }

                    this.setState({ eduAssErrors: eduErrors });
                  } else {
                    this.setState({
                      eduAssessmentForms: [],
                    });
                  }
                } else if (response.data.status_code == 406) {
                  localStorage.removeItem('token');
                  localStorage.removeItem('user_name');

                  localStorage.removeItem('adminCheck');
                  localStorage.setItem('tokenExpired', true);

                  const tokenStatus = localStorage.getItem('tokenExpired');
                  if (tokenStatus) {
                    localStorage.removeItem('tokenExpired');
                    toast.error(TOKEN_EXPIRED, {
                      position: 'top-right',
                      autoClose: 2500,
                    });
                  }

                  const isLogged = !!localStorage.getItem('token');
                  if (!isLogged) {
                    setTimeout(() => {
                      this.props.history.push("/login");
                    }, 2500);
                  }
                }
              } else if (response.data.status === 204) {
                this.setState({
                  'activeAddAssessment': false,
                  'activeAssPlus': true,
                });
                return response;
              }
            })
            .catch(error => {
              console.log(error);
            });
          return false;
        }
      })
      .catch((error) => {
        toast.error(VALID_EDUCATION_DATA, {
          position: 'top-right',
          autoClose: 3500,
        });
        return false;
      });
  };

  updateEduFormSubmit(event, index, eduAssId) {
    event.preventDefault();
    const { assessment_id } = this.state;

    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    let list = [...this.state.eduAssessmentForms];
    let errEduAss = [...this.state.eduAssErrors];

    let errAss = { ...errEduAss[index] };
    let isDraft;

    if (list[index].participation_status == 0 || list[index].relationship_to_patient == '0'
      || list[index].person_spoken_to == '' || list[index].welcome_packet == false || list[index].diagnosisCodes=='' || list[index].physical_edu_complete == ''
      || list[index].self_admin_meds == '' || list[index].administration_route == '' || list[index].direction == '' ||
      list[index].missed_dose_instructions == '' || list[index].reproductive_implications == '' || list[index].storage == ''
      || list[index].disposal == '' || list[index].appropriateness == '' || (this.state.isgotshow == true && list[index].goals_of_therapy == false) || list[index].drug_drug == ''
      || list[index].drug_disease == '' || list[index].drug_condition == '' || list[index].common_side_effects == false
      || list[index].quality_of_life == 0 || list[index].how_to_contact == false
      || list[index].time_with_patient == '' || this.state.selectedDiagnosisCodes[index].length == 0 || this.state.priSelDiagnosisCodes[index] == null) {
      isDraft = true;
    } else {
      isDraft = false;
    }
    if (list[index].participation_status == 1){
      if(list[index].is_active != false && list[index].opt_out_reason != '' && list[index].relationship_to_patient != false && list[index].person_spoken_to != '' && list[index].how_to_contact != false && list[index].time_with_patient != '' && this.state.selectedDiagnosisCodes[index].length != 0 && this.state.priSelDiagnosisCodes[index] != null){
      isDraft = false
      }
      else{
        isDraft = true
      }
    }
    
    if (list[index].is_completed == true && isDraft == true) {
        toast.error(ALL_FIELDS_REQUIRED,{
          position: 'top-right',
          autoClose: 3500,
        });
      }
    if (list[index].participation_status == 0) {
      errAss.participation_status = 'Please Select The Participation Status.';
    } else {
      errAss.participation_status = '';
    }
    if (list[index].opt_out_reason === '' && list[index].participation_status == 1) {
      errAss.opt_out_reason = 'Please Enter Reason for Opt Out.';
    } else {
      errAss.opt_out_reason = '';
    }

    if (list[index].relationship_to_patient == 0) {
      errAss.relationship_to_patient = 'Please Enter Relationship To Patient.';
    } else {
      errAss.relationship_to_patient = '';
    }

    if (list[index].person_spoken_to === '') {
      errAss.person_spoken_to = 'Please Enter Person Spoken To.';
    } else {
      errAss.person_spoken_to = '';
    }

    if (list[index].participation_status != 1){
      if (list[index].welcome_packet === false) {
      errAss.welcome_packet = 'Please Enter Welcome Packet.';
    } else {
      errAss.welcome_packet = '';
    }
    }else{
      errAss.welcome_packet = '';
    }
    
    if (list[index].participation_status != 1){
      if (list[index].physical_edu_complete === '') {
      errAss.physical_edu_complete = 'Please Enter Physical Education Complete.';
    } else {
      errAss.physical_edu_complete = '';
    }
    }else{
      errAss.physical_edu_complete = '';
    }
    if (list[index].participation_status != 1){
      if (list[index].self_admin_meds === '') {
      errAss.self_admin_meds = 'Please Enter Self Admin Medications.';
    } else {
      errAss.self_admin_meds = '';
    }
    }else{
      errAss.self_admin_meds = '';
    }
    
    if (list[index].participation_status != 1){
      if (list[index].administration_route === '') {
      errAss.administration_route = 'Please Enter Administration Route.';
    } else {
      errAss.administration_route = '';
    }
    }else{
      errAss.administration_route = '';
    }
    if (list[index].participation_status != 1){
      if (list[index].direction === '') {
      errAss.direction = 'Please Enter Directions.';
    } else {
      errAss.direction = '';
    }
    }else{
      errAss.direction = '';
    }
    if (list[index].participation_status != 1){
      if (list[index].missed_dose_instructions === '') {
      errAss.missed_dose_instructions = 'Please Enter Missed Dose Instructions.';
    } else {
      errAss.missed_dose_instructions = '';
    }
    }else{
      errAss.missed_dose_instructions = '';
    }
    if (list[index].participation_status != 1){
      if (list[index].reproductive_implications === '') {
      errAss.reproductive_implications = 'Please Enter Reproductive Implications.';
    } else {
      errAss.reproductive_implications = '';
    }
    }else{
      errAss.reproductive_implications = '';
    }
    if (list[index].participation_status != 1){
      if (list[index].storage === '') {
      errAss.storage = 'Please Enter Storage.';
    } else {
      errAss.storage = '';
    }
    }else{
      errAss.storage = '';
    }
    if (list[index].participation_status != 1){
      if (list[index].disposal === '') {
      errAss.disposal = 'Please Enter Disposal.';
    } else {
      errAss.disposal = '';
    }
    }else{
      errAss.disposal = '';
    }
    if (list[index].participation_status != 1){
      if (list[index].appropriateness === '') {
      errAss.appropriateness = 'Please Enter Appropriateness.';
    } else {
      errAss.appropriateness = '';
    }
    }else{
      errAss.appropriateness = '';
    }
    if(this.state.isgotshow)
    {
      if (list[index].goals_of_therapy == false) {
        errAss.goals_of_therapy = 'Please Enter Goals Of Therapy.';
      } else {
        errAss.goals_of_therapy = '';
      }
    }
    if (list[index].participation_status != 1){
      if (list[index].drug_drug === '') {
      errAss.drug_drug = 'Please Enter Drug Drug.';
    } else {
      errAss.drug_drug = '';
    }
    }else{
      errAss.drug_drug = '';
    }
    if (list[index].participation_status != 1){
      if (list[index].drug_disease === '') {
      errAss.drug_disease = 'Please Enter Drug Disease.';
    } else {
      errAss.drug_disease = '';
    }
    }else{
      errAss.drug_disease = '';
    }
    
    if (list[index].participation_status != 1){
      if (list[index].drug_condition === '') {
      errAss.drug_condition = 'Please Enter Drug Condition.';
    } else {
      errAss.drug_condition = '';
    }
    }else{
      errAss.drug_condition = '';
    }
    
    if (list[index].participation_status != 1){
      if (list[index].common_side_effects === false) {
      errAss.common_side_effects = 'Please Enter Common Side Effects.';
    } else {
      errAss.common_side_effects = '';
    }
    }else{
      errAss.common_side_effects = '';
    }
    if (list[index].participation_status != 1){
      if (list[index].quality_of_life == false) {
      errAss.quality_of_life = 'Please Enter Quality Of Life.';
    } else {
      errAss.quality_of_life = '';
    }
    }else{
      errAss.quality_of_life = '';
    }
    if (list[index].how_to_contact == false) {
      errAss.how_to_contact = 'Please Enter How To Contact.';
    } else {
      errAss.how_to_contact = '';
    }

    if (list[index].time_with_patient == '') {
      errAss.time_with_patient = 'Please Enter Time With Patient.';
    } else {
      errAss.time_with_patient = '';
    }

    const arr = [];
    for (var i = 0; i < this.state.selectedDiagnosisCodes[index].length; i++) {
      arr.push(this.state.selectedDiagnosisCodes[index][i].value);
    }
    
    if (arr.length == 0) {
      errAss.diagnosis_codes = 'Please Enter Diagnosis Code.';
    } 
    else {
      errAss.diagnosis_codes = '';
    }

    const medicationId = [];
    if (this.state.eduAssessmentForms[index].medications != null) {
      for (var i = 0; i < this.state.eduAssessmentForms[index].medications.length; i++) {
        var obj = {
          'id': this.state.eduAssessmentForms[index].medications[i].medication_id,
          'officePhone': this.state.eduAssessmentForms[index].medications[i].office_phone,
          'prescribingMD': this.state.eduAssessmentForms[index].medications[i].prescribing_md,
          'refrigerate': this.state.eduAssessmentForms[index].medications[i].refrigeration,
        };
        medicationId.push(obj);
      }
    } else {
      for (var i = 0; i < this.state.updMedInputList.length; i++) {
        var obj = {
          'id': this.state.updMedInputList[i].id,
          'officePhone': this.state.updMedInputList[i].officePhone,
          'prescribingMD': this.state.updMedInputList[i].prescribingMD,
          'refrigerate': this.state.updMedInputList[i].refrigerate,
        };
        medicationId.push(obj);
      }
    }
    let errMeds = []
    // remove empty fields except the first since there must be 1 always there
    for (var j = 1; j < medicationId.length; j++) {
      if (medicationId[j].id == '') {
        medicationId.splice(j, 1);
      }
    }

    // setup up the error messages
    for (var j  = 0; j < medicationId.length; j++) {
      let tempError = {}
      if (medicationId[j]['id'] == '') {
        tempError['id'] = 'Please Enter The Medications.';
      } else {
        tempError['id'] = '';
      }
      if (medicationId[j]['refrigerate'] == '') {
        tempError['refrigerate'] = 'Please Enter The Refrigerate.';
      } else {
        tempError['refrigerate'] = '';
      }
      if (medicationId[j]['prescribingMD'] == '') {
        tempError['prescribingMD'] = 'Please Enter The Prescribing MD.';
      } else {
        tempError['prescribingMD'] = '';
      }
      if (medicationId[j]['officePhone'] == '') {
        tempError['officePhone'] = 'Please Enter The Office Phone.';
      } else {
        tempError['officePhone'] = '';
      }
      // if (medicationId[j]['diagnosisCodes'] == '') {
      //   tempError['diagnosiCodes'] = 'Please Enter The .';
      // } else {
      //   tempError['diagnosiCodes'] = '';
      // }
      errMeds.push(tempError)
    }
    var priDiagnosis = '';
    if (this.state.priSelDiagnosisCodes[index] != null) {
      priDiagnosis = this.state.priSelDiagnosisCodes[index][0].value;
    } else {
      priDiagnosis = null;
    }

    if (priDiagnosis == null) {
      errAss.primary_diagnosis_codes = 'Please Enter Primary Diagnosis Code.';
    } 
    else {
    errAss.primary_diagnosis_codes = '';
    }

    if (!list[index].is_completed) {
      errAss.participation_status = '';
      errAss.relationship_to_patient = '';
      errAss.person_spoken_to = '';
      errAss.welcome_packet = '';
      errAss.physical_edu_complete = '';
      errAss.self_admin_meds = '';
      errAss.administration_route = '';
      errAss.direction = '';
      errAss.missed_dose_instructions = '';
      errAss.reproductive_implications = '';

      errAss.storage = '';
      errAss.disposal = '';
      errAss.appropriateness = '';
      errAss.goals_of_therapy = '';
      errAss.drug_drug = '';

      errAss.drug_disease = '';
      errAss.drug_condition = '';
      errAss.dur_other = '';
      errAss.common_side_effects = '';

      errAss.quality_of_life = '';
      errAss.how_to_contact = '';
      errAss.time_with_patient = '';

      errAss.diagnosis_codes = '';
      errAss.primary_diagnosis_codes = '';

      for (var z = 0; z < medicationId.length; z++) {
        if (z > 0){
          errMeds[z].id = '';
        }
        errMeds[z].refrigerate = '';
        errMeds[z].prescribingMD = '';
        errMeds[z].officePhone = '';
      }
      errEduAss[index] = errAss;
      this.setState({
        eduAssErrors: errEduAss,
        eduMedErrors: errMeds,
      });
    }

    if (errMeds[0].id !== ''){
      toast.error(MEDICATION_MISSING, {
        position: 'top-right',
        autoClose: 3500,
      });
    }
    
    if (errMeds[0].id !== '' || errAss.participation_status !== '' || errAss.opt_out_reason !== '' || errAss.relationship_to_patient !== '' || errAss.person_spoken_to !== '' ||
      errAss.welcome_packet !== ''|| errAss.physical_edu_complete !== '' || errAss.self_admin_meds !== '' || errAss.administration_route !== '' || errAss.direction !== '' ||
      errAss.missed_dose_instructions !== '' || errAss.reproductive_implications !== '' || errAss.storage !== '' || errAss.disposal !== '' || errAss.appropriateness !== '' ||
      errAss.goals_of_therapy !== '' || errAss.drug_drug !== '' || errAss.drug_disease !== '' || errAss.drug_condition !== '' || errAss.common_side_effects !== ''
      || errAss.other_important_info !== '' || errAss.quality_of_life !== '' || errAss.how_to_contact !== '' || errAss.time_with_patient !== '' || errAss.diagnosis_codes !== '' || errAss.primary_diagnosis_codes !== '') {

      errEduAss[index] = errAss;
      this.setState({
        eduAssErrors: errEduAss,
        eduMedError: errMeds,
      });
      return false;
    }

    var isActive;
    if ($('#isActive_' + index).is(":checked")) {
      isActive = true;
    } else {
      isActive = false;
    }

    var emeContactDeclined;
    if ($('#emeContactDeclined_' + index).is(":checked")) {
      emeContactDeclined = true;
    } else {
      emeContactDeclined = false;
    }

    var welcomePacket;
    if ($('#welcomePacket_' + index).is(":checked")) {
      welcomePacket = true;
    } else {
      welcomePacket = false;
    }

    var hipaaSignature;
    if ($('#hipaaSignature_' + index).is(":checked")) {
      hipaaSignature = true;
    } else {
      hipaaSignature = false;
    }

    var missedDoseInstructions;
    if ($('#missedDoseInstructions_' + index).is(":checked")) {
      missedDoseInstructions = true;
    } else {
      missedDoseInstructions = false;
    }

    var goalsOfTherapy;
    if ($('#goalsOfTherapy_' + index).is(":checked")) {
      goalsOfTherapy = true;
    } else {
      goalsOfTherapy = false;
    }

    var howToContact;
    if ($('#howToContact_' + index).is(":checked")) {
      howToContact = true;
    } else {
      howToContact = false;
    }

    var otherImpInfo;
    if ($('#otherImpInfo_' + index).is(":checked")) {
      otherImpInfo = true;
    } else {
      otherImpInfo = false;
    }

    var CommonSideEffects;
    if ($('#CommonSideEffects_' + index).is(":checked")) {
      CommonSideEffects = true;
    } else {
      CommonSideEffects = false;
    }

    var isCompleted;
    if ($('#isCompleted_' + index).is(":checked")) {
      isCompleted = true;
    } else {
      isCompleted = false;
    }

    //console.log('Assessment Forms', this.state.eduAssessmentForms[index]);
    const formData = {
      'patient': this.state.patient_id,
      'is_active': isActive,
      'participation_status': document.getElementById("participation_status_" + index).value,
      'opt_out_reason': document.getElementById("optOutReason_" + index).value,
      'person_spoken_to': document.getElementById("personSpokenTo_" + index).value,
      'person_spoken_to_comments': document.getElementById("personSpokenToComments_" + index).value,
      'relationship_to_patient': document.getElementById("relationshipToPatient_" + index).value,
      'relationship_to_patient_comments': document.getElementById("relationshipToPatientComments_" + index).value,
      'emergency_contact': document.getElementById("emergencyContact_" + index).value,
      'emergency_contact_comments': document.getElementById("emergencyContactComments_" + index).value,
      'emergency_contact_phone': document.getElementById("emergencyContactPhone_" + index).value,
      'emergency_contact_phone_comments': document.getElementById("emergencyContactPhoneComments_" + index).value,
      'emergency_contact_declined': emeContactDeclined,
      'previous_therapy': document.getElementById("previousTherapy_" + index).value,
      'previous_therapy_comments': document.getElementById("previousTherapyComments_" + index).value,
      'previous_pharmacy': document.getElementById("previousPharmacy_" + index).value,
      'previous_pharmacy_comments': document.getElementById("previousPharmacyComments_" + index).value,
      'welcome_packet': welcomePacket,
      'welcome_packet_comments': document.getElementById("welcomePacketComments_" + index).value,
      'hipaa_signature': hipaaSignature,
      'hipaa_form': this.myInitObject,
      'hipaa_signature_comments': 'Present',
      'hipaa_form_comments': 'Screenshot',
      'physical_edu_complete': document.getElementById("physicalEduComplete_" + index).value,
      'physical_edu_complete_comments': document.getElementById("physicalEduCompleteComments_" + index).value,
      'self_admin_meds': document.getElementById("selfAdminMeds_" + index).value,
      'self_admin_meds_comments': document.getElementById("selfAdminMedsComments_" + index).value,
      'administration_route': document.getElementById("administrationRoute_" + index).value,
      'administration_route_comments': document.getElementById("administrationRouteComments_" + index).value,
      'direction': document.getElementById("direction_" + index).value,
      'direction_comments': document.getElementById("directionComments_" + index).value,
      'missed_dose_instructions': document.getElementById("missedDoseInstructions_" + index).value,
      'mdi_comments': document.getElementById("mdiComments_" + index).value,
      'reproductive_implications': document.getElementById("reproductiveImplications_" + index).value,
      'rprdi_comments': document.getElementById("rprdiComments_" + index).value,
      'storage': document.getElementById("storage_" + index).value,
      'storage_comments': document.getElementById("storageComments_" + index).value,
      'disposal': document.getElementById("disposal_" + index).value,
      'disposal_comments': document.getElementById("disposalComments_" + index).value,
      'appropriateness': document.getElementById("appropriateness_" + index).value,
      'appropriateness_comments': document.getElementById("appropriatenessComments_" + index).value,
      'goals_of_therapy': goalsOfTherapy,
      'goals_of_therapy_comments': goalsOfTherapy == true ? document.getElementById("goalsOfTherapyComments_" + index).value : "",
      'drug_drug': document.getElementById("drugDrug_" + index).value,
      'drug_drug_comment': document.getElementById("drugDrugComment_" + index).value,
      'drug_disease': document.getElementById("drugDisease_" + index).value,
      'drug_disease_comment': document.getElementById("drugDiseaseComment_" + index).value,
      'drug_condition': document.getElementById("drugCondition_" + index).value,
      'drug_condition_comment': document.getElementById("drugConditionComment_" + index).value,
      'dur_other': document.getElementById("durOther_" + index).value,
      'dur_other_comment': document.getElementById("durOtherComment_" + index).value,
      'common_side_effects': CommonSideEffects,
      'cse_comments': document.getElementById("cseComments_" + index).value,
      'other_important_info': otherImpInfo,
      'other_imp_info_comments': document.getElementById("otherImpInfoComments_" + index).value,
      'quality_of_life': document.getElementById("qualityOfLife_" + index).value,
      'quality_of_life_comments': document.getElementById("qualityOfLifeComments_" + index).value,
      'how_to_contact': howToContact,
      'how_to_contact_comments': 'Provided',
      'time_with_patient': document.getElementById("timeWithPatient_" + index).value,
      'therapy_others': 0,
      'therapy_others_comments': 'NA',
      'is_completed': isCompleted,
      'is_draft': isCompleted ? false : true,
      "diagnosis_code": arr,
      "primary_diagnosis_code": priDiagnosis,
      "medication": medicationId,
    }

    return axios.put(`${API_URL}/api/educational_assessment/` + eduAssId, formData, config)
      .then((response) => {
        if (response.status === 200) {
          localStorage.removeItem('isPatientEduAssChanged');
          toast.success(UPDATE_EDUCATION_DATA, {
            position: 'top-right',
            autoClose: 3000,
          });
           
          this.setState({ eduAssessmentForms: [], });
          window.setTimeout(function(){
            window.location.reload();
              }, 3000);
          axios
            .get(`${API_URL}/api/educational_assessment/?patient_id=` + this.state.patient_id, this.config)
            .then(response => {
              //console.log('Response Status', response.status);

              if (response.data.status === 200 && response.status === 200) {
                if (response.data.data.length > 0) {
                  this.setState({
                    'activeAddAssessment': true,
                    'activeAssPlus': false,
                    'activeAssMinus': true,
                  });

                  for (var index = 0; index < response.data.data.length; index++) {
                    if (response.data.data[index]['participation_status'] == 1) {
                      response.data.data[index]['showHideOpt'] = false;
                    } else {
                      response.data.data[index]['showHideOpt'] = true;
                    }

                    const arr = [];
                    if (response.data.data[index].diagnosis_code != null) {
                      for (var i = 0; i < response.data.data[index].diagnosis_code.length; i++) {
                        arr.push({ label: response.data.data[index].diagnosis_code[i].diagnosis_code, value: response.data.data[index].diagnosis_code[i].id });
                      }
                    }

                    this.state.selectedDiagnosisCodes[index] = arr;

                    if (response.data.data[index].primary_diagnosis_code != null) {
                      this.state.priSelDiagnosisCodes[index] = [{ label: response.data.data[index].primary_diagnosis_code.diagnosis_code, value: response.data.data[index].primary_diagnosis_code.id }];
                    } else {
                      this.state.priSelDiagnosisCodes[index] = null;
                    }
                    //console.log('Index ' + index, this.state.priSelDiagnosisCodes[index]);
                  }

                  this.setState({
                    eduAssessmentForms: response.data.data,
                  });

                  const eduErrors = [];
                  for (var i = 0; i < response.data.data.length; i++) {
                    this.state.errors.diagnosis_codes = ""
                    this.state.errors.primary_diagnosis_codes = ""
                    eduErrors.push(this.state.errors);
                  }

                  this.setState({ eduAssErrors: eduErrors });
                } else {

                  this.setState({
                    eduAssessmentForms: [],
                  });
                }
              } else if (response.data.status === 204) {
                this.setState({
                  'activeAddAssessment': false,
                  'activeAssPlus': true,
                });
                return response;
              }
            })
            .catch(error => {
              console.log(error);
            });
          return false;
        }
      })
      .catch((error) => {
        toast.error(VALID_EDUCATION_DATA, {
          position: 'top-right',
          autoClose: 3500,
        });
        return false;
      });
  };

  async updateEduMedications(event, index, j) {
    const name = event.target.name;
    const value = event.target.value;

    let list = [...this.state.eduAssessmentForms];

    
    if (name == 'id') {
      axios
        .get(`${API_URL}/api/get_medication_meta/?transaction_med_id=${value}`, this.config)
        .then(response => {
          list[index].medications[j].prescribing_md = response.data.data.prescribing_md;
          list[index].medications[j].office_phone = response.data.data.md_phone;
        })
        .catch(err => console.log(err));
      if(j == list[index].medications.length - 1 ){  
        list[index].medications.push({
          id: '',
          medication_id: '',
          medication_name: '',
          office_phone: '',
          prescribing_md: '',
          refrigeration: ''
        });
      } 
      list[index].medications[j].medication_id = event.target.value;
    } else if (name == 'refrigerate') {
      list[index].medications[j].refrigeration = event.target.value;
    } else if (name == 'prescribingMD') {
      list[index].medications[j].prescribing_md = event.target.value;
    } else {
      list[index].medications[j].office_phone = event.target.value;
    }

    await this.setState({ eduAssessmentForms: list, });
    //console.log('Edu Assessment Forms', this.state.eduAssessmentForms[index].medications);
  };

  deActivateAssessment = id => {
    axios
      .post(`${API_URL}/api/toggle_educational_assessment_status/`, { "assessment_id": id }, this.config)
      .then(response => {
        if (response.status == 200) {  
          axios
          .post(`${API_URL}/api/toggle_oncology_assessment_status/`, { "assessment_id": id }, this.config)
          .then(response => {
            if (response.status === 200) {
            } 
          })
          .catch(error => {
            console.log(error);
          });
          
          toast.error('Assessment Marked InActive', {
            position: 'top-right',
            autoClose: 3500,
          });
          this.getEducationData();
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  updateEduFieldsChanges = (event, index) => {
    const name = event.target.name;
    const value = event.target.value;

    let list = [...this.state.eduAssessmentForms];
    let errEduAss = [...this.state.eduAssErrors];
    let errAss = { ...errEduAss[index] };

    localStorage.setItem('isPatientEduAssChanged', true);
    
    if (name == 'participation_status' && value == 1) {
      this.setState({ 'showHideOpt': false });
    } else if (name == 'participation_status' && value != 1 ) {
      this.setState({ 'showHideOpt': true });
    }
    if (name == 'is_active' && !list[index].is_active == false) {
      localStorage.removeItem('isPatientEduAssChanged');
      confirmAlert({
        title: 'Confirm to submit',
        message: 'Inactivating the Education Assessment will also inactivate all the Follow-ups and Assessments for this Patient. Are you sure to inactivate this Education Assessment?',
        buttons: [
          {
            label: 'Okay',
            onClick: () => this.deActivateAssessment(list[index].id)
          },
          {
            label: 'Cancel',
            onClick: () =>  
            {
              list[index].is_active = true;
              this.setState({
                eduAssessmentForms: list,
              }); 
            }  
          }
        ]
      });
    }

    switch (name) {
      case 'participation_status':
        errAss.participation_status =
          value === '' || value === '0' ? 'Please Select The Participation Status.' : '';
        break;
      case 'opt_out_reason':
        errAss.opt_out_reason =
          value === '' || value.length < 0 ? 'Please Enter Reason For Opt Out.' : '';
      break;
      case 'relationship_to_patient':
        errAss.relationship_to_patient =
          value === '' || value === '0' ? 'Please Enter Relationship To Patient.' : '';
        break;
      case 'person_spoken_to':
        errAss.person_spoken_to =
          value === '' ? 'Please Enter Person Spoken To.' : '';
        break;
      
      case 'welcome_packet':
        if (list[index].participation_status != 1){
          if (list[index].welcome_packet === false) {
        errAss.welcome_packet = '';
        } else {
        errAss.welcome_packet = 'Please Enter Welcome packet.';
        }
        }else{
        errAss.welcome_packet = '';
        }
        break;

      case 'physical_edu_complete':
        
        if(list[index].participation_status != 1){
         errAss.physical_edu_complete = 
         value === '' ? 'Please Enter Physician Education Complete.' : '';
        }else if(list[index].participation_status == 1){
          errAss.physical_edu_complete = '';
        }
      break;

      case 'self_admin_meds':
         if(list[index].participation_status != 1){
           errAss.self_admin_meds = 
           value === '' ? 'Please Enter Self Admin Medications.' : '';
        }else if(list[index].participation_status == 1){
           errAss.self_admin_meds = '';
          
        }
      break;
      case 'administration_route':
        if(list[index].participation_status != 1){
           errAss.administration_route = 
           value === '' ? 'Please Enter Administration Route.' : '';
        }else if(list[index].participation_status == 1){
           errAss.administration_route = '';
        }
      break;
      case 'direction':
        if(list[index].participation_status != 1){
           errAss.direction = 
           value === '' ? 'Please Enter Direction.' : '';
        }else if(list[index].participation_status == 1){
           errAss.direction = '';
        }
      break;
      case 'missed_dose_instructions':
        if(list[index].participation_status != 1){
           errAss.missed_dose_instructions = 
           value === '' ? 'Please Enter Missed Dose Instructions.' : '';
        }else if(list[index].participation_status == 1){
           errAss.missed_dose_instructions = '';
        }
      break;
      case 'reproductive_implications':
        if(list[index].participation_status != 1){
           errAss.reproductive_implications = 
           value === '' ? 'Please Enter Reproductive Implications.' : '';
        }else if(list[index].participation_status == 1){
           errAss.reproductive_implications = '';
        }
      break;
      case 'storage':
        if(list[index].participation_status != 1){
           errAss.storage = 
           value === '' ? 'Please Enter Storage.' : '';
        }else if(list[index].participation_status == 1){
           errAss.storage = '';
        }
      break;
      case 'disposal':
        if(list[index].participation_status != 1){
           errAss.disposal = 
           value === '' ? 'Please Enter Disposal.' : '';
        }else if(list[index].participation_status == 1){
           errAss.disposal = '';
        }
      break;
      case 'appropriateness':
        if(list[index].participation_status != 1){
           errAss.appropriateness = 
           value === '' ? 'Please Enter Appropriateness.' : '';
        }else if(list[index].participation_status == 1){
           errAss.appropriateness = '';
        }
      break;

      case 'goals_of_therapy':
          errAss.goals_of_therapy = this.state.isgotshow === true ?
            (!list[index].goals_of_therapy === false ? 'Please Enter Goals Of Therapy.' : '') : '';
          break;
      case 'drug_drug':
        if(list[index].participation_status != 1){
           errAss.drug_drug = 
           value === '' ? 'Please Enter Drug Drug.' : '';
        }else if(list[index].participation_status == 1){
           errAss.drug_drug = '';
        }
      break;
      case 'drug_disease':
        if(list[index].participation_status != 1){
           errAss.drug_disease = 
           value === '' ? 'Please Enter Drug Disease.' : '';
        }else if(list[index].participation_status == 1){
           errAss.drug_disease = '';
        }
      break;
      case 'drug_condition':
        if(list[index].participation_status != 1){
           errAss.drug_condition = 
           value === '' ? 'Please Enter Drug Condition.' : '';
        }else if(list[index].participation_status == 1){
           errAss.drug_condition = '';
        }
      break;
      case 'common_side_effects':
        if (list[index].participation_status != 1){
          if (list[index].common_side_effects === false) {
        errAss.common_side_effects = '';
        } else {
        errAss.common_side_effects = 'Please Enter Common Side Effects.';
        }
        }else{
        errAss.common_side_effects = '';
        }
      break;
      case 'quality_of_life':
        if(list[index].participation_status != 1){
           errAss.quality_of_life =
           value === '0' ? 'Please Enter Quality Of Life.' : '';
        }else if(list[index].participation_status == 1){
           errAss.quality_of_life = '';
        }
      break;
      case 'how_to_contact':
        errAss.how_to_contact =
          !list[index].how_to_contact === false ? 'Please Enter How To Contact.' : '';
        break;
      case 'time_with_patient':
        errAss.time_with_patient =
          value === '' ? 'Please Enter Time With Patient.' : '';
        break;
      default:
        break;
    }

    errEduAss[index] = errAss;
    this.setState({
      eduAssErrors: errEduAss,
    });

    if (event.target.name == 'participation_status' && event.target.value == 1) {
      list[index]['showHideOpt'] = false;
    } else if (event.target.name == 'participation_status' && event.target.value !== 1) {
      list[index]['showHideOpt'] = true;
    }

    if (name === 'is_active') {
      list[index][event.target.name] = !list[index][event.target.name];
    } else if (name === 'hipaa_signature') {
      list[index][event.target.name] = !list[index][event.target.name];
    } else if (name === 'welcome_packet') {
      list[index][event.target.name] = !list[index][event.target.name];
    } else if (name === 'speak_to_nurse') {
      list[index][event.target.name] = !list[index][event.target.name];
    } else if (name === 'emergency_contact_declined') {
      list[index][event.target.name] = !list[index][event.target.name];
    } else if (name === 'how_to_contact') {
      list[index][event.target.name] = !list[index][event.target.name];
    } else if (name === 'common_side_effects') {
      list[index][event.target.name] = !list[index][event.target.name];
    } else if (name === 'other_important_info') {
      list[index][event.target.name] = !list[index][event.target.name];
    } else if (name === 'goals_of_therapy') {
      list[index][event.target.name] = !list[index][event.target.name];
    } else if (name === 'is_completed') {
      list[index][event.target.name] = !list[index][event.target.name];
    } else {
      list[index][event.target.name] = event.target.value;
    }

    this.setState({
      eduAssessmentForms: list,
    });
 
  };

  delEducation = (id,index,draft) =>{
      confirmAlert({
        title: 'Confirmation',
        message: 'Are you sure you want to delete Educational Assessment ' + (index + 1) + ((draft === true) ? ' (Draft) ' : '') + '?',
        buttons: [
          {
            label: 'Ok',
            onClick: () => this.deleletEducation(id)
          },
          {
            label: 'Cancel',
            onClick: () => ''
          }
        ]
      });
    
  };

  deleletEducation = id => {
    axios
      .delete(`${API_URL}/api/educational_assessment/${id}`, this.config)
      .then(response => {
        if (response.status == 200) {
          toast.success('Assessment deleted successfully.', {
            position: 'top-right',
            autoClose: 3500,
          });
          window.setTimeout(function(){
            window.location.reload();
              }, 3500);
          this.getEducationData();
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  /* Methods For Handling On Change And Validations Events For Education Form */
  handleEduFieldsChanges = (event) => {
    const value = event.target.value;
    const name = event.target.name;
    //console.log('Event', name, value);

    let list = { ...this.state.eduData };
    let errors = this.state.errors;
    //console.log('Edu Data list', list);
    if (name == 'participation_status' && value == 1) {
      this.setState({ 'showHideOpt': false });
    } else if (name == 'participation_status' && value != 1) {
      this.setState({ 'showHideOpt': true });
    }
    
    switch (name) {
      case 'is_active':
        errors.is_active =
          !this.state.eduData.is_active === false ? 'Please Check The Assessment Active.' : '';
        break;
      case 'participation_status':
        errors.participation_status =
          value === '0' ? 'Please select Patient Participation Status.' : '';
        break;
      case 'opt_out_reason':
        errors.opt_out_reason =
          value === '' || value.length < 0 ? 'Please Enter Reason for Opt Out.' : '';
        break;
      case 'relationship_to_patient':
        errors.relationship_to_patient =
          value === '' ? 'Please Enter Relationship To Patient.' : '';
        break;
      case 'person_spoken_to':
        errors.person_spoken_to = value === ''
          ? 'Please Enter Person Spoken To.' : '';
        break;
      case 'welcome_packet':
        if (list.participation_status != 1){
          if (list.welcome_packet === false) {
        errors.welcome_packet = '';
        } else {
        errors.welcome_packet = 'Please Enter Welcome Packet.';
        }
        }else{
        errors.welcome_packet = '';
        }
        break;
      
      case 'physical_edu_complete':
        if(list.participation_status != 1){
         errors.physical_edu_complete =
         value === '' ? 'Please Enter Physician Education Complete.' : '';
        }else if(list.participation_status == 1){
          errors.physical_edu_complete = '';
        }
      break;
      case 'self_admin_meds':
        if(list.participation_status != 1){
          errors.self_admin_meds = 
          value === '' ? 'Please Enter Self Admin Medications.' : '';
        }else if(list.participation_status == 1){
          errors.self_admin_meds = '';
        }
      break;
      case 'administration_route':
        if(list.participation_status != 1){
          errors.administration_route =
          value === '' ? 'Please Enter Administration Route.' : '';
        }else if(list.participation_status == 1){
          errors.administration_route = '';
        }
      break;
      case 'direction':
        if(list.participation_status != 1){
          errors.direction = 
          value === '' ? 'Please Enter Directions.' : '';
        }else if(list.participation_status == 1){
          errors.direction = '';
        }
      break;
      case 'missed_dose_instructions':
        if(list.participation_status != 1){
          errors.missed_dose_instructions = 
          value === '' ? 'Please Enter Missed Dose Instructions.' : '';
        }else if(list.participation_status == 1){
          errors.missed_dose_instructions = '';
        }
      break;
      case 'reproductive_implications':
        if(list.participation_status != 1){
          errors.reproductive_implications = 
          value === '' ? 'Please Enter Reproductive Implications.' : '';
        }else if(list.participation_status == 1){
          errors.reproductive_implications = '';
        }
      break;
      case 'storage':
        if(list.participation_status != 1){
          errors.storage = 
          value === '' ? 'Please enter storage.' : '';
        }else if(list.participation_status == 1){
          errors.storage = '';
        }
      break;
      case 'disposal':
        if(list.participation_status != 1){
          errors.disposal = 
          value === '' ? 'Please Enter Disposal.' : '';
        }else if(list.participation_status == 1){
          errors.disposal = '';
        }
      break;
      case 'appropriateness':
        if(list.participation_status != 1){
          errors.appropriateness = 
          value === '' ? 'Please Enter Appropriateness.' : '';
        }else if(list.participation_status == 1){
          errors.appropriateness = '';
        }
      break;
      case 'goals_of_therapy':
        if(list.participation_status != 1){
          errors.goals_of_therapy = 
          value === '' ? 'Please Enter Goals Of Therapy.' : '';
        }else if(list.participation_status == 1){
          errors.goals_of_therapy = '';
        }
      break;
      case 'drug_drug':
        if(list.participation_status != 1){
          errors.drug_drug = 
          value === '' ? 'Please Enter Drug Drug.' : '';
        }else if(list.participation_status == 1){
          errors.drug_drug = '';
        }
      break;
      case 'drug_disease':
        if(list.participation_status != 1){
          errors.drug_disease = 
          value === '' ? 'Please Enter Drug Disease.' : '';
        }else if(list.participation_status == 1){
          errors.drug_disease = '';
        }
      break;
      case 'drug_condition':
        if(list.participation_status != 1){
          errors.drug_condition = 
          value === '' ? 'Please Enter Drug Condition.' : '';
        }else if(list.participation_status == 1){
          errors.drug_condition = '';
        }
      break;
      case 'common_side_effects':
        if(list.participation_status != 1){
          errors.common_side_effects = !this.state.eduData.common_side_effects === false
          ? 'Please Enter Common Side Effects.' : '';
        }else if(list.participation_status == 1){
          errors.common_side_effects = '';
        }
      break;
      case 'quality_of_life':
        if(list.participation_status != 1){
          errors.quality_of_life = 
          value === '0' ? 'Please Enter Quality Of Life.' : '';
        }else if(list.participation_status == 1){
          errors.quality_of_life = '';
        }
      break;
      case 'how_to_contact':
        errors.how_to_contact = !this.state.eduData.how_to_contact === false
          ? 'Please Enter How To Contact.' : '';
        break;
      case 'time_with_patient':
        errors.time_with_patient = value === ''
          ? 'Please Enter Time With Patient.' : '';
        break;
      default:
        break;
    }
    if (name === 'is_active') {
      list[name] = !this.state.eduData.is_active;
    } else if (name === 'hipaa_signature') {
      list[name] = !this.state.eduData.hipaa_signature;
    } else if (name === 'welcome_packet') {
      list[name] = !this.state.eduData.welcome_packet;
    } else if (name === 'speak_to_nurse') {
      list[name] = !this.state.eduData.speak_to_nurse;
    } else if (name === 'emergency_contact_declined') {
      list[name] = !this.state.eduData.emergency_contact_declined;
    } else if (name === 'how_to_contact') {
      list[name] = !this.state.eduData.how_to_contact;
    } else if (name === 'common_side_effects') {
      list[name] = !this.state.eduData.common_side_effects;
    } else if (name === 'other_important_info') {
      list[name] = !this.state.eduData.other_important_info;
    } else if (name === 'goals_of_therapy') {
      list[name] = !this.state.eduData.goals_of_therapy;
    } else if (name === 'is_completed') {
      list[name] = !this.state.eduData.is_completed;
    } else {
      list[name] = value;
    }

    this.setState({ eduData: list, });

    if (list['is_active'] !== true || list['is_completed'] !== false || list['participation_status'] !== '' || list['opt_out_reason'] !== '' ||
      list['patient_output'] !== 0 || list['person_spoken_to'] !== '' || list['person_spoken_to_comments'] !== '' || list['relationship_to_patient'] !== 0 ||
      list['relationship_to_patient_comments'] !== '' || list['emergency_contact'] !== '' || list['emergency_contact_comments'] !== '' || list['emergency_contact_phone'] !== '' ||
      list['emergency_contact_phone_comments'] !== '' || list['emergency_contact_declined'] !== false || list['emergency_contact_declined_comments'] !== '' || list['previous_therapy'] !== '' ||
      list['previous_therapy_comments'] !== '' || list['previous_pharmacy'] !== '' || list['previous_pharmacy_comments'] !== '' || list['welcome_packet'] !== false ||
      list['welcome_packet_comments'] !== '' || list['hipaa_signature'] !== true || list['hipaa_form'] !== '' || list['physical_edu_complete'] !== '' || list['physical_edu_complete_comments'] ||
      list['self_admin_meds'] !== '' || list['self_admin_meds_comments'] !== '' || list['administration_route'] !== '' || list['administration_route_comments'] !== '' || list['direction'] !== '' ||
      list['direction_comments'] !== '' || list['missed_dose_instructions'] !== '' || list['mdi_comments'] !== '' || list['reproductive_implications'] !== '' || list['storage'] !== '' ||
      list['storage_comments'] !== '' || list['disposal'] !== '' || list['disposal_comments'] !== '' || list['appropriateness'] !== '' || list['appropriateness_comments'] !== '' || list['goals_of_therapy'] !== '' ||
      list['goals_of_therapy_comments'] !== '' || list['drug_drug'] !== '' || list['drug_drug_comment'] !== '' || list['drug_disease'] !== '' || list['drug_disease_comment'] !== '' || list['drug_condition'] !== '' ||
      list['drug_condition_comment'] !== '' || list['dur_other'] !== '' || list['dur_other_comment'] !== '' || list['common_side_effects'] !== '' || list['cse_comments'] !== '' || list['other_important_info'] !== '' ||
      list['other_imp_info_comments'] !== '' || list['quality_of_life'] !== '' || list['quality_of_life_comments'] !== '' || list['how_to_contact'] !== '' || list['how_to_contact_comments'] !== '' || list['time_with_patient'] !== '' ||
      list['time_with_patient_comments'] !== '' || list['therapy_others'] !== '' || list['therapy_others_comments'] !== '') {
      localStorage.setItem('isPatientEduAssChanged', true);
    } else {
      localStorage.removeItem('isPatientEduAssChanged');
    }
  };


  /* Handle Education Medications Changes */
  async updHandleEduMedications(e, index) {
    const { name, value } = e.target;
    const list = [...this.state.updMedInputList];

    if (name == 'id' && index == (this.state.updMedInputList.length - 1)) {
      await axios
        .get(`${API_URL}/api/get_medication_meta/?transaction_med_id=${value}`, this.config)
        .then(response => {
          if (response.status == 200) {
            prescribingMD = response.data.data.prescribing_md;
            officePhone = response.data.data.md_phone;
          } else {
            toast.error(INTERNAL_SERVER_ERROR, {
              position: 'top-right',
              autoClose: 1500,
            });
          }
        })
        .catch(err => console.log(err));

      list.push({
        id: "",
        refrigerate: "",
        prescribingMD: "",
        officePhone: "",
      });

      list[index]['prescribingMD'] = prescribingMD;
      list[index]['officePhone'] = officePhone;
      list[index][name] = value;
    } else {
      list[index][name] = value;
    }

    await this.setState({ updMedInputList: list, });
    //console.log('Upd Medi Input List', this.state.updMedInputList);
    const eduMedErr = [];
    for (var i = 0; i < (list.length); i++) {
      eduMedErr.push({
        id: "",
        refrigerate: "",
        prescribingMD: "",
        officePhone: "",
      });
    }

    await this.setState({ eduMedErrors: eduMedErr });
    let errMedEdu = [...this.state.eduMedErrors];
    let errMedAss = { ...errMedEdu[index] };

    switch (name) {
      case 'id':
        errMedAss.id =
          value == '' || value == '0' ? 'Please Select The Medications.' : '';
        break;
      case 'refrigerate':
        errMedAss.refrigerate =
          value == '' || value == '0' ? 'Please Enter Refrigerate.' : '';
        break;
      case 'prescribingMD':
        errMedAss.prescribingMD =
          value == '' ? 'Please Enter Prescribing MD.' : '';
        break;
      case 'officePhone':
        errMedAss.officePhone =
          value == '' ? 'Please Enter Office Phone.' : '';
        break;
      default:
        break;
    }

    errMedEdu[index] = errMedAss;
    await this.setState({
      eduMedErrors: errMedEdu,
    });
  };

  /* Handle Education Medications Changes */
  async handleEduMedications(e, index) {
    const { name, value } = e.target;
    const list = [...this.state.medicationsInputList];

    if (name == 'id' && index == (this.state.medicationsInputList.length - 1)) {
      await axios
        .get(`${API_URL}/api/get_medication_meta/?transaction_med_id=${value}`, this.config)
        .then(response => {
          if (response.status == 200) {
            prescribingMD = response.data.data.prescribing_md;
            officePhone = response.data.data.md_phone;
          } else {
            toast.error(INTERNAL_SERVER_ERROR, {
              position: 'top-right',
              autoClose: 1500,
            });
          }
        })
        .catch(err => console.log(err));

      list.push({
        id: "",
        refrigerate: "",
        prescribingMD: "",
        officePhone: "",
      });

      list[index]['prescribingMD'] = prescribingMD;
      list[index]['officePhone'] = officePhone;
      list[index][name] = value;
    } else {
      list[index][name] = value;
    }

    await this.setState({ medicationsInputList: list, });

    const eduMedErr = [];
    for (var i = 0; i < (list.length); i++) {
      eduMedErr.push({
        id: "",
        refrigerate: "",
        prescribingMD: "",
        officePhone: "",
      });
    }

    await this.setState({ eduMedErrors: eduMedErr });
    let errMedEdu = [...this.state.eduMedErrors];
    let errMedAss = { ...errMedEdu[index] };

    switch (name) {
      case 'id':
        errMedAss.id =
          value == '' || value == '0' ? 'Please Select The Medications.' : '';
        break;
      case 'refrigerate':
        errMedAss.refrigerate =
          value == '' || value == '0' ? 'Please Enter Refrigerate.' : '';
        break;
      case 'prescribingMD':
        errMedAss.prescribingMD =
          value == '' ? 'Please Enter Prescribing MD.' : '';
        break;
      case 'officePhone':
        errMedAss.officePhone =
          value == '' ? 'Please Enter Office Phone.' : '';
        break;
      default:
        break;
    }

    errMedEdu[index] = errMedAss;
    await this.setState({
      eduMedErrors: errMedEdu,
    });
  };

  /* Delete Education Medications Rows */
  handleMedRemClick = index => {
    const list = [...this.state.medicationsInputList];
    list.splice(index, 1);
    this.setState({
      medicationsInputList: list,
    });
  };


  onKeyUp = (e) => {
    this.setState({ diagnosisCodes: [], });
    var query_string = e.target.value;

    axios
      .get(`${API_URL}/api/get_filtered_diagnosis_codes/?query_param=${query_string}`, this.config)
      .then(response => {
        const diaCodes = [];
        for (var i = 0; i < response.data.data.length; i++) {
          diaCodes.push({ value: response.data.data[i].id, label: response.data.data[i].code_with_short_desc });
        }

        this.setState({ diagnosisCodes: diaCodes, });
      })
      .catch(error => {
        console.log(error);
      });
  };

  async onUpdKeyUp(e, z) {
    this.setState({ updDiagnosisCodes: [], });
    axios
      .get(`${API_URL}/api/get_filtered_diagnosis_codes/?query_param=${z.target.value}`, this.config)
      .then(response => {
        const diaCodes = [];
        for (var i = 0; i < response.data.data.length; i++) {
          diaCodes.push({ value: response.data.data[i].id, label: response.data.data[i].code_with_short_desc });
        }

        // this.state.updDiagnosisCodes[e] = diaCodes;
        this.setState({
          updDiagnosisCodes: [...this.state.updDiagnosisCodes, diaCodes],
        });
      })
      .catch(error => {
        console.log(error);
      });
  };

  async onSelectedDiagnosisCode(e) {
    //console.log('Selected Diagnosis Codes', e);

    let errors = this.state.errors;
    if (e.length == 0) {
      errors.diagnosis_codes = 'Please Enter Diagnosis Codes.';
    } else {
      errors.diagnosis_codes = '';
    }


    this.setState({ errors: errors });
    this.setState({ selDiagnosisCodeValue: e });
    //console.log('Total Selected Items 2', this.state.selDiagnosisCodeValue);

    const arr = [];
    for (var i = 0; i < e.length; i++) {
      arr.push(e[i].value);
    }

    await this.setState(() => ({ selDiagCodeIdsArr: arr }));

    //Set Selected Items
  };

  async onSelPrimaryDiagCode(e) {

    let errors = this.state.errors;
    if (e.length == 0) {
      errors.primary_diagnosis_codes = 'Please Enter Primary Diagnosis Codes.';
    } else if (e.length > 1) {
      errors.primary_diagnosis_codes = 'Please Enter Only One Primary Diagnosis Codes.';
    } else {
      errors.primary_diagnosis_codes = '';
    }

    this.setState({ errors: errors });
    this.setState({ selPriDiagnosisCodeValue: e });
    //console.log('Total Selected Items 2', this.state.selPriDiagnosisCodeValue);

    const arr = [];
    for (var i = 0; i < e.length; i++) {
      arr.push(e[i].value);
    }

    if (arr.length == 0) {
      await this.setState(() => ({ selPriDiagCodeIdsArr: '' }));
    } else {
      await this.setState(() => ({ selPriDiagCodeIdsArr: arr[0] }));
    }

    //Set Selected Items
  };

  /* Delete Education Medications Rows While Updating */
  updMedRemClick = (index, j) => {
    const list = [...this.state.eduAssessmentForms];
    list[index].medications.splice(j, 1);

    this.setState({
      eduAssessmentForms: list,
    });
  };

  /* Delete Education Medications Rows */
  updHandleMedRemClick = index => {
    const list = [...this.state.updMedInputList];
    list.splice(index, 1);
    this.setState({
      updMedInputList: list,
    });
  };

  async onUpdSelectedDiagnosisCode(e, j) {
    let errEduAss = [...this.state.selectedDiagnosisCodes];
    let eduerrAll = { ...this.state.eduAssErrors};
    let errAss = { ...errEduAss[e] };
    errAss = j;
    errEduAss[e] = errAss;

    if (j != null){
      if(j.length == 0){
        eduerrAll[e]['diagnosis_codes'] = "Please Enter Diagnosis Code.";
      }
      else{
        eduerrAll[e]['diagnosis_codes'] = "";
      }
    }

    await this.setState(() => ({ selectedDiagnosisCodes: errEduAss }));
    
    // this.setState({
    //   selectedDiagnosisCodes: errEduAss,
    // });
  };

  async onUpdSelPrimaryDiagCode(e, i) {
    //console.log('Primary Diagnosis Codes', i);
    let errEduAss = [...this.state.priSelDiagnosisCodes];
    let eduerrAll = { ...this.state.eduAssErrors};
    let errAss = { ...errEduAss[e] };

    if (i != null){
      if(i.length == 0){
        eduerrAll[e]['primary_diagnosis_codes'] = 'Please Enter Primary Diagnosis Codes.';
      }
      else if(i.length>1){
        eduerrAll[e]['primary_diagnosis_codes'] = 'Please Enter Only One Primary Diagnosis Codes.';
      }
      else{
        eduerrAll[e]['primary_diagnosis_codes'] = '';
      }
    }

    if (i.length == 0) {
      errAss = null;
    } else {
      errAss = i;
    }

    errEduAss[e] = errAss;
    await this.setState(() => ({ priSelDiagnosisCodes: errEduAss }));
    
    // this.setState({
    //   priSelDiagnosisCodes: errEduAss,
    // });
  };



render() {
    const isLogged = !!localStorage.getItem('token');
    if (!isLogged) {
      return <Redirect to="/login" />;
    }

    const { errors } = this.state;
    return (
      <>
           {/**** starting the Education button*/  }
           <div id="education" className="container-fluid tab-pane fade active in">
                    <br />
                    {/* Past Medical History(button) */}
                    <div className="col-sm-12 text-right px-0">
                      <Link to={{
                        'pathname': '/pastmedicalhistory', 'state': {
                          'transaction_id': this.state.transaction_id,
                          'patient_id': this.state.patient_id, 'patient_name': this.state.patient_name,'data_added': this.state.data_added
                        }
                      }}>
                        <button type="button" class="btn btn-primary btn btn-primary">
                          Past Medical History
                        </button>
                      </Link>
                     {/* Current Rx/OTC Medcations(button) */}
                      <Link to={{
                        'pathname': '/currentrxotcmedications', 'state': {
                          'transaction_id': this.state.transaction_id,
                          'patient_id': this.state.patient_id, 'patient_name': this.state.patient_name,'data_added': this.state.data_added
                        }
                      }}>
                        <button type="button" class="btn btn-primary btn btn-primary">
                          Current Rx/OTC Medcations
                        </button>
                      </Link>
                      {/* <a hidden={this.state.activeAssPlus} className="btn btn-primary" onClick={() => this.plusMinusAddAssessmentToggle('plus')} href="javascript:void(0)">
                        (<PlusIcon />) Assessment
                      </a>
                      <a hidden={this.state.activeAssMinus} className="btn btn-primary" onClick={() => this.plusMinusAddAssessmentToggle('minus')} href="javascript:void(0)">
                        (<RemoveIcon />) Assessment
                      </a> */}
                      <Link to={{'pathname': '/dayfollowup','state':{'transaction_id':this.state.transaction_id,
                          'patient_id':this.state.patient_id,'patient_name':this.state.patient_name, 'data_added': this.state.data_added}}}>
                          <button type="button" class="btn btn-primary btn btn-primary" > 
                            7-Day Follow Up
                          </button>
                      </Link>

                    </div>
                    {/****** Assessment(+) button */  }
                    <Accordion defaultActiveKey="0" hidden={this.state.activeAddAssessment}>
                      <Card>
                        <Card.Header className="align-items-center d-flex justify-content-between text-white">
                          <span className="px-2"> Add Educational Assessment </span>
                          <Accordion.Toggle as={Button} variant="link" eventKey="0" className="float-right">
                            <i class="fa fa-arrow-circle-down text-white" aria-hidden="true"></i>
                          </Accordion.Toggle>
                        </Card.Header>

                        <Accordion.Collapse eventKey="0">
                          <Card.Body>
                            <form>
                              <div class="edu">
                                <div class="row align-items-center">
                                  {/*****  start with  Patient Participation Status*/}
                                  <div class="col-md-6">
                                    <div class="form-group">
                                      <label for="">
                                        Patient Participation Status<span className='error'>*</span>
                                      </label>
                                      <select noValidate value={this.state.eduData.participation_status} onChange={this.handleEduFieldsChanges}
                                        id="participation_status" class="form-control" name="participation_status">
                                        <option value="0">Please Choose</option>
                                        <option value="1">Patient OPTS out of Program</option>
                                        <option value="2">Patient Agrees to Counseling Only</option>
                                        <option value="3">Patient Agrees to Counseling and Program</option>
                                      </select>
                                      {errors.participation_status.length > 0 && <span className='error' style={{ color: 'red' }}>{errors.participation_status}</span>}
                                    </div>
                                  </div>
                                     {/****** start with  Assessment Active*/}
                                  <div class="col-md-6">
                                    <div class="custom-control custom-checkbox">
                                      <input type="checkbox" checked={this.state.eduData.is_active} name="is_active"
                                        id="isActive" onChange={this.handleEduFieldsChanges} class="custom-control-input" />
                                      <label class="custom-control-label" for="isActive">
                                        Assessment Active <span className='error'>*</span>
                                      </label>
                                    </div>
                                    {errors.is_active !== '' && <span className='error' style={{ color: 'red' }}>{errors.is_active}</span>}
                                  </div>
                                </div>
                                <div class="row" hidden={this.state.showHideOpt}>
                                  <div class="col-md-6">
                                    <div class="form-group">
                                      <label for="">
                                        Please document why the patient wishes to "Opt Out of The Program"<span className='error'>*</span>
                                      </label>
                                      <input type="text" class="form-control" name="opt_out_reason" value={this.state.eduData.opt_out_reason}
                                        id="optOutReason" onChange={this.handleEduFieldsChanges.bind(this)} />
                                      {errors.opt_out_reason.length > 0 && <span className='error' style={{ color: 'red' }}>{errors.opt_out_reason}</span>}
                                    </div>
                                  </div>
                                </div>
                                    {/****  start with person spoken to */}
                                  <div class="row">
                                  <div class="col-md-6">
                                    <label for="">Person Spoken To<span className='error'>*</span></label>
                                    <input type="text" value={this.state.eduData.person_spoken_to} class="form-control"
                                      onChange={this.handleEduFieldsChanges} id="personSpokenTo" name="person_spoken_to" />
                                    {errors.person_spoken_to.length > 0 && <span className='error' style={{ color: 'red' }}>{errors.person_spoken_to}</span>}
                                  </div>
                                  {/****  start with person spoken to Comments*/}
                                  <div class="col-md-6">
                                    <div class="form-group">
                                      <label for="">Person Spoken To Comments</label>
                                      <input type="text" noValidate value={this.state.eduData.person_spoken_to_comments} class="form-control" onChange={this.handleEduFieldsChanges}
                                        id="personSpokenToComments" name="person_spoken_to_comments" />
                                    </div>
                                  </div>
                                </div>
                                  {/****  start with Relationship To Patient*/}
                                <div class="row">
                                  <div class="col-md-6">
                                    <label for="">Relationship To Patient<span className='error'>*</span></label>
                                    <select noValidate value={this.state.eduData.relationship_to_patient} onChange={this.handleEduFieldsChanges}
                                      id="relationshipToPatient" class="form-control" name="relationship_to_patient">
                                      <option value="0">Please Choose</option>
                                      <option value="1">Patient</option>
                                      <option value="2">Spouse</option>
                                      <option value="3">Caregiver</option>
                                      <option value="4">Other</option>
                                    </select>
                                    {errors.relationship_to_patient.length > 0 && <span className='error' style={{ color: 'red' }}>{errors.relationship_to_patient}</span>}
                                  </div>
                                  {/****  start with Relationship To Patient Comments*/}
                                  <div class="col-md-6">
                                    <div class="form-group">
                                      <label for="">Relationship To Patient Comments</label>
                                      <input type="text" noValidate value={this.state.eduData.relationship_to_patient_comments} class="form-control" onChange={this.handleEduFieldsChanges}
                                        id="relationshipToPatientComments" name="relationship_to_patient_comments" />
                                    </div>
                                  </div>
                                </div>
                                   {/****  start with Emergency Contact*/}
                                <hr class="mt-4 hr-blue" />
                                <div class="row">
                                  <div class="col-md-6">
                                    <div class="form-group">
                                      <label for="">Emergency Contact</label>
                                      <input type="text" maxLength="25" class="form-control" name="emergency_contact" value={this.state.eduData.emergency_contact}
                                        id="emergencyContact" onChange={this.handleEduFieldsChanges} />
                                    </div>
                                    {errors.emergency_contact.length > 0 && <span className='error' style={{ color: 'red' }}>{errors.emergency_contact}</span>}
                                  </div>
                                  {/****  start with Emergency Contact Comments*/}
                                  <div class="col-md-6">
                                    <div class="form-group">
                                      <label for="">Emergency Contact Comments</label>
                                      <input type="text" class="form-control" name="emergency_contact_comments" id="emergencyContactComments" value={this.state.eduData.emergency_contact_comments} onChange={this.handleEduFieldsChanges} />
                                    </div>
                                  </div>
                                </div>
                                  {/****  start with Emergency Phone*/}
                                <div class="row">
                                  <div class="col-md-6">
                                    <div class="form-group">
                                      <label for="">Emergency Phone</label>
                                      <input type="text" maxLength="25" class="form-control" name="emergency_contact_phone" value={this.state.eduData.emergency_contact_phone}
                                        id="emergencyContactPhone" onChange={this.handleEduFieldsChanges} />
                                    </div>
                                    {errors.emergency_contact_phone.length > 0 && <span className='error' style={{ color: 'red' }}>{errors.emergency_contact_phone}</span>}
                                  </div>
                                  <div class="col-md-6">
                                    {/****  start with Emergency Phone comments*/}
                                    <div class="form-group">
                                      <label for="">Emergency Phone Comments</label>
                                      <input type="text" class="form-control" name="emergency_contact_phone_comments" noValidate
                                        id="emergencyContactPhoneComments" value={this.state.eduData.emergency_contact_phone_comments} onChange={this.handleEduFieldsChanges} />
                                    </div>
                                  </div>
                                </div>

                                <div class="row">
                                  <div class="col-md-6">
                                    <div class="custom-control custom-checkbox pt-2">
                                      <input type="checkbox" checked={this.state.eduData.emergency_contact_declined} name="emergency_contact_declined"
                                        id="emeContactDeclined" onChange={this.handleEduFieldsChanges} class="custom-control-input" />
                                      <label class="custom-control-label font-weight-normal" for="emeContactDeclined">
                                        Decline To Provide Emergency Contact
                                      </label>
                                    </div>
                                  </div>
                                </div>
                                  {/******* start with  Previous Therapy */}
                                <hr class="mt-4 hr-blue" />
                                <div class="row">
                                  <div class="col-md-6">
                                    <div class="form-group">
                                      <label for="">Previous Therapy</label>
                                      <input type="text" value={this.state.eduData.previous_therapy} name="previous_therapy" onChange={this.handleEduFieldsChanges}
                                        id="previousTherapy" class="form-control" noValidate />
                                    </div>
                                  </div>
                                  {/******* start with  Previous Therapy Comments*/}
                                  <div class="col-md-6">
                                    <div class="form-group">
                                      <label for="">Previous Therapy Comments</label>
                                      <input type="text" value={this.state.eduData.previous_therapy_comments} name="previous_therapy_comments" onChange={this.handleEduFieldsChanges}
                                        id="previousTherapyComments" class="form-control" noValidate />
                                    </div>
                                  </div>
                                </div>
                                  {/******* start with  Previous Pharmacy*/}
                                <div class="row">
                                  <div class="col-md-6">
                                    <div class="form-group">
                                      <label for="">Previous Pharmacy</label>
                                      <input type="text" value={this.state.eduData.previous_pharmacy} name="previous_pharmacy" onChange={this.handleEduFieldsChanges}
                                        id="previousPharmacy" class="form-control" noValidate />
                                    </div>
                                  </div>
                                  {/******* start with  Previous Pharmacy Comments*/}
                                  <div class="col-md-6">
                                    <div class="form-group">
                                      <label for="">Previous Pharmacy Comments</label>
                                      <input type="text" value={this.state.eduData.previous_pharmacy_comments} name="previous_pharmacy_comments" onChange={this.handleEduFieldsChanges}
                                        id="previousPharmacyComments" class="form-control" noValidate />
                                    </div>
                                  </div>
                                </div>
                                  {/*********** Discussed with patient  */}
                                <hr class="mt-4 hr-blue" />
                                <div class="row">
                                  <div class="col-md-4">
                                    <div class="custom-control custom-checkbox pt-2">
                                      <input type="checkbox" checked={this.state.eduData.welcome_packet} name="welcome_packet"
                                        id="welcomePacket" onChange={this.handleEduFieldsChanges.bind(this)} class="custom-control-input" />
                                      <label class="custom-control-label" for="welcomePacket">
                                        Welcome Packet received and contents discussed with patient<span className='error' hidden={this.state.showHideOpt === false}>*</span>
                                      </label>
                                    </div>

                                    {errors.welcome_packet !== '' && <span className='error' style={{ color: 'red' }}>{errors.welcome_packet}</span>}
                                  </div>
                                  {/*********** Discussed with patient comments */}
                                  <div class="col-md-6">
                                    <div class="form-group">
                                      <label for="">Welcome Packet Patient Comments</label>
                                      <input type="text" value={this.state.eduData.welcome_packet_comments} name="welcome_packet_comments" onChange={this.handleEduFieldsChanges}
                                        id="welcomePacketComments" class="form-control" noValidate />
                                    </div>
                                  </div>
                                </div>
                                  {/*********** Discussed /signature on file */}
                                <div class="row">
                                  <div class="col-md-8">
                                    <form class="form-inline">
                                      <div class="custom-control custom-checkbox pt-2 pr-4">
                                        <input type="checkbox" checked={this.state.eduData.hipaa_signature} name="hipaa_signature"
                                          id="hipaaSignature" onChange={this.handleEduFieldsChanges.bind(this)} class="custom-control-input" />
                                        <label class="custom-control-label" for="hipaaSignature">
                                          HIPAA discussed/signature on file
                                        </label>
                                      </div>
                                      <input type="file" name="hipaa_form" id="hipaaForm" onChange={this.onFileChange} />
                                    </form>
                                  </div>
                                </div>

                                <hr class="mt-4 hr-blue" />
                                {this.state.medicationsInputList.map((x, i) => {
                                  return (
                                    <div className="row">
                                      <div className="col-lg-12">
                                        <form className="form-inline">
                                          <div className="row mt-3 w-100">
                                            {/***********start with Medication */}
                                            <div className="col-md-4">
                                              <label for="">Medications<span className='error'>*</span>
                                              </label>
                                              <select value={this.state.medicationsInputList[i].id}
                                                onChange={(e) => this.handleEduMedications(e, i)}
                                                id="id" name="id" className="form-control medi-select">
                                                <option value="">Please Choose</option>
                                                {this.state.medicationsArr.map(med => (
                                                  <option value={med.id}>
                                                    {med.name}
                                                  </option>
                                                ))}
                                              </select>
                                              {(this.state.eduMedErrors[i]) ? (this.state.eduMedErrors[i].id.length > 0 &&
                                                <span className='error' style={{ color: 'red' }}>
                                                  {this.state.eduMedErrors[i].id}</span>) : ''}
                                            </div>
                                            {/*********** start with Refrigerate */}
                                            <div className="col-md-2">
                                              <label for="">
                                                Refrigerate<span className='error'>*</span>
                                              </label>
                                              <select value={this.state.medicationsInputList[i].refrigerate} onChange={(e) => this.handleEduMedications(e, i)}
                                                id="refrigerate" name="refrigerate" className="form-control w-100 refrigerator-select">
                                                <option value="" className="opt">Please Choose</option>
                                                <option value="2" className="opt">Yes</option>
                                                <option value="1" className="opt">No</option>
                                              </select>
                                              {(this.state.eduMedErrors[i]) ? (this.state.eduMedErrors[i].refrigerate.length > 0 &&
                                                <span className='error' style={{ color: 'red' }}>
                                                  {this.state.eduMedErrors[i].refrigerate}</span>) : ''}
                                            </div>
                                                  {/* start with  Prescribing MD */}
                                            <div className="col-md-3">
                                              <label for="">
                                                Prescribing MD <span className='error'>*</span>
                                              </label>
                                              <input type="text" value={this.state.medicationsInputList[i].prescribingMD} className="form-control w-100" onChange={(e) => this.handleEduMedications(e, i)}
                                                name="prescribingMD" id="prescribingMd" />
                                              {(this.state.eduMedErrors[i]) ? (this.state.eduMedErrors[i].prescribingMD.length > 0 &&
                                                <span className='error' style={{ color: 'red' }}>
                                                  {this.state.eduMedErrors[i].prescribingMD}</span>) : ''}
                                            </div>
                                                  {/* start with office phone*/}
                                            <div className="col-md-2 parent-label">
                                              <label for="">
                                                Office  Phone <span className='error'>*</span>
                                              </label>
                                              <input type="text" value={this.state.medicationsInputList[i].officePhone} className="form-control w-100 office-inp" onChange={(e) => this.handleEduMedications(e, i)}
                                                name="officePhone" id="officePhone" />
                                              {(this.state.eduMedErrors[i]) ? (this.state.eduMedErrors[i].officePhone.length > 0 &&
                                                <span className='error' style={{ color: 'red' }}>
                                                  {this.state.eduMedErrors[i].officePhone}</span>) : ''}
                                            </div>

                                            <div className="col-md-1">
                                              <td className="d-flex justify-content-end cst-delete-icon">
                                                {
                                                  this.state.medicationsInputList.length !== 1 && i != 0 && <button type="button" className="btn btn-primary mt-1 btnControl btndel" onClick={() => this.handleMedRemClick(i)}>
                                                    <IconWithTooltip
                                                      Icon={DeleteIcon}
                                                      text="Remove"
                                                    /></button>
                                                }
                                              </td>
                                            </div>
                                          </div>
                                        </form>
                                      </div>
                                    </div>
                                  );
                                })}

                                <hr class="mt-4 hr-blue" />
                                <div class="row">
                                   {/* start with  Diagnosis Codes */}
                                  <div class="col-md-3 pr-0">
                                    <label for="DiagnosisCodes" class="col-md-12 pl-0">
                                      Diagnosis Codes  <span class="text-danger">*</span>
                                    </label>
                                  </div>
                                  <div class="col-md-5 pl-0" onKeyUp={this.onKeyUp.bind(this)} onClick={this.onKeyUp.bind(this)}>
                                    
                                      <Select
                                      className="dropdown filterdropdown"
                                      placeholder="Please Choose Diagnosis Codes"
                                      options={this.state.diagnosisCodes} // set list of the data
                                      onChange={this.onSelectedDiagnosisCode.bind(this)} // assign onChange function
                                      isMulti
                                      isSearchable
                                    />
                                    {errors.diagnosis_codes.length > 0 && <span className='error' style={{ color: 'red' }}>{errors.diagnosis_codes}</span>}
                                  </div>
                                </div>

                                     {/* start with primary Diagnosis Codes */}
                                <div class="row mt-5">
                                  <div class="col-md-3 pr-0">
                                    <label for="PrimaryDiagnosisCodes" class="col-md-12 pl-0"> Primary Diagnosis Codes  <span class="text-danger">*</span></label>
                                  </div>
                                  <div class="col-md-5 pl-0">
                                    <Select
                                      className="dropdown prifilterdropdown"
                                      placeholder="Please Choose Primary Diagnosis Codes"
                                      options={this.state.selDiagnosisCodeValue} // set list of the data
                                      onChange={this.onSelPrimaryDiagCode.bind(this)} // assign onChange function
                                      isMulti
                                    />
                                    {errors.primary_diagnosis_codes.length > 0 && <span className='error' style={{ color: 'red' }}>{errors.primary_diagnosis_codes}</span>}
                                  </div>
                                </div>

                                <hr class="mt-4 hr-blue" />
                                <div class="row mt-4">
                                  <div class="col-md-12">
                                    <label className="mb-3 mt-2">
                                      <b>Therapy</b>
                                    </label>
                                  </div>
                                   {/* start with Physician Education Complete */}
                                  <div class="col-md-5 mb-3">
                                    <div class="mb-2">
                                      <div class="custom-control custom-checkbox pt-2">
                                        <label class="font-weight-normal" for="PhysicianEducationComplete">
                                          Physician Education Complete <span className='error' hidden={this.state.showHideOpt === false}>*</span>
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-md-2 mb-3">
                                    <select value={this.state.eduData.physical_edu_complete} onChange={this.handleEduFieldsChanges}
                                      id="physicalEduComplete" name="physical_edu_complete" class="form-control">
                                      <option value="">Please Choose</option>
                                      <option value="2">Yes</option>
                                      <option value="1">No</option>
                                    </select>
                                    {errors.physical_edu_complete.length > 0 && <span className='error'  style={{ color: 'red' }}>{errors.physical_edu_complete}</span>}
                                  </div>
                                  <div class="col-md-5 text-right mb-2">
                                    <input type="text" value={this.state.eduData.physical_edu_complete_comments} onChange={this.handleEduFieldsChanges}
                                      id="physicalEduCompleteComments" name="physical_edu_complete_comments" class="form-control" />
                                  </div>
                                   {/* start with self-administer their medication */}
                                  <div class="col-md-5  mb-2">
                                    <div class="mb-3">
                                      <div class="custom-control custom-checkbox pt-2">
                                        <label class="font-weight-normal" for="IsThePatientSelfAdministerMedications">
                                          Is the patient able to self-administer their medication(s)?<span className='error'  hidden={this.state.showHideOpt === false}>*</span>
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-md-2 mb-3">
                                    <select value={this.state.eduData.self_admin_meds} onChange={this.handleEduFieldsChanges}
                                      id="selfAdminMeds" name="self_admin_meds" class="form-control">
                                      <option value="">Please Choose</option>
                                      <option value="1">Yes</option>
                                      <option value="2">No</option>
                                    </select>
                                    {errors.self_admin_meds.length > 0 && <span className='error' style={{ color: 'red' }}>{errors.self_admin_meds}</span>}
                                  </div>
                                  <div class="col-md-5 text-right  mb-2">
                                    <input type="text" value={this.state.eduData.self_admin_meds_comments} id="selfAdminMedsComments"
                                      name="self_admin_meds_comments" onChange={this.handleEduFieldsChanges} class="form-control" />
                                  </div>
                                  {/* start with Route of Administration */}
                                  <div class="col-md-5  mb-2">
                                    <div class="mb-3">
                                      <div class="custom-control custom-checkbox pt-2">
                                        <label class="font-weight-normal" for="RouteofAdministration">
                                          Route of Administration<span className='error' hidden={this.state.showHideOpt === false}>*</span>
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-md-2 mb-3">
                                    <select value={this.state.eduData.administration_route} onChange={this.handleEduFieldsChanges}
                                      id="administrationRoute" name="administration_route" class="form-control">
                                      <option value="">Please Choose</option>
                                      <option value="2">Yes</option>
                                      <option value="1">No</option>
                                    </select>
                                    {errors.administration_route.length > 0 && <span className='error' style={{ color: 'red' }}>{errors.administration_route}</span>}
                                  </div>
                                  <div class="col-md-5 text-right mb-2">
                                    <input type="text" value={this.state.eduData.administration_route_comments} onChange={this.handleEduFieldsChanges}
                                      id="administrationRouteComments" name="administration_route_comments" class="form-control" />
                                  </div>
                                  {/* start with Direction */}
                                  <div class="col-md-5 mb-2">
                                    <div class="mb-3">
                                      <div class="custom-control custom-checkbox pt-2">
                                        <label class="font-weight-normal" for="Directions">
                                          Directions<span className='error' hidden={this.state.showHideOpt === false}>*</span>
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-md-2 mb-3">
                                    <select value={this.state.eduData.direction} onChange={this.handleEduFieldsChanges} name="direction"
                                      id="direction" class="form-control">
                                      <option value="">Please Choose</option>
                                      <option value="1">Yes</option>
                                      <option value="2">No</option>
                                    </select>
                                    {errors.direction.length > 0 && <span className='error' style={{ color: 'red' }}>{errors.direction}</span>}
                                  </div>
                                  <div class="col-md-5 text-right mb-2">
                                    <input type="text" value={this.state.eduData.direction_comments} onChange={this.handleEduFieldsChanges}
                                      id="directionComments" name="direction_comments" class="form-control" />
                                  </div>
                                    {/* start with Missed Dose Instructions*/}
                                  <div class="col-md-5 mb-2">
                                    <div class="mb-3">
                                      <div class="custom-control custom-checkbox pt-2">
                                        <label class="font-weight-normal" for="RouteofAdministration">
                                          Missed Dose Instructions<span className='error' hidden={this.state.showHideOpt === false}>*</span>
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-md-2 mb-3">
                                    <select value={this.state.eduData.missed_dose_instructions} onChange={this.handleEduFieldsChanges}
                                      id="missedDoseInstructions" name="missed_dose_instructions" class="form-control">
                                      <option value="">Please Choose</option>
                                      <option value="2">Yes</option>
                                      <option value="1">No</option>
                                    </select>
                                    {errors.missed_dose_instructions.length > 0 && <span className='error' style={{ color: 'red' }}>{errors.missed_dose_instructions}</span>}
                                  </div>
                                  <div class="col-md-5 text-right mb-2">
                                    <input type="text" value={this.state.eduData.mdi_comments} onChange={this.handleEduFieldsChanges}
                                      id="mdiComments" name="mdi_comments" class="form-control" />
                                  </div>
                                   {/* start with Reproductive implications*/}
                                  <div class="col-md-5 mb-2">
                                    <div class="mb-3">
                                      <div class="custom-control custom-checkbox pt-2">
                                        <label class="font-weight-normal" for="ReproductiveImplications">
                                          Reproductive implications<span className='error' hidden={this.state.showHideOpt === false}>*</span>
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-md-2 mb-3">
                                    <select value={this.state.eduData.reproductive_implications} onChange={this.handleEduFieldsChanges}
                                      id="reproductiveImplications" name="reproductive_implications" class="form-control">
                                      <option value="">Please Choose</option>
                                      <option value="1">Yes</option>
                                      <option value="0">No</option>
                                    </select>
                                    {errors.reproductive_implications.length > 0 && <span className='error' style={{ color: 'red' }}>{errors.reproductive_implications}</span>}
                                  </div>
                                  <div class="col-md-5 text-right mb-2">
                                    <input type="text" value={this.state.eduData.rprdi_comments} onChange={this.handleEduFieldsChanges}
                                      id="rprdiComments" name="rprdi_comments" class="form-control" />
                                  </div>
                                   {/* start with storage*/}
                                  <div class="col-md-5 mb-2">
                                    <div class="mb-3">
                                      <div class="custom-control custom-checkbox pt-2">
                                        <label class="font-weight-normal" for="Storage">
                                          Storage<span className='error' hidden={this.state.showHideOpt === false}>*</span>
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-md-2 mb-3">
                                    <select value={this.state.eduData.storage} onChange={this.handleEduFieldsChanges} name="storage"
                                      id="storage" class="form-control">
                                      <option value="">Please Choose</option>
                                      <option value="2">Yes</option>
                                      <option value="1">No</option>
                                    </select>
                                    {errors.storage.length > 0 && <span className='error' style={{ color: 'red' }}>{errors.storage}</span>}
                                  </div>
                                  <div class="col-md-5 text-right mb-2">
                                    <input type="text" value={this.state.eduData.storage_comments} onChange={this.handleEduFieldsChanges}
                                      id="storageComments" name="storage_comments" class="form-control" />
                                  </div>
                                   {/* start with Disposal*/}
                                  <div class="col-md-5 mb-2">
                                    <div class="mb-3">
                                      <div class="custom-control custom-checkbox pt-2">
                                        <label class="font-weight-normal" for="Disposal">
                                          Disposal<span className='error' hidden={this.state.showHideOpt === false}>*</span>
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-md-2 mb-3">
                                    <select value={this.state.eduData.disposal} onChange={this.handleEduFieldsChanges} name="disposal"
                                      id="disposal" class="form-control">
                                      <option value="">Please Select</option>
                                      <option value="2">Yes</option>
                                      <option value="1">No</option>
                                    </select>
                                    {errors.disposal.length > 0 && <span className='error' style={{ color: 'red' }}>{errors.disposal}</span>}
                                  </div>
                                  <div class="col-md-5 text-right mb-2">
                                    <input type="text" value={this.state.eduData.disposal_comments} onChange={this.handleEduFieldsChanges}
                                      id="disposalComments" name="disposal_comments" class="form-control" />
                                  </div>
                                   {/* start with Approriatenss*/}
                                  <div class="col-md-5 mb-2">
                                    <div class="mb-3">
                                      <div class="custom-control custom-checkbox pt-2">
                                        <label class="font-weight-normal" for="Appropriateness">
                                          Appropriateness<span className='error' hidden={this.state.showHideOpt === false}>*</span>
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-md-2 mb-3">
                                    <select value={this.state.eduData.appropriateness} onChange={this.handleEduFieldsChanges}
                                      id="appropriateness" name="appropriateness" class="form-control">
                                      <option value="">Please Choose</option>
                                      <option value="2">Yes</option>
                                      <option value="1">No</option>
                                    </select>
                                    {errors.appropriateness.length > 0 && <span className='error' style={{ color: 'red' }}>{errors.appropriateness}</span>}
                                  </div>
                                  <div class="col-md-5 text-right mb-2">
                                    <input type="text" value={this.state.eduData.appropriateness_comments} id="appropriatenessComments"
                                      onChange={this.handleEduFieldsChanges} name="appropriateness_comments" class="form-control" />
                                  </div>
                                     {/* start with Goal of therapy*/}
                                  {this.state.isgotshow ? (
                                    <div class="col-md-12 row marginzero" >
                                      <div class="col-md-5 mb-2">
                                        <div class="mb-3">
                                          <div class="custom-control custom-checkbox pt-2">
                                            <input type="checkbox" onChange={this.handleEduFieldsChanges} name="goals_of_therapy"
                                              id="goalsOfTherapy" className="custom-control-input" />
                                            <label class="custom-control-label font-weight-normal" for="goalsOfTherapy">
                                              Goals of Therapy<span className='error'>*</span>
                                            </label>
                                          </div>
                                          {errors.goals_of_therapy !== '' && <span className='error' style={{ color: 'red' }}>{errors.goals_of_therapy}</span>}
                                        </div>
                                      </div>
                                      <div class="col-md-2 mb-3"></div>
                                      <div class="col-md-5 text-right mb-2">
                                        <input type="text" value={this.state.eduData.goals_of_therapy_comments} id="goalsOfTherapyComments"
                                          onChange={this.handleEduFieldsChanges} name="goals_of_therapy_comments" class="form-control" />
                                      </div>
                                    </div>
                                  ) : null }

                                  <div class="col-md-12">
                                    <label className="mb-2 mt-2">
                                      <b>DUR</b>
                                    </label>
                                  </div>
                                   {/* start with Drug-drug*/}
                                  <div class="col-md-5 mb-2">
                                    <div class="custom-checkbox mb-3">
                                      <div class="custom-control custom-checkbox pt-2">
                                        <label class="font-weight-normal" for="DrugDrug">
                                          Drug-Drug<span className='error' hidden={this.state.showHideOpt === false}>*</span>
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-md-2 mb-3">
                                    <select value={this.state.eduData.drug_drug} onChange={this.handleEduFieldsChanges} name="drug_drug"
                                      id="drugDrug" class="form-control">
                                      <option value="">Please Choose</option>
                                      <option value="1">Yes</option>
                                      <option value="0">No</option>
                                    </select>
                                    {errors.drug_drug.length > 0 && <span className='error' style={{ color: 'red' }}>{errors.drug_drug}</span>}
                                  </div>
                                  <div class="col-md-5 text-right mb-2">
                                    <input type="text" value={this.state.eduData.drug_drug_comment} name="drug_drug_comment" id="drugDrugComment"
                                      onChange={this.handleEduFieldsChanges} class="form-control" />
                                  </div>
                                      {/* start with drug-disease*/}
                                  <div class="col-md-5 mb-2">
                                    <div class="custom-checkbox mb-3">
                                      <div class="custom-control custom-checkbox pt-2">
                                        <label class="font-weight-normal" for="DrugDisease">
                                          Drug-Disease<span className='error' hidden={this.state.showHideOpt === false}>*</span>
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-md-2 mb-3">
                                    <select value={this.state.eduData.drug_disease} onChange={this.handleEduFieldsChanges} name="drug_disease"
                                      id="drugDisease" class="form-control">
                                      <option value="">Please Choose</option>
                                      <option value="1">Yes</option>
                                      <option value="0">No</option>
                                    </select>
                                    {errors.drug_disease.length > 0 && <span className='error' style={{ color: 'red' }}>{errors.drug_disease}</span>}
                                  </div>
                                  <div class="col-md-5 text-right mb-2">
                                    <input type="text" value={this.state.eduData.drug_disease_comment} onChange={this.handleEduFieldsChanges}
                                      id="drugDiseaseComment" name="drug_disease_comment" class="form-control" />
                                  </div>
                                  {/* start with drug-condition*/}
                                  <div class="col-md-5 mb-2">
                                    <div class="mb-3">
                                      <div class="custom-control custom-checkbox pt-2">
                                        <label class="font-weight-normal" for="DrugCondition">
                                          Drug-Condition<span className='error' hidden={this.state.showHideOpt === false}>*</span>
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-md-2 mb-3">
                                    <select value={this.state.eduData.drug_condition} onChange={this.handleEduFieldsChanges} name="drug_condition"
                                      id="drugCondition" class="form-control">
                                      <option value="">Please Choose</option>
                                      <option value="1">Yes</option>
                                      <option value="0">No</option>
                                    </select>
                                    {errors.drug_condition.length > 0 && <span className='error' style={{ color: 'red' }}>{errors.drug_condition}</span>}
                                  </div>
                                  <div class="col-md-5 text-right mb-2">
                                    <input type="text" value={this.state.eduData.drug_condition_comment} onChange={this.handleEduFieldsChanges}
                                      id="drugConditionComment" name="drug_condition_comment" class="form-control" />
                                  </div>
                                  {/* start with other*/}
                                  <div class="col-md-5 mb-2">
                                    <div class="mb-3">
                                      <div class="custom-control custom-checkbox pt-2">
                                        <label class="font-weight-normal" for="Other">
                                          Other
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-md-2 mb-3">
                                    <select value={this.state.eduData.dur_other} onChange={this.handleEduFieldsChanges} name="dur_other"
                                      id="durOther" class="form-control">
                                      <option value="">Please Choose</option>
                                      <option value="1">Yes</option>
                                      <option value="0">No</option>
                                    </select>
                                  </div>
                                  <div class="col-md-5 text-right mb-2">
                                    <input type="text" value={this.state.eduData.dur_other_comment} name="dur_other_comment"
                                      id="durOtherComment" onChange={this.handleEduFieldsChanges} class="form-control" />
                                  </div>

                                  <div class="col-md-12">
                                    <label className="mb-2 mt-2">
                                      <b>Treatment</b>
                                    </label>
                                  </div>
                                  {/* start with common side effects*/}
                                  <div class="col-md-5 mb-2">
                                    <div class="mb-3">
                                      <div class="custom-control custom-checkbox pt-2">
                                        <input type="checkbox" onChange={this.handleEduFieldsChanges} value={this.state.eduData.common_side_effects}
                                          name="common_side_effects" className="custom-control-input" id="CommonSideEffects" />
                                        <label class="custom-control-label font-weight-normal" for="CommonSideEffects">
                                          Common Side Effects<span className='error' hidden={this.state.showHideOpt === false}>*</span>
                                        </label>
                                      </div>
                                      {errors.common_side_effects !== '' && <span className='error' style={{ color: 'red' }}>{errors.common_side_effects}</span>}
                                    </div>
                                  </div>
                                  <div class="col-md-2 mb-3"></div>
                                  <div class="col-md-5 text-right mb-2">
                                    <input type="text" value={this.state.eduData.cse_comments} onChange={this.handleEduFieldsChanges}
                                      id="cseComments" name="cse_comments" class="form-control" />
                                  </div>
                                  {/* start with other important Information*/}
                                  <div class="col-md-5 mb-2">
                                    <div class="mb-3">
                                      <div class="custom-control custom-checkbox pt-2">
                                        <input type="checkbox" onChange={this.handleEduFieldsChanges} name="other_important_info"
                                          className="custom-control-input" id="otherImpInfo" />
                                        <label class="custom-control-label font-weight-normal" for="otherImpInfo">
                                          Other Important Information
                                        </label>
                                      </div>
                                      {errors.other_important_info !== '' && <span className='error' style={{ color: 'red' }}>{errors.other_important_info}</span>}
                                    </div>
                                  </div>
                                  <div class="col-md-2 mb-3"></div>
                                  <div class="col-md-5 text-right mb-2">
                                    <input type="text" value={this.state.eduData.other_imp_info_comments} name="other_imp_info_comments"
                                      id="otherImpInfoComments" onChange={this.handleEduFieldsChanges} class="form-control" />
                                  </div>

                                  <div class="col-md-12">
                                    <label className="mb-2 mt-2">
                                      <b>Other</b>
                                    </label>
                                  </div>
                                  {/************ start with Life assessment ********/  }
                                  <div class="col-md-3 mb-2">
                                    <div class="mb-3">
                                      <div class="custom-control custom-checkbox pt-2">
                                        <label class="font-weight-normal" for="QualityOfLifeAssessment">
                                          Quality of Life Assessment<span className='error' hidden={this.state.showHideOpt === false}>*</span>
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-md-4 mb-3">
                                    <select value={this.state.eduData.quality_of_life} onChange={this.handleEduFieldsChanges} name="quality_of_life"
                                      id="qualityOfLife" class="form-control">
                                      <option value="0">Please Choose</option>
                                      <option value="100">Normal, no complaints, no evidence of disease</option>
                                      <option value="90">Able to carry on normal activity, Minor symptoms of disease</option>
                                      <option value="80">Normal activity with effort. Some symptoms of disease</option>
                                      <option value="70">Cares for self. Unable to carry on normal activity or active work</option>
                                      <option value="60">Requires occasional assistance but is able to care for needs</option>
                                      <option value="50">Requires considerable assistanace and frequent medical care</option>
                                      <option value="40">Disabled. Requires special care and assistance</option>
                                      <option value="30">Severely disabled. Hospitalization is indicated, death not imminent</option>
                                      <option value="20">Very sick, hospitalization necessary. Active treatment necessary</option>
                                      <option value="10">Moribund, fatal processes progressing rapidly</option>
                                    </select>
                                    {errors.quality_of_life.length > 0 && <span className='error' style={{ color: 'red' }}>{errors.quality_of_life}</span>}
                                  </div>
                                  <div class="col-md-5 text-right mb-2">
                                    <input type="text" value={this.state.eduData.quality_of_life_comments} onChange={this.handleEduFieldsChanges}
                                      id="qualityOfLifeComments" name="quality_of_life_comments" class="form-control" />
                                   </div>
                                    {/******  start with Contack the pharmacy *****/}

                                  <div class="col-md-5 mb-2">
                                    <div class="mb-3">
                                      <div class="custom-control custom-checkbox pt-2">
                                        <input type="checkbox" onChange={this.handleEduFieldsChanges} name="how_to_contact"
                                          className="custom-control-input" id="howToContact" />
                                        <label class="custom-control-label font-weight-normal" for="howToContact">
                                          How to contact the pharmacy<span className='error'>*</span>
                                        </label>
                                      </div>
                                      {errors.how_to_contact !== '' && <span className='error' style={{ color: 'red' }}>{errors.how_to_contact}</span>}
                                    </div>
                                  </div>
                                  <div class="col-md-2 mb-3"></div>
                                  <div class="col-md-5 text-right mb-2"></div>
                                  
                                  <div class="col-md-5 mb-2">
                                    <div class="mb-3">
                                      <div class="custom-control custom-checkbox pt-2">
                                        <label class="font-weight-normal" for="TimeSpentWithPatient">
                                          Time Spent with Patient<span className='error'>*</span>
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-md-2 mb-3"></div>
                                  <div class="col-md-5 text-right mb-2">
                                    <input type="text" value={this.state.eduData.time_with_patient} name="time_with_patient"
                                      id="timeWithPatient" onChange={this.handleEduFieldsChanges} class="form-control" />
                                    {errors.time_with_patient.length > 0 && <span className='error' style={{ color: 'red' }}>{errors.time_with_patient}</span>}
                                  </div>
                                </div>
                              </div>
                              {/* start with Is Complate*/}
                              <div class="col-md-5 mb-2">
                                <div class="mb-3">
                                  <div class="custom-control custom-checkbox pt-2">
                                    <input type="checkbox" value={this.state.eduData.is_completed} onChange={this.handleEduFieldsChanges} name="is_completed"
                                      className="custom-control-input" id="isCompleted" />
                                    <label class="custom-control-label font-weight-normal" for="isCompleted">
                                      Is Complete ?
                                    </label>
                                  </div>
                                </div>
                              </div>
                              <div class="row mt-5">
                                <div class="col-sm-8">
                                  <div class="d-flex">
                                    <label class="font-weight-normal mr-2"><b></b></label>
                                    <label class="font-weight-normal"><b> </b></label>
                                  </div>
                                </div>
                                <div class="col-sm-4 text-right">
                                  <button type="submit" class="btn btn-primary" onClick={(evt) => this.handleEduFormSubmit(evt)}>Save</button>
                                </div>
                              </div>

                            </form>
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>
                    </Accordion> 
                                    {/***** Edit the patient  Educational Assessment details  ***/}
                    {this.state.eduAssessmentForms.length > 0 && this.state.eduAssessmentForms.map((x, i) => {
                      return (
                        <Accordion>
                          <Card>
                            <Card.Header className="align-items-center d-flex justify-content-between text-white">
                              <span className="px-2"> Educational Assessment {i + 1}
                                {(this.state.eduAssessmentForms[i].is_draft === true) ? ' (Draft) ' : ''} | &nbsp;
                                {this.state.priSelDiagnosisCodes[i] !== null ? this.state.priSelDiagnosisCodes[i][0].label + '  |' : ''} &nbsp;&nbsp; <b> Created Date :</b> &nbsp;
                                {Moment(this.state.eduAssessmentForms[i].created_on).format('MM/DD/YYYY')} {Moment(this.state.eduAssessmentForms[i].created_on).format('HH:mm')}
                                &nbsp; <b> | Created By :</b> {this.state.eduAssessmentForms[i].created_by_user}</span>
                              <div ClassName="float-right" style={{marginRight:'10px'}}>
                                <Accordion.Toggle as={Button} variant="link" eventKey="0" className="">
                                  <i class="fa fa-arrow-circle-down text-white" aria-hidden="true"></i>
                                </Accordion.Toggle>
                                {this.state.eduAssessmentForms[i].is_draft === true ? (
                                <button type="button" className="btn btn-primary btnControl btndel" 
                                        onClick={() => this.delEducation(this.state.eduAssessmentForms[i].id,i,this.state.eduAssessmentForms[i].is_draft)}>
                                        <IconWithTooltip
                                          Icon={DeleteIcon}
                                          text="Delete"
                                        />
                                </button>) : null
                                }
                              </div>
                            </Card.Header>

                            <Accordion.Collapse eventKey="0">
                              <Card.Body>
                                <form>
                                  <div class="edu">
                                    <div class="row align-items-center">
                                      <div class="col-md-6">
                                        <div class="form-group">
                                          <label for="">Patient Participation Status <span className='error'>*</span></label>
                                          <select value={this.state.eduAssessmentForms[i].participation_status}
                                            onChange={(e) => this.updateEduFieldsChanges(e, i)} id={"participation_status_" + i} class="form-control" name="participation_status">
                                            <option value="0">Please Choose</option>
                                            <option value="1">Patient OPTS out of Program</option>
                                            <option value="2">Patient Agrees to Counseling Only</option>
                                            <option value="3">Patient Agrees to Counseling and Program</option>
                                          </select>
                                          {(this.state.eduAssErrors[i]) ? (this.state.eduAssErrors[i].participation_status.length > 0 &&
                                            <span className='error' style={{ color: 'red' }}>
                                              {this.state.eduAssErrors[i].participation_status}</span>) : ''}
                                        </div>
                                      </div>
                                      <div class="col-md-6">
                                        <div class="custom-control custom-checkbox">
                                          <input type="checkbox" checked={this.state.eduAssessmentForms[i].is_active} name="is_active"
                                            id={"isActive_" + i} onChange={(e) => this.updateEduFieldsChanges(e, i)} class="custom-control-input" />
                                          <label class="custom-control-label" for={"isActive_" + i}>
                                            Assessment Active<span className='error'>*</span>
                                          </label>
                                        </div>
                                        {(this.state.eduAssErrors[i]) ? (this.state.eduAssErrors[i].is_active !== '' &&
                                          <span className='error' style={{ color: 'red' }}>
                                            {this.state.eduAssErrors[i].is_active}</span>) : ''}
                                      </div>
                                    </div>
                                    <div className="row" hidden={this.state.eduAssessmentForms[i].showHideOpt}>
                                      <div class="col-md-6">
                                        <div class="form-group">
                                          <label for="">
                                            Please document why the patient wishes to "Opt Out of The Program"<span className='error'>*</span>
                                          </label>
                                          <input type="text" class="form-control" name="opt_out_reason" value={this.state.eduAssessmentForms[i].opt_out_reason}
                                            id={"optOutReason_" + i} onChange={(e) => this.updateEduFieldsChanges(e, i)} />
                                          {(this.state.eduAssErrors[i]) ? (this.state.eduAssErrors[i].opt_out_reason.length > 0 &&
                                            <span className='error' style={{ color: 'red' }}>
                                              {this.state.eduAssErrors[i].opt_out_reason}</span>) : ''}
                                        </div>
                                      </div>
                                    </div>

                                    <div class="row">
                                      <div class="col-md-6">
                                        <label for="">Person Spoken To<span className='error'>*</span></label>
                                        <input type="text" value={this.state.eduAssessmentForms[i].person_spoken_to}
                                          class="form-control" onChange={(e) => this.updateEduFieldsChanges(e, i)} id={"personSpokenTo_" + i} name="person_spoken_to" />
                                        {(this.state.eduAssErrors[i]) ? (this.state.eduAssErrors[i].person_spoken_to.length > 0 &&
                                          <span className='error' style={{ color: 'red' }}>
                                            {this.state.eduAssErrors[i].person_spoken_to}</span>) : ''}
                                      </div>
                                      <div class="col-md-6">
                                        <div class="form-group">
                                          <label for="">Person Spoken To Comments</label>
                                          <input type="text" value={this.state.eduAssessmentForms[i].person_spoken_to_comments}
                                            class="form-control" onChange={(e) => this.updateEduFieldsChanges(e, i)} id={"personSpokenToComments_" + i} name="person_spoken_to_comments" />
                                        </div>
                                      </div>
                                    </div>

                                    <div class="row">
                                      <div class="col-md-6">
                                        <label for="">Relationship To Patient<span className='error'>*</span></label>
                                        <select value={this.state.eduAssessmentForms[i].relationship_to_patient}
                                          onChange={(e) => this.updateEduFieldsChanges(e, i)} id={"relationshipToPatient_" + i} class="form-control"
                                          name="relationship_to_patient">
                                          <option value="0">Please Choose</option>
                                          <option value="1">Patient</option>
                                          <option value="2">Spouse</option>
                                          <option value="3">Caregiver</option>
                                          <option value="4">Other</option>
                                        </select>
                                        {(this.state.eduAssErrors[i]) ? (this.state.eduAssErrors[i].relationship_to_patient.length > 0 &&
                                          <span className='error' style={{ color: 'red' }}>
                                            {this.state.eduAssErrors[i].relationship_to_patient}</span>) : ''}
                                      </div>
                                      <div class="col-md-6">
                                        <div class="form-group">
                                          <label for="">Relationship To Patient Comments</label>
                                          <input type="text" value={this.state.eduAssessmentForms[i].relationship_to_patient_comments}
                                            class="form-control" onChange={(e) => this.updateEduFieldsChanges(e, i)}
                                            id={"relationshipToPatientComments_" + i} name="relationship_to_patient_comments" />
                                        </div>
                                      </div>
                                    </div>

                                    <hr class="mt-4 hr-blue" />
                                    <div class="row">
                                      <div class="col-md-6">
                                        <div class="form-group">
                                          <label for="">Emergency Contact</label>
                                          <input type="text" maxLength="25" class="form-control"
                                            name="emergency_contact" value={this.state.eduAssessmentForms[i].emergency_contact}
                                            id={"emergencyContact_" + i} onChange={(e) => this.updateEduFieldsChanges(e, i)} />
                                        </div>
                                      </div>
                                      <div class="col-md-6">
                                        <div class="form-group">
                                          <label for="">Emergency Contact Comments</label>
                                          <input type="text" class="form-control" name="emergency_contact_comments"
                                            id={"emergencyContactComments_" + i} value={this.state.eduAssessmentForms[i].emergency_contact_comments}
                                            onChange={(e) => this.updateEduFieldsChanges(e, i)} />
                                        </div>
                                      </div>
                                    </div>

                                    <div class="row">
                                      <div class="col-md-6">
                                        <div class="form-group">
                                          <label for="">Emergency Phone</label>
                                          <input type="text" maxLength="25" class="form-control"
                                            name="emergency_contact_phone" value={this.state.eduAssessmentForms[i].emergency_contact_phone}
                                            id={"emergencyContactPhone_" + i} onChange={(e) => this.updateEduFieldsChanges(e, i)} />
                                        </div>
                                      </div>
                                      <div class="col-md-6">
                                        <div class="form-group">
                                          <label for="">Emergency Phone Comments</label>
                                          <input type="text" class="form-control" name="emergency_contact_phone_comments"
                                            id={"emergencyContactPhoneComments_" + i} value={this.state.eduAssessmentForms[i].emergency_contact_phone_comments}
                                            onChange={(e) => this.updateEduFieldsChanges(e, i)} />
                                        </div>
                                      </div>
                                    </div>

                                    <div class="row">
                                      <div class="col-md-6">
                                        <div class="custom-control custom-checkbox pt-2">
                                          <input type="checkbox" checked={this.state.eduAssessmentForms[i].emergency_contact_declined} name="emergency_contact_declined"
                                            id={"emeContactDeclined_" + i} onChange={(e) => this.updateEduFieldsChanges(e, i)} class="custom-control-input" />
                                          <label class="custom-control-label font-weight-normal" for={"emeContactDeclined_" + i}>
                                            Decline To Provide Emergency Contact
                                          </label>
                                        </div>
                                      </div>
                                    </div>

                                    <hr class="mt-4 hr-blue" />
                                    <div class="row">
                                      <div class="col-md-6">
                                        <div class="form-group">
                                          <label for="">Previous Therapy</label>
                                          <input type="text" value={this.state.eduAssessmentForms[i].previous_therapy} name="previous_therapy"
                                            onChange={(e) => this.updateEduFieldsChanges(e, i)} id={"previousTherapy_" + i} class="form-control" />
                                        </div>
                                      </div>
                                      <div class="col-md-6">
                                        <div class="form-group">
                                          <label for="">Previous Therapy Comments</label>
                                          <input type="text" value={this.state.eduAssessmentForms[i].previous_therapy_comments} name="previous_therapy_comments"
                                            onChange={(e) => this.updateEduFieldsChanges(e, i)} id={"previousTherapyComments_" + i} class="form-control" noValidate />
                                        </div>
                                      </div>
                                    </div>

                                    <div class="row">
                                      <div class="col-md-6">
                                        <div class="form-group">
                                          <label for="">Previous Pharmacy</label>
                                          <input type="text" value={this.state.eduAssessmentForms[i].previous_pharmacy} name="previous_pharmacy"
                                            onChange={(e) => this.updateEduFieldsChanges(e, i)} id={"previousPharmacy_" + i} class="form-control" noValidate />
                                        </div>
                                      </div>
                                      <div class="col-md-6">
                                        <div class="form-group">
                                          <label for="">Previous Pharmacy Comments</label>
                                          <input type="text" value={this.state.eduAssessmentForms[i].previous_pharmacy_comments} name="previous_pharmacy_comments"
                                            onChange={(e) => this.updateEduFieldsChanges(e, i)} id={"previousPharmacyComments_" + i} class="form-control" noValidate />
                                        </div>
                                      </div>
                                    </div>
                                    <hr class="mt-4 hr-blue" />
                                    <div class="row">
                                      <div class="col-md-4">
                                        <div class="custom-control custom-checkbox pt-2">
                                          <input type="checkbox" checked={this.state.eduAssessmentForms[i].welcome_packet} name="welcome_packet"
                                            id={"welcomePacket_" + i} required = "required"
                                            onChange={(e) => this.updateEduFieldsChanges(e, i)} class="custom-control-input" id={"welcomePacket_" + i} />
                                          <label class="custom-control-label" for={"welcomePacket_" + i}>
                                            Welcome Packet received and contents discussed with patient<span className='error' hidden={this.state.showHideOpt === false}>*
                                            
                                            </span>
                                            
                                          </label>
                                          {(this.state.eduAssErrors[i]) ? (this.state.eduAssErrors[i].welcome_packet !== '' &&
                                            <span className='error' style={{ color: 'red' }}>
                                              {this.state.eduAssErrors[i].welcome_packet} </span>) : ''}
                                        </div>
                                      </div>
                                      <div class="col-md-6">
                                        <div class="form-group">
                                          <label for="">Welcome Packet Patient Comments</label>
                                          <input type="text" value={this.state.eduAssessmentForms[i].welcome_packet_comments} name="welcome_packet_comments"
                                            onChange={(e) => this.updateEduFieldsChanges(e, i)} id={"welcomePacketComments_" + i} class="form-control" noValidate />
                                        </div>
                                      </div>
                                    </div>

                                    <div class="row">
                                      <div class="col-md-8">
                                        <form class="form-inline">
                                          <div class="custom-control custom-checkbox pt-2 pr-4">
                                            <input type="checkbox" checked={this.state.eduAssessmentForms[i].hipaa_signature} name="hipaa_signature"
                                              id={"hipaaSignature_" + i} onChange={(e) => this.updateEduFieldsChanges(e, i)} class="custom-control-input" />
                                            <label class="custom-control-label" for={"hipaaSignature_" + i}>
                                              HIPAA discussed/signature on file
                                            </label>
                                          </div>
                                          <input type="file" name="hipaa_form" id={"hipaaForm_" + i} onChange={this.onFileChange} />
                                        </form>
                                      </div>
                                    </div>

                                    <hr class="mt-4 hr-blue" />
                                    {this.state.eduAssessmentForms[i].medications != null && this.state.eduAssessmentForms[i].medications.map((x, j) => {
                                      return (
                                        <div className="row">
                                          <div className="col-lg-12">
                                            <form className="form-inline">
                                              <div className="row mt-3 w-100">
                                                <div className="col-md-4">
                                                  <label for="">
                                                    Medications<span className='error'>*</span>
                                                  </label>
                                                  <select value={this.state.eduAssessmentForms[i].medications[j].medication_id} onChange={(e) => this.updateEduMedications(e, i, j)}
                                                    id="id" name="id" className="form-control medi-select">
                                                    <option value="">Please Choose</option>
                                                    {this.state.medicationsArr.map(med => (
                                                      <option value={med.id}>
                                                        {med.name}
                                                      </option>
                                                    ))}
                                                  </select>
                                                  {(this.state.eduMedErrors[i]) ? (this.state.eduMedErrors[i].id.length > 0 &&
                                                    <span className='error' style={{ color: 'red' }}>
                                                      {this.state.eduMedErrors[i].id}</span>) : ''}
                                                </div>
                                                <div className="col-md-2">
                                                  <label for="">
                                                    Refrigerate<span className='error'>*</span>
                                                  </label>
                                                  <select value={this.state.eduAssessmentForms[i].medications[j].refrigeration} onChange={(e) => this.updateEduMedications(e, i, j)}
                                                    id="refrigerate" name="refrigerate" className="form-control w-100 refrigerator-select">
                                                    <option value="">Please Choose</option>
                                                    <option value="2">Yes</option>
                                                    <option value="1">No</option>
                                                  </select>
                                                  {(this.state.eduMedErrors[i]) ? (this.state.eduMedErrors[i].refrigerate.length > 0 &&
                                                    <span className='error' style={{ color: 'red' }}>
                                                      {this.state.eduMedErrors[i].refrigerate}</span>) : ''}
                                                </div>

                                                <div className="col-md-3">
                                                  <label for="">
                                                    Prescribing MD
                                                  </label>
                                                  <input type="text" value={this.state.eduAssessmentForms[i].medications[j].prescribing_md} className="form-control w-100" onChange={(e) => this.updateEduMedications(e, i, j)} name="prescribingMD" id="prescribingMd" />
                                                  {(this.state.eduMedErrors[i]) ? (this.state.eduMedErrors[i].prescribingMD.length > 0 &&
                                                    <span className='error' style={{ color: 'red' }}>
                                                      {this.state.eduMedErrors[i].prescribingMD}</span>) : ''}
                                                </div>

                                                <div className="col-md-2 parent-label">
                                                  <label for="">
                                                    Office  Phone
                                                  </label>
                                                  <input type="text" value={this.state.eduAssessmentForms[i].medications[j].office_phone} className="form-control w-100 office-inp"
                                                    onChange={(e) => this.updateEduMedications(e, i, j)} name="office_phone" id="officePhone" />
                                                  {(this.state.eduMedErrors[i]) ? (this.state.eduMedErrors[i].officePhone.length > 0 &&
                                                    <span className='error' style={{ color: 'red' }}>
                                                      {this.state.eduMedErrors[i].officePhone}</span>) : ''}
                                                </div>

                                                <div className="col-md-1">
                                                  <td className="d-flex justify-content-end cst-delete-icon">
                                                    {
                                                      this.state.eduAssessmentForms[i].medications[j].length !== 1 && j != 0 &&
                                                      <button type="button" className="btn btn-primary mt-1 btnControl btndel"
                                                        onClick={() => this.updMedRemClick(i, j)}>
                                                        <IconWithTooltip
                                                          Icon={DeleteIcon}
                                                          text="Remove"
                                                        /></button>
                                                    }
                                                  </td>
                                                </div>
                                              </div>
                                            </form>
                                          </div>
                                        </div>
                                      );
                                    })}

                                    {this.state.eduAssessmentForms[i].medications == null && this.state.updMedInputList.map((x, i) => {
                                      return (
                                        <div className="row">
                                          <div className="col-lg-12">
                                            <form className="form-inline">
                                              <div className="row mt-3 w-100">
                                                <div className="col-md-4">
                                                  <label for="">
                                                    Medications<span className='error'>*</span>
                                                  </label>
                                                  <select value={this.state.updMedInputList[i].id}
                                                    onChange={(e) => this.updHandleEduMedications(e, i)}
                                                    id="id" name="id" className="form-control medi-select">
                                                    <option value="">Please Choose</option>
                                                    {this.state.medicationsArr.map(med => (
                                                      <option value={med.id}>
                                                        {med.name}
                                                      </option>
                                                    ))}
                                                  </select>
                                                  {(this.state.eduMedErrors[i]) ? (this.state.eduMedErrors[i].id.length > 0 &&
                                                    <span className='error' style={{ color: 'red' }}>
                                                      {this.state.eduMedErrors[i].id}</span>) : ''}
                                                </div>
                                                <div className="col-md-2">
                                                  <label for="">
                                                    Refrigerate<span className='error'>*</span>
                                                  </label>
                                                  <select value={this.state.updMedInputList[i].refrigerate} onChange={(e) => this.updHandleEduMedications(e, i)}
                                                    id="refrigerate" name="refrigerate" className="form-control w-100 refrigerator-select">
                                                    <option value="">Please Choose</option>
                                                    <option value="2">Yes</option>
                                                    <option value="1">No</option>
                                                  </select>
                                                  {(this.state.eduMedErrors[i]) ? (this.state.eduMedErrors[i].refrigerate.length > 0 &&
                                                    <span className='error' style={{ color: 'red' }}>
                                                      {this.state.eduMedErrors[i].refrigerate}</span>) : ''}
                                                </div>

                                                <div className="col-md-3">
                                                  <label for="">
                                                    Prescribing MD <span className='error'>*</span>
                                                  </label>
                                                  <input type="text" value={this.state.updMedInputList[i].prescribingMD} className="form-control w-100" onChange={(e) => this.updHandleEduMedications(e, i)}
                                                    name="prescribingMD" id="prescribingMd" />
                                                  {(this.state.eduMedErrors[i]) ? (this.state.eduMedErrors[i].prescribingMD.length > 0 &&
                                                    <span className='error' style={{ color: 'red' }}>
                                                      {this.state.eduMedErrors[i].prescribingMD}</span>) : ''}
                                                </div>

                                                <div className="col-md-2 parent-label">
                                                  <label for="">
                                                    Office  Phone <span className='error'>*</span>
                                                  </label>
                                                  <input type="text" value={this.state.updMedInputList[i].officePhone} className="form-control w-100 office-inp" onChange={(e) => this.updHandleEduMedications(e, i)}
                                                    name="officePhone" id="officePhone" />
                                                  {(this.state.eduMedErrors[i]) ? (this.state.eduMedErrors[i].officePhone.length > 0 &&
                                                    <span className='error' style={{ color: 'red' }}>
                                                      {this.state.eduMedErrors[i].officePhone}</span>) : ''}
                                                </div>

                                                <div className="col-md-1">
                                                  <td className="d-flex justify-content-end cst-delete-icon">
                                                    {
                                                      this.state.updMedInputList.length !== 1 && i != 0 && <button type="button" className="btn btn-primary mt-1 btnControl btndel" onClick={() => this.updHandleMedRemClick(i)}>
                                                        <IconWithTooltip
                                                          Icon={DeleteIcon}
                                                          text="Remove"
                                                        /></button>
                                                    }
                                                  </td>
                                                </div>
                                              </div>
                                            </form>
                                          </div>
                                        </div>
                                      );
                                    })}

                                    <hr class="mt-4 hr-blue" />

                                    <div class="row">
                                      <div class="col-md-3 pr-0">
                                        <label for="Medications" class="col-md-12 pl-0"> Diagnosis Codes  <span class="text-danger">*</span></label>
                                      </div>
                                      <div class="col-md-5 pl-0" onKeyUp={this.onUpdKeyUp.bind(this, i)}>
                                        <Select
                                          className="dropdown filterdropdown"
                                          placeholder="Please Choose Diagnosis Codes"
                                          options={this.state.updDiagnosisCodes[i]} // set list of the data
                                          isMulti
                                          value={this.state.selectedDiagnosisCodes[i]}
                                          onChange={this.onUpdSelectedDiagnosisCode.bind(this, i)}
                                        />
                                        {(this.state.eduAssErrors[i]) ? (this.state.eduAssErrors[i].diagnosis_codes.length > 0 &&
                                          <span className='error' style={{ color: 'red' }}>
                                            {this.state.eduAssErrors[i].diagnosis_codes}</span>) : ''}
                                      
                                      </div>
                                    </div>

                                    <div class="row mt-5">
                                      <div class="col-md-3 pr-0">
                                        <label for="Medications" class="col-md-12 pl-0"> Primary Diagnosis Codes  <span class="text-danger">*</span></label>
                                      </div>
                                      <div class="col-md-5 pl-0">
                                        <Select
                                          className="dropdown prifilterdropdown"
                                          placeholder="Please Choose Primary Diagnosis Codes"
                                          options={this.state.selectedDiagnosisCodes[i]} // set list of the data
                                          isMulti
                                          onChange={this.onUpdSelPrimaryDiagCode.bind(this, i)}
                                          value={this.state.priSelDiagnosisCodes[i]}
                                        />
                                        {(this.state.eduAssErrors[i]) ? (this.state.eduAssErrors[i].primary_diagnosis_codes.length > 0 &&
                                          <span className='error' style={{ color: 'red' }}>
                                            {this.state.eduAssErrors[i].primary_diagnosis_codes}</span>) : ''}
                                      </div>
                                    </div>

                                    <hr class="mt-4 hr-blue" />
                                    <div class="row mt-4">
                                      <div class="col-md-12">
                                        <label className="mb-3 mt-2">
                                          <b>Therapy</b>
                                        </label>
                                      </div>
                                      <div class="col-md-5 mb-3">
                                        <div class="mb-2">
                                          <div class="custom-control custom-checkbox pt-2">
                                            <label class="font-weight-normal" for="PhysicianEducationComplete">
                                              Physician Education Complete<span className='error' hidden={this.state.showHideOpt === false}>*</span>
                                            </label>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="col-md-2 mb-3">
                                        <select value={this.state.eduAssessmentForms[i].physical_edu_complete} onChange={(e) => this.updateEduFieldsChanges(e, i)}
                                          id={"physicalEduComplete_" + i} name="physical_edu_complete" class="form-control">
                                          <option value="">Please Choose</option>
                                          <option value="2">Yes</option>
                                          <option value="1">No</option>
                                        </select>
                                        {(this.state.eduAssErrors[i]) ? (this.state.eduAssErrors[i].physical_edu_complete.length > 0 &&
                                          <span className='error' style={{ color: 'red' }}>
                                            {this.state.eduAssErrors[i].physical_edu_complete}</span>) : ''}
                                      </div>
                                      <div class="col-md-5 text-right mb-2">
                                        <input type="text" value={this.state.eduAssessmentForms[i].physical_edu_complete_comments} onChange={(e) => this.updateEduFieldsChanges(e, i)}
                                          id={"physicalEduCompleteComments_" + i} name="physical_edu_complete_comments" class="form-control" />
                                      </div>
                                      <div class="col-md-5  mb-2">
                                        <div class="mb-3">
                                          <div class="custom-control custom-checkbox pt-2">
                                            <label class="font-weight-normal" for="IsThePatientSelfAdministerMedications">
                                              Is the patient able to self-administer their medication(s)?<span className='error' hidden={this.state.showHideOpt === false}>*</span>
                                            </label>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="col-md-2 mb-3">
                                        <select value={this.state.eduAssessmentForms[i].self_admin_meds} onChange={(e) => this.updateEduFieldsChanges(e, i)}
                                          id={"selfAdminMeds_" + i} name="self_admin_meds" class="form-control">
                                          <option value="">Please Choose</option>
                                          <option value="1">Yes</option>
                                          <option value="2">No</option>
                                        </select>
                                        {(this.state.eduAssErrors[i]) ? (this.state.eduAssErrors[i].self_admin_meds.length > 0 &&
                                          <span className='error' style={{ color: 'red' }}>
                                            {this.state.eduAssErrors[i].self_admin_meds}</span>) : ''}
                                      </div>
                                      <div class="col-md-5 text-right  mb-2">
                                        <input type="text" value={this.state.eduAssessmentForms[i].self_admin_meds_comments} id={"selfAdminMedsComments_" + i}
                                          name="self_admin_meds_comments" onChange={(e) => this.updateEduFieldsChanges(e, i)} class="form-control" />
                                      </div>
                                      <div class="col-md-5  mb-2">
                                        <div class="mb-3">
                                          <div class="custom-control custom-checkbox pt-2">
                                            <label class="font-weight-normal" for="RouteofAdministration">
                                              Route of Administration<span className='error' hidden={this.state.showHideOpt === false}>*</span>
                                            </label>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="col-md-2 mb-3">
                                        <select value={this.state.eduAssessmentForms[i].administration_route} onChange={(e) => this.updateEduFieldsChanges(e, i)}
                                          id={"administrationRoute_" + i} name="administration_route" class="form-control">
                                          <option value="">Please Choose</option>
                                          <option value="2">Yes</option>
                                          <option value="1">No</option>
                                        </select>
                                        {(this.state.eduAssErrors[i]) ? (this.state.eduAssErrors[i].administration_route.length > 0 &&
                                          <span className='error' style={{ color: 'red' }}>
                                            {this.state.eduAssErrors[i].administration_route}</span>) : ''}
                                      </div>
                                      <div class="col-md-5 text-right mb-2">
                                        <input type="text" value={this.state.eduAssessmentForms[i].administration_route_comments} onChange={(e) => this.updateEduFieldsChanges(e, i)}
                                          id={"administrationRouteComments_" + i} name="administration_route_comments" class="form-control" />
                                      </div>
                                      <div class="col-md-5 mb-2">
                                        <div class="mb-3">
                                          <div class="custom-control custom-checkbox pt-2">
                                            <label class="font-weight-normal" for="Directions">
                                              Directions<span className='error' hidden={this.state.showHideOpt === false}>*</span>
                                            </label>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="col-md-2 mb-3">
                                        <select value={this.state.eduAssessmentForms[i].direction} name="direction"
                                          id={"direction_" + i} class="form-control" onChange={(e) => this.updateEduFieldsChanges(e, i)} >
                                          <option value="">Please Choose</option>
                                          <option value="1">Yes</option>
                                          <option value="2">No</option>
                                        </select>
                                        {(this.state.eduAssErrors[i]) ? (this.state.eduAssErrors[i].direction.length > 0 &&
                                          <span className='error' style={{ color: 'red' }}>
                                            {this.state.eduAssErrors[i].direction}</span>) : ''}
                                      </div>
                                      <div class="col-md-5 text-right mb-2">
                                        <input type="text" value={this.state.eduAssessmentForms[i].direction_comments} onChange={(e) => this.updateEduFieldsChanges(e, i)}
                                          id={"directionComments_" + i} name="direction_comments" class="form-control" />
                                      </div>
                                              
                                      <div class="col-md-5 mb-2">
                                        <div class="mb-3">
                                          <div class="custom-control custom-checkbox pt-2">
                                            <label class="font-weight-normal" for="MissedDoseInstructions">
                                              Missed Dose Instructions<span className='error' hidden={this.state.showHideOpt === false}>*</span>
                                            </label>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="col-md-2 mb-3">
                                        <select value={this.state.eduAssessmentForms[i].missed_dose_instructions} onChange={(e) => this.updateEduFieldsChanges(e, i)}
                                          id={"missedDoseInstructions_" + i} name="missed_dose_instructions" class="form-control">
                                          <option value="">Please Choose</option>
                                          <option value="2">Yes</option>
                                          <option value="1">No</option>
                                        </select>
                                        {(this.state.eduAssErrors[i]) ? (this.state.eduAssErrors[i].missed_dose_instructions.length > 0 &&
                                          <span className='error' style={{ color: 'red' }}>
                                            {this.state.eduAssErrors[i].missed_dose_instructions}</span>) : ''}
                                      </div>
                                      <div class="col-md-5 text-right mb-2">
                                        <input type="text" value={this.state.eduAssessmentForms[i].mdi_comments} onChange={this.handleEduFieldsChanges}
                                          id={"mdiComments_" + i} name="mdi_comments" class="form-control" onChange={(e) => this.updateEduFieldsChanges(e, i)} />
                                      </div>
                                            
                                      <div class="col-md-5 mb-2">
                                        <div class="mb-3">
                                          <div class="custom-control custom-checkbox pt-2">
                                            <label class="font-weight-normal" for="ReproductiveImplications">
                                              Reproductive implications<span className='error' hidden={this.state.showHideOpt === false}>*</span>
                                            </label>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="col-md-2 mb-3">
                                        <select value={this.state.eduAssessmentForms[i].reproductive_implications} onChange={(e) => this.updateEduFieldsChanges(e, i)}
                                          id={"reproductiveImplications_" + i} name="reproductive_implications" class="form-control">
                                          <option value="">Please Choose</option>
                                          <option value="1">Yes</option>
                                          <option value="2">No</option>
                                        </select>
                                        {(this.state.eduAssErrors[i]) ? (this.state.eduAssErrors[i].reproductive_implications.length > 0 &&
                                          <span className='error' style={{ color: 'red' }}>
                                            {this.state.eduAssErrors[i].reproductive_implications}</span>) : ''}
                                      </div>
                                      <div class="col-md-5 text-right mb-2">
                                        <input type="text" value={this.state.eduAssessmentForms[i].rprdi_comments} onChange={(e) => this.updateEduFieldsChanges(e, i)}
                                          id={"rprdiComments_" + i} name="rprdi_comments" class="form-control" />
                                      </div>
                                          
                                      <div class="col-md-5 mb-2">
                                        <div class="mb-3">
                                          <div class="custom-control custom-checkbox pt-2">
                                            <label class="font-weight-normal" for="Storage">
                                              Storage<span className='error' hidden={this.state.showHideOpt === false}>*</span>
                                            </label>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="col-md-2 mb-3">
                                        <select value={this.state.eduAssessmentForms[i].storage} onChange={(e) => this.updateEduFieldsChanges(e, i)} name="storage"
                                          id={"storage_" + i} class="form-control">
                                          <option value="">Please Choose</option>
                                          <option value="2">Yes</option>
                                          <option value="1">No</option>
                                        </select>
                                        {(this.state.eduAssErrors[i]) ? (this.state.eduAssErrors[i].storage.length > 0 &&
                                          <span className='error' style={{ color: 'red' }}>
                                            {this.state.eduAssErrors[i].storage}</span>) : ''}
                                      </div>
                                      <div class="col-md-5 text-right mb-2">
                                        <input type="text" value={this.state.eduAssessmentForms[i].storage_comments} onChange={(e) => this.updateEduFieldsChanges(e, i)}
                                          id={"storageComments_" + i} name="storage_comments" class="form-control" />
                                      </div>
                                          
                                      <div class="col-md-5 mb-2">
                                        <div class="mb-3">
                                          <div class="custom-control custom-checkbox pt-2">
                                            <label class="font-weight-normal" for="Disposal">
                                              Disposal<span className='error' hidden={this.state.showHideOpt === false}>*</span>
                                            </label>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="col-md-2 mb-3">
                                        <select value={this.state.eduAssessmentForms[i].disposal} onChange={(e) => this.updateEduFieldsChanges(e, i)} name="disposal"
                                          id={"disposal_" + i} class="form-control">
                                          <option value="">Please Select</option>
                                          <option value="2">Yes</option>
                                          <option value="1">No</option>
                                        </select>
                                        {(this.state.eduAssErrors[i]) ? (this.state.eduAssErrors[i].disposal.length > 0 &&
                                          <span className='error' style={{ color: 'red' }}>
                                            {this.state.eduAssErrors[i].disposal}</span>) : ''}
                                      </div>
                                      <div class="col-md-5 text-right mb-2">
                                        <input type="text" value={this.state.eduAssessmentForms[i].disposal_comments} onChange={(e) => this.updateEduFieldsChanges(e, i)}
                                          id={"disposalComments_" + i} name="disposal_comments" class="form-control" />
                                      </div>
                                          
                                      <div class="col-md-5 mb-2">
                                        <div class="mb-3">
                                          <div class="custom-control custom-checkbox pt-2">
                                            <label class="font-weight-normal" for="Appropriateness">
                                              Appropriateness<span className='error' hidden={this.state.showHideOpt === false}>*</span>
                                            </label>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="col-md-2 mb-3">
                                        <select value={this.state.eduAssessmentForms[i].appropriateness} onChange={(e) => this.updateEduFieldsChanges(e, i)}
                                          id={"appropriateness_" + i} name="appropriateness" class="form-control">
                                          <option value="">Please Choose</option>
                                          <option value="2">Yes</option>
                                          <option value="1">No</option>
                                        </select>
                                        {(this.state.eduAssErrors[i]) ? (this.state.eduAssErrors[i].appropriateness.length > 0 &&
                                          <span className='error' style={{ color: 'red' }}>
                                            {this.state.eduAssErrors[i].appropriateness}</span>) : ''}
                                      </div>
                                      <div class="col-md-5 text-right mb-2">
                                        <input type="text" value={this.state.eduAssessmentForms[i].appropriateness_comments} id={"appropriatenessComments_" + i}
                                          onChange={(e) => this.updateEduFieldsChanges(e, i)} name="appropriateness_comments" class="form-control" />
                                      </div>
                                          
                                      {this.state.isgotshow &&
                                        <div class="col-md-12 row marginzero" >
                                          <div class="col-md-5 mb-2" >
                                            <div class="mb-3">
                                              <div class="custom-control custom-checkbox pt-2 pr-4">
                                                <input type="checkbox" checked={this.state.eduAssessmentForms[i].goals_of_therapy} name="goals_of_therapy"
                                                  onChange={(e) => this.updateEduFieldsChanges(e, i)} id={"goalsOfTherapy_" + i} className="custom-control-input" />
                                                <label class="custom-control-label" for={"goalsOfTherapy_" + i}>
                                                  Goals of Therapy<span className='error'>*</span>
                                                </label>
                                              </div>
                                              {(this.state.eduAssErrors[i]) ? (this.state.eduAssErrors[i].goals_of_therapy !== '' &&
                                                <span className='error' style={{ color: 'red' }}>
                                                  {this.state.eduAssErrors[i].goals_of_therapy}</span>) : ''}
                                            </div>
                                          </div>
                                          <div class="col-md-2 mb-3" ></div>
                                          <div class="col-md-5 text-right mb-2" >
                                            <input type="text" value={this.state.eduAssessmentForms[i].goals_of_therapy_comments} id={"goalsOfTherapyComments_" + i}
                                              onChange={(e) => this.updateEduFieldsChanges(e, i)} name="goals_of_therapy_comments" class="form-control" />
                                          </div>
                                         </div>
                                          }

                                      <div class="col-md-12">
                                        <label className="mb-2 mt-2">
                                          <b>DUR</b>
                                        </label>
                                      </div>
                                      <div class="col-md-5 mb-2">
                                        <div class="custom-checkbox mb-3">
                                          <div class="custom-control custom-checkbox pt-2">
                                            <label class="font-weight-normal" for="DrugDrug">
                                              Drug-Drug<span className='error' hidden={this.state.showHideOpt === false}>*</span>
                                            </label>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="col-md-2 mb-3">
                                        <select value={this.state.eduAssessmentForms[i].drug_drug} onChange={(e) => this.updateEduFieldsChanges(e, i)} name="drug_drug"
                                          id={"drugDrug_" + i} class="form-control">
                                          <option value="">Please Choose</option>
                                          <option value="1">Yes</option>
                                          <option value="0">No</option>
                                        </select>
                                        {(this.state.eduAssErrors[i]) ? (this.state.eduAssErrors[i].drug_drug.length > 0 &&
                                          <span className='error' style={{ color: 'red' }}>
                                            {this.state.eduAssErrors[i].drug_drug}</span>) : ''}
                                      </div>
                                      <div class="col-md-5 text-right mb-2">
                                        <input type="text" value={this.state.eduAssessmentForms[i].drug_drug_comment} name="drug_drug_comment" id={"drugDrugComment_" + i}
                                          onChange={(e) => this.updateEduFieldsChanges(e, i)} class="form-control" />
                                      </div>
                                        
                                      <div class="col-md-5 mb-2">
                                        <div class="custom-checkbox mb-3">
                                          <div class="custom-control custom-checkbox pt-2">
                                            <label class="font-weight-normal" for="DrugDisease">
                                              Drug-Disease<span className='error' hidden={this.state.showHideOpt === false}>*</span>
                                            </label>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="col-md-2 mb-3">
                                        <select value={this.state.eduAssessmentForms[i].drug_disease} onChange={(e) => this.updateEduFieldsChanges(e, i)} name="drug_disease"
                                          id={"drugDisease_" + i} class="form-control">
                                          <option value="">Please Choose</option>
                                          <option value="1">Yes</option>
                                          <option value="0">No</option>
                                        </select>
                                        {(this.state.eduAssErrors[i]) ? (this.state.eduAssErrors[i].drug_disease.length > 0 &&
                                          <span className='error' style={{ color: 'red' }}>
                                            {this.state.eduAssErrors[i].drug_disease}</span>) : ''}
                                      </div>
                                      <div class="col-md-5 text-right mb-2">
                                        <input type="text" value={this.state.eduAssessmentForms[i].drug_disease_comment} onChange={(e) => this.updateEduFieldsChanges(e, i)}
                                          id={"drugDiseaseComment_" + i} name="drug_disease_comment" class="form-control" />
                                      </div>
                                      <div class="col-md-5 mb-2">
                                        <div class="mb-3">
                                          <div class="custom-control custom-checkbox pt-2">
                                            <label class="font-weight-normal" for="DrugCondition">
                                              Drug-Condition<span className='error' hidden={this.state.showHideOpt === false}>*</span>
                                            </label>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="col-md-2 mb-3">
                                        <select value={this.state.eduAssessmentForms[i].drug_condition} onChange={(e) => this.updateEduFieldsChanges(e, i)} name="drug_condition"
                                          id={"drugCondition_" + i} class="form-control">
                                          <option value="">Please Choose</option>
                                          <option value="1">Yes</option>
                                          <option value="0">No</option>
                                        </select>
                                        {(this.state.eduAssErrors[i]) ? (this.state.eduAssErrors[i].drug_condition.length > 0 &&
                                          <span className='error' style={{ color: 'red' }}>
                                            {this.state.eduAssErrors[i].drug_condition}</span>) : ''}
                                      </div>
                                      <div class="col-md-5 text-right mb-2">
                                        <input type="text" value={this.state.eduAssessmentForms[i].drug_condition_comment} onChange={(e) => this.updateEduFieldsChanges(e, i)}
                                          id={"drugConditionComment_" + i} name="drug_condition_comment" class="form-control" />
                                      </div>
                                      <div class="col-md-5 mb-2">
                                        <div class="mb-3">
                                          <div class="custom-control custom-checkbox pt-2">
                                            <label class="font-weight-normal" for="Other">
                                              Other
                                            </label>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="col-md-2 mb-3">
                                        <select value={this.state.eduAssessmentForms[i].dur_other} onChange={(e) => this.updateEduFieldsChanges(e, i)} name="dur_other"
                                          id={"durOther_" + i} class="form-control">
                                          <option value="">Please Choose</option>
                                          <option value="1">Yes</option>
                                          <option value="0">No</option>
                                        </select>
                                      </div>
                                      <div class="col-md-5 text-right mb-2">
                                        <input type="text" value={this.state.eduAssessmentForms[i].dur_other_comment} name="dur_other_comment"
                                          id={"durOtherComment_" + i} onChange={(e) => this.updateEduFieldsChanges(e, i)} class="form-control" />
                                      </div>
                                         
                                      <div class="col-md-12">
                                        <label className="mb-2 mt-2">
                                          <b>Treatment</b>
                                        </label>
                                      </div>
                                        <div class="col-md-5 mb-2">
                                        <div class="mb-3">
                                          <div class="custom-control custom-checkbox pt-2">
                                            <input type="checkbox" onChange={(e) => this.updateEduFieldsChanges(e, i)}
                                              checked={this.state.eduAssessmentForms[i].common_side_effects}
                                              name="common_side_effects" className="custom-control-input" id={"CommonSideEffects_" + i} />
                                            <label class="custom-control-label" for={"CommonSideEffects_" + i}>
                                              Common Side Effects<span className='error' hidden={this.state.showHideOpt === false}>*</span>
                                            </label>
                                          </div>
                                          {(this.state.eduAssErrors[i]) ? (this.state.eduAssErrors[i].common_side_effects !== '' &&
                                            <span className='error' style={{ color: 'red' }}>
                                              {this.state.eduAssErrors[i].common_side_effects}</span>) : ''}
                                        </div>
                                      </div>
                                      <div class="col-md-2 mb-3"></div>
                                      <div class="col-md-5 text-right mb-2">
                                        <input type="text" checked={this.state.eduAssessmentForms[i].cse_comments}
                                          value={this.state.eduAssessmentForms[i].cse_comments} onChange={(e) => this.updateEduFieldsChanges(e, i)}
                                          id={"cseComments_" + i} name="cse_comments" class="form-control" />
                                      </div>
                                       <div class="col-md-5 mb-2">
                                        <div class="mb-3">
                                          <div class="custom-control custom-checkbox pt-2">
                                            <input type="checkbox" checked={this.state.eduAssessmentForms[i].other_important_info} onChange={(e) => this.updateEduFieldsChanges(e, i)} name="other_important_info"
                                              className="custom-control-input" id={"otherImpInfo_" + i} />
                                            <label class="custom-control-label" for={"otherImpInfo_" + i}>
                                              Other Important Information
                                            </label>
                                          </div>
                                          {(this.state.eduAssErrors[i]) ? (this.state.eduAssErrors[i].other_important_info !== '' &&
                                            <span className='error' style={{ color: 'red' }}>
                                              {this.state.eduAssErrors[i].other_important_info}</span>) : ''}
                                        </div>
                                      </div>
                                      <div class="col-md-2 mb-3"></div>
                                      <div class="col-md-5 text-right mb-2">
                                        <input type="text" checked={this.state.eduAssessmentForms[i].other_imp_info_comments} value={this.state.eduAssessmentForms[i].other_imp_info_comments} name="other_imp_info_comments"
                                          id={"otherImpInfoComments_" + i} onChange={(e) => this.updateEduFieldsChanges(e, i)} class="form-control" />
                                      </div>

                                      <div class="col-md-12">
                                        <label className="mb-2 mt-2">
                                          <b>Other</b>
                                        </label>
                                      </div>
                                      <div class="col-md-3 mb-2">
                                        <div class="mb-3">
                                          <div class="custom-control custom-checkbox pt-2">
                                            <label class="font-weight-normal" for="QualityOfLifeAssessment">
                                              Quality of Life Assessment<span className='error' hidden={this.state.showHideOpt === false}>*</span>
                                            </label>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="col-md-4 mb-3">
                                        <select value={this.state.eduAssessmentForms[i].quality_of_life} onChange={(e) => this.updateEduFieldsChanges(e, i)} name="quality_of_life"
                                          id={"qualityOfLife_" + i} class="form-control">
                                          <option value="0">Please Choose</option>
                                          <option value="100">Normal, no complaints, no evidence of disease</option>
                                          <option value="90">Able to carry on normal activity, Minor symptoms of disease</option>
                                          <option value="80">Normal activity with effort. Some symptoms of disease</option>
                                          <option value="70">Cares for self. Unable to carry on normal activity or active work</option>
                                          <option value="60">Requires occasional assistance but is able to care for needs</option>
                                          <option value="50">Requires considerable assistanace and frequent medical care</option>
                                          <option value="40">Disabled. Requires special care and assistance</option>
                                          <option value="30">Severely disabled. Hospitalization is indicated, death not imminent</option>
                                          <option value="20">Very sick, hospitalization necessary. Active treatment necessary</option>
                                          <option value="10">Moribund, fatal processes progressing rapidly</option>
                                        </select>
                                        {(this.state.eduAssErrors[i]) ? (this.state.eduAssErrors[i].quality_of_life.length > 0 &&
                                          <span className='error' style={{ color: 'red' }}>
                                            {this.state.eduAssErrors[i].quality_of_life}</span>) : ''}
                                      </div>
                                      <div class="col-md-5 text-right mb-2">
                                        <input type="text" value={this.state.eduAssessmentForms[i].quality_of_life_comments} onChange={(e) => this.updateEduFieldsChanges(e, i)}
                                          id={"qualityOfLifeComments_" + i} name="quality_of_life_comments" class="form-control" />
                                      </div>
                                      
                                      <div class="col-md-5 mb-2">
                                        <div class="mb-3">
                                          <div class="custom-control custom-checkbox pt-2">
                                            <input type="checkbox" onChange={(e) => this.updateEduFieldsChanges(e, i)} name="how_to_contact"
                                              className="custom-control-input" id={"howToContact_" + i} checked={this.state.eduAssessmentForms[i].how_to_contact} />
                                            <label class="custom-control-label font-weight-normal" for={"howToContact_" + i}>
                                              How to contact the pharmacy<span className='error'>*</span>
                                            </label>
                                          </div>
                                          {(this.state.eduAssErrors[i]) ? (this.state.eduAssErrors[i].how_to_contact !== '' &&
                                            <span className='error' style={{ color: 'red' }}>
                                              {this.state.eduAssErrors[i].how_to_contact}</span>) : ''}
                                        </div>
                                      </div>
                                      <div class="col-md-2 mb-3"></div>
                                      <div class="col-md-5 text-right mb-2"></div>

                                      {/* <div class="col-md-5 mb-2">
                                        <div class="mb-3">
                                          <div class="custom-control custom-checkbox pt-2">
                                            <label class="font-weight-normal" for="OtherImpInfo">
                                              Assessment Completed by
                                            </label>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="col-md-2 mb-3"></div>
                                      <div class="col-md-5 text-right mb-2">
                                        <input type="text" value={this.state.eduAssessmentForms[i].assessmentCompletedBy} name="asessment_completed_by"
                                          id={"assessmentCompletedBy_" + i} onChange={(e) => this.updateEduFieldsChanges(e, i)} class="form-control" />
                                      </div> */}
                                      <div class="col-md-5 mb-2">
                                        <div class="mb-3">
                                          <div class="custom-control custom-checkbox pt-2">
                                            <label class="font-weight-normal" for="TimeSpentWithTime">
                                              Time Spent with Patient<span className='error'>*</span>
                                            </label>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="col-md-2 mb-3"></div>
                                      <div class="col-md-5 text-right mb-2">
                                        <input type="text" value={this.state.eduAssessmentForms[i].time_with_patient} name="time_with_patient"
                                          id={"timeWithPatient_" + i} onChange={(e) => this.updateEduFieldsChanges(e, i)} class="form-control" />
                                        {(this.state.eduAssErrors[i]) ? (this.state.eduAssErrors[i].time_with_patient.length > 0 &&
                                          <span className='error' style={{ color: 'red' }}>
                                            {this.state.eduAssErrors[i].time_with_patient}</span>) : ''}
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-md-5 mb-2">
                                    <div class="mb-3">
                                      <div class="custom-control custom-checkbox pt-2">
                                        <input type="checkbox" value={this.state.eduAssessmentForms[i].is_completed} onChange={(e) => this.updateEduFieldsChanges(e, i)} name="is_completed"
                                          className="custom-control-input" id={"isCompleted_" + i} checked={this.state.eduAssessmentForms[i].is_completed} />
                                        <label class="custom-control-label font-weight-normal" for={"isCompleted_" + i}>
                                          Is Complete ?
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="row mt-5">
                                    <div class="col-sm-8">
                                      <div class="d-flex">
                                        
                                        {this.state.eduAssessmentForms[i].is_completed === true && this.state.eduAssessmentForms[i].is_draft === false ?(
                                        <label class="font-weight-normal mr-2"> {this.state.eduAssessmentForms[i].is_completed === true  ? 'Completed By : ' + Moment(this.state.eduAssessmentForms[i].created_on).format('MM/DD/YYYY HH:mm') : ''}&nbsp; | &nbsp; {this.state.eduAssessmentForms[i].created_by_user} </label>):null}
                                      </div>
                                      <div class="d-flex">
                                        <label class="font-weight-normal mr-2"> { 'Updated By : ' +  Moment(this.state.eduAssessmentForms[i].updated_on).format('MM/DD/YYYY HH:mm') }&nbsp; | &nbsp; {this.state.eduAssessmentForms[i].updated_by_user} </label>
                                      </div>
                                    </div>
                                    <div class="col-sm-4 text-right">
                                      <button type="submit" class="btn btn-primary" onClick={(evt) => this.updateEduFormSubmit(evt, i, this.state.eduAssessmentForms[i].id)}>
                                        Save
                                      </button>
                                      { this.state.eduAssessmentForms[i].is_draft === true ? (
                                       <button type="button" class="btn btn-danger" onClick={() => this.delEducation(this.state.eduAssessmentForms[i].id,i,this.state.eduAssessmentForms[i].is_draft)}>
                                        Delete
                                      </button>
                                      ) : null}
                                    </div>
                                    
                                  </div>
                                 </form>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>
                        </Accordion>
                      );
                    })}
                  </div>
                      {/* end of the Edit Edducational Assessment */}
      </>
    );
  }
}