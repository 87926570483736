import React from 'react';
import axios from 'axios';
import { Redirect, Link } from 'react-router-dom';
import { Button, Modal } from 'react-bootstrap';
import dateFormat from 'dateformat';
import { ToastContainer, toast } from 'react-toastify';
import '../../containers/PatientClinicalProfile/bootstrap.min.css';

import {
  VALID_EDUCATION_DATA,
  UPDATE_REFILL_REMINDER_DATA,
  TOKEN_EXPIRED
} from './constants';

import {API_URL,} from '../../config';
import $ from 'jquery';
import IconWithTooltip from 'icon-with-tooltip';
import InfoIcon from '@material-ui/icons/Info';
import 'react-toastify/dist/ReactToastify.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'bootstrap/dist/css/bootstrap.css';
import '../../containers/PatientClinicalProfile/style.css';
import 'react-confirm-alert/src/react-confirm-alert.css';

export default class RefillCodeNotes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        patient_id: this.props !== undefined ? this.props.patient_id : null,
        refillCallNotesInputList: [
        {
          refCallCommDate: "",
          refCallNotesType: "",
          refCallCommNotes: "",
          refFollowUpRequired: "",
          refPersonSpokenTo: "",
          refCallNotesStatus: "",
          refCallNotesFollowDate: "",
          refCallId: "",
        },
      ],
        trackRefillCallInputList: [{
          refCallCommNotes: "",
          refCallNotesStatus: "",
          refCallNotesFollowDate: "",
        }],
        refillRemind: {
          medication: '',
          call_status: '',
          call_status_comment: '',
          person_spoken_to: '',
          person_spoken_comment: '',
          relationship_to_patient: '',
          relationship_to_patient_comment: '',
          patient_residency_status: '',
          patient_care_facility: '',
          patient_care_facility_comment: '',
          doses_remaining: '',
          doses_remaining_comment: '',
          last_refill_missed_doses: '',
          last_refill_missed_doses_comment: '',
          missed_doses: '',
          therapy_score: '',
          otc_medications: '',
          new_medications: '',
          side_effects: '',
          side_effects_comment: '',
          describe_side_effects: '',
          describe_side_effects_comment: '',
          pharmacist_question: '',
          pharmacist_question_comment: '',
          follow_up_required: '',
          amountDue: '',
          amountDueComment: '',
          paymentAmount: '',
          refillCallNotes: '',
          follow_up_date: '',
          medication:[],
        },
        show : false,
      
    }
  }
  // today date
  todayDate = new Date().toISOString().slice(0,10)
  
  // get token from local storage (Data fetch)
  token = localStorage.getItem('token');
  config = {
    headers: { Authorization: `Bearer ${this.token}` },
  };
  /* Show Modal Pop Up Of Refill Reminder Details */
  handleShow = (id) => {
    this.setState({ show: true, });
    axios
      .get(`${API_URL}/api/refill_reminder/${id}`, this.config)
      .then(response => {
        //console.log('Refill remind', response.data.data);
        if (response.status === 200) {
          if (response.data.data.relationship_to_patient == 1) {
            response.data.data.relationship_to_patient = 'Caregiver';
          } else if (response.data.data.relationship_to_patient == 2) {
            response.data.data.relationship_to_patient = 'Spouse';
          } else {
            response.data.data.relationship_to_patient = 'Patient';
          }

          if (response.data.data.patient_residency_status == 1) {
            response.data.data.patient_residency_status = 'Yes';
          } else {
            response.data.data.patient_residency_status = 'No';
          }

          if (response.data.data.patient_care_facility == 1) {
            response.data.data.patient_care_facility = 'Yes';
          } else {
            response.data.data.patient_care_facility = 'No';
          }

          if (response.data.data.last_refill_missed_doses == 50) {
            response.data.data.last_refill_missed_doses = 'Yes';
          } else {
            response.data.data.last_refill_missed_doses = 'No';
          }

          if (response.data.data.otc_medications == 1) {
            response.data.data.otc_medications = 'Yes';
          } else {
            response.data.data.otc_medications = 'No';
          }

          if (response.data.data.side_effects == 1) {
            response.data.data.side_effects = 'Yes';
          } else {
            response.data.data.side_effects = 'No';
          }

          if (response.data.data.pharmacist_question == 1) {
            response.data.data.pharmacist_question = 'Yes';
          } else {
            response.data.data.pharmacist_question = 'No';
          }

          if (response.data.data.amountDue == 1) {
            response.data.data.amountDue = 'Yes';
          } else {
            response.data.data.amountDue = 'No';
          }
          debugger
          if (response.data.data.medication){
            let medication = [];
            for (let i = 0; i < response.data.data.medication.length; i++){
              medication.push(response.data.data.medication[i].medication_name);
            }
            response.data.data.medication = medication;
          }

          this.setState({ refillRemind: response.data.data });

        } else {
          toast.error(VALID_EDUCATION_DATA, {
            position: 'top-right',
            autoClose: 3500,
          });
        }
      })
      .catch(err => console.log(err))
  }
  //Get the call For RefillReminderNotes
  getRefillReminder = () => {
    if (localStorage.getItem('patAllFillsHisChanged') || localStorage.getItem('isPatientAddDelChanged')
      || localStorage.getItem('patCommunicationsChanged') || localStorage.getItem('patTherapyInputChanged')
      || localStorage.getItem('isPatientEduAssChanged') || localStorage.getItem('isAssChanged')) {
      const confirmBox = window.confirm("You have unsaved changes! Do you really want to move to another tab?");
      if (confirmBox === true) {
        
        localStorage.setItem('prevTab', localStorage.getItem('currTab'));
        localStorage.setItem('currTab', 'refillCalNotes');

        localStorage.removeItem('isPatientAddDelChanged');
        localStorage.removeItem('patAllFillsHisChanged');
        localStorage.removeItem('patCommunicationsChanged');
        localStorage.removeItem('patTherapyInputChanged');
        localStorage.removeItem('isPatientEduAssChanged');
        localStorage.removeItem('isAssChanged')

        $("#refCallNotes").attr('href', '#refillCallNotes');
        $("#allFilesHis").removeAttr('href');

        $("#medTherapy").removeAttr('href');
        $("#eduAssessment").removeAttr('href');

        $("#schDelivery").removeAttr('href');
        $("#assCommunications").removeAttr('href');
        $("#archivesAss").removeAttr('href');
        $("#oncoAssessments").removeAttr('href');
      } else {
        setTimeout(() => this.props.setTab(), 250);
      }
    } else {
      localStorage.setItem('prevTab', localStorage.getItem('currTab'));
      localStorage.setItem('currTab', 'refillCalNotes');

      $("#refCallNotes").attr('href', '#refillCallNotes');
      $("#allFilesHis").removeAttr('href');

      $("#eduAssessment").removeAttr('href');
      $("#medTherapy").removeAttr('href');

      $("#schDelivery").removeAttr('href');
      $("#assCommunications").removeAttr('href');
      $("#archivesAss").removeAttr('href');
      $("#oncoAssessments").removeAttr('href');
    }
    this.setState({refillCallNotesInputList: []})
    axios
      .get(`${API_URL}/api/refill_reminder/?patient_id=${this.state.patient_id}`, this.config)
      .then(response => {
        if (response.status == 200) {
          if (response.data.status == 200 || response.data.status == 204) {
            let allRefillData;
            let trackAllRefillData;

            if (response.data.data !== null) {
              allRefillData = response.data.data.map((data, index) => ({
                refCallCommDate: data.created,
                refCallNotesType: "",
                refPersonSpokenTo: data.person_spoken_to,
                refFollowUpRequired: data.follow_up_required,
                refCallCommNotes: data.refillCallNotes,
                refCallNotesStatus: data.call_status,
                refCallNotesFollowDate: data.follow_up_date,
                refCallId: data.id,
              }));

              trackAllRefillData = response.data.data.map((data, index) => ({
                refCallCommNotes: data.refillCallNotes,
                refCallNotesStatus: data.call_status,
                refCallNotesFollowDate: data.follow_up_date,
              }));
            } else {
              allRefillData = [];
              trackAllRefillData = [];
            }

            allRefillData = allRefillData.sort(function(a,b){return (a.refCallCommDate > b.refCallCommDate) ? -1 : 1;})
            this.setState({ refillCallNotesInputList: allRefillData, });
            this.setState({ trackRefillCallInputList: trackAllRefillData, });

          } else if (response.data.status_code == 406) {
            localStorage.removeItem('token');
            localStorage.removeItem('user_name');

            localStorage.removeItem('adminCheck');
            localStorage.setItem('tokenExpired', true);

            const tokenStatus = localStorage.getItem('tokenExpired');
            if (tokenStatus) {
              localStorage.removeItem('tokenExpired');
              toast.error(TOKEN_EXPIRED, {
                position: 'top-right',
                autoClose: 2500,
              });
            }

            const isLogged = !!localStorage.getItem('token');
            if (!isLogged) {
              setTimeout(() => {
                this.props.history.push("/login");
              }, 2500);
            }
          }
        } else if (response.status !== 200) {
          toast.error(VALID_EDUCATION_DATA, {
            position: 'top-right',
            autoClose: 3500,
          });
          return false;
        }
      })
      .catch(error => {
        console.log(error);
      });
  }
  // Handle On Click Events Of Add Button Of Refill Call
  handleRefillCallAddClick = () => {
    const list = [...this.state.refillCallNotesInputList];
    list.push({
      refCallCommDate: "",
      refCallNotesType: "",
      refCallCommNotes: "",
      refCallNotesStatus: "",
      refCallNotesFollowDate: "",
      refCallNotesCheck: "",
      refCallId: "",
    });
    this.setState({
      refillCallNotesInputList: list,
    });
  };
  // Handle On Click Event Of The Remove Button Of Refill Call
  handleRefillCallRemoveClick = index => {
    const list = [...this.state.refillCallNotesInputList];
    list.splice(index, 1);
    this.setState({
      refillCallNotesInputList: list,
    });
  };

  // Handle On Change Event Of The Refill Call
  handleRefillInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...this.state.refillCallNotesInputList];

    list[index][name] = value;
    this.setState({ refillCallNotesInputList: list, });

    for (var i = 0; i < list.length; i++) {
      if ((this.state.trackRefillCallInputList[i].refCallCommNotes !== list[i]['refCallCommNotes'])
        || (this.state.trackRefillCallInputList[i].refCallNotesFollowDate !== list[i]['refCallNotesFollowDate'])
        || (this.state.trackRefillCallInputList[i].refCallNotesStatus !== list[i]['refCallNotesStatus'])) {
        localStorage.setItem('patRefillCallNotesChanged', true);
        break;
      } else {
        localStorage.removeItem('patRefillCallNotesChanged');
      }
    }

  };
   
  /* Handling Refill Reminder Bulk Data */
  handleRefilleBulkData = () => {
    
    let res = this.state.refillCallNotesInputList.map((data, index) => ({
      refCallId: data.refCallId,
      refCallNotesStatus: data.refCallNotesStatus,
      refCallCommNotes: data.refCallCommNotes,
      refCallNotesFollowDate: data.refCallNotesFollowDate,
    }));

    axios
      .post(`${API_URL}/api/refill_reminder_bulk_update/`, res, this.config)
      .then(response => {
        if (response.status === 200) {
          toast.success(UPDATE_REFILL_REMINDER_DATA, {
            position: 'top-right',
            autoClose: 3000,
          });
          localStorage.removeItem('patRefillCallNotesChanged');
          return false;
        }
      })
      .catch(err => console.log(err));
    
  };

  /* Show Modal Pop Up Of Refill Reminder Details */
  handleShowRefillReminderDetails = (id) => {
    this.setState({ show: true });
    this.setState({ refCallId: id });
  }
  // /* Close Modal Pop Up Of Refill Reminder Details */
  handleClose = () => {
    this.setState({
      show: false,
    });
  };
  

  render() {       
    const isLogged = !!localStorage.getItem('token');
		if (!isLogged) {
			return <Redirect to="/login" />;
		}
    return (
        <>
        <Modal show={this.state.show} onHide={this.handleClose} backdrop="static">
                    <Modal.Header closeButton>
                      <Modal.Title>Refill Reminder Details</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <table className="table">
                        <thead>
                          <tr>
                            <th>Medications</th>
                            <th>Call Status</th>
                            <th>Call Status Comments</th>
                            <th>Person Spoken To</th>
                            <th>Person Spoken To Comments</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td id="refillRemMed">
                              {this.state.refillRemind.medication.map((eachMed) => {
                                  return <p>{eachMed}</p>
                              })}
                            </td>
                            <td>
                              {this.state.refillRemind.call_status}
                            </td>
                            <td>
                              {this.state.refillRemind.call_status_comment}
                            </td>
                            <td>
                              {this.state.refillRemind.person_spoken_to}
                            </td>
                            <td>
                              {this.state.refillRemind.person_spoken_comment}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <table className="table">
                        <thead>
                          <tr>
                            <th>Relationship To Patient</th>
                            <th>Relationship To Patient Comments</th>
                            <th>Patient's Residency Status</th>
                            <th>Licensed Care Facility</th>
                            <th> Care Facility Comments</th>
                            <th>Remaining Doses</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              {this.state.refillRemind.relationship_to_patient}
                            </td>
                            <td>
                              {this.state.refillRemind.relationship_to_patient_comment}
                            </td>
                            <td>
                              {this.state.refillRemind.patient_residency_status}
                            </td>
                            <td>
                              {this.state.refillRemind.patient_care_facility}
                            </td>
                            <td>
                              {this.state.refillRemind.patient_care_facility_comment}
                            </td>
                            <td>
                              {this.state.refillRemind.doses_remaining}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <table className="table">
                        <thead>
                          <tr>
                            <th>Remaining Doses Comments</th>
                            <th>Last Refill Missed Any Doses</th>
                            <th>Last Refill Missed Any Doses Comments</th>
                            <th>New OTC Medications</th>
                            <th>New Medications</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              {this.state.refillRemind.doses_remaining_comment}
                            </td>
                            <td>
                              {this.state.refillRemind.last_refill_missed_doses}
                            </td>
                            <td>
                              {this.state.refillRemind.last_refill_missed_doses_comment}
                            </td>
                            <td>
                              {this.state.refillRemind.otc_medications}
                            </td>
                            <td>
                              {this.state.refillRemind.new_medications}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <table className="table">
                        <thead>
                          <tr>
                            <th>New Side Effects</th>
                            <th>Side Effects Comments</th>
                            <th>Questions For Pharmacist</th>
                            <th>Questions For Pharmacist Comments</th>
                            <th>Follow Up</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              {this.state.refillRemind.side_effects}
                            </td>
                            <td>
                              {this.state.refillRemind.side_effects_comment}
                            </td>
                            <td>
                              {this.state.refillRemind.pharmacist_question}
                            </td>
                            <td>
                              {this.state.refillRemind.pharmacist_question_comment}
                            </td>
                            <td>
                              {this.state.refillRemind.follow_up_required}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <table className="table">
                        <thead>
                          <tr>
                            <th>Co-Payment Discussed?</th>
                            <th>Co-Payment Amount Comments</th>
                            <th>Co-Payment Amount</th>
                            <th>Communication Notes</th>
                            <th>Follow Up Date</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              {this.state.refillRemind.amountDue}
                            </td>
                            <td>
                              {this.state.refillRemind.amountDueComment}
                            </td>
                            <td>
                              {this.state.refillRemind.paymentAmount}
                            </td>
                            <td>
                              {this.state.refillRemind.refillCallNotes}
                            </td>
                            <td>
                              {this.state.refillRemind.follow_up_date}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button variant="secondary" onClick={this.handleClose}>
                        Close
                      </Button>
                    </Modal.Footer>
                  </Modal>

                  <div id="refillCallNotes" className="container-fluid tab-pane fade">
                    <br />

                    <div className="table-responsive">
                      <table className="table">
                        <thead>
                          <tr>
                            <th className="w-145">Created Date</th>
                            <th>Person Spoken To</th>
                            <th>Communication Notes</th>
                            <th>Status</th>
                            <th className="w-145">Follow Up Date</th>
                            <th style={{ width: '40px' }}></th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.refillCallNotesInputList.length > 0 ? this.state.refillCallNotesInputList.map((x, i) => {
                            return (
                              <tr key={i}>
                                <td>
                                  <input type="date" className="form-control" name="refCallCommDate" value={dateFormat(x.refCallCommDate, "yyyy-mm-dd")}
                                    onChange={e => this.handleRefillInputChange(e, i)} readOnly />
                                </td>
                                <td>
                                  <input type="text" className="form-control" name="refPersonSpokenTo" value={x.refPersonSpokenTo}
                                    onChange={e => this.handleRefillInputChange(e, i)} readOnly />
                                </td>
                                <td style={{ width: '35%' }}>
                                  <textarea rows="1" className="form-control" name="refCallCommNotes" defaultValue={x.refCallCommNotes}
                                    onChange={e => this.handleRefillInputChange(e, i)}>
                                  </textarea>
                                </td>
                                <td>
                                  <select className="form-control" name="refCallNotesStatus" value={x.refCallNotesStatus} onChange={e => this.handleRefillInputChange(e, i)}>
                                    <option value="open">Open</option>
                                    <option value="closed">Closed</option>
                                  </select>
                                </td>
                                <td>
                                  <input type="date" className="form-control calcWidth" name="refCallNotesFollowDate" min = {this.todayDate} onKeyDown={(event)=>{event.preventDefault()}}
                                    value={dateFormat(x.refCallNotesFollowDate, "yyyy-mm-dd")} onChange={e => this.handleRefillInputChange(e, i)} />
                                </td>
                                <td className="d-flex justify-content-end">
                                  <button onClick={() => this.handleShow(x.refCallId)} type="button" className="btn btn-primary mt-1 btnControl">
                                    <IconWithTooltip
                                      Icon={InfoIcon}
                                      text=""
                                    />
                                  </button>
                                </td>
                              </tr>
                            );
                          }) :
                            (
                              <tr>
                                <td colSpan="6">No records found</td>
                              </tr>
                            )}
                        </tbody>
                      </table>
                    </div>
                    {this.state.refillCallNotesInputList.length > 0 ? <Button onClick={() => this.handleRefilleBulkData()} className="btn btn-primary float-right mt-5">Save Changes</Button> : ''}
                     </div>                                                                                           
      </>
    );
  }
}