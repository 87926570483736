/*
 * TaskPage
 *
 *
 */

import React from 'react';

import CommonHeader from '../../components/CommonHeader';
import CommonSidebar from '../../components/CommonSidebar';
import CommonFooter from '../../components/CommonFooter';

import axios from 'axios';
import { API_URL, } from '../../config';

import { Redirect, Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { UPDATE_CLIENT_LOGO } from './constants';

import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'mdbreact/dist/css/mdb.css';

import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';

import '../../css/style_semantic.css';
import './style.css';

export default class ClientLogo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      clientLogoData: [],
      logoName: '',
      logoImage: '',
      selectedFile: null,
    }
  }

  // get token from localstorage 
  token = localStorage.getItem('token');
  config = {
    headers: { Authorization: `Bearer ${this.token}` },
  };

  getClientLogo = () => {
    axios
      .get(`${API_URL}/api/client_logo/`, this.config)
      .then(response => {
        const arr = [];
        if (response.status == 200 && response.data.data != null) {
          arr.push({
            logoId: response.data.data.id,
            logoName: response.data.data.logo_name,
            logoImage: response.data.data.logo_image,
          });
          // this.setState({ 
          //   selectedFile: response.data.data.logo_image,
          // });
        }
        this.setState({ clientLogoData: arr });
      })
  }


  createClientLogo = () => {
    const formData = new FormData();
    formData.append(
      'logo_image',
      this.state.selectedFile,
      this.state.selectedFile.name,
    );
    formData.append(
      'logo_name',
      this.state.logoName,
    );
    axios
      .post(`${API_URL}/api/client_logo/`, formData, this.config)
      .then(response => {
        if (response.status == 200) {
          window.onload = this.componentDidMount();
          toast.success(response.data.message, {
            position: 'top-right',
            autoClose: 3000,
          });
          window.location.reload();
        }
      })
  }

  updateClientLogo = (e, i) => {
    //console.log('Logo Data', this.state.selectedFile);
    const formData = new FormData();

    if (this.state.selectedFile == null) {
      formData.append('logo_image', null,);
    } else {
      formData.append(
        'logo_image',
        this.state.selectedFile,
        this.state.selectedFile.name,
      );
    }

    formData.append('logo_name', this.state.clientLogoData[0].logoName,);
    axios
      .put(`${API_URL}/api/client_logo/` + this.state.clientLogoData[0].logoId, formData, this.config)
      .then(response => {
        if (response.status == 200) {
          toast.success(UPDATE_CLIENT_LOGO, {
            position: 'top-right',
            autoClose: 3000,
          });
          setTimeout(() => {
            window.location.reload();
          }, 2500);
        }
      })
  }

  handleUpdChange = (e) => {
    let clientLogo = [...this.state.clientLogoData];
    let logoData = { ...clientLogo[e] };

    logoData = e.target.value;
    clientLogo[0]['logoName'] = logoData;

    this.setState({ clientLogoData: clientLogo, });
  };

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  onFileChange = event => {
    this.setState({ selectedFile: event.target.files[0] });
  };

  componentDidMount() {
    this.getClientLogo();
  }

  Logout = () => {
    localStorage.removeItem('token');
  };

  render() {
    const isLogged = !!localStorage.getItem('token');
    if (!isLogged) {
      return <Redirect to="/login" />;
    }

    const isadmin = localStorage.getItem('adminCheck');
    if (isadmin === 'false') {
      return <Redirect to="/tasks" />;
    }

    return (
      <React.Fragment>
        <ToastContainer />
        <CommonHeader />
        <section id="wrapper">
          <CommonSidebar />
          <div className="queue-block">
            <h1>Client Logo</h1>
          </div>
          {this.state.clientLogoData.length > 0 ? this.state.clientLogoData.map((x, i) => {
            return (
              <div>
                <div className="form-group" style={{ display: "-webkit-inline-box" }}>
                  <div class="custom-control">
                    <label class="font-weight-normal" for="OtherImpInfo"> Logo Name </label>
                  </div>
                  <div className="col-md-12">
                    <input type="text" onChange={this.handleUpdChange.bind(this)}
                      value={this.state.clientLogoData[i].logoName} name="update_logo_name"
                      id="updateLogoName" class="form-control" />
                  </div>
                </div>
                <br />
                <div className="form-group" style={{ display: "-webkit-inline-box" }}>
                  <div class="custom-control">
                    <label class="font-weight-normal" for="OtherImpInfo"> Logo Image </label>
                  </div>
                  <div className="col-md-12">
                    <input type="file" name="update_logo_image" accept="image/*"
                      id="updateLogoImage" className="filetype" onChange={this.onFileChange} />
                    <img src={this.state.clientLogoData[i].logoImage} style={{ height: 100, width: 100 }} />
                  </div>
                </div>
                <br />
                <button type="submit" class="btn btn-primary" onClick={(e) => this.updateClientLogo(e, i)} > Update </button>
              </div>
            )
          }) : (
            <div>
              <div className="form-group" style={{ display: "-webkit-inline-box" }}>
                <div class="custom-control">
                  <label class="font-weight-normal" for="OtherImpInfo">
                    Logo Name</label>
                </div>
                <div className="col-md-12">
                  <input type="text" name="logoName"
                    id="logoName" class="form-control" onChange={this.handleChange} />
                </div>
              </div>
              <br />
              <div className="form-group" style={{ display: "-webkit-inline-box" }}>
                <div class="custom-control">
                  <label class="font-weight-normal" for="OtherImpInfo">
                    Logo Image </label>
                </div>
                <div className="col-md-12">
                  <input type="file" name="logoImage" accept="image/*"
                    id="logoImage" className="filetype" onChange={this.onFileChange} />
                </div>
              </div>
              <br />
              <button type="submit" class="btn btn-primary" onClick={(evt) => this.createClientLogo()} > save </button>
            </div>

          )}



        </section>
        <CommonFooter />
      </React.Fragment>
    );
  }
}