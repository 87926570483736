/*
 * TaskPage
 *
 *
 */

import React from 'react';
import { MDBDataTable } from 'mdbreact';

import CommonHeader from '../../components/CommonHeader';
import CommonSidebar from '../../components/CommonSidebar';
import CommonFooter from '../../components/CommonFooter';

import axios from 'axios';

import IconWithTooltip from 'icon-with-tooltip';
import PlusIcon from '@material-ui/icons/Add';

import { Modal, Button} from 'react-bootstrap';

import $ from 'jquery';
import { Redirect, Link } from 'react-router-dom';

import { ToastContainer, toast } from 'react-toastify';
import {
  UPLOAD_INTERNAL_ERROR, TOKEN_EXPIRED, INTERNAL_FAILURE
} from './constants';

import {
  API_URL,
} from '../../config';

import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'mdbreact/dist/css/mdb.css';

import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';

import '../../css/style_semantic.css';
import './style.css';

import DisplayModal from './DisplayModal';

export default class OutComesReport extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
      columns: [
        {
          label: 'Sno',
          field: 'Sno',
          sort: 'asc',
          width: 270,
        },
        {
          label: 'UserName',
          field: 'username',
          sort: 'asc',
          width: 270,
        },
        {
          label: 'Name',
          field: 'name',
          sort: 'asc',
          width: 200,
        },
        {
          label: 'Email',
          field: 'email',
          sort: 'asc',
          width: 100,
        },
        {
          label: 'Active',
          field: 'is_active',
          sort: 'asc',
          width: 100,
        },
        {
          label: 'Action',
          field: 'Action',
          sort: 'asc',
          width: 100,
        },
      ],
      rowData: [],
      action: 'None',
      curModalTitle: 'Modal',
      show: false,
      activationUsername: '',
    };

    this.handleChange = this.handleChange.bind(this);
  }

  token = localStorage.getItem('token');
  config = {
    headers: { Authorization: `Bearer ${this.token}` },
  };

  handleChange(event){
    this.setState({[event.target.name]: event.target.value})
  }

  getAllUsers = () => {
    axios
      .get(`${API_URL}/api/all_users/`, this.config)
      .then(response => { 
        if (response.status == 200) {
          if(response.data.status == 200 && response.data.data != null) {
            let responseData = response.data.data.map((data, index)=> ({
              Sno: index + 1,
              username: data.username,
              name: data.first_name + '  ' + data.last_name,
              email: data.email,
              is_active: data.is_active == true ? 'Active':'InActive',
              Action: <><Button className="f-12 nowrap" onClick={() =>{this.handleActiveInactive(data.username, data.is_active)}}> <i className="fa fa-user" aria-hidden="true"></i></Button> 
                        &nbsp;&nbsp;<Button className="f-12 nowrap" onClick={() =>{this.handlePasswordReset(data.email)}}><i className="fa fa-key" aria-hidden="true"></i></Button></>,
            }));
            this.setState({ rowData: responseData });
          } else if(response.data.status_code == 406) {
            localStorage.removeItem('token');
            localStorage.removeItem('user_name');

            localStorage.removeItem('adminCheck');
            localStorage.setItem('tokenExpired', true);

            const tokenStatus = localStorage.getItem('tokenExpired');
            if(tokenStatus) {
              localStorage.removeItem('tokenExpired');
              toast.error(TOKEN_EXPIRED, {
                position: 'top-right',
                autoClose: 2500,
              });
            }

            const isLogged = !!localStorage.getItem('token');
            if (!isLogged) {
             setTimeout(() => { 
              this.props.history.push("/login");
              }, 2500);
            }
          }
        }
      })
      .catch(error => {
        console.log(error);
      });
  }

  componentDidMount() {
    this.getAllUsers();
  }

  Logout = () => {
    localStorage.removeItem('token');
  };

  changeActionToEmail = event =>{
    this.setState({action: 'EmailPopUp', curModalTitle: 'Successful User Creation', activationUsername: ''})
  }

  handleActiveInactive = (username, active) => {
    this.setState({activationUsername: username, show: true,})
    if (active===true){
      this.setState({curModalTitle: "Deactivation Warning", action: "Deactivate"})
    }
    else{
      this.setState({curModalTitle: "Activation Warning", action: "Activate"})
    }
  }

  handlePasswordReset = (email)=>{
    const data = {
      'user_email': email,
    };
    axios.post(`${API_URL}/dashboard/password_reset_init/`, data)
    .then((response)=> {
      if (response.status === 200) {
        if (response.data.status === 200) {
          toast.success(response.data.message,{
            position:'top-right',
            autoClose:3000,
          });
        }
        else if (response.data.status === 500){
          toast.error(INTERNAL_FAILURE, {
            position: 'top-right',
            autoClose: 3500,
          });
          return false;
        }
        else if (response.data.status !== 200){
          toast.error(response.data.message, {
            position: 'top-right',
            autoClose: 3500,
          });
          return false;
        }
      } 
    })
    .catch(error => {
      console.log(error);
    });
  }

  closeModal = event =>{
    this.setState({
      show: false,
      username: '',
      first_name: '',
      last_name: '',
      email: '',
      mobile: '',
      role: '',
    });
    window.setTimeout(function () {
      window.location.reload();
    }, 0);
  }

  handleCreateUser = (e) => {
    this.setState({show: true, action: "NewUser", curModalTitle: "User Creation Form", })
  }

  render() {
    const isLogged = !!localStorage.getItem('token');
    const isadmin = localStorage.getItem('adminCheck');

    if (!isLogged) {
      return <Redirect to="/login" />;
    }
    if (isadmin === 'false') {
      return <Redirect to="/tasks" />;
    }

    let mbdDatas = {
      columns: this.state.columns,
      rows: this.state.rowData
    }

    return (
      <React.Fragment>
        <ToastContainer />
        <CommonHeader />
        <section id="wrapper">
          <CommonSidebar /> 
          <div className="headingmaintop">
            <h2>
              Manage Users
            </h2>
          </div>
          <div className="ui grid">
            <div className="column">
              <div className="create_button">
                <button type="button" onClick ={this.handleCreateUser} className="btn btn-success mt-1 btnControl btnsv">
                  <IconWithTooltip Icon={PlusIcon} text="Create User" />
                </button>
              </div>
              <div className="tabs-outer tabsouterCustom mb-5">
                <div className="ui tab active" data-tab="edit">
                  <div className="ui form customSelectIcon">
                    <div className="table-responsive">
                      <MDBDataTable
                        striped
                        bordered
                        small
                        hover
                        searching={true}
                        data={mbdDatas}
                        disableRetreatAfterSorting={true}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Modal show={this.state.show} onHide={this.closeModal} backdrop="static">
          <Modal.Header closeButton>
              <Modal.Title>{this.state.curModalTitle}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <DisplayModal API_URL={API_URL} action={this.state.action} 
              closeModal={this.closeModal}
              config={this.config} changeAction={this.changeActionToEmail} 
              token={this.token} activationUsername={this.state.activationUsername}
              />
            </Modal.Body>
          </Modal>
      </section>
       <CommonFooter />
       </React.Fragment> 
    );    
  } 
}