/*
 * HomeConstants
 * Each action has a corresponding type, which the reducer knows and picks up on.
 * To avoid weird typos between the reducer and the actions, we save them as
 * constants here. We prefix them with 'yourproject/YourComponent' so we avoid
 * reducers accidentally picking up actions they shouldn't.
 *
 * Follow this format:
 * export const YOUR_ACTION_CONSTANT = 'yourproject/YourContainer/YOUR_ACTION_CONSTANT';
 */

export const GET_TASK_LIST = 'GET_TASK_LIST';
export const INTERNAL_SERVER_ERROR = 'Something went wrong with educational details.';

export const VALID_COMM_STATUS = 'Please enter communcation status for this.';
export const VALID_COMM_METHOD = 'Please enter communcation method for this.';
export const VALID_COMM_DATE = 'Please enter communcation date For Current Row.';

export const VALID_DEL_STREET_ADD = 'Please enter delivery street address for this.';
export const VALID_DEL_CITY = 'Please enter delivery city for this.';
export const VALID_DEL_STATE = 'Please enter delivery state for this.';
export const VALID_DEL_ZIPCODE = 'Please enter delivery zip code for this.';

export const VALID_ALL_FILLS_HISTORY_RX = 'Please enter RX # for this.';
export const VALID_ALL_FILLS_HISTORY_DESC = 'Please enter description for this.';
export const VALID_ALL_FILLS_HISTORY_QTY = 'Please enter quantity for this.';

export const VALID_ALL_FILLS_HISTORY_DAYS = 'Please enter days for this.';
export const VALID_ALL_FILLS_HISTORY_REFILL = 'Please enter refill for this.';
export const VALID_ALL_FILLS_HISTORY_START_DATE = 'Please enter start date for this.';

export const VALID_ARCHIVE_ASSESSMENT_ID = 'Please enter assessment id for this.';
export const VALID_ARCHIVE_DATE = 'Please enter archive date for this.';
export const VALID_ARCHIVE_DESC = 'Please enter archive description for this.';

export const VALID_THERAPY_START_DATE = 'Please enter therapy start date for this.';
export const VALID_THERAPY_QTY = 'Please enter therapy quantity for this.';

export const VALID_THERAPY_DAYS = 'Please enter therapy days for this.';
export const VALID_EDUCATION_DATA = 'Something went wrong !.';

export const SUCCESS_EDUCATION_DATA = 'Education data inserted successfully.';
export const UPDATE_EDUCATION_DATA = 'Education data updated successfully.';

export const UPDATE_THERAPY_DATA = 'Therapy updated successfully.';
export const UPDATE_REFILL_REMINDER_DATA = 'Refill Reminders data updated successfully.';

export const VALIDATE_EDU_FORM = 'Please fill the forms properly. Some of the fields are missing.';
export const API_URL = 'http://pioneer.d2api.local:8001';
export const SERVER_INTERNAL_ERROR = 'Something went wrong while saving !.';
export const DATA_UPDATED = 'Successfully Updated.';
export const ASSESSMENT_ACTIVE = 'Please check the assessment active.';

export const UPDATE_PATIENT_DATA = 'Patient updated successfully.';
export const SAVE_ADDRESS_DATA = 'Patient Delivery Address saved successfully.';

export const UPDATE_ADDRESS_DATA = 'Patient Delivery Address updated successfully.';
export const DELETE_ADDRESS_DATA = 'Patient Delivery Address deleted successfully.';

export const STREET_ADDRESS_DATA = 'Street of delivery address is mandatory.';
export const LOCATION_ADDRESS_DATA = 'Location of delivery address is mandatory.';

export const CITY_ADDRESS_DATA = 'City of delivery address is mandatory.';
export const STATE_ADDRESS_DATA = 'State of delivery address is mandatory.';

export const ZIPCODE_ADDRESS_DATA = 'Zipcode of delivery address is mandatory.';
export const TOKEN_EXPIRED = 'Please login again. Your token has expired.';
export const ALL_FIELDS_REQUIRED = 'All required fields must be completed before marking an assessment Completed.';
export const MEDICATION_MISSING = 'Minimum 1 medication required';