/**
 * NotFoundPage
 *
 * This is the page we show when the user visits a url that doesn't have a route
 */

import React from 'react';
import { Link } from 'react-router-dom';

import './style.css';

export default function NotFound() {
  return (
    <div id="notfound">
		<div className="notfound">
			<div className="notfound-404">
			   <h1>Oops!</h1>
			</div>
			<h2>404 - Page not found</h2>
			<p>The page you are looking for might have been removed had its name changed or is temporarily unavailable.</p>
			<Link to="/" className="forgot-link">
			  Go To Homepage
			</Link>
		</div>
	</div>
  );
}
