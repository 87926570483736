import React from 'react';
import { Link, Redirect } from 'react-router-dom';

import '../../containers/css/style_semantic.css';
import './style.css';
import axios from 'axios';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import logo from '../../images/logo.png';
import loginbg from '../../images/loginbg.jpg';

import loginbgTwo from '../../images/loginbg2.jpg';
import loginbgThree from '../../images/loginbg3.jpg';

import {
  API_URL,
} from '../../config';
import { VALID_PASSWORD, VALID_CONFIRM_PASSWORD, INTERNAL_FAILURE, PASSWORD_VALIDATION,TOKEN_EXPIRED_MSG_DB,TOKEN_EXPIRED } from './constants';
import { Alert } from 'react-bootstrap';

export default class ResetPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      password: '',
      confirmPassword: '',
      validationMsg: '',
      token: '',
      activateUser: 'false',
      tokenExpired: false,
      isResetPasswordSucessfully : false
    };
  }

  componentDidMount() {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let activateUser = params.get('activate');
    let token = params.get('q');
    // let token = localStorage.getItem('token1');
    //console.log('Quote Params', token);
    if(activateUser==='true'){
      this.setState({activateUser:activateUser})
    }
    this.setState({
      token: token,
    });
  
    if(token !== null || token !== undefined)
     {
      this.isTokenExpired(token);
     }
  }
  
  config = {
    headers: { Authorization: `Bearer ${this.token}` },
};
  isTokenExpired = (token) => {
    axios
    .get(`${API_URL}/dashboard/password_reset/?token=`+token, this.config)
    .then(response => {
      if (response.data.status === 200 && response.status === 200) 
      {
       if(response.data.data != null)
       {
           this.setState({
             tokenExpired : response.data.data,
           });
        };
        //console.log(edumedication);
       }
       if(response.data.status == 500 && response.data.message==TOKEN_EXPIRED_MSG_DB)
        {
            this.setState({
              tokenExpired : true
            });
       }
      })
     
    .catch(error => {
      console.log(error);
     });
   }
  handleChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  };

  passwordValidation = () => {
    const { password, confirmPassword } = this.state;
    if (password == null || password.length === 0) {
      toast.error(VALID_PASSWORD, {
        position: 'top-right',
        autoClose: 2000,
      });
    } else if (confirmPassword == null || confirmPassword.length === 0) {
      toast.error(VALID_CONFIRM_PASSWORD, {
        position: 'top-right',
        autoClose: 2000,
      });
    }
    else if (password === confirmPassword) {
      return true;
    } else if (password !== confirmPassword) {
      toast.error(PASSWORD_VALIDATION, {
        position: 'top-right',
        autoClose: 2000,
      });
    }
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const { resetPassDetails } = this.props;

    if (this.passwordValidation()) {
      const { resetApi } = this.props;
      const { password, confirmPassword, token, activateUser } = this.state;
      const resetToken = {
        password,
        confirmPassword,
        token,
        activateUser,
      };

      return axios
        .put(`${API_URL}/dashboard/password_reset/`, resetToken, this.config)
        .then(response => {
          //console.log('Response', response.status);
          if (response.data.status === 200) {
            toast.success(response.data.message, {
              position: 'top-right',
              autoClose: 2000,
            });

            this.setState({
              password: '',
              confirmPassword: '',
              validationMsg: '',
              isResetPasswordSucessfully:true
            });
            return response;
          }
          else if (response.data.status === 500){
            toast.error(INTERNAL_FAILURE, {
              position: 'top-right',
              autoClose: 3500,
            });
            return false;
          }
          else if (response.data.status !== 200){
            toast.error(response.data.message, {
              position: 'top-right',
              autoClose: 3500,
            });
            return false;
          }
        });
    } else {
      this.setState({
        validationMsg: PASSWORD_VALIDATION,
      });
    }
  };

  render() {
    const isLoggedIn =
      localStorage.getItem('token') &&
        localStorage.getItem('token') !== 'undefined'
        ? true
        : false;

    if (isLoggedIn) {
      return <Redirect to="/" />;
    }
     
    if(this.state.isResetPasswordSucessfully){
      return  <Redirect to="/login"></Redirect> 
     }
    const is_tokenExpired = this.state.tokenExpired;
    const { password, confirmPassword, validationMsg, token } = this.state;
    const { resetPasswordMsg } = this.props;

    const messageStyle = { color: '#144E6C' };
    const errorStyle = { color: 'red' };

    return (
      <React.Fragment>
        <ToastContainer />
        <div className="login-page">
          <div className="container">
            <div className="col-sm-5 forgot-center">
              <div className="login-box">
             { is_tokenExpired?(
                 <div className="logo-box">
                     <img src={logo} width="120px" alt="" style={{marginBottom: "10px"}} />
                    <p style={errorStyle}>{TOKEN_EXPIRED}</p>
                 </div>
             ):
                
                (<div className="logo-box">
                  <img src={logo} width="120px" alt="" />
                  <h3 style={messageStyle}>Reset Password</h3>
                  <p style={errorStyle}>{resetPasswordMsg}</p>
                  <form onSubmit={this.handleSubmit}>
                    <div className="input-field">
                      <i className="fa fa-password"> </i>
                      <input
                        id="password"
                        name="password"
                        type="password"
                        className="form-control"
                        placeholder="Password"
                        value={password}
                        onChange={this.handleChange}
                      />
                    </div>
                    <div className="input-field">
                      <i className="fa fa-password"> </i>
                      <input
                        id="confirmPassword"
                        name="confirmPassword"
                        type="password"
                        className="form-control"
                        placeholder="Confirm password"
                        value={confirmPassword}
                        onChange={this.handleChange}
                      />
                    </div>
                    <div className="input-field">
                      <i className="fa fa-token"> </i>
                      <input
                        id="token"
                        name="token"
                        type="hidden"
                        className="form-control"
                        placeholder="Token Value"
                        value={token}
                      />
                    </div>
                    <input
                      type="submit"
                      className="btn btn-block login-btn"
                      placeholder="Reset Password"
                    />
                  </form>
                  <br/>
                  <div className="text-bottom" >
                    <Link to="login" className=""><u style={{ cursor : "pointer", color: "black"}}> LOGIN </u>
                    </Link> 
                    {/* <div className="text-center">
                    <Link to="/forgot-password" className="forgot-link">
                      Forgot Password?
                    </Link> 
                  </div> */}
                  </div>
                </div>)}
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}