/*
 * HomeConstants
 * Each action has a corresponding type, which the reducer knows and picks up on.
 * To avoid weird typos between the reducer and the actions, we save them as
 * constants here. We prefix them with 'yourproject/YourComponent' so we avoid
 * reducers accidentally picking up actions they shouldn't.
 *
 * Follow this format:
 * export const YOUR_ACTION_CONSTANT = 'yourproject/YourContainer/YOUR_ACTION_CONSTANT';
 */

export const TOKEN_EXPIRED = 'Please login again. Your token has expired.';
export const SUCCESS_SCHDEULE_DELIVERY = 'Schedule Delivery Created Successfully.';

export const UPDATE_SCHDEULE_DELIVERY = 'Schedule Delivery Updated Successfully.';
export const ERROR_DATA_SAVE = 'Something went wrong while adding.';
export const INTERNAL_SERVER_ERROR = 'Something went wrong.';