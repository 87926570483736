export const VALID_ALL_FILLS_HISTORY_RX = 'Please enter RX # for this.';
export const VALID_ALL_FILLS_HISTORY_DESC = 'Please enter description for this.';
export const VALID_ALL_FILLS_HISTORY_QTY = 'Please enter quantity for this.';

export const VALID_ALL_FILLS_HISTORY_DAYS = 'Please enter days for this.';
export const VALID_ALL_FILLS_HISTORY_REFILL = 'Please enter refill for this.';
export const VALID_ALL_FILLS_HISTORY_START_DATE = 'Please enter start date for this.';

export const VALID_THERAPY_DAYS = 'Please enter therapy days for this.';
export const VALID_EDUCATION_DATA = 'Something went wrong !.';
export const TOKEN_EXPIRED = 'Please login again. Your token has expired.';
