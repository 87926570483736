/*
 * TaskPage
 *
 *
 */

import React from 'react';
import { MDBDataTable } from 'mdbreact';

import CommonHeader from '../../components/CommonHeader';
import CommonSidebar from '../../components/CommonSidebar';
import CommonFooter from '../../components/CommonFooter';

import axios from 'axios';
import Button from 'react-bootstrap/Button';

import $ from 'jquery';
import { Redirect, Link } from 'react-router-dom';

import { ToastContainer, toast } from 'react-toastify';
import {
  UPLOAD_TASK_LIST,
  UPLOAD_ERROR_TASK_LIST,
  VALIDATE_UPLOAD_TASK_LIST,
  VALIDATE_UPLOAD_FILE,
  UPLOAD_INTERNAL_ERROR,
  ASSESSMENT_COUNT_ERROR,
  TOKEN_EXPIRED,
} from './constants';

import {
  API_URL,
} from '../../config';

import { PDFExport } from '@progress/kendo-react-pdf';
import Moment from 'moment';

import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'mdbreact/dist/css/mdb.css';

import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';

import '../../css/style_semantic.css';
import './style.css';

export default class TasksPage extends React.Component {
  pdfExportComponent;
  constructor(props) {
    super(props);
    this.forgotStyle = { centered: true };

    this.state = {
      noEducationOnFile: 0,
      incompAssessment: 0,
      logIntervention: 0,
      assessmentComm: 0,
      reassessmentCommCount: 0,
      followup_count: 0,
      imageInputRef: '',
      selectedFile: null,
      columns: [
        {
          label: 'Sr No.',
          field: 'Sno',
          sort: 'asc',
          width: 150,
        },
        {
          label: 'Date of Services',
          field: 'DateOfService',
          sort: 'asc',
          width: 270,
        },
        {
          label: 'MRN',
          field: 'MRN',
          sort: 'asc',
          width: 200,
        },
        {
          label: 'Patient Name',
          field: 'PatientName',
          sort: 'asc',
          width: 100,
        },
        {
          label: 'Medication',
          field: 'Medication',
          sort: 'asc',
          width: 150,
        },
        {
          label: 'Drug Type',
          field: 'DrugType',
          sort: 'asc',
          width: 150,
        },
        {
          label: 'Type',
          field: 'Type',
          sort: 'asc',
          width: 100,
        },
        {
          label: 'Action',
          field: 'Action',
          sort: 'asc',
          width: 100,
        }
      ],
      column: [
        {
          label: 'Sr No.',
          field: 'Sno',
          sort: 'asc',
          width: 150,
        },
        {
          label: 'Date of Services',
          field: 'DateOfService',
          sort: 'asc',
          width: 270,
        },
        {
          label: 'MRN',
          field: 'MRN',
          sort: 'asc',
          width: 200,
        },
        {
          label: 'Patient Name',
          field: 'PatientName',
          sort: 'asc',
          width: 100,
        },
        {
          label: 'Medication',
          field: 'Medication',
          sort: 'asc',
          width: 150,
        },
        {
          label: 'Drug Type',
          field: 'DrugType',
          sort: 'asc',
          width: 150,
        },
        {
          label: 'Type',
          field: 'Type',
          sort: 'asc',
          width: 100,
        },
      ],
      rowData: [],
      rowPdfData: [],
    };
  }

  onFileChange = event => {
    this.setState({ selectedFile: event.target.files[0] });
    //console.log('File Uploads', this.state.selectedFile);
    //console.log('Event File Upload', event.target.files[0].type);
  };

  onFileUpload = event => {
    if (this.state.selectedFile === null) {
      toast.error(VALIDATE_UPLOAD_TASK_LIST, {
        position: 'top-right',
        autoClose: 5000,
      });
      return false;
    }

    if (this.state.selectedFile.type != 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
      toast.error(VALIDATE_UPLOAD_FILE, {
        position: 'top-right',
        autoClose: 10000,
      });
      return false;
    }

    const formData = new FormData();
    formData.append(
      'dispensing_log',
      this.state.selectedFile,
      this.state.selectedFile.name,
    );

    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    return axios
      .post(`${API_URL}/api/import_excel_dispensing_log/`, formData, config)
      .then(response => {
        //console.log('Response', response.data);
        //console.log('Response', response);
        if (response.status == 200) {
          if (response.data.response.status == 200) {
            toast.success(UPLOAD_TASK_LIST, {
              position: 'top-right',
              autoClose: 5000,
            });

            this.state.imageInputRef.value = '';
            this.state.selectedFile = null;
          } else if (response.data.status_code == 406) {
            localStorage.removeItem('token');
            localStorage.removeItem('user_name');

            localStorage.removeItem('adminCheck');
            localStorage.setItem('tokenExpired', true);

            const tokenStatus = localStorage.getItem('tokenExpired');
            if (tokenStatus) {
              localStorage.removeItem('tokenExpired');
              toast.error(TOKEN_EXPIRED, {
                position: 'top-right',
                autoClose: 2500,
              });
            }

            const isLogged = !!localStorage.getItem('token');
            if (!isLogged) {
              setTimeout(() => {
                this.props.history.push("/login");
              }, 2500);
            }
          }
        } else if (response.status != 200) {
          toast.error(UPLOAD_INTERNAL_ERROR, {
            position: 'top-right',
            autoClose: 5000,
          });
        } else {
          toast.error(UPLOAD_ERROR_TASK_LIST, {
            position: 'top-right',
            autoClose: 5000,
          });
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  handleOnChange = event => {
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    axios
      .get(`${API_URL}/api/patients-assessment/?type=${event.target.value}`, config)
      .then(response => {
        if (response.status === 200) {
          if (response.data.status == 200) {
            let responseData = response.data.data != null ? response.data.data.map((data, index) => ({
              Sno: index + 1,
              DateOfService: Moment(data.dos).format('MM/DD/YYYY'),
              MRN: data.mrn,
              PatientName: <Link to={{
                'pathname': '/patientcliprofile', 'state': {
                  'transaction_id': data.transaction_id,
                  'patient_id': data.patient_id, 'patient_name': data.patient_name, 
                  'data_added' : Moment(data.data_added).format('MM/DD/YYYY') 
                }
              }} searchvalue={data.patient_name}> {data.patient_name} </Link>,
              Medication: data.medication_name.charAt(0).toUpperCase() + data.medication_name.slice(1), 
              DrugType: data.medication_class,
              Type: data.type,
              Action: <Link to={{
                'pathname': '/patientcliprofile', 'state': {
                  'transaction_id': data.transaction_id,
                  'patient_id': data.patient_id, 'patient_name': data.patient_name,
                  'data_added' : Moment(data.data_added).format('MM/DD/YYYY') 
                }
              }} className="btn btn-primary">
                <i className="fa fa-user" aria-hidden="true"></i>
              </Link>,
            })) : []
            this.setState({ rowData: responseData });
          } else if (response.data.status_code == 406) {
            localStorage.removeItem('token');
            localStorage.removeItem('user_name');

            localStorage.removeItem('adminCheck');
            localStorage.setItem('tokenExpired', true);

            const tokenStatus = localStorage.getItem('tokenExpired');
            if (tokenStatus) {
              localStorage.removeItem('tokenExpired');
              toast.error(TOKEN_EXPIRED, {
                position: 'top-right',
                autoClose: 2500,
              });
            }

            const isLogged = !!localStorage.getItem('token');
            if (!isLogged) {
              setTimeout(() => {
                this.props.history.push("/login");
              }, 2500);
            }
          }
        } else {
          toast.success(UPLOAD_INTERNAL_ERROR, {
            position: 'top-right',
            autoClose: 3000,
          });
          return false;
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  componentDidMount() {
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const req_all_transaction = axios.get(`${API_URL}/api/patients-assessment/?type=all`, config)
    const req_assessment_count = axios.get(`${API_URL}/api/assessment_count/`, config)

    axios.all([req_all_transaction, req_assessment_count]).then(axios.spread((...responses) => {
      const res_all_transaction = responses[0];
      const res_assessment_count = responses[1];

      // assessment_rec_count
      if (res_assessment_count.status === 200) {
        if (res_assessment_count.data.status == 200) {
          this.setState({
            noEducationOnFile: res_assessment_count.data.data.no_assessment_rec_count,
            incompAssessment: res_assessment_count.data.data.incomplete_assessment_rec_count,
            logIntervention: res_assessment_count.data.data.intervention_count,
            assessmentComm: res_assessment_count.data.data.communication_count,
            reassessmentCommCount: res_assessment_count.data.data.reassessment_records_count,
            followup_count: res_assessment_count.data.data.sevenday
          });
        } else if (res_assessment_count.data.status_code == 406) {
          localStorage.removeItem('token');
          localStorage.removeItem('user_name');

          localStorage.removeItem('adminCheck');
          localStorage.setItem('tokenExpired', true);

          const tokenStatus = localStorage.getItem('tokenExpired');
          if (tokenStatus) {
            localStorage.removeItem('tokenExpired');
            toast.error(TOKEN_EXPIRED, {
              position: 'top-right',
              autoClose: 2500,
            });
          }

          const isLogged = !!localStorage.getItem('token');
          if (!isLogged) {
            setTimeout(() => {
              this.props.history.push("/login");
            }, 2500);
          }
        }
      } else {
        toast.error(ASSESSMENT_COUNT_ERROR, {
          position: 'top-right',
          autoClose: 1500,
        });
      }

      // all_transaction record
      if (res_all_transaction.status == 200) {
        let responseData = res_all_transaction.data.data.map((data, index) => ({
          Sno: index + 1,
          DateOfService: Moment(data.dos).format('MM/DD/YYYY'),
          MRN: data.mrn,
          PatientName: <Link to={{
            'pathname': '/patientcliprofile', 'state': {
              'transaction_id': data.transaction_id,
              'patient_id': data.patient_id, 'patient_name': data.patient_name,
              'data_added' : Moment(data.data_added).format('MM/DD/YYYY')
            }
          }} searchvalue={data.patient_name}> {data.patient_name} </Link>,
          Medication: data.medication_name.charAt(0).toUpperCase() + data.medication_name.slice(1),
          DrugType: data.medication_class,
          Type: data.type,
          Action: <Link to={{
            'pathname': '/patientcliprofile', 'state': {
              'transaction_id': data.transaction_id,
              'patient_id': data.patient_id, 'patient_name': data.patient_name,
              'data_added' : Moment(data.data_added).format('MM/DD/YYYY')
            }
          }}>
            <Button className="f-12 nowrap"> <i className="fa fa-user" aria-hidden="true"></i></Button>
          </Link>,
        }));
        this.setState({ rowData: responseData });
      } else {
        toast.success(UPLOAD_INTERNAL_ERROR, {
          position: 'top-right',
          autoClose: 3000,
        });
        return false;
      }
    })).catch(error => {
      console.log(error);
    })
  }

  Logout = () => {
    localStorage.removeItem('token');
  };

  tasksGeneratePdf = type => {
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    axios
      .get(`${API_URL}/api/patients-assessment/?type=${type}`, config)
      .then(response => {
        if (response.status === 200) {
          if (response.data.status == 200) {
            let responseData = response.data.data != null ? response.data.data.map((data, index) => ({
              Sno: index + 1,
              DateOfService: Moment(data.dos).format('MM/DD/YYYY'),
              MRN: data.mrn,
              PatientName: data.patient_name,
              Medication: data.medication_name.charAt(0).toUpperCase() + data.medication_name.slice(1),
              DrugType: data.medication_class,
              Type: data.type,
            })) : []
            this.setState({ rowPdfData: responseData });
              setTimeout(() => {
                $("#printPDf").toggle();
                this.pdfExportComponent.save();
                $("#printPDf").toggle();
              }, 2000);
              
          } else if (response.data.status_code == 406) {
            localStorage.removeItem('token');
            localStorage.removeItem('user_name');

            localStorage.removeItem('adminCheck');
            localStorage.setItem('tokenExpired', true);

            const tokenStatus = localStorage.getItem('tokenExpired');
            if (tokenStatus) {
              localStorage.removeItem('tokenExpired');
              toast.error(TOKEN_EXPIRED, {
                position: 'top-right',
                autoClose: 2500,
              });
            }

            const isLogged = !!localStorage.getItem('token');
            if (!isLogged) {
              setTimeout(() => {
                this.props.history.push("/login");
              }, 2500);
            }
          }
        } else {
          toast.success(UPLOAD_INTERNAL_ERROR, {
            position: 'top-right',
            autoClose: 3000,
          });
          return false;
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  render() {
    const isLogged = !!localStorage.getItem('token');
    if (!isLogged) {
      return <Redirect to="/login" />;
    }

    let mbdDatas = {
      columns: this.state.columns,
      rows: this.state.rowData
    }

    let mbdPdfDatas = {
      columns: this.state.column,
      rows: this.state.rowPdfData
    }

    return (
      <React.Fragment>
        <ToastContainer />
        <CommonHeader />
        <section id="wrapper">
          <CommonSidebar />
          <div className="queue-block">
            <h1>Daily work queue functions</h1>
            <div className="ui grid stackable justify-content-center">
              <div className="seven column row">
                <div className="column">
                  <div className="queue-inner ui form">
                    <div className="task-count">{this.state.noEducationOnFile}</div>
                    <a href="javascript:void(0)">
                      <h3 onClick={() => this.tasksGeneratePdf('no')}>
                        No Education on File
                      </h3>
                    </a>
                  </div>
                </div>
                <div className="column">
                  <div className="queue-inner ui form">
                    <div className="task-count">{this.state.incompAssessment}</div>
                    <a href="javascript:void(0)">
                      <h3 onClick={() => this.tasksGeneratePdf('incomplete')}>
                        Incomplete Education
                      </h3>
                    </a>
                  </div>
                </div>
              <div className="column">
                  <div className="queue-inner ui form">
                    <div className="task-count">{this.state.followup_count}</div>
                    <a href="javascript:void(0)">
                      <h3 onClick={() => this.tasksGeneratePdf('followup')}> 
                        Follow Up
                      </h3>
                    </a>
                  </div>
                </div> 
                <div className="column">
                  <div className="queue-inner ui form">
                    <div className="task-count">{this.state.logIntervention}</div>
                    <a href="javascript:void(0)">
                      <h3 onClick={() => this.tasksGeneratePdf('intervention')}>
                        Intervention
                      </h3>
                    </a>
                  </div>
                </div>
                <div className="column">
                  <div className="queue-inner ui form">
                    <div className="task-count">{this.state.assessmentComm}</div>
                    <a href="javascript:void(0)">
                      <h3 onClick={() => this.tasksGeneratePdf('communication')}>
                        Patient Communication
                      </h3>
                    </a>
                  </div>
                </div>
                <div className="column">
                  <div className="queue-inner ui form">
                    <div className="task-count">{this.state.reassessmentCommCount}</div>
                    <a href="javascript:void(0)">
                      <h3 onClick={() => this.tasksGeneratePdf('reassessment')}>
                        Annual Reassesssment
                      </h3>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="ui grid">
            <div className="column">
              <div className="tabs-outer tabsouterCustom mb-5">
                <div className="ui menu top stackable">
                  <div className="col-md-3">
                    <select
                      onChange={this.handleOnChange}
                      name="type"
                      id="type"
                    >
                      <option value="all">All</option>
                      <option value="no">No Education</option>
                      <option value="incomplete">Incomplete Education</option>
                      <option value="intervention">Intervention</option>
                      <option value="communication">Communication</option>
                      <option value="reassessment">Reassesssment</option>
                    </select>
                  </div>
                  <div className="col-md-9 text-right importInput">
                    <input
                      type="file"
                      name="uploadFile"
                      id="uploadFile"
                      ref={ref => this.state.imageInputRef = ref}
                      onChange={this.onFileChange}
                    />
                    <Button
                      className="customImport"
                      variant="primary"
                      onClick={this.onFileUpload}
                    >
                      Import
                    </Button>
                  </div>
                </div>
                <div className="ui tab active" data-tab="edit">
                  <div className="ui form customSelectIcon">
                    <div className="table-responsive">
                      <MDBDataTable
                        striped
                        bordered
                        small
                        hover
                        searching={true}
                        data={mbdDatas}
                        sortRows ={['PatientName']}
                        disableRetreatAfterSorting={true}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Commenting out from PDFExport - > forcePageBreak=".page-break" */}
          <PDFExport ref={(component) => this.pdfExportComponent = component}>
            <div id="printPDf" style={{ width: "1350px", height: "420px", display: "none", }}>
              <MDBDataTable
                striped
                bordered
                small
                hover
                searching={false}
                paging={false}
                data={mbdPdfDatas}
                disableRetreatAfterSorting={true}
              />
            </div>
          </PDFExport>
        </section>
        <CommonFooter />
      </React.Fragment>
    );
  }
}
