/*
 * HomeConstants
 * Each action has a corresponding type, which the reducer knows and picks up on.
 * To avoid weird typos between the reducer and the actions, we save them as
 * constants here. We prefix them with 'yourproject/YourComponent' so we avoid
 * reducers accidentally picking up actions they shouldn't.
 *
 * Follow this format:
 * export const YOUR_ACTION_CONSTANT = 'yourproject/YourContainer/YOUR_ACTION_CONSTANT';
 */



export const VALID_DEL_STREET_ADD = 'Please enter delivery street address for this.';
export const VALID_DEL_CITY = 'Please enter delivery city for this.';
export const VALID_DEL_STATE = 'Please enter delivery state for this.';
export const VALID_DEL_ZIPCODE = 'Please enter delivery zip code for this.';


export const API_URL = 'http://pioneer.d2api.local:8001';
export const SERVER_INTERNAL_ERROR = 'Something went wrong while saving !.';
export const DATA_UPDATED = 'Successfully Updated.';


export const UPDATE_PATIENT_DATA = 'Patient updated successfully.';
export const SAVE_ADDRESS_DATA = 'Patient Delivery Address saved successfully.';

export const UPDATE_ADDRESS_DATA = 'Patient Delivery Address updated successfully.';
export const DELETE_ADDRESS_DATA = 'Patient Delivery Address deleted successfully.';

export const STREET_ADDRESS_DATA = 'Street of delivery address is mandatory.';
export const LOCATION_ADDRESS_DATA = 'Location of delivery address is mandatory.';

export const CITY_ADDRESS_DATA = 'City of delivery address is mandatory.';
export const STATE_ADDRESS_DATA = 'State of delivery address is mandatory.';

export const ZIPCODE_ADDRESS_DATA = 'Zipcode of delivery address is mandatory.';
export const TOKEN_EXPIRED = 'Please login again. Your token has expired.';

export const INVALID_DATA = 'Something went wrong !.';