/*
 * HomePage
 *
 * This is the first thing users see of our App, at the '/' route
 */

import React from 'react';

import CommonSidebar from '../../components/CommonSidebar';
import CommonHeader from '../../components/CommonHeader';
import CommonFooter from '../../components/CommonFooter';

import { Redirect, Link } from 'react-router-dom';

import '../../css/style_semantic.css';
import './style.css';

import taskMemo from '../../images/memo.png';
import accreditation from '../../images/accreditation.png';
import healthCare from '../../images/health-care.png';
import settings from '../../images/settings.png';
import event from '../../images/event.png';
import statistics from '../../images/statistics.png';
import metrics from '../../images/metrics.png';
import upAndDownArrows from '../../images/up-and-down-arrows.png';


const HomePage = () => {
	
	const isLogged = !!localStorage.getItem('token');
    if (!isLogged) {
      return <Redirect to="/login" />;
    }
	
	return (
		<>
		  <CommonHeader />
		  <section id="wrapper">
			<CommonSidebar />
			<div className="ui grid row stackable ">
			  <div className="four wide column">
				<Link to="/tasks">
				  <div className="task-block">
					<img src={taskMemo} alt="" />
					<p>My Task</p>
				  </div>
				</Link>
			  </div>
			  <div className="four wide column">
				<div className="task-block">
				  <img src={healthCare} alt="" />
				  <p> Clinical</p>
				</div>
			  </div>
			  <div className="four wide column">
				<div className="task-block">
				  <img src={settings} alt="" />
				  <p> Technician</p>
				</div>
			  </div>
			  <div className="four wide column">
				<div className="task-block">
				  <img src={event} alt="" />
				  <p>Events</p>
				</div>
			  </div>

			  <div className="four wide column">
			  	<Link to="/reports">
				  <div className="task-block">
					<img src={statistics} alt="" />
					<p>Reports</p>
				  </div>
				</Link>
			  </div>

			  <div class="four wide column">
				<div class="task-block">
				  <img src={metrics} alt="" />
				  <p>Metrics</p>
				</div>
			  </div>

			  <div className="four wide column">
			  	<Link to="/outcomes">
					<div className="task-block">
					<img src={upAndDownArrows} alt="" />
					<p>Outcomes</p>
					</div>
				</Link>
			  </div>

			  <div className="four wide column">
				<div className="task-block">
				  <img src={accreditation} alt="" />
				  <p>Accreditation</p>
				</div>
			  </div>
			</div>
		  </section>
		  <CommonFooter />
		</>
	);
}

export default HomePage;