export const SENT_LINK = 'forgot/SENT_LINK';
export const SUCCESS_FORGOT_PASSWORD = 'forgot/SUCCESS_FORGOT_PASSWORD';
export const ERROR_FORGOT_PASSWORD = 'forgot/ERROR_FORGOT_PASSWORD';
export const SUCCESS_FORGOT_MESSAGE =
  'Check your email for a link to reset your password. If it doesn’t appear within a few minutes, check your spam folder.';
export const ERROR_FORGOT_MESSAGE = 'Something went wrong! Please try again.';

export const API_URL = 'http://172.16.9.117:8001';
export const EMAIL_VALID_ERROR = 'Email should not be empty!';
export const VALID_EMAIL_ERROR = 'Please enter valid email!';
export const RESET_LINK_SENT = 'Password reset link has been sent';
export const INTERNAL_FAILURE = "Internal Failure";