import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import IdleTimer from 'react-idle-timer';
import PropTypes from 'prop-types';
import 'bootstrap/dist/css/bootstrap.min.css';
import { IdleModal } from '../CommonFooter/IdleModal';
import {  Link } from 'react-router-dom';

class Layout extends React.Component {
    
    constructor(props){
        super(props)

        this.state = {
            timeout:1000 * 60 * 12,//12 mints
            showModal: false,
            userLoggedIn: false,
            isTimedOut: false,
            doLogOut : false,
            
           
        }
        this.idleTimer = null
        this.onAction = this._onAction.bind(this)
        this.onActive = this._onActive.bind(this)
        this.onIdle = this._onIdle.bind(this)
        this.handleClose = this.handleClose.bind(this)
        this.handleLogout = this.handleLogout.bind(this)
       

    }
    handleClose() {
      this.setState({showModal: false})
      this.state.showModal = true
      this.state.timeout = 1000 * 60 * 12
      this.idleTimer.reset();
      this.setState({isTimedOut: false})
      
      
    }
    handleLogout() {
      this.setState({showModal: false})
      localStorage.removeItem('token');
      localStorage.removeItem('user_name');
      const isLogged = !!localStorage.getItem('token');
       if (!isLogged) {
        this.state.doLogOut = true;
        this.state.isTimedOut =true;
      }
      
     }
    _onAction(e) {
      console.log('this._onAction')
      this.setState({isTimedOut: false})
      this.setState({timeout:1000 * 60 * 12})
      
    }
   
  
    _onActive(e) {
      console.log('this._onActive')
      this.setState({isTimedOut: false})
    }
    _onIdle(e) {
      
      if (this.state.isTimedOut) {
        
         console.log('logout');
          this.handleLogout();         
      } else {
        console.log(this.state.timeout,'4')
        this.setState({showModal: true})
        
        this.setState({timeout:1000 * 60 * 3})//3 min
        if(this.state.showModal == true){
          
          this.handleLogout();
        }
        this.setState({isTimedOut: false})

      }

    }
    
    render(){
      const { match } = this.props
      
      if(this.state.doLogOut){
            return  <Redirect to="/login"></Redirect> 
      }
      return(
        <>
          
            <IdleTimer
            ref={ref => { this.idleTimer = ref }}
            element={document}
            onActive={this.onActive}
            onIdle={this.onIdle}
            onAction={this.onAction}
            debounce={250}
            timeout={this.state.timeout} 
            />
            <div className="">
            <IdleModal
            showModal={this.state.showModal}
            handleClose={this.handleClose}
            handleLogout={this.handleLogout}
            />

            </div>
            <footer>
            <p>© {new Date().getFullYear()} D2 Pharma. All Rights Reserved</p>
            </footer>

          
        </>
        
      )
      
   }

 }

 Layout.propTypes = {
     match: PropTypes.any.isRequired,
 }

export default Layout