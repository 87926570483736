/*
 * TaskPage
 *
 *
 */

import React from 'react';

import CommonHeader from '../../components/CommonHeader';
import CommonSidebar from '../../components/CommonSidebar';
import CommonFooter from '../../components/CommonFooter';

import axios from 'axios';
import { Redirect, Link, Prompt, } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';

import {
  TOKEN_EXPIRED, UPDATE_REFILL_FORM_DATA, INTERNAL_SERVER_ERROR,
} from './constants';
import {
  API_URL,
} from '../../config';

import Select from 'react-select';
import Moment from 'moment';

import '../../containers/css/style_semantic.css';
import './style.css';
import dateFormat from 'dateformat';

export default class RefillRemiderCall extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      refillReminderData: '',
      refillData: {
        amountDue: "",
        amountDueComment: "",
        anyQuesPharmacist: "",
        anyQuesPharmacistComment: "",
        callStatus: "",
        callStatusComment: "",
        dosesMissed: null,
        dosesRemaining: null,
        dosesRemainingComment: "",
        followUpCall: "",
        followUpDate: "",
        is_completed: "",
        lastRefillDoses: "",
        lastRefillDosesComment: "",
        medicationIdsArr: [],
        medications: "",
        newMedications: "",
        newSideEffects: "",
        newSideEffectsComment: "",
        otcMedication: "",
        patientCareFacility: "",
        patientCareFacilityComment: "",
        patientResidencyStatus: "",
        paymentAmount: null,
        personSpokenTo: "",
        personSpokenToComment: "",
        refillCallNotes: "",
        relToPatient: "",
        relToPatientComment: "",
        sideEffects: "",
        sideEffectsComment: "",
        therapyScore: "",
      },
      trackRefillData: {},
      patient_name: this.props.location.state !== undefined ? this.props.location.state.patient_name : null,
      id: '',
      show: '',
      medications: '',
      callStatus: '',
      callStatusComment: '',
      personSpokenTo: '',
      personSpokenToComment: '',
      relToPatient: '',
      relToPatientComment: '',
      patientResidencyStatus: '',
      patientCareFacility: '',
      patientCareFacilityComment: '',
      dosesRemaining: null,
      dosesRemainingComment: '',
      lastRefillDoses: '',
      lastRefillDosesComment: '',
      dosesMissed: null,
      therapyScore: '',
      otcMedication: '',
      newMedications: '',
      newSideEffects: '',
      newSideEffectsComment: '',
      sideEffects: '',
      sideEffectsComment: '',
      anyQuesPharmacist: '',
      anyQuesPharmacistComment: '',
      followUpCall: '',
      amountDue: '',
      amountDueComment: '',
      paymentAmount: null,
      refillCallNotes: '',
      followUpDate: null,
      formErrors: {},
      updated_by_user: '',
      updated_on: '',
      is_completed: false,
      patient_id: this.props.location.state !== undefined ? this.props.location.state.patient_id : null,
      patient_name: this.props.location.state !== undefined ? this.props.location.state.patient_name : null,
      transaction_id: this.props.location.state !== undefined ? this.props.location.state.transaction_id : null,
      medicationData: [{
        medicName: '',
        medicId: '',
      }],
      data_added : this.props.location.state !== undefined ? this.props.location.state.data_added : null,
      plainArray: [{}],
      medicationIdsArr: [],
      selectedMedValue: [],
    }
  }

  // today date
  todayDate = new Date().toISOString().slice(0,10)
  

  token = localStorage.getItem('token');
  config = {
    headers: { Authorization: `Bearer ${this.token}` },
  };

  async onSelectedItemsChange(e) {
    this.setState({ selectedMedValue: e });

    const arr = [];
    for (var i = 0; i < e.length; i++) {
      arr.push(e[i].value);
    }

    await this.setState(() => ({ medicationIdsArr: arr }));
  };

  /* Get Therapy Listing Common Method*/
  getMedication = () => {
    axios
      .get(`${API_URL}/api/get_all_fills_history/?patient_id=${this.state.patient_id}` + '&all_fills_medication=all', this.config)
      .then(response => {
        if (response.status === 200) {
          const arr = [];
          for (var i = 0; i < response.data.data.length; i++) {
            arr.push({ label: response.data.data[i].name, value: response.data.data[i].trans_med_id });
          }
          this.setState({ plainArray: arr });
        }
      })
      .catch(err => console.log(err))
  }

  /* Default Method To Be Called After Rndering */
  componentDidMount() {
    this.getMedication();
    axios
      .get(`${API_URL}/api/refill_reminder_incomplete/?patient_id=${this.state.patient_id}`, this.config)
      .then(response => {
        if (response.status == 200) {
          if (response.data.data !== null) {
            localStorage.setItem('totalRefillReminder', 1);
          } else {
            localStorage.setItem('totalRefillReminder', 0);
          }

          if (response.data.status == 200) {
            this.setState({ callStatus: response.data.data.call_status });
            this.setState({ callStatusComment: response.data.data.call_status_comment });
            this.setState({ personSpokenTo: response.data.data.person_spoken_to });

            this.setState({ personSpokenToComment: response.data.data.person_spoken_comment });
            this.setState({ relToPatient: response.data.data.relationship_to_patient });
            this.setState({ relToPatientComment: response.data.data.relationship_to_patient_comment });

            this.setState({ patientResidencyStatus: response.data.data.patient_residency_status });
            this.setState({ patientCareFacility: response.data.data.patient_care_facility });
            this.setState({ patientCareFacilityComment: response.data.data.patient_care_facility_comment });

            this.setState({ dosesRemaining: response.data.data.doses_remaining });
            this.setState({ dosesRemainingComment: response.data.data.doses_remaining_comment });
            this.setState({ lastRefillDoses: response.data.data.last_refill_missed_doses });

            this.setState({ lastRefillDosesComment: response.data.data.last_refill_missed_doses_comment });
            this.setState({ dosesMissed: response.data.data.missed_doses });
            this.setState({ therapyScore: response.data.data.therapy_score });

            this.setState({ otcMedication: response.data.data.otc_medications });
            this.setState({ newMedications: response.data.data.new_medications });
            this.setState({ newSideEffects: response.data.data.describe_side_effects });

            this.setState({ newSideEffectsComment: response.data.data.describe_side_effects_comment });
            this.setState({ sideEffects: response.data.data.side_effects });
            this.setState({ sideEffectsComment: response.data.data.side_effects_comment });

            this.setState({ anyQuesPharmacist: response.data.data.pharmacist_question });
            this.setState({ anyQuesPharmacistComment: response.data.data.pharmacist_question_comment });
            this.setState({ followUpCall: response.data.data.follow_up_required });

            this.setState({ amountDue: response.data.data.amountDue });
            this.setState({ amountDueComment: response.data.data.amountDueComment });
            this.setState({ paymentAmount: response.data.data.paymentAmount });

            this.setState({ refillCallNotes: response.data.data.refillCallNotes });
            this.setState({ followUpDate: response.data.data.follow_up_date });
            this.setState({ is_completed: response.data.data.is_completed });

            const arr = [];
            const medIds = [];

            for (var i = 0; i < response.data.data.medication.length; i++) {
              arr.push({ label: response.data.data.medication[i].medication_name, value: response.data.data.medication[i].id });
              medIds.push(response.data.data.medication[i].id);
            }

            this.setState({ medicationIdsArr: medIds });
            this.setState({ selectedMedValue: arr });

            this.setState({ updated_by_user: response.data.data.updated_by_user });
            this.setState({ updated_on: response.data.data.modified });

            this.setState({ id: response.data.data.id });
            this.setState({ 'show': true });
            this.setState({ trackRefillData: response.data.data });
            //console.log('Track Refill Data', this.state.trackRefillData);
          } else if (response.data.status == 204) {
            //console.log('Updated On', this.state.updated_on);
            this.setState({ 'show': false });
          } else if (response.data.status_code == 406) {
            localStorage.removeItem('token');
            localStorage.removeItem('user_name');

            localStorage.removeItem('adminCheck');
            localStorage.setItem('tokenExpired', true);

            const tokenStatus = localStorage.getItem('tokenExpired');
            if (tokenStatus) {
              localStorage.removeItem('tokenExpired');
              toast.error(TOKEN_EXPIRED, {
                position: 'top-right',
                autoClose: 2500,
              });
            }

            const isLogged = !!localStorage.getItem('token');
            if (!isLogged) {
              setTimeout(() => {
                this.props.history.push("/login");
              }, 2500);
            }
          }
        }
      })
      .catch(err => console.log(err))
  }

  /* To Handle Refill Refimnder Form Data Changes */
  refillFormChangeHandle = (evt) => {
    if (evt.target.name == 'is_completed') {
      this.setState({ [evt.target.name]: !this.state.is_completed, });
    } else {
      this.setState({ [evt.target.name]: evt.target.value, });
    }
    
    let list = { ...this.state.refillData };
    list[evt.target.name] = evt.target.value;
    this.setState({ refillData: list, });
  
    if (localStorage.getItem('totalRefillReminder') == 0) {
      if (list.callStatus !== '' || list.personSpokenTo !== '' || list.personSpokenToComment !== '' || list.relToPatient !== ''
        || list.relToPatientComment !== '' || list.patientResidencyStatus !== '' || list.patientCareFacility !== '' || list.patientCareFacilityComment !== ''
        || list.patientCareFacility !== '' || list.patientCareFacilityComment !== '' || list.dosesRemaining !== '' || list.dosesRemainingComment !== ''
        || list.dosesMissed !== '' || list.otcMedication !== '' || list.newMedications !== '' || list.newSideEffects !== ''
        || list.newSideEffectsComment !== '' || list.anyQuesPharmacist !== '' || list.anyQuesPharmacistComment !== '' || list.followUpCall !== ''
        || list.amountDue !== '' || list.amountDueComment !== '' || list.followUpDate !== '') {
        this.setState({ 'refillReminderData': true });
      } else {
        this.setState({ 'refillReminderData': false });
      }
    } else {
      if (this.state.trackRefillData.call_status !== this.state.callStatus || this.state.trackRefillData.person_spoken_to !== this.state.personSpokenTo) {
        localStorage.setItem('refillReminderData', true);
      } else {
        localStorage.removeItem('refillReminderData');
      }
    }
        //console.log('Refill Form value 1 amountDue', list.amountDue);
  };

  // Form validation
  handleFormValidation() {
    const { medicationIdsArr, callStatus, personSpokenTo, relToPatient, patientResidencyStatus,
      dosesRemaining, lastRefillDoses, dosesMissed, therapyScore, otcMedication, newMedications, newSideEffects,
      sideEffects, anyQuesPharmacist, followUpCall, amountDue, paymentAmount,
    } = this.state;
    //console.log('Validation Is Completed', this.state.is_completed);
    let formErrors = {};
    let formIsValid = true;

    if (medicationIdsArr.length == 0) {
      formIsValid = false;
      formErrors["medicationsErr"] = "Please Select Medication.";
    }

    if (this.state.is_completed == true) {
      if (!personSpokenTo) {
        formIsValid = false;
        formErrors["personSpokenToErr"] = "This field is required.";
      }

      if (relToPatient === '') {
        formIsValid = false;
        formErrors["relToPatientErr"] = "Please Select Patient Relation.";
      }
      if (anyQuesPharmacist === '') {
        formIsValid = false;
        formErrors["anyQuesPharmacistErr"] = "Please Select Question Pharmacist.";
      }

      if (followUpCall === '') {
        formIsValid = false;
        formErrors["followUpCallErr"] = "This field is required.";
      }

      if (patientResidencyStatus === '') {
        formIsValid = false;
        formErrors["patientResidencyStatusErr"] = "Please Select Patient Residency Status.";
      }

      if (newSideEffects === '') {
        formIsValid = false;
        formErrors["newSideEffectsErr"] = "Please Select Side Effects.";
      }
      else{
           
        if (newSideEffects == 1) {
          if (sideEffects === '') {
            formIsValid = false;
            formErrors["sideEffectsErr"] = "This field is required.";
          }
        }
        else {
          formIsValid = true;
          formErrors["sideEffectsErr"] = ""
        }
      }


      if (amountDue === '') {
        formIsValid = false;
        formErrors["amountDueErr"] = "Please Select Amount Due.";

      }
      else{
        if (amountDue == 1) {
          if (paymentAmount === '') {
            formIsValid = false;
            formErrors["paymentAmountErr"] = "This field is required.";
          } else {
            var numPattern = /^0|[1-9]\d*$/;
            if (!numPattern.test(paymentAmount)) {
              formIsValid = false;
              formErrors["paymentAmountErr"] = "Please enter only number.";
            }
          }
        } else {
          formIsValid = true;
          formErrors["paymentAmountErr"] = ""
        }
  
      }     

      if (otcMedication === '') {
        formIsValid = false;
        formErrors["otcMedicationErr"] = "Please Select OTC Medication.";
      }
      else{
        if (otcMedication == 1) {
          if (newMedications === '') {
            formIsValid = false;
            formErrors["newMedicationsErr"] = "This field is required.";
          }
        }
        else {
          formIsValid = true;
          formErrors["newMedicationsErr"] = ""
        }
  
      }
      if (lastRefillDoses === '') {
        formIsValid = false;
        formErrors["lastRefillDosesErr"] = "Please Select Last refill.";
      }
      else{
        if (lastRefillDoses == 50) {
          if (!dosesMissed) {
            formIsValid = false;
            formErrors["dosesMissedErr"] = "This field is required.";
          } else {
            var numPattern = /^0|[1-9]\d*$/;
            if (!numPattern.test(dosesMissed)) {
              formIsValid = false;
              formErrors["dosesMissedErr"] = "Please enter only number.";
            }
          }
        }
  
        else if (lastRefillDoses == 100) {
          if (!therapyScore) {
            formIsValid = false;
            formErrors["therapyScoreErr"] = "This field is required.";
          } else {
            var numPattern = /^0|[1-9]\d*$/;
            if (!numPattern.test(therapyScore)) {
              formIsValid = false;
              formErrors["therapyScoreErr"] = "Please enter only number.";
            }
          }
        } else {
          formIsValid = true;
          formErrors["therapyScoreErr"] = ""
          formErrors["dosesMissedErr"] = ""
        }
      }
      if(dosesRemaining){
        var numPattern = /^0|[1-9]\d*$/;
          if (!numPattern.test(dosesRemaining)) {
            formIsValid = false;
            formErrors["dosesRemainingErr"] = "Please enter only number.";
          }
      }
      if (!dosesRemaining) {
        if (dosesRemaining === '' || dosesRemaining == null){
          formIsValid = false;
          formErrors["dosesRemainingErr"] = "This field is required.";
        }
      }
      if (callStatus === '') {
        formIsValid = false;
        formErrors["callStatusErr"] = "Please Select Call Status.";
      }
      // Final check to handle some error message cases that are setting formIsValidate to True
      for (const i in formErrors){
        if (formErrors[i] == ""){
          formIsValid = true
          continue
        }
        else{
          formIsValid = false
          break
        }
      }
    } else { }

    this.setState({ formErrors: formErrors });
    return formIsValid;
  }

  refillReminderForm = e => {
    const {
      medicationIdsArr,
      callStatus,
      callStatusComment,
      personSpokenTo,
      personSpokenToComment,
      relToPatient,
      relToPatientComment,
      patientResidencyStatus,
      patientCareFacility,
      patientCareFacilityComment,
      dosesRemaining,
      dosesRemainingComment,
      lastRefillDoses,
      lastRefillDosesComment,
      dosesMissed,
      therapyScore,
      otcMedication,
      newMedications,
      newSideEffects,
      newSideEffectsComment,
      sideEffects,
      sideEffectsComment,
      anyQuesPharmacist,
      anyQuesPharmacistComment,
      followUpCall,
      amountDue,
      amountDueComment,
      paymentAmount,
      refillCallNotes,
      followUpDate,
      patient_id,
      is_completed,
    } = this.state;

    let refillFormData = {
      medication: medicationIdsArr,
      patient: patient_id,
      relationship_to_patient: relToPatient,
      relationship_to_patient_comment: relToPatientComment,
      call_status: callStatus,
      call_status_comment: callStatusComment,
      follow_up_required: followUpCall,
      amountDue: amountDue,
      amountDueComment: amountDueComment,
      paymentAmount: paymentAmount,
      refillCallNotes: refillCallNotes,
      person_spoken_to: personSpokenTo,
      person_spoken_comment: personSpokenToComment,
      patient_residency_status: patientResidencyStatus,
      patient_care_facility: patientCareFacility,
      patient_care_facility_comment: patientCareFacilityComment,
      doses_remaining: dosesRemaining,
      doses_remaining_comment: dosesRemainingComment,
      missed_doses: dosesMissed,
      last_refill_missed_doses: lastRefillDoses,
      last_refill_missed_doses_comment: lastRefillDosesComment,
      therapy_score: therapyScore,
      otc_medications: otcMedication,
      new_medications: newMedications,
      side_effects: sideEffects,
      side_effects_comment: sideEffectsComment,
      describe_side_effects: newSideEffects,
      describe_side_effects_comment: newSideEffectsComment,
      follow_up_date: followUpDate,
      pharmacist_question: anyQuesPharmacist,
      pharmacist_question_comment: anyQuesPharmacistComment,
      is_completed : is_completed
    }

    if (this.handleFormValidation()) {
      axios
        .post(`${API_URL}/api/refill_reminder/`, refillFormData, this.config)
        .then(response => {
          if (response.data.status === 201 && response.status === 200) {
            toast.success('Successfully Created', {
              position: 'top-right',
              autoClose: 3500,
            });
            window.setTimeout(function () {
              window.location.reload();
            }, 3500);
          }
        })
        .catch(err => console.log(err))
    } else {
      //console.log(this.handleFormValidation())
    }
  }

  updRefillReminderForm = (e, id) => {
    const {
      medicationIdsArr,
      callStatus,
      callStatusComment,
      personSpokenTo,
      personSpokenToComment,
      relToPatient,
      relToPatientComment,
      patientResidencyStatus,
      patientCareFacility,
      patientCareFacilityComment,
      dosesRemaining,
      dosesRemainingComment,
      lastRefillDoses,
      lastRefillDosesComment,
      dosesMissed,
      therapyScore,
      otcMedication,
      newMedications,
      newSideEffects,
      newSideEffectsComment,
      sideEffects,
      sideEffectsComment,
      anyQuesPharmacist,
      anyQuesPharmacistComment,
      followUpCall,
      amountDue,
      amountDueComment,
      paymentAmount,
      refillCallNotes,
      followUpDate,
      patient_id,
      is_completed,
    } = this.state;

    let refillFormData = {
      medication: medicationIdsArr,
      patient: patient_id,
      relationship_to_patient: relToPatient,
      relationship_to_patient_comment: relToPatientComment,
      call_status: callStatus,
      call_status_comment: callStatusComment,
      follow_up_required: followUpCall,
      amountDue: amountDue,
      amountDueComment: amountDueComment,
      paymentAmount: paymentAmount,
      refillCallNotes: refillCallNotes,
      person_spoken_to: personSpokenTo,
      person_spoken_comment: personSpokenToComment,
      patient_residency_status: patientResidencyStatus,
      patient_care_facility: patientCareFacility,
      patient_care_facility_comment: patientCareFacilityComment,
      doses_remaining: dosesRemaining,
      doses_remaining_comment: dosesRemainingComment,
      missed_doses: dosesMissed,
      last_refill_missed_doses: lastRefillDoses,
      last_refill_missed_doses_comment: lastRefillDosesComment,
      therapy_score: therapyScore,
      otc_medications: otcMedication,
      new_medications: newMedications,
      side_effects: sideEffects,
      side_effects_comment: sideEffectsComment,
      describe_side_effects: newSideEffects,
      describe_side_effects_comment: newSideEffectsComment,
      follow_up_date: followUpDate,
      pharmacist_question: anyQuesPharmacist,
      pharmacist_question_comment: anyQuesPharmacistComment,
      is_completed: is_completed,
    }

    if (this.handleFormValidation()) {
      axios
        .put(`${API_URL}/api/refill_reminder/${id}`, refillFormData, this.config)
        .then(res => {
          if (res.status == 200) {
            if (res.data.status == 200) {
              toast.success(UPDATE_REFILL_FORM_DATA, {
                position: 'top-right',
                autoClose: 2500,
              });

              window.setTimeout(function () {
                window.location.reload();
              }, 3500);
            } else if (res.data.status_code == 406) {
              localStorage.removeItem('token');
              localStorage.removeItem('user_name');

              localStorage.removeItem('adminCheck');
              localStorage.setItem('tokenExpired', true);

              const tokenStatus = localStorage.getItem('tokenExpired');
              if (tokenStatus) {
                localStorage.removeItem('tokenExpired');
                toast.error(TOKEN_EXPIRED, {
                  position: 'top-right',
                  autoClose: 2500,
                });
              }

              const isLogged = !!localStorage.getItem('token');
              if (!isLogged) {
                setTimeout(() => {
                  this.props.history.push("/login");
                }, 2500);
              }
            }
          } else if (res.status !== 200) {
            toast.error(INTERNAL_SERVER_ERROR, {
              position: 'top-right',
              autoClose: 3000,
            });
            return false;
          }
        })
        .catch(err => console.log(err))
    } else {
      //console.log(this.handleFormValidation())
    }
  }

  render() {
    const {
      medications,
      callStatus,
      callStatusComment,
      personSpokenTo,
      personSpokenToComment,
      relToPatient,
      relToPatientComment,
      patientResidencyStatus,
      patientCareFacility,
      patientCareFacilityComment,
      dosesRemaining,
      dosesRemainingComment,
      lastRefillDoses,
      lastRefillDosesComment,
      dosesMissed,
      therapyScore,
      otcMedication,
      newMedications,
      newSideEffects,
      newSideEffectsComment,
      sideEffects,
      sideEffectsComment,
      anyQuesPharmacist,
      anyQuesPharmacistComment,
      followUpCall,
      amountDue,
      amountDueComment,
      paymentAmount,
      refillCallNotes,
      followUpDate,
      medicationData,
    } = this.state;

    const { medicationsErr,
      callStatusErr,
      personSpokenToErr,
      relToPatientErr,
      patientResidencyStatusErr,
      patientCareFacilityErr,
      dosesRemainingErr,
      lastRefillDosesErr,
      dosesMissedErr,
      therapyScoreErr,
      otcMedicationErr,
      newMedicationsErr,
      newSideEffectsErr,
      sideEffectsErr,
      anyQuesPharmacistErr,
      followUpCallErr,
      amountDueErr,
      paymentAmountErr,
      followUpDateErr,
    } = this.state.formErrors;

    const isLogged = localStorage.getItem('token');
    if (!isLogged) {
      return <Redirect to="/login" />;
    }

    return (
      <>
        <Prompt when={this.state.refillReminderData} message="You have unsaved changes! Save changes before leaving ?" />
        <ToastContainer />
        <CommonHeader />
        <section id="wrapper">
          <CommonSidebar />
          <div className="col-md-12 refillreminderblock">
            <div className="row">
              <div className="col-sm-12 mb-4">
                <h3 className="text-uppercase font-weight-bold">
                  Refill Reminder Communication
                </h3>
              </div>
              <div className="col-sm-12">
                <h3 className="text-blue mb-1">
                  <i className="fa fa-user fa-fw user-icon mr-2"></i>
                  <Link to={{
                    'pathname': '/patientcliprofile', 'state': {
                      'transaction_id': this.state.transaction_id,
                      'patient_id': this.state.patient_id, 'patient_name': this.state.patient_name,'data_added' : this.state.data_added
                    }
                  }}>
                    {this.state.patient_name}
                  </Link>
                </h3>
                <hr />
              </div>
              <div className="col-sm-12 text-right mb-4">
                {/* <Link to={{'pathname': '/patientcliprofile','state':{'patient_id':this.state.patient_id, 
                          'patient_name': this.state.patient_name}}}><button className="btn btn-primary"> Return to Patient </button></Link> */}
                <button className="btn btn-primary mr-2"> Export To Word </button>
                <button className="btn btn-primary mr-2"> Print Record </button>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 mb-4">
                <label htmlFor="Medications" className="text-left">Medications : <span className="text-danger">*</span></label>
                <div>
                  <Select
                    className="dropdown"
                    placeholder="Please Choose Medications"
                    options={this.state.plainArray} // set list of the data
                    onChange={this.onSelectedItemsChange.bind(this)} // assign onChange function
                    value={this.state.selectedMedValue}
                    isMulti
                  />
                </div>
                {medicationsErr &&
                  <div className="errorMsg">{medicationsErr}</div>
                }
              </div>

              <div className="col-md-6 mb-4">
                <label for="FollowUpDate">Call Status : <span className="text-danger">*</span></label>
                <select className="form-control text-left" name="callStatus"
                  onChange={e => this.refillFormChangeHandle(e)}
                  value={callStatus}>
                  <option value="">------- Please Select -------</option>
                  <option value="open">Open</option>
                  <option value="closed">Closed</option>
                </select>
                {callStatusErr &&
                  <div className="errorMsg">{callStatusErr}</div>
                }
              </div>
            </div>
            {/*********** Call Status ************ */}

            {/* <div className="col-sm-6 mb-4">
                  <div className="d-flex">
                     <textarea className="form-control flex-4" rows="1" name="callStatusComment"
                      onChange={e => this.refillFormChangeHandle(e)}
                      value={callStatusComment}
                      placeholder="Comment..."></textarea>
                  </div>
                </div> */}

            {/*********** End Call Status ************ */}

            {/***********  Person Spoken ************ */}
            <div className="row align-item-fe selectWidth">
              <div className="col-md-3 mb-4">
                <label for="FollowUpDate" className="align-items-start flex-2">
                  Person Spoken To : <span className="text-danger">*</span>
                </label>
                <input type="text" className="form-control" value={personSpokenTo}
                  onChange={(e) => this.refillFormChangeHandle(e)} name="personSpokenTo" />
                {personSpokenToErr &&
                  <div className="errorMsg">{personSpokenToErr}</div>
                }

              </div>
              <div className="col-md-9 mb-4">
                <textarea className="form-control" rows="1"
                  onChange={(e) => this.refillFormChangeHandle(e)}
                  value={personSpokenToComment}
                  name="personSpokenToComment" placeholder="Comment..."></textarea>
              </div>

              {/***********  End Person Spoken ************ */}

              {/** Relationship to patient */}
              <div className="col-md-3 mb-4">
                <label for="FollowUpDate" className="align-items-start flex-2">
                  Relationship To Patient : <span className="text-danger">*</span>
                </label>
                <select className="form-control text-left" name="relToPatient"
                  onChange={(e) => this.refillFormChangeHandle(e)}
                  value={relToPatient} defaultValue="">
                  <option value=""> -- Please Select -- </option>
                  <option value="1">Caregiver</option>
                  <option value="2">Spouse</option>
                  <option value="3">Patient</option>
                </select>
                {relToPatientErr &&
                  <div className="errorMsg">{relToPatientErr}</div>
                }
              </div>
              <div className="col-md-9 mb-4">
                <textarea className="form-control" rows="1" name="relToPatientComment"
                  onChange={(e) => this.refillFormChangeHandle(e)}
                  value={relToPatientComment} placeholder="Comment..."></textarea>
              </div>


              {/** End Relationship to patient */}

              {/** Residency Status */}

              <div className="col-md-12 mb-4">
                <label for="FollowUpDate" className="align-items-start flex-2">
                  Has there been any changes to the patient's residency status ? <span className="text-danger">*</span>
                </label>
                <div className="d-flex">
                  <select className="form-control text-left"
                    onChange={(e) => this.refillFormChangeHandle(e)}
                    value={patientResidencyStatus} name="patientResidencyStatus">
                    <option value=""> -- Please Select -- </option>
                    <option value="1">Yes</option>
                    <option value="0">No</option>
                  </select>
                  {patientResidencyStatusErr &&
                    <div className="errorMsg">{patientResidencyStatusErr}</div>
                  }
                </div>
              </div>



              {/** End Residency Status */}

              {/** Patient licensed care facility */}
              <div className="col-md-3 mb-4" style={{ display: this.state.patientResidencyStatus === '1' ? 'block' : 'None' }}>
                <label for="FollowUpDate" className="align-items-start">
                  Does the patient now reside in a licensed care facility ?
                </label>
                <select className="form-control text-left" name="patientCareFacility"
                  onChange={(e) => this.refillFormChangeHandle(e)}
                  value={patientCareFacility}>
                  <option value=""> --- Please Select --- </option>
                  <option value="1">Yes</option>
                  <option value="0">No</option>
                </select>
                {patientCareFacilityErr &&
                  <div className="errorMsg">{patientCareFacilityErr}</div>
                }
              </div>
              <div className="col-md-9 mb-4" style={{ display: this.state.patientResidencyStatus === '1' ? 'block' : 'None' }}>
                <textarea className="form-control" rows="1" name="patientCareFacilityComment"
                  onChange={(e) => this.refillFormChangeHandle(e)}
                  value={patientCareFacilityComment} placeholder="Comment..."></textarea>
              </div>



              {/** End Patient licensed care facility */}

              {/** Doses remaining */}
              <div className="col-md-12">
                <label for="FollowUpDate" className="align-items-start">
                  How many doses of your medication do you have remaining ? <span className="text-danger">*</span>
                </label>
              </div>
              <div className="col-md-3 mb-4">

                <input className="form-control text-left" name="dosesRemaining"
                  onChange={(e) => this.refillFormChangeHandle(e)}
                  value={dosesRemaining} />
                {dosesRemainingErr &&
                  <div className="errorMsg">{dosesRemainingErr}</div>
                }
              </div>
              <div className="col-md-9 mb-4">
                <textarea className="form-control" rows="1" name="dosesRemainingComment"
                  onChange={(e) => this.refillFormChangeHandle(e)}
                  value={dosesRemainingComment} placeholder="Comment..."></textarea>
              </div>


              {/** End Doses remaining */}

              {/** Last refill missed */}
              <div className="col-md-12">
                <label for="FollowUpDate" className="align-items-start">
                  Since your last refill have you missed taking any doses of your medication ? <span className="text-danger">*</span>
                </label>
              </div>
              <div className="col-md-3 mb-4">

                <select className="form-control text-left" name="lastRefillDoses"
                  onChange={(e) => this.refillFormChangeHandle(e)}
                  value={lastRefillDoses}>
                  <option value=""> -- Please Select -- </option>
                  <option value="50">Yes</option>
                  <option value="100">No</option>
                </select>
                {lastRefillDosesErr &&
                  <div className="errorMsg">{lastRefillDosesErr}</div>
                }
              </div>
              <div className="col-md-9 mb-4">
                <textarea className="form-control" rows="1" name="lastRefillDosesComment"
                  onChange={(e) => this.refillFormChangeHandle(e)}
                  value={lastRefillDosesComment} placeholder="Comment..."></textarea>
              </div>
                

                {/* Last refill missed--select yes then using the form */}
              <div className="col-md-12 mb-4" style={{ display: this.state.lastRefillDoses === '50' ? 'block' : 'None' }}>
                <label for="FollowUpDate" className="align-items-start flex-2">
                  How many doses have you missed ? <span className="text-danger">*</span>
                </label>
                <input className="form-control text-left left-controlW" name="dosesMissed"
                  onChange={(e) => this.refillFormChangeHandle(e)}
                  value={dosesMissed} />
                {dosesMissedErr &&
                  <div className="errorMsg">{dosesMissedErr}</div>
                }
              </div>
                  
              <div className="col-md-6 mb-4" style={{ display: this.state.lastRefillDoses === '100' ? 'block' : 'None' }}>
                <label for="FollowUpDate" className="align-items-start flex-2">
                  Goals of Therapy Score : <span className="text-danger">*</span>
                </label>
                <input className="form-control text-left flex-4" name="therapyScore"
                  onChange={(e) => this.refillFormChangeHandle(e)}
                  value={therapyScore} />
                {therapyScoreErr &&
                  <div className="errorMsg">{therapyScoreErr}</div>
                }
              </div>


              {/** End last refill missed */}

              {/** OTC medications */}
              <div className="col-md-12">
                <label for="FollowUpDate" className="align-items-start flex-2">
                  Have you started any new prescription or OTC medications since your last refill? <span className="text-danger">*</span>
                </label></div>
              <div className="col-md-3 mb-4">

                <select className="form-control text-left flex-4" name="otcMedication"
                  onChange={(e) => this.refillFormChangeHandle(e)}
                  value={otcMedication}>
                  <option value=""> --- Please Select -- </option>
                  <option value="1">Yes</option>
                  <option value="0">No</option>
                </select>
                {otcMedicationErr &&
                  <div className="errorMsg">{otcMedicationErr}</div>
                }
              </div>
                {/* OTC select the yes then using the form */}
              <div className="col-md-9 mb-4" style={{ visibility: this.state.otcMedication === '1' ? 'visible' : 'hidden' }}>
                <label className="align-items-start flex-2">New medications : <span className="text-danger">*</span></label>
                <input className="form-control text-left flex-4" name="newMedications"
                  onChange={(e) => this.refillFormChangeHandle(e)}
                  value={newMedications} />
                {newMedicationsErr &&
                  <div className="errorMsg">{newMedicationsErr}</div>
                }
              </div>
              {/** End OTC medications */}

              {/** side effects  */}
              <div className="col-md-12">
                <label for="FollowUpDate" className="align-items-start flex-2">
                  Are you experiencing any new side effects you have not discussed with your provider ? <span className="text-danger">*</span>
                </label>
              </div>
              <div className="col-md-3 mb-4">

                <select className="form-control text-left" name="newSideEffects"
                  onChange={(e) => this.refillFormChangeHandle(e)}
                  value={newSideEffects}>
                  <option value=""> -- Please Select -- </option>
                  <option value="1">Yes</option>
                  <option value="0">No</option>
                </select>
                {newSideEffectsErr &&
                  <div className="errorMsg">{newSideEffectsErr}</div>
                }
              </div>
              <div className="col-md-9 mb-4">
                <textarea className="form-control" rows="1" name="newSideEffectsComment"
                  onChange={(e) => this.refillFormChangeHandle(e)}
                  value={newSideEffectsComment} placeholder="Comment..."></textarea>
              </div>

                  {/*****side effects yes then create the form using   ****/}
              <div className="col-md-3 mb-4" style={{ display: this.state.newSideEffects === '1' ? 'block' : 'None' }}>
                <label for="FollowUpDate" className="align-items-start">
                  Describe the side effects you are experiencing : <span className="text-danger">*</span>
                </label>
                <textarea className="form-control" rows="1" name="sideEffects"
                  onChange={(e) => this.refillFormChangeHandle(e)}
                  value={sideEffects} placeholder="Comment..."></textarea>
                {sideEffectsErr &&
                  <div className="errorMsg">{sideEffectsErr}</div>
                }
              </div>
              <div className="col-md-9 mb-4" style={{ display: this.state.newSideEffects === '1' ? 'block' : 'None' }}>
                <textarea className="form-control flex-4" rows="1" name="sideEffectsComment"
                  onChange={(e) => this.refillFormChangeHandle(e)}
                  value={sideEffectsComment} placeholder="Comment..."></textarea>
              </div>

              {/** End side effects  */}

              {/** pharmacist question */}
              <div className="col-md-3 mb-4">
                <label for="FollowUpDate" className="align-items-start flex-2">
                  Do you have any questions for the pharmacist ? <span className="text-danger">*</span>
                </label>
                <select className="form-control text-left" name="anyQuesPharmacist"
                  onChange={(e) => this.refillFormChangeHandle(e)}
                  value={anyQuesPharmacist}>
                  <option value=""> -- Please Select -- </option>
                  <option value="1">Yes</option>
                  <option value="0">No</option>
                </select>
                {anyQuesPharmacistErr &&
                  <div className="errorMsg">{anyQuesPharmacistErr}</div>
                }
              </div>
              <div className="col-md-9 mb-4">
                <textarea className="form-control" rows="1" name="anyQuesPharmacistComment"
                  onChange={(e) => this.refillFormChangeHandle(e)}
                  value={anyQuesPharmacistComment} placeholder="Comment..."></textarea>
              </div>


              {/** end pharmacist question */}

              {/** Follow up require */}
              <div className="col-md-4 mb-4">
                <label for="FollowUpDate" className="align-items-start flex-2">
                  Does this call require a pharmacist do follow up? <span className="text-danger">*</span>
                </label>
                <textarea className="form-control flex-4" rows="1" name="followUpCall"
                  onChange={(e) => this.refillFormChangeHandle(e)}
                  value={followUpCall} placeholder="Comment..."></textarea>
                {followUpCallErr &&
                  <div className="errorMsg">{followUpCallErr}</div>
                }
              </div>

              <div className="col-md-8 mb-6">
                <div className="d-flex">
                  {/* <textarea className="form-control flex-4" rows="1" placeholder="Comment..."></textarea> */}
                </div>
              </div>
              {/** End follow up require */}

              {/** Co-Payment */}
              <div className="col-md-12">
                <label for="FollowUpDate" className="align-items-start flex-2">
                  Did you discuss the Co-Payment amount due with the patient? <span className="text-danger">*</span>
                </label></div>
              <div className="col-md-3 mb-4">

                <select className="form-control text-left" name="amountDue"
                  onChange={(e) => this.refillFormChangeHandle(e)}
                  value={amountDue}
                >
                  <option value=""> -- Please Select -- </option>
                  <option value="1">Yes</option>
                  <option value="0">No</option>
                </select>
                {amountDueErr &&
                  <div className="errorMsg">{amountDueErr}</div>
                }
              </div>

              <div className="col-md-9 mb-4">
                <textarea className="form-control" rows="1" name="amountDueComment"
                  onChange={(e) => this.refillFormChangeHandle(e)}
                  value={amountDueComment} placeholder="Comment..."></textarea>
              </div>


                {/* Co-Payment Amount yes then using the form */}
              <div className="col-md-12" style={{ display: this.state.amountDue === '1' ? 'block' : 'None' }}>
                <div className="d-flex">
                  <div className="left-controlW mr-4 mb-4">
                    <label for="FollowUpDate" className="align-items-start">
                      Co-Payment Amount : <span className="text-danger">*</span>
                    </label>
                    <input className="form-control" name="paymentAmount"
                      onChange={(e) => this.refillFormChangeHandle(e)}
                      value={paymentAmount} />
                    {paymentAmountErr &&
                      <div className="errorMsg2">{paymentAmountErr}</div>
                    }
                  </div>

                  <div className="w-100 mb-4">
                    <label for="FollowUpDate" className="align-items-start">
                      Refill call notes this call :
                    </label>
                    <textarea className="form-control" name="refillCallNotes"
                      onChange={(e) => this.refillFormChangeHandle(e)}
                      value={refillCallNotes} rows="1"></textarea>
                  </div>
                </div>
              </div>
              {/** End Co-Payment */}
                  {/*Follow up date  */}
              <div className="col-md-3 mb-4 ">
                <label for="FollowUpDate" className="align-items-start flex-2">  Follow Up Date : </label>
                
                <input type="date" className="form-control" name="followUpDate" min = {this.todayDate} onKeyDown={(event)=>{event.preventDefault()}}
                  onChange={(e) => this.refillFormChangeHandle(e)} value={followUpDate} />
                {followUpDateErr &&
                  <div className="errorMsg">{followUpDateErr}</div>
                }
              </div>

            </div>

                  {/* Is Complate */}
            <div className="row">
              <div className="col-sm-4 mb-4">
                <div class="custom-control custom-checkbox pt-2">
                  <input type="checkbox" value={this.state.is_completed} name="is_completed" onChange={(e) => this.refillFormChangeHandle(e)}
                    className="custom-control-input" id="isCompleted" />
                  <label class="custom-control-label font-weight-normal" for="isCompleted">
                    Is Complete ?
                  </label>
                </div>
              </div>
            </div>
            <div className="row">

              <div className="col-md-8 mb-3">
                <label for="updatedOn" className="align-items-start flex-2 mr-3">  Updated On :
                  <b>{this.state.updated_on != '' ? dateFormat(this.state.updated_on , "mm/dd/yyyy  HH:MM") : ''}</b>
                </label>

                <label for="updatedBy" className="align-items-start flex-2">  Updated By :
                  <b>{this.state.updated_by_user != '' ? this.state.updated_by_user : ''}</b> </label>
              </div>

              <div className="col-md-4 mb-4  text-right">
                <button hidden={this.state.show} className="btn btn-primary mb-4" onClick={e => this.refillReminderForm(e)}>Save Changes</button>
                <button hidden={!this.state.show} className="btn btn-primary mb-4" onClick={e => this.updRefillReminderForm(e, this.state.id)}>Save Changes</button>
              </div>
            </div>

          </div>
          {/***********3*************/}
        </section>
        <CommonFooter />
      </>
    );
  }
}
