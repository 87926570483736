import React from 'react';
import { Redirect, Link } from 'react-router-dom';
import dateFormat from 'dateformat';
import IconWithTooltip from 'icon-with-tooltip';
import CheckIcon from '@material-ui/icons/Check';
import $ from 'jquery';
import {API_URL,} from '../../config';
import axios from 'axios';
import insertionSort from './utils';
import { ToastContainer, toast } from 'react-toastify';
import './style.css';
import {
  VALID_ALL_FILLS_HISTORY_RX,
  VALID_ALL_FILLS_HISTORY_DESC,
  VALID_ALL_FILLS_HISTORY_QTY,
  VALID_ALL_FILLS_HISTORY_DAYS,
  VALID_ALL_FILLS_HISTORY_REFILL,
  VALID_ALL_FILLS_HISTORY_START_DATE,
  VALID_EDUCATION_DATA,
  TOKEN_EXPIRED,
} from './constants';

export default class AllFillHistory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        patient_id: this.props !== undefined ? this.props.patient_id : null,
        trackAllHistoryInputList: [{
        startDateAllHistory: "",
        }],
        allHistoryInputList: [
        {
          doneRefillAllHistory: "",
          remDueAllHistory: "",
          noassessmentAllHistory: "",
          actAllHistory: "",
          dateFilled: "",
          originalDate: "",
          rxAllHistory: "",
          oriDateAllHistory: "",
          startDateAllHistory: "",
          drugDescriptionAllHistory: "",
          qtyAllHistory: "",
          daysAllHistory: "",
          refAllHistory: "",
          idAllHistory: ""
        },
      ],
      
    
    }
  }
  // get token from loclastorage
  token = localStorage.getItem('token');
  config = {
    headers: { Authorization: `Bearer ${this.token}` },
  };
   //sort list 
   sortLists = (list,type) => {
    let uneditAble = [];
    let editAble = [];
    if(type === 'therapy'){
      list.map((each) => {
        each.therapyActive === true ? editAble.push(each) : uneditAble.push(each)
      })
    }
    if(type === 'rx'){
      list.map((each) => {
        each.actAllHistory === true ? editAble.push(each) : uneditAble.push(each)
      })
    }
    
    uneditAble = insertionSort(uneditAble)
    editAble = insertionSort(editAble)
    let arraySorted = editAble.concat(uneditAble)
    // console.log(arraySorted)
    return arraySorted
  };
  // get refill due
  getRefillDue = (dos, days) => {
    var current_date = new Date(dos)
    const start_date = current_date.setDate(current_date.getDate() + days)
    const before_two_day = current_date.setDate(current_date.getDate() - 7)
    return dateFormat(current_date.toISOString().substr(0, 10), "mm/dd/yyyy")
  }
  /* Set All Fills History Tab */
  // setAllFilesHistoryTab = () => {
  //   if (localStorage.getItem('currTab') == 'Del') {
  //     $("#schDelivery").attr('href', '#deliveries');
  //     $("#allFilesHis").removeAttr('href');
  //     $("#eduAssessment").removeAttr('href');

  //     $("#refCallNotes").removeAttr('href');
  //     $("#assCommunications").removeAttr('href');
  //     $("#medTherapy").removeAttr('href');

  //     $("#schDelivery").addClass("active");
  //     $("#allFilesHis").removeClass("active");
  //     $("#eduAssessment").removeClass('active');

  //     $("#refCallNotes").removeClass("active");
  //     $("#assCommunications").removeClass("active");
  //     $("#medTherapy").removeClass("active");
  //   } else if (localStorage.getItem('currTab') == 'comm') {
  //     $("#assCommunications").attr('href', '#communications');
  //     $("#schDelivery").removeAttr('href');
  //     $("#eduAssessment").removeAttr('href');

  //     $("#refCallNotes").removeAttr('href');
  //     $("#allFilesHis").removeAttr('href');
  //     $("#medTherapy").removeAttr('href');

  //     $("#assCommunications").addClass("active");
  //     $("#schDelivery").removeClass("active");
  //     $("#eduAssessment").removeClass('active');

  //     $("#allFilesHis").removeClass("active");
  //     $("#refCallNotes").removeClass("active");
  //     $("#medTherapy").removeClass("active");
  //   } else if (localStorage.getItem('currTab') == 'refillCalNotes') {
  //     $("#refCallNotes").attr('href', '#refillCalNotes');
  //     $("#allFilesHis").removeAttr('href');
  //     $("#eduAssessment").removeAttr('href');

  //     $("#schDelivery").removeAttr('href');
  //     $("#refCallNotes").removeAttr('href');
  //     $("#medTherapy").removeAttr('href');

  //     $("#refCallNotes").addClass("active");
  //     $("#schDelivery").removeClass("active");
  //     $("#eduAssessment").removeClass('active');

  //     $("#allFilesHis").removeClass("active");
  //     $("#assCommunications").removeClass("active");
  //     $("#medTherapy").removeClass("active");
  //   } else if (localStorage.getItem('currTab') == 'therapy') {
  //     $("#medTherapy").attr('href', '#therapy');
  //     $("#allFilesHis").removeAttr('href');
  //     $("#eduAssessment").removeAttr('href');

  //     $("#schDelivery").removeAttr('href');
  //     $("#refCallNotes").removeAttr('href');
  //     $("#assCommunications").removeAttr('href');

  //     $("#medTherapy").addClass("active");
  //     $("#schDelivery").removeClass("active");
  //     $("#eduAssessment").removeClass('active');

  //     $("#allFilesHis").removeClass("active");
  //     $("#assCommunications").removeClass("active");
  //     $("#refCallNotes").removeClass("active");
  //   } else if (localStorage.getItem('currTab') == 'education') {
  //     $("#eduAssessment").attr('href', '#education');
  //     $("#allFilesHis").removeAttr('href');
  //     $("#assCommunications").removeAttr('href');

  //     $("#schDelivery").removeAttr('href');
  //     $("#refCallNotes").removeAttr('href');
  //     $("#medTherapy").removeAttr('href');

  //     $("#eduAssessment").addClass("active");
  //     $("#schDelivery").removeClass("active");
  //     $("#assCommunications").removeClass('active');

  //     $("#allFilesHis").removeClass("active");
  //     $("#refCallNotes").removeClass('active');
  //     $("#medTherapy").removeClass('active');
  //   }
  // };

  /* Get All Fills History Common Method*/
  getAllFilesHistory = () => {
    if (localStorage.getItem('isPatientAddDelChanged') || localStorage.getItem('patRefillCallNotesChanged') ||
      localStorage.getItem('patCommunicationsChanged') || localStorage.getItem('patTherapyInputChanged')
      || localStorage.getItem('isPatientEduAssChanged') || localStorage.getItem('isAssChanged')) {
      const confirmBox = window.confirm("You have unsaved changes! Do you really want to move to another tab?");
      if (confirmBox === true) {
        localStorage.setItem('prevTab', localStorage.getItem('currTab'));
        localStorage.setItem('currTab', 'AllFillsHis');

        localStorage.removeItem('isPatientAddDelChanged');
        localStorage.removeItem('patCommunicationsChanged');
        localStorage.removeItem('patRefillCallNotesChanged');
        localStorage.removeItem('patTherapyInputChanged');
        localStorage.removeItem('isPatientEduAssChanged');
        localStorage.removeItem('isAssChanged')

        $("#allFilesHis").attr('href', '#allFilesHistory');
        $("#schDelivery").removeAttr('href');
        $("#assCommunications").removeAttr('href');
        $("#refCallNotes").removeAttr('href');
        $("#medTherapy").removeAttr('href');
        $("#eduAssessment").removeAttr('href');
        $("#archivesAss").removeAttr('href');
        $("#oncoAssessments").removeAttr('href');

      } else {
        setTimeout(() => this.props.setTab(), 250);
      }
    } else {
      localStorage.setItem('prevTab', localStorage.getItem('currTab'));
      localStorage.setItem('currTab', 'AllFillsHis');

      $("#allFilesHis").attr('href', '#allFilesHistory');
      $("#schDelivery").removeAttr('href');
      $("#eduAssessment").removeAttr('href');

      $("#assCommunications").removeAttr('href');
      $("#refCallNotes").removeAttr('href');
      $("#medTherapy").removeAttr('href');
      $("#archivesAss").removeAttr('href');
      $("#oncoAssessments").removeAttr('href');
    }

    axios
      .get(`${API_URL}/api/get_all_fills_history/?patient_id=` + this.state.patient_id, this.config)
      .then(response => {
        if (response.status === 200) {
          let allHistoryData;
          let trackAllHistoryData;
          if (response.data.status === 200) {
            if (response.data.data !== null) {
              allHistoryData = response.data.data.map((data, index) => ({
                doneRefillAllHistory: data.is_refill_reminder_done,
                remDueAllHistory: this.getRefillDue(data.dos, data.days),
                actAllHistory: data.active,
                noassessmentAllHistory: data.is_history,
                rxAllHistory: data.rx,
                oriDateAllHistory: data.original_therapy_date,
                originalDate: data.date_written,
                dateFilled: data.dos,
                startDateAllHistory: data.start_date == null ? data.dos : data.start_date,
                drugDescriptionAllHistory: data.name,
                qtyAllHistory: data.quantity,
                daysAllHistory: data.days,
                refAllHistory: data.refill,
                idAllHistory: data.trans_med_id
              }));

              trackAllHistoryData = response.data.data.map((data, index) => ({
                startDateAllHistory: dateFormat(data.dos, "yyyy-mm-dd"),
              }));
            } else {
              allHistoryData = [];
            }

            this.setState({ allHistoryInputList: this.sortLists(allHistoryData,'rx'), });
            this.setState({ trackAllHistoryInputList: this.sortLists(trackAllHistoryData,'rx'), });

          } else if (response.data.status_code == 406) {
            localStorage.removeItem('token');
            localStorage.removeItem('user_name');

            localStorage.removeItem('adminCheck');
            localStorage.setItem('tokenExpired', true);

            const tokenStatus = localStorage.getItem('tokenExpired');
            if (tokenStatus) {
              localStorage.removeItem('tokenExpired');
              toast.error(TOKEN_EXPIRED, {
                position: 'top-right',
                autoClose: 2500,
              });
            }

            const isLogged = !!localStorage.getItem('token');
            if (!isLogged) {
              setTimeout(() => {
                this.props.history.push("/login");
              }, 2500);
            }
          }
        } else if (response.status !== 200) {
          toast.error(VALID_EDUCATION_DATA, {
            position: 'top-right',
            autoClose: 3500,
          });
          return false;
        }
      })
      .catch(error => {
        console.log(error);
      });
  }
  // Handle Input Change Of All History
  handleAllHistoryInputChange = (e, index, id) => {
    const { name, value, type, checked } = e.target;
    const list = [...this.state.allHistoryInputList];

    list[index][name] = type === "checkbox" ? checked : value;
    this.setState({ allHistoryInputList: this.sortLists(list,'rx'), });

    if (name === 'actAllHistory') {
      axios
        .put(`${API_URL}/api/update_therapy/${id}/`, { is_active: this.state.allHistoryInputList[index].actAllHistory }, this.config)
        .then(response => {
          if (response.data.status === 200 && response.status === 200) {
            toast.success(response.data.message, {
              position: 'top-right',
              autoClose: 3500,
            })
            this.getMedication();
          } else {
            toast.error(VALID_EDUCATION_DATA, {
              position: 'top-right',
              autoClose: 3500,
            });
          }
        })
        .catch(err => console.log(err))
    }
    if (name === 'noassessmentAllHistory') {
      axios
        .put(`${API_URL}/api/update_therapy/${id}/`, { is_history: this.state.allHistoryInputList[index].noassessmentAllHistory }, this.config)
        .then(response => {
          if (response.data.status === 200 && response.status === 200) {
            toast.success(response.data.message, {
              position: 'top-right',
              autoClose: 3500,
            })
          } else {
            toast.error(VALID_EDUCATION_DATA, {
              position: 'top-right',
              autoClose: 3500,
            });
          }
        })
        .catch(err => console.log(err))
    }

    if (name === 'doneRefillAllHistory') {
      axios
        .put(`${API_URL}/api/update_therapy/${id}/`,
          { refill_reminder_done: this.state.allHistoryInputList[index].doneRefillAllHistory }, this.config)
        .then(response => {
          if (response.data.status === 200 && response.status === 200) {
            toast.success(response.data.message, {
              position: 'top-right',
              autoClose: 3500,
            })
          } else {
            toast.error(VALID_EDUCATION_DATA, {
              position: 'top-right',
              autoClose: 3500,
            });
          }
        })
        .catch(err => console.log(err))
    }

    if (name === 'startDateAllHistory' && this.state.trackAllHistoryInputList[index].startDateAllHistory !== this.state.allHistoryInputList[index].startDateAllHistory) {
      localStorage.setItem('patAllFillsHisChanged', true);
    } else {
      localStorage.removeItem('patAllFillsHisChanged');
    }
  };

  // Handle Click Event Of The Add Button Of All History
  handleAllHistoryAddClick = () => {
    const list = [...this.state.allHistoryInputList];
    list.push({
      doneRefillAllHistory: "",
      remDueAllHistory: "",
      actAllHistory: "",
      
      rxAllHistory: "",
      oriDateAllHistory: "",
      startDateAllHistory: "",
      drugDescriptionAllHistory: "",
      qtyAllHistory: "",
      daysAllHistory: "",
      refAllHistory: "",
    });
    this.setState({
      allHistoryInputList: this.sortLists(list,'rx'),
    });
  };

  // Handle Click event Of The Remove Button Of All History
  handleAllHistoryRemoveClick = index => {
    const list = [...this.state.allHistoryInputList];
    list.splice(index, 1);
    this.setState({
      allHistoryInputList: this.sortLists(list,'rx'),
    });
  };
  // Handle Validations For All History Rows Input Fields
  handleCurrAllFillsHistoryRow = (index, id) => {
    const list = [...this.state.allHistoryInputList];
    if (list[index].rxAllHistory === '') {
      toast.error(VALID_ALL_FILLS_HISTORY_RX, {
        position: 'top-right',
        autoClose: 3000,
      });
      return false;
    } else if (list[index].startDateAllHistory === '') {
      toast.error(VALID_ALL_FILLS_HISTORY_START_DATE, {
        position: 'top-right',
        autoClose: 3000,
      });
      return false;
    } else if (list[index].drugDescriptionAllHistory === '') {
      toast.error(VALID_ALL_FILLS_HISTORY_DESC, {
        position: 'top-right',
        autoClose: 3000,
      });
      list[index].textInput.focus();
      return false;
    } else if (list[index].qtyAllHistory === '') {
      toast.error(VALID_ALL_FILLS_HISTORY_QTY, {
        position: 'top-right',
        autoClose: 3000,
      });
      return false;
    } else if (list[index].daysAllHistory === '') {
      toast.error(VALID_ALL_FILLS_HISTORY_DAYS, {
        position: 'top-right',
        autoClose: 3000,
      });
      return false;
    } else if (list[index].refAllHistory === '') {
      toast.error(VALID_ALL_FILLS_HISTORY_REFILL, {
        position: 'top-right',
        autoClose: 3000,
      });
      return false;
    }
    axios
      .put(`${API_URL}/api/update_therapy/${id}/`, { start_date: this.state.allHistoryInputList[index].startDateAllHistory }, this.config)
      .then(response => {
        if (response.data.status === 200 && response.status === 200) {
          window.onload = this.getAllFilesHistory();
          localStorage.removeItem('patAllFillsHisChanged');
          toast.success(`Successfully ${response.data.message}`, {
            position: 'top-right',
            autoClose: 3500,
          })
        } else {
          toast.error(VALID_EDUCATION_DATA, {
            position: 'top-right',
            autoClose: 3500,
          });
        }
      })
      .catch(err => console.log(err))
  };

  render() {
      const isLogged = !!localStorage.getItem('token');
		if (!isLogged) {
			return <Redirect to="/login" />;
		}
    return (
        <>
        
          <div id="allFilesHistory" className="container-fluid tab-pane fade">            
            <div className="table-responsive">
              <table className="table">
                <thead>
                    <tr>
                    <th id="align">Done-Refill</th>
                    <th id="align">Reminder - Due</th>
                    <th id="align">Active</th>
                    <th id="align">Assessment N/A</th>
                    <th id="align">RX #</th>
                    <th id="align">Original Date</th>
                    <th id="align">Date Filled</th>
                    <th id="align">Start Date</th>
                    <th id="align">Drug Description</th>
                    <th id="align">Qty.</th>
                    <th id="align">Days</th>
                    <th id="align">Refill #</th>
                    <th style={{ width: '100px' }}></th>
                    </tr>
                </thead>
                <tbody>
                          {this.state.allHistoryInputList.length > 0 ? this.state.allHistoryInputList.map((x, i) => {
                            return (
                              <tr key={x.trans_med_id} style={x.actAllHistory == false ? { backgroundColor: "#e9ecef" } : {}}>
                              <td>
                                  <div className="custom-control custom-checkbox">
                                    <input type="checkbox" className="custom-control-input" id={"doneRefillAllHistory_" + i}
                                      name="doneRefillAllHistory" value={x.doneRefillAllHistory} checked={x.doneRefillAllHistory ? true : false}
                                      onChange={e => this.handleAllHistoryInputChange(e, i, x.idAllHistory)} />
                                    <label className="custom-control-label" htmlFor={"doneRefillAllHistory_" + i}></label>
                                  </div>
                                </td>
                                
                                <td style={{ width: '250px' }}>
                                  <input type="text" className="form-control  w-100" name="remDueAllHistory" value={x.remDueAllHistory}
                                    onChange={e => this.handleAllHistoryInputChange(e, i, x.idAllHistory)} readOnly />
                                </td>
                                <td>
                                  <div className="custom-control custom-checkbox">
                                    <input type="checkbox" className="custom-control-input" id={"defaultUnchecked_" + i}
                                      name="actAllHistory" defaultValue={x.actAllHistory} onChange={e => this.handleAllHistoryInputChange(e, i, x.idAllHistory)}
                                      checked={x.actAllHistory ? true : false}
                                      style={{ marginLeft: '8px', zIndex: '99999' }}
                                    />
                                    <label className="custom-control-label" htmlFor={"defaultUnchecked_" + i}></label>
                                  </div>
                                </td>
                                <td>
                                  <div className="custom-control custom-checkbox">
                                    <input type="checkbox" className="custom-control-input" id={"noAssessmentsAllHistory_" + i} name="noassessmentAllHistory" checked={x.noassessmentAllHistory ? true : false} disabled={x.refAllHistory > 0} onChange={e => this.handleAllHistoryInputChange(e, i, x.idAllHistory)}/> 
                                    <label className="custom-control-label" htmlFor={"noAssessmentsAllHistory_" + i}></label>
                                  </div>
                                </td>
                                <td>
                                  <input type="text" style={{ width: '75px' }} className="form-control" name="rxAllHistory" value={x.rxAllHistory} title={x.rxAllHistory}
                                    onChange={e => this.handleAllHistoryInputChange(e, i, x.idAllHistory)} readOnly />
                                </td>
                                <td>
                                  <input type="date" className="form-control w-145" name="originalDate" value={dateFormat(x.originalDate, "yyyy-mm-dd", "UTC:h:MM:ss TT Z")}
                                    onChange={e => this.handleAllHistoryInputChange(e, i, x.idAllHistory)} readOnly />
                                </td>
                                <td>
                                  <input type="date" className="form-control w-145" name="dateFilled" value={dateFormat(x.dateFilled, "yyyy-mm-dd", "UTC:h:MM:ss TT Z")}
                                    onChange={e => this.handleAllHistoryInputChange(e, i, x.idAllHistory)} readOnly />
                                </td>
                                <td>
                                  <input type="date" className="form-control w-145" name="startDateAllHistory" value={dateFormat(x.startDateAllHistory, "yyyy-mm-dd", "UTC:h:MM:ss TT Z")}
                                    onChange={e => this.handleAllHistoryInputChange(e, i, x.idAllHistory)}  style={x.actAllHistory == false ? { backgroundColor: "#e9ecef" } : {}} />
                                </td>
                                <td>
                                  <input type="text" style={{ width: '320px'}}  className="form-control" name="drugDescriptionAllHistory" Value={x.drugDescriptionAllHistory}
                                    onChange={e => this.handleAllHistoryInputChange(e, i, x.idAllHistory)} readOnly title={x.drugDescriptionAllHistory} />
                                  
                                </td>
                                <td style={{ width: '100px' }}>
                                  <input style={{ width: '50px' }} type="text" className="form-control" name="qtyAllHistory" value={x.qtyAllHistory}
                                    onChange={e => this.handleAllHistoryInputChange(e, i, x.idAllHistory)} readOnly />
                                </td>
                                <td style={{ width: '100px' }}>
                                  <input style={{ width: '50px' }} type="text" className="form-control" name="daysAllHistory" value={x.daysAllHistory}
                                    onChange={e => this.handleAllHistoryInputChange(e, i, x.idAllHistory)} readOnly />
                                </td>
                                <td style={{ width: '100px' }}>
                                  <input style={{ width: '50px' }} type="text" className="form-control" name="refAllHistory" value={x.refAllHistory}
                                    onChange={e => this.handleAllHistoryInputChange(e, i, x.idAllHistory)} readOnly />
                                </td>
                                <td className="d-flex justify-content-end">
                                  <button type="button" className="btn btn-primary mt-1 btnControl btnsv"
                                    onClick={() => this.handleCurrAllFillsHistoryRow(i, x.idAllHistory)}>
                                    <IconWithTooltip
                                      Icon={CheckIcon}
                                      text="Save Changes"
                                    />
                                  </button>
                                </td>
                              </tr>
                            );
                          }) : (
                            <tr>
                              <td colSpan="6">No records found</td>
                            </tr>
                          )}
                        </tbody>             
              </table>
            </div>
          </div>                                                   
      </>
    );

  }
}