import React from 'react';
import CommonHeader from '../../components/CommonHeader';
import CommonSidebar from '../../components/CommonSidebar';
import CommonFooter from '../../components/CommonFooter';
import { MDBDataTable } from 'mdbreact';
import axios from 'axios';
import { Redirect, Link } from 'react-router-dom';
import Communication from '../Communication/Communication';
import { Accordion, Card, Button, Modal } from 'react-bootstrap';
import dateFormat from 'dateformat';
import { ToastContainer, toast } from 'react-toastify';
import insertionSort from './utils';
import RefillCodeNotes from '../RefillCallNotes/RefillCallNotes';
import Therapy from '../Therapy/therapy';
import PlusIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';

import {
  VALID_THERAPY_START_DATE,
  VALID_THERAPY_QTY,
  VALID_THERAPY_DAYS,
  VALID_EDUCATION_DATA,
  TOKEN_EXPIRED,
} from './constants';

import {
  API_URL,
} from '../../config';

import $ from 'jquery';
import 'react-toastify/dist/ReactToastify.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'bootstrap/dist/css/bootstrap.css';
import '../../containers/css/style_semantic.css';
import './style.css';
import './bootstrap.min.css';
import 'react-confirm-alert/src/react-confirm-alert.css';
import PatientDemographics from '../PatientDemographics';
import Archives from '../Archives/Archives';
import AllFillHistory from '../AllFillHistory/AllFillHistory';
import Deliveries from '../Deliveries/Deliveries';
import EducationAssessment from '../EducationAssessment/EducationAssessment';
import Assessments from '../Assessments';

const validateForm = errors => {
  let valid = true;
  Object.values(errors).forEach(val => val.length > 0 && (valid = false));
  return valid;
};

var prescribingMD;
var officePhone;

export default class PatientClinicalProfile extends React.Component {
  constructor(props) {
    super(props);
    this.RefillCodeNotes = React.createRef();
    this.Archives = React.createRef();
    this.AllFillHistory = React.createRef();
    this.therapy = React.createRef();
    this.Communication = React.createRef();
    this.Deliveries = React.createRef();
    this.EducationAssessment = React.createRef();
    this.Assessments = React.createRef();
    this.state = {
      currentDate: new Date('07/01/2021'),  //Moment(new Date()).format('MM/DD/YYYY'),
      isgotshow: false,
      showPastDelModal: false,
      updDiagnosisCodes: [],
      diagnosisCodes: [],
      selDiagnosisCodeValue: [],
      selPriDiagnosisCodeValue: [],
      selDiagCodeIdsArr: [],
      selPriDiagCodeIdsArr: [],
      rowData: [],
      eduAssessmentForms: [],
      trackEduAssessmentForms: [],
      eduAssErrors: [],
      eduMedErrors: [],
      selectedDiagnosisCodes: [],
      priSelDiagnosisCodes: [],
      medicationsArr: [],
      trackAllHistoryInputList: [{
        startDateAllHistory: "",
      }],

      trackRefillCallInputList: [{
        refCallCommNotes: "",
        refCallNotesStatus: "",
        refCallNotesFollowDate: "",
      }],
      trackCommInputList: [],
      trackTherapyInputList: [],
      show: false,
      isFetchData: false,
      activeMenu: true,
      activePlus: false,
      activeMinus: true,
      showHideOpt: true,
      activeAddAssessment: false,
      minusAddAssessment: false,
      activeAssPlus: false,
      activeAssMinus: true,
      patientProData: {
        patient_name: '',
        gender: 'f',
        age: '',
        mrn: '',
        deceased: '',
        patient_phone: '',
        patient_cell: '',
        patient_height: '',
        patient_weight: '',
        dob: '',
        pregnancy_comments: '',
        patient_email: '',
        existing_patient_record: false,
        active: true,
        patient_identifier: '',
        patient_first_name: '',
        patient_last_name: '',
        patient_address: '',
        patient_city: '',
        patient_state: '',
        patient_zip: '',
        age_risk: true,
        pregnant: true,
        pregnancy_due: null,
        risk_comments: null,
      },

      assessment_id: this.props.location.state !== undefined ? this.props.location.state.assessment_id : null,
      transaction_id: this.props.location.state !== undefined ? this.props.location.state.transaction_id : null,
      patient_id: this.props.location.state !== undefined ? this.props.location.state.patient_id : null,
      patient_name: this.props.location.state !== undefined ? this.props.location.state.patient_name : null,
      data_added: this.props.location.state !== undefined ? this.props.location.state.data_added : null,


      medicationsInputList: [
        {
          id: "",
          refrigerate: "",
          prescribingMD: "",
          officePhone: "",
        },
      ],
      updMedInputList: [
        {
          id: "",
          refrigerate: "",
          prescribingMD: "",
          officePhone: "",
        },
      ],
      commInputList: [
        {
          comId: "",
          typeOfCom: "",
          comDate: "",
          comNotes: "",
          comStatus: "",
          comCheck: "",
          comFollowUpDate: "",
        },
      ],

    };

    this.myInitObject = null;
  }

  // today date
  todayDate = new Date().toISOString().slice(0, 10)

  // get token from loclastorage
  token = localStorage.getItem('token');
  config = {
    headers: { Authorization: `Bearer ${this.token}` },
  };


  /* Set Therapy Tab Activ And In Active After Switching Tab */
  setTab = () => {

    if (localStorage.getItem('currTab') == 'Del') {
      $("#schDelivery").attr('href', '#deliveries');
      $("#allFilesHis").removeAttr('href');

      $("#eduAssessment").removeAttr('href');
      $("#refCallNotes").removeAttr('href');

      $("#communications").removeAttr('href');
      $("#medTherapy").removeAttr('href');

      $("#schDelivery").addClass("active");
      $("#allFilesHis").removeClass("active");

      $("#eduAssessment").removeClass("active");
      $("#refCallNotes").removeClass("active");

      $("#assCommunications").removeClass('active');
      $("#medTherapy").removeClass('active');

      $("#oncoAssessments").removeAttr('href');
      $("#oncoAssessments").removeClass('active');

      $("#archivesAss").removeAttr('href');
      $("#archivesAss").removeClass('active');

    } else if (localStorage.getItem('currTab') === 'AllFillsHis') {
      $("#allFilesHis").attr('href', '#allFilesHistory');
      $("#schDelivery").removeAttr('href');
      $("#eduAssessment").removeAttr('href');

      $("#refCallNotes").removeAttr('href');
      $("#assCommunications").removeAttr('href');
      $("#medTherapy").removeAttr('href');

      $("#allFilesHis").addClass("active");
      $("#schDelivery").removeClass("active");
      $("#eduAssessment").removeClass("active");

      $("#refCallNotes").removeClass("active");
      $("#assCommunications").removeClass('active');
      $("#medTherapy").removeClass('active');

      $("#oncoAssessments").removeAttr('href');
      $("#oncoAssessments").removeClass('active');

      $("#archivesAss").removeAttr('href');
      $("#archivesAss").removeClass('active');

    } else if (localStorage.getItem('currTab') === 'refillCalNotes') {
      $("#refCallNotes").attr('href', '#refillCalNotes');
      $("#eduAssessment").removeAttr('href');
      $("#allFilesHis").removeAttr('href');

      $("#schDelivery").removeAttr('href');
      $("#assCommunications").removeAttr('href');
      $("#medTherapy").removeAttr('href');

      $("#refCallNotes").addClass("active");
      $("#eduAssessment").removeClass("active");

      $("#schDelivery").removeClass("active");
      $("#allFilesHis").removeClass("active");

      $("#assCommunications").removeClass('active');
      $("#medTherapy").removeClass('active');

      $("#oncoAssessments").removeAttr('href');
      $("#oncoAssessments").removeClass('active');
      $("#archivesAss").removeAttr('href');
      $("#archivesAss").removeClass('active');

    } else if (localStorage.getItem('currTab') === 'comm') {
      $("#assCommunications").attr('href', '#communications');
      $("#allFilesHis").removeAttr('href');

      $("#eduAssessment").removeAttr('href');
      $("#schDelivery").removeAttr('href');

      $("#assCommunications").removeAttr('href');
      $("#medTherapy").removeAttr('href');

      $("#assCommunications").addClass("active");
      $("#schDelivery").removeClass("active");

      $("#eduAssessment").removeClass("active");
      $("#allFilesHis").removeClass("active");

      $("#refCallNotes").removeClass('active');
      $("#medTherapy").removeClass('active');

      $("#oncoAssessments").removeAttr('href');
      $("#oncoAssessments").removeClass('active');
      $("#archivesAss").removeAttr('href');
      $("#archivesAss").removeClass('active');

    } else if (localStorage.getItem('currTab') === 'therapy') {
      $("#medTherapy").attr('href', '#therapy');
      $("#schDelivery").removeAttr('href');
      $("#assCommunications").removeAttr('href');
      $("#eduAssessment").removeAttr('href');
      $("#refCallNotes").removeAttr('href');
      $("#allFilesHis").removeAttr('href');
      $("#oncoAssessments").removeAttr('href');

      $("#medTherapy").addClass("active");
      $("#schDelivery").removeClass("active");
      $("#eduAssessment").removeClass("active");
      $("#refCallNotes").removeClass("active");
      $("#allFilesHis").removeClass('active');
      $("#assCommunications").removeClass('active');
      $("#oncoAssessments").removeClass('active');
      $("#archivesAss").removeAttr('href');
      $("#archivesAss").removeClass('active');

    } else if (localStorage.getItem('currTab') === 'education') {
      $("#eduAssessment").attr('href', '#education');
      $("#allFilesHis").removeAttr('href');

      $("#assCommunications").removeAttr('href');
      $("#schDelivery").removeAttr('href');

      $("#medTherapy").removeAttr('href');

      $("#assCommunications").removeClass("active");
      $("#schDelivery").removeClass("active");

      $("#eduAssessment").addClass('active');

      $("#allFilesHis").removeClass("active");

      $("#refCallNotes").removeClass('active');
      $("#medTherapy").removeClass('active');

      $("#oncoAssessments").removeAttr('href');
      $("#oncoAssessments").removeClass('active');
      $("#archivesAss").removeAttr('href');
      $("#archivesAss").removeClass('active');

    } else if (localStorage.getItem('currTab') === 'archives') {
      $("#archivesAss").attr('href', '#archives');

      $("#allFilesHis").removeAttr('href');
      $("#eduAssessment").removeAttr('href');
      $("#assCommunications").removeAttr('href');
      $("#schDelivery").removeAttr('href')
      $("#assCommunications").removeAttr('href');
      $("#medTherapy").removeAttr('href');
      $("#assCommunications").removeAttr("active");

      $("#schDelivery").removeClass("active");
      $("#archivesAss").addClass('active');
      $("#allFilesHis").removeClass("active");
      $("#refCallNotes").removeClass('active');
      $("#medTherapy").removeClass('active');
      $("#eduAssessment").removeClass('active');

      $("#oncoAssessments").removeAttr('href');
      $("#oncoAssessments").removeClass('active');
    } else if (localStorage.getItem('currTab') === 'assessments') {
      $("#oncoAssessments").attr('href', '#assessments');
      $("#oncoAssessments").addClass('active');

      $("#archivesAss").removeAttr('href');
      $("#refCallNotes").removeAttr('href');
      $("#eduAssessment").removeAttr('href');
      $("#schDelivery").removeAttr('href');
      $("#assCommunications").removeAttr('href');
      $("#medTherapy").removeAttr('href');
      $("#allFilesHis").removeAttr('href');

      $("#schDelivery").removeClass("active");
      $("#archivesAss").removeClass('active');
      $("#assCommunications").removeClass("active");
      $("#refCallNotes").removeClass('active');
      $("#medTherapy").removeClass('active');
      $("#eduAssessment").removeClass('active');
      $("#allFilesHis").removeClass('active');

    }
  };

  // end get therapy listing method
  getData = (val) => {
    switch (val) {
      case 'therapy':
        this.therapy.current.getTherapy();
        break;
      case 'education':
        this.EducationAssessment.current.getEducation();
        break;
      case 'assessments':
        this.Assessments.current.getAllAssessmentLst();
        break;
      case 'allFilesHistory':
        this.AllFillHistory.current.getAllFilesHistory();
        break;
      case 'communications':
        this.Communication.current.getAssessComm();
        break;
      case 'deliveries':
        this.Deliveries.current.getDelivery();
        break;
      case 'archives':
        this.Archives.current.getAllArchives();
        break;
      case 'refillCallNotes':
        this.RefillCodeNotes.current.getRefillReminder();
        break;
      default:
        break;
    }

    this.setState({
      activeMenu: false,
    })
  }

  /* Method For Assessment Count After Component Rendering */
  componentDidMount() {
    if (new Date(this.state.data_added) < this.state.currentDate) {
      this.setState({ 'isgotshow': true });
    }

    localStorage.setItem('currTab', 'Del');
    localStorage.setItem('prevTab', 'Del');

    localStorage.removeItem('patAllFillsHisChanged');
    localStorage.removeItem('isPatientAddDelChanged');
    localStorage.removeItem('patRefillCallNotesChanged');

    localStorage.removeItem('patCommunicationsChanged');
    localStorage.removeItem('patTherapyInputChanged');

    localStorage.removeItem('isPatientEduAssChanged');
    localStorage.removeItem('refillReminderData');
    localStorage.removeItem('schDeliveryDataChanges');

    const eduMedErr = [];
    for (var i = 0; i < (this.state.medicationsInputList.length); i++) {
      eduMedErr.push({
        id: "",
        refrigerate: "",
        prescribingMD: "",
        officePhone: "",
      });
    }
    this.setState({ eduMedErrors: eduMedErr });

    axios
      .get(`${API_URL}/api/educational_assessment/?patient_id=` + this.state.patient_id, this.config)
      .then(response => {
        if (response.status === 200) {
          if (response.data.status === 200) {
            if (response.data.data.length > 0) {
              this.setState({
                'activeAddAssessment': true,
                'activeAssPlus': false,
              });


              for (var index = 0; index < response.data.data.length; index++) {
                if (response.data.data[index]['participation_status'] == 1) {
                  response.data.data[index]['showHideOpt'] = false;
                } else {
                  response.data.data[index]['showHideOpt'] = true;
                }

                const arr = [];

                if (response.data.data[index].diagnosis_code != null) {
                  for (var i = 0; i < response.data.data[index].diagnosis_code.length; i++) {
                    arr.push({ label: response.data.data[index].diagnosis_code[i].code_with_short_desc, value: response.data.data[index].diagnosis_code[i].id });
                  }
                }

                this.state.selectedDiagnosisCodes[index] = arr;

                if (response.data.data[index].primary_diagnosis_code != null) {
                  this.state.priSelDiagnosisCodes[index] = [{ label: response.data.data[index].primary_diagnosis_code.code_with_short_desc, value: response.data.data[index].primary_diagnosis_code.id }];
                } else {
                  this.state.priSelDiagnosisCodes[index] = null;
                }
              }

              this.setState({
                eduAssessmentForms: response.data.data,
              });
              let trackAsseData = response.data.data;
              this.setState({
                trackEduAssessmentForms: trackAsseData,
              });

              const eduErrors = [];
              for (var i = 0; i < response.data.data.length; i++) {
                eduErrors.push(this.state.errors);
              }

              this.setState({ eduAssErrors: eduErrors });
            } else {
              this.setState({
                eduAssessmentForms: [],
              });
            }
          } else if (response.data.status_code == 406) {
            localStorage.removeItem('token');
            localStorage.removeItem('user_name');

            localStorage.removeItem('adminCheck');
            localStorage.setItem('tokenExpired', true);

            const tokenStatus = localStorage.getItem('tokenExpired');
            if (tokenStatus) {
              localStorage.removeItem('tokenExpired');
              toast.error(TOKEN_EXPIRED, {
                position: 'top-right',
                autoClose: 2500,
              });
            }

            const isLogged = !!localStorage.getItem('token');
            if (!isLogged) {
              setTimeout(() => {
                this.props.history.push("/login");
              }, 2500);
            }
          }
        } else if (response.data.status === 204) {
          this.setState({
            'activeAddAssessment': false,
            'activeAssPlus': true,
          });
          return response;
        }
      })
      .catch(error => {
        console.log(error);
      });

    axios
      .get(`${API_URL}/api/api-patient/${this.state.patient_id}`, this.config)
      .then(response => {
        if (response.data.status === 200 && response.status === 200) {
          let patientData = { ...this.state.patientProData };
          const {
            patient_first_name,
            patient_last_name,
            gender,
            mrn,
            deceased,
            patient_phone,
            patient_cell,
            patient_height,
            patient_weight,
            dob,
            pregnancy_comments,
            patient_email,
          } = response.data.data;

          this.setState({
            patientProData: {
              patient_name: `${patient_first_name} ${patient_last_name}`,
              gender: gender,
              mrn: mrn,
              deceased: deceased,
              patient_phone: patient_phone,
              patient_phone: patient_phone,
              patient_cell: patient_cell,
              patient_height: patient_height,
              patient_weight: patient_weight,
              dob: dob,
              age: this.calculate_age(dob),
              pregnancy_comments: pregnancy_comments,
              patient_email: patient_email,
            }
          });
        } else if (response.status !== 200) {
          toast.error(VALID_EDUCATION_DATA, {
            position: 'top-right',

            autoClose: 3500,
          });
          return false;
        }
      })
      .catch(error => {
        console.log(error);
      });

    this.getMedication();
  }

  /* Get Medications Listing Common Method*/
  getMedication = () => {
    axios
      .get(`${API_URL}/api/get_therapy_info/?patient_id=${this.state.patient_id}`, this.config)
      .then(response => {
        const arr = [];
        const arrIds = [];

        if (response.status === 200) {
          for (var i = 0; i < response.data.data.length; i++) {
            arr.push({ name: response.data.data[i].name, id: response.data.data[i].trans_med_id });
            arrIds.push(response.data.data[i].trans_med_id);
          }

          this.setState({ medicationsArr: arr });
          this.setState({ medicationIdsArr: arrIds });
        }
      })
      .catch(error => {
        console.log(error);
      });
  }


  /* Method For Calculating Age Of Paatient Dynamically On The Basis Of Date Of Birth */
  calculate_age = (dob) => {
    var today = new Date();
    var birthDate = new Date(dob);  // create a date object directly from `dob1` argument
    var age_now = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age_now--;
    }
    return age_now;
  }

  /* Method For Logout */
  Logout = () => {
    localStorage.removeItem('token');
  };

  /* Handling Patient Profile Data */
  handlePatientDataChanges = (event) => {
    let list = { ...this.state.patientProData };
    const { name, value } = event.target;
    list[name] = value;
    this.setState({
      patientProData: list,
    });
    if (name == 'dob') {
      list['age'] = this.calculate_age(value)
    }
    if (name === "patient_phone" || name === 'patient_cell'
      || name === 'patient_height' || name === 'patient_weight') {
      list[name] = value.replace(/\D/g, "");
    }
  };

  // Handle Input Change Of Communication
  handleCommInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...this.state.commInputList];
    list[index][name] = value;
    this.setState({ commInputList: list, });

    for (var i = 0; i < list.length; i++) {
      if ((this.state.trackCommInputList[i].typeOfCom !== list[i]['typeOfCom']) || (this.state.trackCommInputList[i].comStatus !== list[i]['comStatus'])
        || (this.state.trackCommInputList[i].comNotes !== list[i]['comNotes']) || (this.state.trackCommInputList[i].comFollowUpDate !== list[i]['comFollowUpDate'])) {
        localStorage.setItem('patCommunicationsChanged', true);
        break;
      } else {
        localStorage.removeItem('patCommunicationsChanged');
      }
    }
  };

  //Handle Click event Of The Remove Button Of Communication
  handleCommRemoveClick = index => {
    const list = [...this.state.commInputList];
    list.splice(index, 1);
    this.setState({
      commInputList: list,
    });
  };

  /* Method For Handling File Upload For Hipaa Form */
  plusMinusToggle = event => {
    if (event === 'plus') {
      this.setState({ 'activePlus': true });
      this.setState({ 'activeMinus': false });
    } else {
      this.setState({ 'activeMinus': true });
      this.setState({ 'activePlus': false });
    }
  }

  render() {
    const isLogged = !!localStorage.getItem('token');
    if (!isLogged) {
      return <Redirect to="/login" />;
    }
    let mbdDatas = {
      columns: this.state.columns,
      rows: this.state.rowData
    }

    const { errors } = this.state;
    return (
      <>
        <ToastContainer />
        <CommonHeader />
        <section id="wrapper">
          <CommonSidebar />
          <div className="queue-block patientPageContainer">
            <form>
              <Accordion>
                <Card>
                  <Card.Header>
                    <div className="row align-items-center p-3">
                      <div className="col-lg-5 mb-lg-0">
                        <h3> Patient Clinical Profile | <span className="PatientName">{this.state.patient_name} </span> </h3>
                      </div>
                      <div className="col-lg-7">
                        <div className="form-groupp row patientHeaderButton">
                          {/* Button(CLINICAL PROFILE) click after then using button(Refill Reminder Call,Goals Of Therapy / Intervention ),Schedule Delivery  */}
                          <Link to={{
                            'pathname': '/refillremindercall', 'state': {
                              'transaction_id': this.state.transaction_id,
                              'patient_id': this.state.patient_id, 'patient_name': this.state.patientProData.patient_name,'data_added': this.state.data_added
                            }
                          }}>
                            <Button className="btn btn-primary">Refill Reminder Call</Button>
                          </Link>

                          <Link to={{
                            'pathname': '/logintervention', 'state': {
                              'transaction_id': this.state.transaction_id,
                              'patient_id': this.state.patient_id, 'patient_name': this.state.patientProData.patient_name,'data_added': this.state.data_added
                            }
                          }}>
                            <Button className="btn btn-primary"> Goals Of Therapy / Intervention </Button>
                          </Link>
                          <Link to={{
                            'pathname': '/scheduledelivery', 'state': {
                              'transaction_id': this.state.transaction_id,
                              'patient_id': this.state.patient_id, 'patient_name': this.state.patientProData.patient_name,'data_added': this.state.data_added
                            }
                          }}>
                            <Button className="btn btn-primary"> Schedule Delivery </Button>
                          </Link>
                          <Accordion.Toggle hidden={this.state.activePlus} as={Button} onClick={() => this.plusMinusToggle('plus')} eventKey="0">
                            <PlusIcon className="float-right !important" />
                          </Accordion.Toggle>
                          <Accordion.Toggle hidden={this.state.activeMinus} as={Button} onClick={() => this.plusMinusToggle('minus')} eventKey="0">
                            <RemoveIcon as={Button} className="float-right !important" />
                          </Accordion.Toggle>

                        </div>
                      </div>
                    </div>
                  </Card.Header>
                  <Accordion.Collapse eventKey="0">
                    <Card.Body>
                      {/* Start the (+) button  */}
                      <form>
                        <PatientDemographics patient_id={this.state.patient_id} patientProData={this.state.patientProData} handlePatientDataChanges={this.handlePatientDataChanges}
                        />
                      </form>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>
              {/* starting Therapy(button) */}
              <div className="tabsCustom">
                <ul className="nav nav-pills" role="tablist">
                  <li className="nav-item">
                    <a className="nav-link" data-toggle="pill" ref="therapy" id="medTherapy"
                      onClick={() => this.getData("therapy")}>
                      Therapy
                    </a>
                  </li>
                  {/* Button of Education */}
                  <li className={this.state.activeMenu ? "nav-item active" : "nav-item"}>
                    <a className="nav-link" data-toggle="pill" ref="education" id="eduAssessment"
                      onClick={() => this.getData("education")}>
                      Education
                    </a>
                  </li>
                  {/* Button of assessment */}
                  <li className="nav-item" disabled>
                    <a className="nav-link" data-toggle="pill" ref="assessments" id="oncoAssessments" 
                      onClick={() => this.getData("assessments")}>
                      Assessments
                    </a>
                  </li>
                  {/* Button of Communications*/}
                  <li className="nav-item">
                    <a className="nav-link" data-toggle="pill" ref="communications" id="assCommunications"
                      onClick={() => this.getData("communications")}>
                      Communications
                    </a>
                  </li>
                  {/* Button of Refill call notes*/}
                  <li className="nav-item">
                    <a className="nav-link" data-toggle="pill" ref="refillCallNotes" id="refCallNotes"
                      onClick={() => this.getData("refillCallNotes")}>
                      Refill Call Notes
                    </a>
                  </li>
                  {/* Button of Deliveries*/}
                  <li className="nav-item">
                    <a className="nav-link" data-toggle="pill" ref="deliveries" id="schDelivery"
                      onClick={() => this.getData("deliveries")}>
                      Deliveries
                    </a>
                  </li>
                  {/* Button of All fills History*/}
                  <li className="nav-item">
                    <a className="nav-link" data-toggle="pill" ref="allFilesHistory" id="allFilesHis"
                      onClick={() => this.getData("allFilesHistory")}>
                      All Fills History
                    </a>
                  </li>
                  {/* Button of Achives*/}
                  <li className="nav-item">
                    <a className="nav-link" data-toggle="pill" ref="archives" id="archivesAss"
                      onClick={() => this.getData("archives")}>Archives</a>
                  </li>
                </ul>
                {/* starting the Therapy  */}
                <div className="tab-content">
                  <Therapy ref={this.therapy} patient_id={this.state.patient_id} setTab={this.setTab} />
                  <EducationAssessment ref={this.EducationAssessment} patient_id={this.state.patient_id} patient_name={this.state.patient_name} assessment_id={this.state.assessment_id} transaction_id={this.state.transaction_id} data_added={this.state.data_added} setTab={this.setTab} />
                  <Assessments ref={this.Assessments} patient_id={this.state.patient_id} setTab={this.setTab} />
                  <Communication ref={this.Communication} patient_id={this.state.patient_id} setTab={this.setTab} />
                  <RefillCodeNotes ref={this.RefillCodeNotes} patient_id={this.state.patient_id} setTab={this.setTab} />
                  <Deliveries ref={this.Deliveries} patient_id={this.state.patient_id} setTab={this.setTab} />
                  <AllFillHistory ref={this.AllFillHistory} patient_id={this.state.patient_id} setTab={this.setTab} />
                  <Archives ref={this.Archives} patient_id={this.state.patient_id} setTab={this.setTab} />
                </div>
              </div>
            </form>
          </div>
        </section>
        <CommonFooter />
      </>
    );
  }
}