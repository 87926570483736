/*
 * HomePage
 *
 * This is the first thing users see of our App, at the '/' route
 */

import React from 'react';

import CommonSidebar from '../../components/CommonSidebar';
import CommonHeader from '../../components/CommonHeader';
import CommonFooter from '../../components/CommonFooter';

import { Redirect, Link } from 'react-router-dom';

import '../../css/style_semantic.css';
import './style.css';

import taskMemo from '../../images/memo.png';
import favicon from '../../images/favicon.ico';
import healthCare from '../../images/health-care.png';
import settings from '../../images/settings.png';
import event from '../../images/event.png';


const OutComes = () => {
	
	const isLogged = !!localStorage.getItem('token');
    if (!isLogged) {
      return <Redirect to="/login" />;
    }
	
	return (
		<>
		  <CommonHeader />
		  <section id="wrapper">
			<CommonSidebar />
			<div className="headinmain">
			  <img src={favicon} alt="" />
			  <div>
			    <h2>
                 Outcomes Dashboard
                </h2>
			  </div>
			</div>
			<div className="ui grid row stackable ">
			  <div className="four wide column">
				<Link to="/outcomesreport">
				  <div className="task-block">
					<img src={taskMemo} alt="" />
					<p>Total Adverse Events</p>
				  </div>
				</Link>
			  </div>
			  <div className="four wide column">
				<Link to="/adverseevents">
					<div className="task-block">
					<img src={healthCare} alt="" />
					<p> Adverse Events at 7 Days </p>
					</div>
				</Link>
			  </div>
			  <div className="four wide column">
			  	<Link to="/advinitialkarnofsky">
					<div className="task-block">
					<img src={settings} alt="" />
					<p> Average Initial Karnofsky Score</p>
					</div>
				</Link>
			  </div>
			  <div className="four wide column">
			  	<Link to="/durrates">
					<div className="task-block">
					<img src={event} alt="" />
					<p>DUR Rates</p>
					</div>
				</Link>
			  </div>
			</div>
		  </section>
		  <CommonFooter />
		</>
	);
}

export default OutComes;