/*
 * HomeConstants
 * Each action has a corresponding type, which the reducer knows and picks up on.
 * To avoid weird typos between the reducer and the actions, we save them as
 * constants here. We prefix them with 'yourproject/YourComponent' so we avoid
 * reducers accidentally picking up actions they shouldn't.
 *
 * Follow this format:
 * export const YOUR_ACTION_CONSTANT = 'yourproject/YourContainer/YOUR_ACTION_CONSTANT';
 */

export const GET_TASK_LIST = 'GET_TASK_LIST';
export const INTERNAL_SERVER_ERROR = 'Something went wrong with educational details.';


export const VALID_EDUCATION_DATA = 'Something went wrong !.';

export const SUCCESS_EDUCATION_DATA = 'Education data inserted successfully.';
export const UPDATE_EDUCATION_DATA = 'Education data updated successfully.';


export const VALIDATE_EDU_FORM = 'Please fill the forms properly. Some of the fields are missing.';
export const API_URL = 'http://pioneer.d2api.local:8001';
export const SERVER_INTERNAL_ERROR = 'Something went wrong while saving !.';
export const DATA_UPDATED = 'Successfully Updated.';
export const ASSESSMENT_ACTIVE = 'Please check the assessment active.';


export const TOKEN_EXPIRED = 'Please login again. Your token has expired.';
export const ALL_FIELDS_REQUIRED = 'All required fields must be completed before marking an assessment Completed.';
export const MEDICATION_MISSING = 'Minimum 1 medication required';