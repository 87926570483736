/*
 * HomeConstants
 * Each action has a corresponding type, which the reducer knows and picks up on.
 * To avoid weird typos between the reducer and the actions, we save them as
 * constants here. We prefix them with 'yourproject/YourComponent' so we avoid
 * reducers accidentally picking up actions they shouldn't.
 *
 * Follow this format:
 * export const YOUR_ACTION_CONSTANT = 'yourproject/YourContainer/YOUR_ACTION_CONSTANT';
 */

export const VALID_RX_MED_DRUG = 'Please enter drug for this.';
export const VALID_RX_MED_NOTES = 'Please enter notes for this.';

export const VALID_RX_OTC_TYPE = 'Please enter type of medications.';
export const SUCCESS_RX_OTC_MED = 'Records created successfully.';

export const UPDATE_RX_OTC_DATA = 'Records updated successfully.';
export const DELETE_CURR_RX_OTC_DATA = 'Records deleted successfully.';

export const TOKEN_EXPIRED = 'Please login again. Your token has expired.';
export const INTERNAL_SERVER_ERROR = 'Something went wrong.';