import React from 'react';

import 'bootstrap/dist/css/bootstrap.css';
import './style.css';
import '../css/style_semantic.css';

import { ToastContainer, toast } from 'react-toastify';

import {
  EMAIL_VALID_ERROR,
  VALID_EMAIL_ERROR,
  RESET_LINK_SENT,
  INTERNAL_FAILURE
} from './constants';
import {
  API_URL,
} from '../../config';
import logo from '../../images/logo.png';
import ValidateEmail from './ValidateEmail';
import loginBackground from '../../images/login_page_image.png';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';



export default class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = { username: '' };
  }
  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  
  handleSubmit = (event) => {
    event.preventDefault();
    // const { onUserLogin } = this.props;
    if (this.state.username === null || this.state.username.length === 0) {
      toast.error(EMAIL_VALID_ERROR, {
        position: 'top-right',
        autoClose: 5000,
      });
    } else if (ValidateEmail(this.state.username) === false) {
      toast.error(VALID_EMAIL_ERROR, {
        position: 'top-right',
        autoClose: 5000,
      });
    } else {
      let emaildata = {'user_email': this.state.username}
      axios.post(`${API_URL}/dashboard/password_reset_init/`, emaildata)
      .then(resp => {
        if (resp.status === 200){
          if (resp.data.status === 200) {
            toast.success(RESET_LINK_SENT, {
              position: 'top-right',
              autoClose: 5000,
            });
          }
          else if (resp.data.status === 500){
            toast.error(INTERNAL_FAILURE, {
              position: 'top-right',
              autoClose: 3500,
            });
            return false;
          }
          else if (resp.data.status !== 200){
            toast.error(resp.data.message, {
              position: 'top-right',
              autoClose: 3500,
            });
            return false;
          }
      }
      })
      .catch(err => {
        console.warn('err', err)
      })
    }
  };

  render() {
    const { username } = this.state;
    const forgotStyle = { color: '#144E6C' };
    return (
      <React.Fragment>
        <ToastContainer />
        <div className="login-bg-fix">
          <div className="ui grid  stackable grid">
            <div className="ten wide column">
              <div className="background-img">
                <img src={loginBackground} alt="Background" className="ui wireframe image" />
              </div>
            </div>
            <div className="five wide column ipad-fix tab only">
              <div className="login-inner-form">
                <div className="logo-box">
                  <img src={logo} alt="Login Logo" />
                </div>
                <h3 style={forgotStyle}>Forgot Password</h3>
                <form onSubmit={this.handleSubmit}>
                  <div className="input-field">
                    <input
                      id="username"
                      name="username"
                      type="text"
                      className="form-control"
                      placeholder="Email"
                      value={username}
                      onChange={this.handleChange}
                    />
                  </div>
                  <input
                    className="btn btn-block login-btn"
                    type="submit"
                    value="Send Password Reset Email"
                  />
                </form>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

