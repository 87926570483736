
export const VALIDATE_EDU_FORM = 'Please fill the forms properly. Some of the fields are missing.';
export const SERVER_INTERNAL_ERROR = 'Something went wrong while saving !.';
export const DATA_UPDATED = 'Successfully Updated.';
export const ASSESSMENT_ACTIVE = 'Please check the assessment active.';
export const TOKEN_EXPIRED = 'Please login again. Your token has expired.';
export const VALID_EDUCATION_DATA = 'Something went wrong !.';
export const VALID_THERAPY_START_DATE = 'Please enter therapy start date for this.';
export const VALID_THERAPY_QTY = 'Please enter therapy quantity for this.';
export const VALID_THERAPY_DAYS = 'Please enter therapy days for this.';
