/*
 * LoginPage
 *
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, Link } from 'react-router-dom';

import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';

import { VALIDATE_USERNAME, VALIDATE_PASSWORD, ERROR_LOGIN, SERVER_LOGIN_ERROR, } from './constants';
import { API_URL,} from '../../config';

import logo from '../../images/logo.png';
import spinner from '../../images/Spinner-1s-200px.gif';

import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.css';

import '../../css/style_semantic.css';
import './style.css';

export default class LoginPage extends React.Component {
  constructor(props) {
    super(props);
    this.userName = '';
    this.password = '';
    
    this.state = {
      username: this.userName,
      password: this.password,
      spinnerLoader: true,
    };
  }

  handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    const finalValue = type === 'checkbox' ? checked : value;
    this.setState({
      [name]: finalValue,
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const { username, password } = this.state;
    if (username === null || username === undefined || username === '') {
      toast.error(VALIDATE_USERNAME, {
        position: 'top-right',
        autoClose: 2000,
      });
      return;
    } else if (password === null || password === undefined || password === '') {
      toast.error(VALIDATE_PASSWORD, {
        position: 'top-right',
        autoClose: 2000,
      });
      return;
    } else {
      const data = {
        'username': username,
        'password': password
      };

    return axios.post(`${API_URL}/dashboard/login/`, data)
      .then((response) => {
        const httpStatus = response.data.status;
        if (httpStatus === 200) {
          localStorage.setItem('token', response.data.token);
          localStorage.setItem('user_name', response.data.user_name);
          localStorage.setItem('adminCheck', response.data.is_super_admin);
          this.props.history.push("/");
        } else if(response.data.status === 404) {
          toast.error(ERROR_LOGIN, {
            position: 'top-right',
            autoClose: 3000,
          });
          return response;
        } else if((response.status !== 404) || (response.status !== 200)) {
          toast.error(SERVER_LOGIN_ERROR, {
            position: 'top-right',
            autoClose: 5000,
          });
          return response;
        }
      })
      .catch((error) => {
        toast.error("Invalid Credentials!");
      });
    }
  };

  componentDidMount() {
    this.setState({ spinnerLoader: false, });
    setTimeout(() => this.spinnerLoader(), 5000);
  };

  spinnerLoader() {
    axios.get(`${API_URL}/dashboard/default_admin_users/`)
      .then(resp => {
        if (resp.data.status == 200) {
          this.setState({ spinnerLoader: true, });
        } else if (resp.data.status == 201) {
          this.setState({ spinnerLoader: true, });
          toast.success(resp.data.message, {
            position: 'top-right',
            autoClose: 3000,
          });
        }
      })
      .catch(err=> console.log(err));
  };

  render() {
    const isLoggedIn =
      localStorage.getItem('token') &&
      localStorage.getItem('token') !== 'undefined'
        ? true
        : false;

    if (isLoggedIn) {
      return <Redirect to="/" />;
    }
    const { username, password } = this.state;
    const forgotStyle = { color: '#45B2EF' };
    return (
      <>
        <ToastContainer />
        <div hidden={this.state.spinnerLoader} class="loader-bg">
          <div class="loader-p">
            <img src={spinner} alt="Initialising" />
            <p>Initializing...</p>
          </div>
        </div>
        <div className="login-bg-fix">
          <div className="ui grid  stackable grid">
            <div className="ten wide column">
              <div className="background-img">
                {/* <img src={loginBackground} alt="Background" className="ui wireframe image" /> */}
              </div>
            </div>
            <div className="five wide column ipad-fix tab only">
              <div className="login-inner-form">
                <div className="logo-box">
                  <img src={logo} alt="Login Logo" />
                </div>
                <form onSubmit={this.handleSubmit}>
                  <div className="inner-box-login">
                    <h3 style={forgotStyle} className="login-heading">
                      Login
                    </h3>
                    <div className="inner-form-design">
                      <div className="ui form">
                        <div className="field">
                          <label htmlFor="Username">
                            <span className="fa fa-user"> </span>
                            Username
                          </label>
                          <input
                            type="text"
                            id="username"
                            name="username"
                            className="form-control"
                            placeholder="Username"
                            value={username}
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>

                      <div className="ui form">
                        <div className="field">
                          <label htmlFor="Password">
                            <span className="fa fa-lock"> </span>
                            Password
                          </label>
                          <input
                            type="password" 
                            name="password"
                            id="password"
                            className="form-control"
                            placeholder="Password"
                            value={password}
                            onChange={this.handleChange}
                            autoComplete="on"
                          />
                        </div>
                      </div>
                      <button
                        type="submit"
                        className="ui blue button login-btn medium">Login
                        <span className="fa fa-arrow-right icon-right"> </span>
                      </button>
                    </div>
                    <div className="row">
                    <p className="col-md-12 text-right mt-2">
                      <Link
                        to="/forgot-password"
                        className="forget-link"
                        style={forgotStyle}>
                        Forgot Password?
                      </Link>
                    </p>
                    </div>
                  </div>
                </form>
                
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

LoginPage.propTypes = {
  onUserLogin: PropTypes.func,
};
