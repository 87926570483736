import React from 'react';
import axios from 'axios';
import { Redirect, Link } from 'react-router-dom';
import { Accordion, Card, Button, Modal } from 'react-bootstrap';
import dateFormat from 'dateformat';
import { ToastContainer, toast } from 'react-toastify';
import { API_URL, } from '../../config';
import Moment from 'moment';
import { confirmAlert } from 'react-confirm-alert';
import { blockInvalidChar } from "./utils";

import {
  OncoError,
  OncoInitialCondition,
  VALID_DATA,
  SUCCESS_DATA,
  UPDATE_DATA,
} from './constants';

import 'react-toastify/dist/ReactToastify.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'bootstrap/dist/css/bootstrap.css';
import '../../containers/css/style_semantic.css';
import PlusIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import './style.css';
import 'react-confirm-alert/src/react-confirm-alert.css';
import DeleteIcon from '@material-ui/icons/Delete';
import IconWithTooltip from 'icon-with-tooltip';

export default class AssessmentLst extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      patient_id: this.props !== undefined ? this.props.patient_id : null,
      // Onco States
      onco_configuration: true, // Get Onco Configuration from backend to show button or not 
      assessment_id: '', //Set Assessment ID of Education Assessment which is complete and Active
      active_medications: [],
      show_goaltherapy: false,
      activeOncoAddAssessment: true,
      activeOncoAssPlus: false,
      activeOncoAssMinus: true,
      assessmentlst: [],
      updatedassessmentlst: [],
      is_draft: true,
      completed_on: '',
      completed_by: '',
    }
  }
  
  // get token from localstorage
  token = localStorage.getItem('token');
  config = {
    headers: { Authorization: `Bearer ${this.token}` },
  };

  componentDidMount() {
    this.getOncologyAssessment();
    this.getactiveMed();
  }

  //get oncology assessment list
  getOncologyAssessment = () => {
    axios
      .get(`${API_URL}/api/oncology_assessment/?patient_id=` + this.state.patient_id, this.config)
      .then(response => {
        if (response.status === 200) {
          if (response.data.data !== null && response.data.data.length > 0) {
            this.setState({ assessmentlst: JSON.parse(JSON.stringify(response.data.data)) });          }
        }
        if(response.data.status === 204){
          this.setState({ assessmentlst: [] });
        }
      })
      .catch(err => console.log(err))
  }
  /* Toggle for Onco Assessment */
  plusMinusOncoAssessmentToggle = event => {
    if (event === 'plus') {
      if (this.state.active_medications.length == 0 || this.state.assessment_id == '') {
        toast.error(OncoInitialCondition, {
          position: 'top-right',
          autoClose: 3500,
        });
      }
      else {
        this.setState({ 'activeOncoAssPlus': true });
        this.setState({ 'activeOncoAddAssessment': false });
        this.setState({ 'activeOncoAssMinus': false });
      }
    } else {
      this.setState({ 'activeOncoAssPlus': false });
      this.setState({ 'activeOncoAddAssessment': true });
      this.setState({ 'activeOncoAssMinus': true });
    }
  };

  /* Close Modal Pop Up Of Goal Therapy in Onco Assessment */
  handle_goal_Close = () => {
    this.setState({ show_goaltherapy: false });
  };

  // Methods For Handling Oncology Change And Validations Events For Education Form
  handleOncolstFieldsChanges = (event, index) => {
    //const value = event.target.value;

    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
    const name = event.target.name;

    let list = [...this.state.assessmentlst];
    if (name == 'goal_therapy_established' && value == 1) {
      this.setState({ 'show_goaltherapy': true });
    }

    if (name === 'is_assessment_completed' && value === true) {
      list[index]['is_draft'] = false;
      list[index]['completed_on'] = dateFormat(new Date().toISOString().slice(0, 10), "mm/dd/yyyy");
    } else if (name === 'is_assessment_completed' && value === false) {
      list[index]['is_draft'] = true;
      list[index]['completed_on'] = '';
    }

    list[index][name] = value;
    this.setState({
      assessmentlst: list,
    });
    localStorage.setItem('isAssChanged', true);
  };

  // Edit Onco Form
  updateEduFormSubmit(event, index, AssessmentId) {

    event.preventDefault();
    let list = { ...this.state.assessmentlst };

    // Months length & Default Value
    if (list[index].therapy_before === "2" && list[index].months.length > 3){
      toast.error('Number entered for Months field exceeds the limit', {
        position: 'top-right',
        autoClose: 2500,
      });
      return false;
    }
    if (list[index].therapy_before === "1"){
      list[index].months = 0
    }

    // Dose rounding condition
    if (list[index].is_assessment_completed === true) {
      if (list[index].dose_rounding === "" || (list[index].dose_rounding === "2" && list[index].dose_rounding_comment === '')) {
        toast.error(OncoError, {
          position: 'top-right',
          autoClose: 2500,
        });
        return false;
      }
      // Pain Medication condition
      if (list[index].pain_medication === "" || (list[index].pain_medication === "2" || list[index].pain_medication === "1") && list[index].pain_medication_comment === '') {
        toast.error(OncoError, {
          position: 'top-right',
          autoClose: 2500,
        });
        return false;
      }
      // Nausea condition
      if (list[index].nausea_medication === "" || ((list[index].nausea_medication === "2" || list[index].nausea_medication === "1") && list[index].nausea_medication_comment === '')) {
        toast.error(OncoError, {
          position: 'top-right',
          autoClose: 2500,
        });
        return false;
      }
      // Therapy before condition
      if (list[index].therapy_before === ""){
        toast.error(OncoError, {
          position: 'top-right',
          autoClose: 2500,
        });
        return false;
      }
      // Allergies condition
      if (list[index].allergies === "" || (list[index].allergies === "2" && list[index].allergies_comments === '')) {
        toast.error(OncoError, {
          position: 'top-right',
          autoClose: 2500,
        });
        return false;
      }
      // Current symptoms condition
      if (list[index].current_symptoms == '') {
        toast.error(OncoError, {
          position: 'top-right',
          autoClose: 2500,
        });
        return false;
      }
      // Care plan
      if (list[index].care_plan == "" || (list[index].care_plan == "2" && list[index].understand_plan_care == "") || (list[index].care_plan == "2" && list[index].care_plan_comments == '')) {
        toast.error(OncoError, {
          position: 'top-right',
          autoClose: 2500,
        });
        return false;
      }
      // Goal Therapy
      if (list[index].goal_therapy_established == "") {
        toast.error(OncoError, {
          position: 'top-right',
          autoClose: 2500,
        });
        return false;
      }
    }
    let createOncoFormData = {
      patient: this.state.patient_id,
      assessment_id: this.state.assessment_id,
      // medication: this.state.active_medications,
      id: AssessmentId,
      dose_rounding: Number(list[index].dose_rounding),
      dose_rounding_comment: list[index].dose_rounding_comment,
      pain_medication: list[index].pain_medication,
      pain_medication_comment: list[index].pain_medication_comment,
      nausea_medication: Number(list[index].nausea_medication),
      nausea_medication_comment: list[index].nausea_medication_comment,
      therapy_before: Number(list[index].therapy_before),
      therapy_before_comment: list[index].therapy_before_comment,
      months: Number(list[index].months),
      allergies: Number(list[index].allergies),
      allergies_comments: list[index].allergies_comments,
      current_symptoms: list[index].current_symptoms,
      care_plan: Number(list[index].care_plan),
      care_plan_comments: list[index].care_plan_comments,
      understand_plan_care: Number(list[index].understand_plan_care),
      goal_therapy_established: Number(list[index].goal_therapy_established),
      additional_comments: list[index].additional_comments,
      is_assessment_completed: Boolean(list[index].is_assessment_completed),
      is_draft: Boolean(list[index].is_draft)
    }
    axios.put(`${API_URL}/api/oncology_assessment/` + AssessmentId, createOncoFormData, this.config)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.status === 400) {
            toast.error(VALID_DATA, {
              position: 'top-right',
              autoClose: 3000,
            });
          }
          else {
            toast.success(UPDATE_DATA, {
              position: 'top-right',
              autoClose: 3000,
            });
            localStorage.removeItem('isAssChanged');
            this.getOncologyAssessment();
            this.setState({ createOncoFormData: [] });
          }
        }
      })
      .catch((error) => {
        toast.error(VALID_DATA, {
          position: 'top-right',
          autoClose: 3500,
        });
        return false;
      });
  }

  delAssessment = (id, index, draft) => {
    confirmAlert({
      title: 'Confirmation',
      message: 'Are you sure you want to delete Assessment ' + (index + 1) + ((draft === true) ? ' (Draft) ' : '') + '?',
      buttons: [
        {
          label: 'Ok',
          onClick: () => this.deleletAssessment(id)
        },
        {
          label: 'Cancel',
          onClick: () => ''
        }
      ]
    });
  };

  deleletAssessment = id => {
    axios
      .delete(`${API_URL}/api/oncology_assessment/${id}`, this.config)
      .then(response => {
        if (response.status == 200) {
          toast.success('Assessment deleted successfully.', {
            position: 'top-right',
            autoClose: 3500,
          });
          this.getOncologyAssessment();
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  // Get active Medication 
  getactiveMed = () => {
    axios
      .get(`${API_URL}/api/educational_assessment/?patient_id=` + this.state.patient_id, this.config)
      .then(response => {
        if (response.status === 200) {
          if (response.data.status === 200) {
            if (response.data.data.length > 0) {
              // Get medication of Active Education Assessment
              const active_medications = [];
              for (var index = 0; index < response.data.data.length; index++) {
                if (response.data.data[index]['is_active'] == true && response.data.data[index]['is_completed'] && response.data.data[index]['medications'] != null) {
                  this.setState({
                    assessment_id: response.data.data[index]['id'],
                  });
                  for (var i = 0; i < response.data.data[index].medications.length; i++) {
                    if (i == Number(response.data.data[index].medications.length) - 1) {
                      active_medications.push(response.data.data[index].medications[i].medication_name + " ");
                    }
                    else {
                      active_medications.push(response.data.data[index].medications[i].medication_name + ", ");
                    }
                  }
                }
                this.setState({
                  active_medications: active_medications,
                });
              }
            }
          }
        }
      })
      .catch(error => {
        console.log(error);
      });
  }

  render() {
    const isLogged = !!localStorage.getItem('token');
    if (!isLogged) {
      return <Redirect to="/login" />;
    }
    return (
      <>
        {this.state.assessmentlst.length > 0 && this.state.assessmentlst.map((x, i) => {
          return (
            <div>
              <Accordion>
                <Card>
                  <Card.Header className="align-items-center d-flex justify-content-between text-white">
                    {/* <span className="px-2"> Oncology Initial Assessment </span> */}
                    <span className="px-2"> Oncology Assessment {i + 1}
                      {(this.state.assessmentlst[i].is_draft === true) ? ' (Draft) ' : ''} | &nbsp;
                      <b> Created Date :</b> &nbsp;
                      {Moment(this.state.assessmentlst[i].created_on).format('MM/DD/YYYY')} {Moment(this.state.assessmentlst[i].created_on).format('HH:mm')}
                      &nbsp; <b> | Created By :</b> {this.state.assessmentlst[i].created_by_user}
                    </span>
                    <div ClassName="float-right" style={{ marginRight: '10px' }}>
                      <Accordion.Toggle as={Button} variant="link" eventKey="0" className="">
                        <i class="fa fa-arrow-circle-down text-white" aria-hidden="true"></i>
                      </Accordion.Toggle>
                      {this.state.assessmentlst[i].is_draft === true ? (
                        <button type="button" className="btn btn-primary btnControl btndel"
                          onClick={() => this.delAssessment(this.state.assessmentlst[i].id, i, this.state.assessmentlst[i].is_draft)}>
                          <IconWithTooltip
                            Icon={DeleteIcon}
                            text="Delete"
                          />
                        </button>) : null
                      }
                    </div>
                  </Card.Header>
                  <Accordion.Collapse eventKey="0">
                    <Card.Body>
                      <form>
                        <div class="onco">
                          <div class="row mt-4">
                            <div class="col-md-12 mb-3">
                              <div class="mb-2">
                                <div class="custom-control custom-checkbox pt-2">
                                  {this.state.assessmentlst[i].medications.map((med,index) => {
                                    if(index == this.state.assessmentlst[i].medications.length-1){
                                      return <label class="font-weight-bold" id="medication_label" for="">{med.medication_name}<span>&nbsp;</span></label>
                                    }
                                    else{
                                      return <label class="font-weight-bold" id="medication_label" for="">{med.medication_name}<span>&nbsp;|&nbsp;</span></label>
                                    }
                                  })} 
                                </div>
                              </div>
                            </div>
                            <div class="col-md-5 mb-3">
                              <div class="mb-2">
                                <div class="custom-control custom-checkbox pt-2">
                                  <label class="font-weight-normal" for="">
                                    Will dose rounding be used for this therapy? <span className='error'>*</span>
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-2 mb-3">
                              <select value={this.state.assessmentlst[i].dose_rounding} onChange={(e) => this.handleOncolstFieldsChanges(e, i)}
                                id="doserounding" name="dose_rounding" class="form-control">
                                <option value="">Please Choose</option>
                                <option value="2">Yes</option>
                                <option value="1">No</option>
                              </select>
                            </div>
                            <div class="col-md-5 text-right mb-2" >
                              {this.state.assessmentlst[i].dose_rounding === '2' ? (
                                <input type="text" value={this.state.assessmentlst[i].dose_rounding_comment} onChange={(e) => this.handleOncolstFieldsChanges(e, i)}
                                  id="doseroundingComments" name="dose_rounding_comment" class="form-control" placeholder="Add your comments" />
                              ) : null
                              }
                            </div>
                            <div class="col-md-5  mb-2">
                              <div class="mb-3">
                                <div class="custom-control custom-checkbox pt-2">
                                  <label class="font-weight-normal" for="">
                                    Is the patient taking any pain medications?<span className='error'>*</span>
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-2 mb-3">
                              <select value={this.state.assessmentlst[i].pain_medication} onChange={(e) => this.handleOncolstFieldsChanges(e, i)}
                                id="pain_medication" name="pain_medication" class="form-control">
                                <option value="">Please Choose</option>
                                <option value="1">Scheduled</option>
                                <option value="2">PRN</option>
                                <option value="3">None</option>
                              </select>
                            </div>
                            <div class="col-md-5 text-right  mb-2">
                              {(this.state.assessmentlst[i].pain_medication === '2' || this.state.assessmentlst[i].pain_medication === '1') ? (
                                <input type="text" value={this.state.assessmentlst[i].pain_medication_comment} id="pain_medication_comment"
                                  name="pain_medication_comment" onChange={(e) => this.handleOncolstFieldsChanges(e, i)} class="form-control" placeholder="Add your comments" />
                              ) : null}
                            </div>
                            <div class="col-md-5  mb-2">
                              <div class="mb-3">
                                <div class="custom-control custom-checkbox pt-2">
                                  <label class="font-weight-normal" for="">
                                    Is the patient taking any nausea medications?<span className='error'>*</span>
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-2 mb-3">
                              <select value={this.state.assessmentlst[i].nausea_medication} onChange={(e) => this.handleOncolstFieldsChanges(e, i)}
                                id="nausea_medication" name="nausea_medication" class="form-control">
                                <option value="">Please Choose</option>
                                <option value="1">Scheduled</option>
                                <option value="2">PRN</option>
                                <option value="3">None</option>
                              </select>
                            </div>
                            <div class="col-md-5 text-right mb-2">
                              {(this.state.assessmentlst[i].nausea_medication === '2' || this.state.assessmentlst[i].nausea_medication === '1') ? (
                                <input type="text" value={this.state.assessmentlst[i].nausea_medication_comment} onChange={(e) => this.handleOncolstFieldsChanges(e, i)}
                                  id="nausea_medication_comment" name="nausea_medication_comment" class="form-control" placeholder="Add your comments" />
                              ) : null}
                            </div>

                            <div class="col-md-5 mb-2">
                              <div class="mb-3">
                                <div class="custom-control custom-checkbox pt-2">
                                  <label class="font-weight-normal" for="">
                                    Has the patient been on this therapy before?<span className='error'>*</span>
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-2 mb-3">
                              <select value={this.state.assessmentlst[i].therapy_before} onChange={(e) => this.handleOncolstFieldsChanges(e, i)} name="therapy_before"
                                id="therapy_before" class="form-control">
                                <option value="">Please Choose</option>
                                <option value="1">Naive</option>
                                <option value="2">Experienced</option>
                              </select>
                            </div>
                            <div class="col-md-5 mb-2"></div>
                            <div class="col-md-5 mb-2">
                              {this.state.assessmentlst[i].therapy_before === '2' ? (
                                <div class="mb-3 ml-4">
                                  <div class="custom-control custom-checkbox pt-2">
                                    <label class="font-weight-normal" for="">
                                      How long in months has the patient been on this therapy?<span className='error'>*</span>
                                    </label>
                                  </div>
                                </div>) : null}
                            </div>
                            <div class="col-md-3 text-right mb-2">
                              {this.state.assessmentlst[i].therapy_before === '2' ? (
                                <input type="number" min="0" value={this.state.assessmentlst[i].months} onKeyDown={blockInvalidChar} onChange={(e) => this.handleOncolstFieldsChanges(e, i)}
                                  id="months" name="months" class="form-control" placeholder="Enter a Number (Default value is 0)" />
                              ) : null}
                            </div>
                            <div class="col-md-5 mb-2">
                              <div class="mb-3">
                                <div class="custom-control custom-checkbox pt-2">
                                  <label class="font-weight-normal" for="">
                                    Does the patient have any allergies that could prevent them from taking this medication?<span className='error'>*</span>
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-2 mb-3">
                              <select value={this.state.assessmentlst[i].allergies} onChange={(e) => this.handleOncolstFieldsChanges(e, i)}
                                id="allergies" name="allergies" class="form-control">
                                <option value="">Please Choose</option>
                                <option value="2">Yes</option>
                                <option value="1">No</option>
                              </select>
                            </div>
                            <div class="col-md-5 text-right mb-2">
                              {this.state.assessmentlst[i].allergies === '2' ? (
                                <input type="text" value={this.state.assessmentlst[i].allergies_comments} onChange={(e) => this.handleOncolstFieldsChanges(e, i)}
                                  id="allergies_comments" name="allergies_comments" class="form-control" placeholder="Add your comments" />) : null
                              }
                            </div>
                            <div class="col-md-5 mb-2">
                              <div class="mb-3">
                                <div class="custom-control custom-checkbox pt-2">
                                  <label class="font-weight-normal" for="">
                                    What are your current symptoms?<span className='error'>*</span>
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-5 text-right mb-2">
                              <input type="text" value={this.state.assessmentlst[i].current_symptoms} onChange={(e) => this.handleOncolstFieldsChanges(e, i)}
                                id="current_symptoms" name="current_symptoms" class="form-control" placeholder="Add your comments" />
                            </div>
                            <div class="col-md-5 mb-2">
                              <div class="mb-3">
                                <div class="custom-control custom-checkbox pt-2">
                                  <label class="font-weight-normal" for="">
                                    Is a Care Plan required?<span className='error'>*</span>
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-2 mb-3">
                              <select value={this.state.assessmentlst[i].care_plan} onChange={(e) => this.handleOncolstFieldsChanges(e, i)} name="care_plan"
                                id="care_plan" class="form-control">
                                <option value="">Please Choose</option>
                                <option value="2">Yes</option>
                                <option value="1">No</option>
                              </select>
                            </div>
                            <div class="col-md-5 text-right mb-2">
                              {this.state.assessmentlst[i].care_plan === '2' ? (
                                <input type="text" value={this.state.assessmentlst[i].care_plan_comments} onChange={(e) => this.handleOncolstFieldsChanges(e, i)}
                                  id="care_plan_comments" name="care_plan_comments" class="form-control" placeholder="Add your comments" />) : null
                              }
                            </div>
                            <div class="col-md-5 mb-2">
                              {this.state.assessmentlst[i].care_plan === '2' ? (
                                <div class="mb-3 ml-4">
                                  <div class="custom-control custom-checkbox pt-2">
                                    <label class="font-weight-normal" for="">
                                      Does the patient understand the plan of care?
                                    </label>
                                  </div>
                                </div>) : null
                              }
                            </div>
                            {this.state.assessmentlst[i].care_plan === '2' ? (
                              <div class="col-md-2 mb-3">
                                <select value={this.state.assessmentlst[i].understand_plan_care} onChange={(e) => this.handleOncolstFieldsChanges(e, i)} name="understand_plan_care"
                                  id="understand_plan_care" class="form-control">
                                  <option value="">Please Select</option>
                                  <option value="2">Yes</option>
                                  <option value="1">No</option>
                                </select>
                              </div>) : null}
                            <div class="col-md-5 mb-2"></div>
                            <div class="col-md-5 mb-2">
                              <div class="mb-3">
                                <div class="custom-control custom-checkbox pt-2">
                                  <label class="font-weight-normal" for="">
                                    Have Goal(s) of Therapy been established?<span className='error'>*</span>
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-2 mb-3">
                              <select value={this.state.assessmentlst[i].goal_therapy_established} onChange={(e) => this.handleOncolstFieldsChanges(e, i)} name="goal_therapy_established"
                                id="goal_therapy_established" class="form-control">
                                <option value="">Please Select</option>
                                <option value="2">Yes</option>
                                <option value="1">No</option>
                              </select>
                            </div>
                            <div class="col-md-5 mb-2"></div>
                            <div class="col-md-5 mb-2">
                              <div class="mb-3">
                                <div class="custom-control custom-checkbox pt-2">
                                  <label class="font-weight-normal" for="">
                                    Additional Comments
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-5 text-right mb-2">
                              <input type="text" value={this.state.assessmentlst[i].additional_comments} onChange={(e) => this.handleOncolstFieldsChanges(e, i)}
                                id="additional_comments" name="additional_comments" class="form-control" placeholder="Add your comments" />
                            </div>
                            <div class="col-md-5 mb-2">
                              <div class="mb-3">
                                <div class="custom-control custom-checkbox pt-2">
                                  <label class="font-weight-normal" for={"is_assessment_completed_" + i}>
                                    Is Assessment Complete?
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-5 mb-2 mt-1 ml-3">
                              <input type="checkbox" value={this.state.assessmentlst[i].is_assessment_completed} checked={this.state.assessmentlst[i].is_assessment_completed}
                                onChange={(e) => this.handleOncolstFieldsChanges(e, i)}
                                id={"is_assessment_completed_" + i} name="is_assessment_completed" />
                            </div>
                            <div class="row col-md-12">
                              <div class="col-sm-8">
                                <div class="d-flex custom-control">
                                  <label class="font-weight-normal mr-2"> {this.state.assessmentlst[i].is_assessment_completed === true ? 'Completed By : ' + Moment(this.state.assessmentlst[i].created_on).format('MM/DD/YYYY HH:mm') + ' | ': ''} </label>
                                  <label class="font-weight-normal mr-2">{this.state.assessmentlst[i].is_assessment_completed === true ? this.state.assessmentlst[i].created_by_user : ''} </label>
                                </div>
                                <div class="d-flex custom-control">
                                  <label class="font-weight-normal mr-2"> {this.state.assessmentlst[i].is_assessment_completed === true ? 'Updated By : ' + Moment(this.state.assessmentlst[i].updated_on).format('MM/DD/YYYY HH:mm') + ' | ': ''} </label>
                                  <label class="font-weight-normal mr-2">{this.state.assessmentlst[i].is_assessment_completed === true ? this.state.assessmentlst[i].updated_by_user : ''} </label>
                                </div>
                              </div>
                              <div class="col-sm-4 text-right custom-control">
                                <button type="submit" class="btn btn-primary" onClick={(evt) => this.updateEduFormSubmit(evt, i, this.state.assessmentlst[i].id)}>
                                  Save
                                </button>
                                {this.state.assessmentlst[i].is_draft === true ? (
                                  <button type="button" class="btn btn-danger" onClick={() => this.delAssessment(this.state.assessmentlst[i].id, i, this.state.assessmentlst[i].is_draft)}>
                                    Delete
                                  </button>
                                ) : null}
                              </div>

                            </div>
                          </div>
                        </div>
                      </form>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>
              <Modal id="customStyles" show={this.state.show_goaltherapy} onHide={this.handle_goal_Close} backdrop="static">
                <Modal.Header closeButton>
                  <Modal.Title></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <p>Goal(s) of Therapy are required for all patients. PLEASE CREATE.</p>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={this.handle_goal_Close}>Close</Button>
                </Modal.Footer>
              </Modal>
            </div>
          );
        })}
      </>
    );
  }
}