import React from 'react';
import axios from 'axios';
import { Redirect, Link } from 'react-router-dom';
import $ from 'jquery';
import { Accordion, Card, Button, Modal } from 'react-bootstrap';
import dateFormat from 'dateformat';
import { ToastContainer, toast } from 'react-toastify';
import insertionSort from './utils';
import {API_URL,} from '../../config';

import {
  
  VALID_THERAPY_START_DATE,
  VALID_THERAPY_QTY,
  VALID_THERAPY_DAYS,
  VALID_EDUCATION_DATA,
  TOKEN_EXPIRED,
  
} from './constants';

import 'react-toastify/dist/ReactToastify.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'bootstrap/dist/css/bootstrap.css';
import '../../containers/css/style_semantic.css';
import IconWithTooltip from 'icon-with-tooltip';
import CheckIcon from '@material-ui/icons/Check';
import './style.css';
import 'react-confirm-alert/src/react-confirm-alert.css';

export default class therapy extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      patient_id: this.props !== undefined ? this.props.patient_id : null,
      trackTherapyInputList: [],      
      therapyInputList:[
        {
          therapyActive: "",
          noAssessmentsTherapy: "",
          rxTherapy: "",
          orgTherapy: "",
          originalDate: "",
          dateFilled: "",
          startDateTherapy: "",
          drugClassTherapy: "",
          qtyTherapy: "",
          daysTherapy: "",
          refillTherapy: "",
          rphTherapy: "",
          abc: "",
        },
      ], 
    
    }
  }
  // get token from loclastorage
  token = localStorage.getItem('token');
  config = {
    headers: { Authorization: `Bearer ${this.token}` },
  };
  
  //sort list 
 sortLists = (list) => {
  let uneditAble = [];
  let editAble = [];
  list.map((each) => {
      each.therapyActive === true ? editAble.push(each) : uneditAble.push(each)
    })
  uneditAble = insertionSort(uneditAble)
  editAble = insertionSort(editAble)
  let arraySorted = editAble.concat(uneditAble)
  
  return arraySorted
  };
  
  /* Get Therapy Listing Common Method*/
  getTherapy = () => {   
    if (localStorage.getItem('patAllFillsHisChanged') || localStorage.getItem('isPatientAddDelChanged') ||
      localStorage.getItem('patRefillCallNotesChanged') || localStorage.getItem('patCommunicationsChanged')
      || localStorage.getItem('isPatientEduAssChanged') || localStorage.getItem('isAssChanged')) {
      const confirmBox = window.confirm("You have unsaved changes! Do you really want to move to another tab?");
      if (confirmBox === true) {
        localStorage.setItem('prevTab', localStorage.getItem('currTab'));
        localStorage.setItem('currTab', 'therapy');

        localStorage.removeItem('isPatientAddDelChanged');
        localStorage.removeItem('patAllFillsHisChanged');
        localStorage.removeItem('patRefillCallNotesChanged');
        localStorage.removeItem('patCommunicationsChanged');
        localStorage.removeItem('isPatientEduAssChanged');
        localStorage.removeItem('isAssChanged');

        $("#medTherapy").attr('href', '#therapy');
        $("#assCommunications").removeAttr('href');
        $("#allFilesHis").removeAttr('href');
        $("#schDelivery").removeAttr('href');
        $("#refCallNotes").removeAttr('href');
        $("#eduAssessment").removeAttr('href');
        $("#archivesAss").removeAttr('href');
        $("#oncoAssessments").removeAttr('href');
      } else {
        setTimeout(() => this.props.setTab(), 250);
      }
    } else {
      localStorage.setItem('prevTab', localStorage.getItem('currTab'));
      localStorage.setItem('currTab', 'therapy');

      $("#medTherapy").attr('href', '#therapy');
      $("#assCommunications").removeAttr('href');
      $("#allFilesHis").removeAttr('href');
      $("#schDelivery").removeAttr('href');
      $("#refCallNotes").removeAttr('href');
      $("#eduAssessment").removeAttr('href');
      $("#archivesAss").removeAttr('href');
      $("#oncoAssessments").removeAttr('href');
    }

    axios
      .get(`${API_URL}/api/get_therapy_info/?patient_id=` + this.state.patient_id + '&therapy=all', this.config)
      .then(response => {
       
        if (response.status == 200) {
          if (response.data.status == 200 || response.data.status == 404) {
            let patientData;
            let trackTherapyPatData;
            

            if (response.data.data !== null) {
              patientData = response.data.data.map((data, index) => ({
                therapyActive: data.active,
                noAssessmentsTherapy: data.no_assessment,
                rxTherapy: data.rx,
                orgTherapy: data.original_therapy_date,
                originalDate: data.date_written,
                startDateTherapy: data.start_date == null ? data.dos : data.start_date,
                dateFilled: data.dos,
                drugClassTherapy: data.medication_class,
                qtyTherapy: data.quantity,
                daysTherapy: data.days,
                refillTherapy: data.refill,
                rphTherapy: data.rph,
                abc: data.trans_med_id,
                drugDescTherapy: data.name,
              }));
              
              trackTherapyPatData = response.data.data.map((data, index) => ({
                startDateTherapy: dateFormat(data.dos, "yyyy-mm-dd"),
              }));
            } else {
              patientData = [];
              trackTherapyPatData = [];
            }
            this.setState({ therapyInputList: this.sortLists(patientData,'therapy'), });
            this.setState({ trackTherapyInputList: this.sortLists(trackTherapyPatData,'therapy'), });

          } else if (response.data.status_code == 406) {
            localStorage.removeItem('token');
            localStorage.removeItem('user_name');

            localStorage.removeItem('adminCheck');
            localStorage.setItem('tokenExpired', true);

            const tokenStatus = localStorage.getItem('tokenExpired');
            if (tokenStatus) {
              localStorage.removeItem('tokenExpired');
              toast.error(TOKEN_EXPIRED, {
                position: 'top-right',
                autoClose: 2500,
              });
            }

            const isLogged = !!localStorage.getItem('token');
            if (!isLogged) {
              setTimeout(() => {
                this.props.history.push("/login");
              }, 2500);
            }
          }
        } else if (response.status !== 200) {
          toast.error(VALID_EDUCATION_DATA, {
            position: 'top-right',
            autoClose: 3500,
          });
          return false;
        }
      })
      .catch(error => {
        console.log(error);
      });
  }
  

// Handle Input Change Of Therapy
  handleTherapyInputChange = (e, index, id) => {
    const list = [...this.state.therapyInputList];
    
    if (e.target.name == 'therapyActive') {
      
      
      axios
        .put(`${API_URL}/api/update_therapy/${id}/`,
        
          { 'is_active': !this.state.therapyInputList[index].therapyActive }, this.config)
        .then(response => {
          if (response.data.status === 200 && response.status === 200) {
            this.getTherapy()
            toast.success(response.data.message, {
              position: 'top-right',
              autoClose: 3500,
            });
            
          } else {
            toast.error(VALID_EDUCATION_DATA, {
              position: 'top-right',
              autoClose: 3500,
            });
          }
        })
        .catch(err => console.log(err))
        

    } else if (e.target.name == 'noAssessmentsTherapy') {
      list[index][e.target.name] = !list[index][e.target.name];
      axios
        .put(`${API_URL}/api/update_therapy/${id}/`,
          { 'is_history': this.state.therapyInputList[index].noAssessmentsTherapy }, this.config)
        .then(response => {
          if (response.data.status === 200 && response.status === 200) {
            this.getTherapy();
            toast.success(response.data.message, {
              position: 'top-right',
              autoClose: 3500,
            })
          } else {
            toast.error(VALID_EDUCATION_DATA, {
              position: 'top-right',
              autoClose: 3500,
            });
          }
        })
        .catch(err => console.log(err))
    } else {
      list[index][e.target.name] = e.target.value;
    }

    this.setState({ therapyInputList: this.sortLists(list,'therapy'), });

    for (var i = 0; i < list.length; i++) {
      if (this.state.trackTherapyInputList[i].startDateTherapy !== list[i]['startDateTherapy']) {
        localStorage.setItem('patTherapyInputChanged', true);
        break;
      } else {
        localStorage.removeItem('patTherapyInputChanged');
      }
    }
    
};
// Handle Click Event Of The Add Button Of Therapy
  handleTherapyAddClick = () => {
    const list = [...this.state.therapyInputList];
    list.push({
      therapyActive: "",
      noAssessmentsTherapy: "",
      rxTherapy: "",
      orgTherapy: "",
      startDateTherapy: "",
      drugDescTherapy: "",
      drugClassTherapy: "",
      qtyTherapy: "",
      daysTherapy: "",
      refillTherapy: "",
      rphTherapy: "",
    });
    this.setState({
      therapyInputList: this.sortLists(list,'therapy'),
    });
};
 // Handle Click event Of The Remove Button Of Therapy
  handleTherapyRemoveClick = index => {
    const list = [...this.state.therapyInputList];
    list.splice(index, 1);
    this.setState({
      therapyInputList: this.sortLists(list,'therapy'),
    });
  };
// Handle Validations For Therapy Rows Input Fields
  handleCurrTherapyRow = (index, id) => {
    const list = [...this.state.therapyInputList];
    if (list[index].startDateTherapy === '') {
      toast.error(VALID_THERAPY_START_DATE, {
        position: 'top-right',
        autoClose: 3000,
      });
      return false;
    } else if (list[index].qtyTherapy === '') {
      toast.error(VALID_THERAPY_QTY, {
        position: 'top-right',
        autoClose: 3000,
      });
      return false;
    } else if (list[index].daysTherapy === '') {
      toast.error(VALID_THERAPY_DAYS, {
        position: 'top-right',
        autoClose: 3000,
      });
      return false;
    }

    axios
      .put(`${API_URL}/api/update_therapy/${id}/`, { start_date: list[index].startDateTherapy }, this.config)
      .then(response => {
        if (response.data.status === 200 && response.status === 200) {
          toast.success(`Successfully ${response.data.message}`, {
            position: 'top-right',
            autoClose: 3500,
          });
          localStorage.removeItem('patTherapyInputChanged');
        } else {
          toast.error(VALID_EDUCATION_DATA, {
            position: 'top-right',
            autoClose: 3500,
          });
        }
      })
      .catch(err => console.log(err))
  };
  
  render() {
      const isLogged = !!localStorage.getItem('token');
		if (!isLogged) {
			return <Redirect to="/login" />;
		}
    return (
        <>
        
          <div id="therapy" className="container-fluid tab-pane fade">            
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th>Active</th>
                    <th>Assessment N/A</th>
                    <th>RX #</th>
                    <th className="w-145">Original Date</th>
                    <th className="w-145">Date Filled</th>
                    <th className="w-145">Start Date</th>
                    <th>Drug Description</th>
                    <th>Drug Class / Type</th>
                    <th>Qty.</th>
                    <th>Days</th>
                    <th>Refill #</th>
                    <th>RPH</th>
                    <th style={{ width: '100px' }}></th>
                  </tr>
                </thead>
                        {/*  Input this detail in therapy */}
                  <tbody>
                  {this.state.therapyInputList.length > 0 ? this.state.therapyInputList.map((x, i) => {
                    return (
                      <tr key={i} style={x.therapyActive == false ? { backgroundColor: "#e9ecef" } : {}}>
                        <td>
                          {/* Active  */}
                          <div className="custom-control custom-checkbox">
                            <input type="checkbox" className="custom-control-input" id={"therapyActive_" + i}  
                              name="therapyActive" checked={x.therapyActive} onChange={e => this.handleTherapyInputChange(e, i, x.abc)} checked={x.therapyActive} />
                            <label className="custom-control-label" htmlFor={"therapyActive_" + i}></label>
                          </div>
                        </td>
                        {/* No Assessment*/}
                        <td>
                          <div className="custom-control custom-checkbox">
                            <input type="checkbox" className="custom-control-input" id={"noAssessmentsTherapy_" + i}
                              name="noAssessmentsTherapy" checked={x.noAssessmentsTherapy} onChange={e => this.handleTherapyInputChange(e, i, x.abc)} checked={x.noAssessmentsTherapy} />
                            <label className="custom-control-label" htmlFor={"noAssessmentsTherapy_" + i}></label>
                          </div>
                        </td>
                        {/* RX # */}
                        <td>
                          <input type="text" className="form-control" readOnly style={{ width: '75px' }}
                            name="rxTherapy" value={x.rxTherapy} onChange={e => this.handleTherapyInputChange(e, i)} title={x.rxTherapy}/>
                        </td>
                        {/* Original Date	 */}
                        <td>
                          <input type="text" className="form-control" readOnly style={{ width: '150px' }}
                            name="orgDateTherapy" value={dateFormat(x.originalDate, "mm/dd/yyyy", "UTC:h:MM:ss TT Z")} onChange={e => this.handleTherapyInputChange(e, i)} />
                        </td>
                        {/* Date Filled	 */}
                        <td>
                          <input type="text" className="form-control" readOnly style={{ width: '150px' }}
                            name="dateFilled" value={dateFormat(x.dateFilled, "mm/dd/yyyy", "UTC:h:MM:ss TT Z")} onChange={e => this.handleTherapyInputChange(e, i)} />
                        </td>
                        {/* Start Date	 */}
                        <td>
                          <input type="date" className="form-control" style={x.therapyActive == false ? { backgroundColor: "#e9ecef", width: "150px"  } : { width: "150px" }}
                            name="startDateTherapy" value={dateFormat(x.startDateTherapy, "yyyy-mm-dd", "UTC:h:MM:ss TT Z")} onChange={e => this.handleTherapyInputChange(e, i)} />
                        </td>
                        <td>
                          <input style={{ width: '200px' }} readOnly className="form-control" name="drugDescTherapy" value={x.drugDescTherapy}
                          onChange={e => this.handleTherapyInputChange(e, i)} title={x.drugDescTherapy} />

                        </td>
                        {/* Drug Class / Type	 */}
                        <td>
                          <input type="text" className="form-control" readOnly  title={x.drugClassTherapy}
                            name="drugClassTherapy" value={x.drugClassTherapy} onChange={e => this.handleTherapyInputChange(e, i)} />
                        </td>
                            {/* Qty */}
                        <td>
                          <input type="text" className="form-control" style={{ width: '40px' }}
                            name="qtyTherapy" value={x.qtyTherapy} onChange={e => this.handleTherapyInputChange(e, i)} readOnly />
                        </td>
                        {/* Days */}
                        <td>
                          <input type="text" className="form-control" style={{ width: '40px' }}
                            name="daysTherapy" value={x.daysTherapy} onChange={e => this.handleTherapyInputChange(e, i)} readOnly />
                        </td>
                        {/* Refill #	 */}
                        <td>
                          <input type="text" className="form-control" style={{ width: '40px' }}
                            name="refillTherapy" value={x.refillTherapy} onChange={e => this.handleTherapyInputChange(e, i)} readOnly />
                        </td>
                        {/* RPH */}
                        <td>
                          <input type="text" className="form-control" style={{ width: '70px' }} title={x.rphTherapy}
                            name="rphTherapy" value={x.rphTherapy} onChange={e => this.handleTherapyInputChange(e, i)} readOnly />
                        </td>
                        {/* icon button */}
                        <td className="d-flex justify-content-end">
                          <button type="button" className="btn btn-primary mt-1 btnControl btnsv"
                            onClick={() => this.handleCurrTherapyRow(i, x.abc)}>
                            <IconWithTooltip
                              Icon={CheckIcon}
                              text="Save Changes"
                            />
                          </button>
                        </td>
                      </tr>
                    );
                  }) : (
                    <tr>
                      <td colSpan="6">No records found</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>                                                   
      </>
    );

  }
}
  
    