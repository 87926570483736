/*
 * TaskPage
 *
 *
 */

import React from 'react';
import { MDBDataTable } from 'mdbreact';

import CommonHeader from '../../components/CommonHeader';
import CommonSidebar from '../../components/CommonSidebar';
import CommonFooter from '../../components/CommonFooter';

import axios from 'axios';
import Button from 'react-bootstrap/Button';

import $ from 'jquery';
import { Redirect, Link } from 'react-router-dom';

import {
  API_URL,
} from '../../config';
import {
  TOKEN_EXPIRED,
} from './constants';

import { ToastContainer, toast } from 'react-toastify';
import favicon from '../../images/favicon.ico';
import { PDFExport } from '@progress/kendo-react-pdf';

import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'mdbreact/dist/css/mdb.css';

import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';

import '../../css/style_semantic.css';
import './style.css';

export default class DurRates extends React.Component {
  pdfExportComponent;
  constructor(props) {
    super(props);
    
    this.state = {
      year: '',
      quarter: '',
      columns: [
        {
          label: 'Year',
          field: 'year',
          sort: 'asc',
          width: 270,
        },
        {
          label: 'Month Name',
          field: 'month',
          sort: 'asc',
          width: 200,
        },
        {
          label: 'Assessments',
          field: 'total_assessments',
          sort: 'asc',
          width: 100,
        },
        {
          label: 'Drug Drug',
          field: 'drug_drug',
          sort: 'asc',
          width: 100,
        },
        {
          label: 'Drug Condition',
          field: 'drug_condition',
          sort: 'asc',
          width: 100,
        },
        {
          label: 'Drug Disease',
          field: 'drug_disease',
          sort: 'asc',
          width: 100,
        },
      ],
      column: [
        {
          label: 'Year',
          field: 'year',
          sort: 'asc',
          width: 270,
        },
        {
          label: 'Months',
          field: 'month',
          sort: 'asc',
          width: 200,
        },
        {
          label: 'Assessments',
          field: 'total_assessments',
          sort: 'asc',
          width: 100,
        },
        {
          label: 'Drug Drug',
          field: 'drug_drug',
          sort: 'asc',
          width: 100,
        },
        {
          label: 'Drug Condition',
          field: 'drug_condition',
          sort: 'asc',
          width: 100,
        },
        {
          label: 'Drug Disease',
          field: 'drug_disease',
          sort: 'asc',
          width: 100,
        },
      ],
      rowData: [],
      rowPdfData: [],
    };
  }

  componentDidMount() {
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    return axios
      .get(`${API_URL}/api/dur_rates_report/`, config)
      .then(response => { 
        if (response.status == 200) {
          if(response.data.status == 200 && response.data.data != null) {
            let monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
            for (let item of response.data.data) {
              item.month = monthNames[item.month -1];
            }
            this.setState({ rowData: response.data.data });
            this.setState({ rowPdfData: response.data.data });
          } else if(response.data.status_code == 406) {
            localStorage.removeItem('token');
            localStorage.removeItem('user_name');

            localStorage.removeItem('adminCheck');
            localStorage.setItem('tokenExpired', true);

            const tokenStatus = localStorage.getItem('tokenExpired');
            if(tokenStatus) {
              localStorage.removeItem('tokenExpired');
              toast.error(TOKEN_EXPIRED, {
                position: 'top-right',
                autoClose: 2500,
              });
            }

            const isLogged = !!localStorage.getItem('token');
            if (!isLogged) {
             setTimeout(() => { 
              this.props.history.push("/login");
              }, 2500);
            }
          }
        }
      })
      .catch(error => {
        console.log(error);
      });
  }

  Logout = () => {
    localStorage.removeItem('token');
  };

  outcomesGeneratePdf = type => {
    $("#printPDf").toggle();
    this.pdfExportComponent.save();
    setTimeout(() => { $("#printPDf").toggle(); }, 100);
  };

  handleOnChange = event => {
    if(event.target.name == 'year') {
      this.setState({ year: event.target.value });
    } else {
      this.setState({ quarter: event.target.value });
    }
  };

  handleOnSubmit = event => {
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    axios
      .get(`${API_URL}/api/dur_rates_report/?year=${this.state.year}&quarter=${this.state.quarter}`, config)
      .then(response => {
        if(response.status === 200) {
          if(response.data.data != null) {
            let monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
            for (let item of response.data.data) {
              item.month = monthNames[item.month -1];
            }
            this.setState({ rowData: response.data.data });
            this.setState({ rowPdfData: response.data.data });
          } else if(response.data.status_code == 406) {
            localStorage.removeItem('token');
            localStorage.removeItem('user_name');

            localStorage.removeItem('adminCheck');
            localStorage.setItem('tokenExpired', true);

            const tokenStatus = localStorage.getItem('tokenExpired');
            if(tokenStatus) {
              localStorage.removeItem('tokenExpired');
              toast.error(TOKEN_EXPIRED, {
                position: 'top-right',
                autoClose: 2500,
              });
            }

            const isLogged = !!localStorage.getItem('token');
            if (!isLogged) {
             setTimeout(() => { 
              this.props.history.push("/login");
              }, 2500);
            }
          } else if(response.data.data == null) {
            this.setState({ rowData: [] });
            this.setState({ rowPdfData: [] });
          }
         }
       })
      .catch(error => {
        console.log(error);
      });
  };

  render() {
    const isLogged = !!localStorage.getItem('token');
    if (!isLogged) {
      return <Redirect to="/login" />;
    }

    let mbdDatas = {
      columns: this.state.columns,
      rows: this.state.rowData
    }

    let mbdPdfDatas = {
      columns: this.state.column,
      rows: this.state.rowPdfData
    }
    let minOffset = 0, maxOffset = 30;
    let thisYear = (new Date()).getFullYear();
    let allYears = [];
    
    for(let x = minOffset; x <= maxOffset; x++) {
        allYears.push(thisYear - x)
        
    }
    
    const yearList = allYears.map((x) => {return(<option key={x}>{x}</option>)});

    return (
      <React.Fragment>
        <ToastContainer />
        <CommonHeader />
        <section id="wrapper">
          <CommonSidebar />
          <a href="javascript:void(0)">
            <h1  onClick={() => this.outcomesGeneratePdf()}> 
              DUR Rates 
            </h1>
          </a>
          <div className="ui grid">
            <div className="column">
              <div className="tabs-outer tabsouterCustom mb-5">
                <div className="ui menu top stackable">
                  <div className="col-md-3">
                    <select
                      onChange={this.handleOnChange}
                      name="year"
                      id="year"
                    >
                      <option value="">Select Year</option>
                      {yearList}
                    </select>
                  </div>
                  <div className="col-md-3">
                    <select
                      onChange={this.handleOnChange}
                      name="quarter"
                      id="quarter"
                    >
                      <option value="">Select Quarter</option>
                      <option value="1">1st Quarter</option>
                      <option value="2">2nd Quarter</option>
                      <option value="3">3rd Quarter</option>
                      <option value="4">4th Quarter</option>
                    </select>
                  </div>
                  <div className="col-md-3">
                    <button onClick={this.handleOnSubmit} type="button" class="btn btn-primary">Filter</button>
                  </div>
                </div>
                <div className="ui tab active" data-tab="edit">
                  <div className="ui form customSelectIcon">
                    <div className="table-responsive">
                      <MDBDataTable
                        striped
                        bordered
                        small
                        hover
                        searching={true}
                        data={mbdDatas}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <PDFExport forcePageBreak=".page-break" ref={(component) => this.pdfExportComponent = component}>
				    <div id="printPDf" style={{ width: "1350px", height: "420px", display: "none", }}>
            <div className="pdfLogo"><img src={favicon} alt="" /><b>DUR Rates</b></div>
              <MDBDataTable
                striped
                bordered
                small
                hover
                searching={false}
                paging={false}
                data={mbdPdfDatas}
              />
            </div>
			    </PDFExport>
        </section>
        <CommonFooter />
      </React.Fragment>
    );
  }
}