import React from 'react';
import { Redirect, Link } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import 'react-datepicker/dist/react-datepicker.css';
import $ from 'jquery';
import 'bootstrap/dist/css/bootstrap.css';
import '../../containers/css/style_semantic.css';
import {API_URL,} from '../../config';
import Moment from 'moment';
import axios from 'axios';
import '../../containers/PatientClinicalProfile/style.css';
import '../../containers/PatientClinicalProfile/constants.js';
import '../../containers/PatientClinicalProfile/bootstrap.min.css';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { ToastContainer, toast } from 'react-toastify';

import {
 
  VALID_ARCHIVE_ASSESSMENT_ID,
  VALID_ARCHIVE_DATE,
  VALID_ARCHIVE_DESC,
  VALID_EDUCATION_DATA,
  TOKEN_EXPIRED,
  
} from './constants';


export default class Archives extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        patient_id: this.props !== undefined ? this.props.patient_id : null,
        anyActive: false,
        archivesInputList: [
        {
          archiveActive: false,
          archiveAssessmentId: "",
          archiveDate: "",
          archiveDiagnosis: "",
          archivePatientTime: "",
          archiveLastUpdated: "",
          archiveUpdatedBy: "",
        },
      ],
      
    
    }
  }
  // get token from local storage (Data fetch)
  token = localStorage.getItem('token');
  config = {
    headers: { Authorization: `Bearer ${this.token}` },
  };
  
  //Get the data for All Archives
  getAllArchives = () => {

      if (localStorage.getItem('patAllFillsHisChanged') || localStorage.getItem('isPatientAddDelChanged') ||
      localStorage.getItem('patRefillCallNotesChanged') || localStorage.getItem('patCommunicationsChanged')
      || localStorage.getItem('isPatientEduAssChanged') || localStorage.getItem('patTherapyInputChanged') 
      || localStorage.getItem('isAssChanged')) {
      const confirmBox = window.confirm("You have unsaved changes! Do you really want to move to another tab?");
      if (confirmBox === true) {
        localStorage.setItem('prevTab', localStorage.getItem('currTab'));
        localStorage.setItem('currTab', 'archives');

        localStorage.removeItem('isPatientAddDelChanged');
        localStorage.removeItem('patAllFillsHisChanged');
        localStorage.removeItem('patRefillCallNotesChanged');
        localStorage.removeItem('patCommunicationsChanged');
        localStorage.removeItem('isPatientEduAssChanged');
        localStorage.removeItem('patTherapyInputChanged');
        localStorage.removeItem('isAssChanged');

        $("#archivesAss").attr('href', '#archives');
        $("#medTherapy").removeAttr('href');
        $("#assCommunications").removeAttr('href');
        $("#allFilesHis").removeAttr('href');
        $("#schDelivery").removeAttr('href');
        $("#refCallNotes").removeAttr('href');
        $("#eduAssessment").removeAttr('href');
        $("#oncoAssessments").removeAttr('href');
       

      } else {
        setTimeout(() => this.props.setTab(), 250);
      }
    }
    else {
      
      localStorage.setItem('prevTab', localStorage.getItem('currTab'));
      localStorage.setItem('currTab', 'archives');

      $("#archivesAss").attr('href', '#archives');
      $("#medTherapy").removeAttr('href');
      $("#assCommunications").removeAttr('href');
      $("#allFilesHis").removeAttr('href');
      $("#schDelivery").removeAttr('href');
      $("#refCallNotes").removeAttr('href');
      $("#eduAssessment").removeAttr('href');
     
    }


    axios
      .get(`${API_URL}/api/get_archived_assessments/?patient_id=${this.state.patient_id}`, this.config)
      .then(response => {
        if (response.status == 200) {
          let allArchivesData;
          if (response.data.status == 200 || response.data.status == null) {
            if (response.data.data !== null) {
              allArchivesData = response.data.data.map((data, index) => ({
                archiveActive: data.is_active,
                archiveAssessmentId: data.id,
                archiveDate: Moment(data.created_on).format('YYYY-MM-DD'),
                archiveDiagnosis: "Not Applicable for Sands",
                archivePatientTime: data.time_with_patient,
                archiveLastUpdated: Moment(data.updated_on).format('YYYY-MM-DD'),
                archiveUpdatedBy: data.updated_by_user,
              }));
              this.checkActiveAssessment();
            } else {
              allArchivesData = [];
            }

            this.setState({
              archivesInputList: allArchivesData,
            });
          } else if (response.data.status_code == 406) {
            localStorage.removeItem('token');
            localStorage.removeItem('user_name');

            localStorage.removeItem('adminCheck');
            localStorage.setItem('tokenExpired', true);

            const tokenStatus = localStorage.getItem('tokenExpired');
            if (tokenStatus) {
              localStorage.removeItem('tokenExpired');
              toast.error(TOKEN_EXPIRED, {
                position: 'top-right',
                autoClose: 2500,
              });
            }

            const isLogged = !!localStorage.getItem('token');
            if (!isLogged) {
              setTimeout(() => {
                this.props.history.push("/login");
              }, 2500);
            }
          } else if (response.data.status == 204 && response.status == 200) {
            let allArchivesData = [];
            this.setState({
              archivesInputList: allArchivesData,
            });
          }
        } else if (response.status !== 200) {
          toast.error(VALID_EDUCATION_DATA, {
            position: 'top-right',
            autoClose: 3500,
          });
          return false;
        }
      })
      .catch(error => {
        console.log(error);
      });
     
  }

  componentDidMount() {
    this.getAllArchives();
    //this.checkActiveAssessment();
  }
  // Handle Click Event Of The Add Button Of Archives
  handleArchivesAddClick = () => {
    const list = [...this.state.archivesInputList];
    list.push({
      archiveActive: "",
      archiveAssessmentId: "",
      archiveDate: "",
      archiveDiagnosis: "",
      archivePatientTime: "",
      archiveLastUpdated: "",
      archiveUpdatedBy: "",
    });
    this.setState({
      archivesInputList: list,
    });
  };

  // Handle Click event Of The Remove Button Of Archives
  handleArchivesRemoveClick = index => {
    const list = [...this.state.archivesInputList];
    list.splice(index, 1);
    this.setState({
      archivesInputList: list,
    });
  };

  // Handle Input Change Of Archives
  handleArchivesInputChange = (e, index) => {
    //e.preventDefault();
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    const name = e.target.name;

    let list = [...this.state.archivesInputList];
    list[index][name] = value; 
   
    if (name === 'archiveActive' && value === true) {
      if(this.state.anyActive)
      {
          toast.error('Patient already has an Active/Draft Assessment', {
          position: 'top-right',
          autoClose: 3500,
        });
        list[index].archiveActive = false;
      }
      else
      {
        axios
          .post(`${API_URL}/api/toggle_educational_assessment_status/`, { assessment_id: list[index].archiveAssessmentId }, this.config)
          .then(response => {
            if (response.data.status === 200 && response.status === 200) {
              this.getAllArchives();
              toast.success(response.data.message, {
                position: 'top-right',
                autoClose: 3500,
              })
            } else {
              toast.error(VALID_EDUCATION_DATA, {
                position: 'top-right',
                autoClose: 3500,
              });
              return false;
            }
          })
          .catch(err => {
            console.log(err, 'err')
          })
      }
    }
    this.setState({
      archivesInputList: list,
    });
   
  };
  // Handle Validations For Archives Rows Input Fields
  handleCurrArchivesRow = index => {
    const list = [...this.state.archivesInputList];
    if (list[index].archiveAssessmentId === '') {
      toast.error(VALID_ARCHIVE_ASSESSMENT_ID, {
        position: 'top-right',
        autoClose: 3000,
      });
      return false;
    } else if (list[index].archiveDate === '') {
      toast.error(VALID_ARCHIVE_DATE, {
        position: 'top-right',
        autoClose: 3000,
      });
      return false;
    } else if (list[index].archiveDiagnosis === '') {
      toast.error(VALID_ARCHIVE_DESC, {
        position: 'top-right',
        autoClose: 3000,
      });
      // list[index].textInput.focus();
      return false;
    }
  };
  
  async checkActiveAssessment()
  {
    axios
      .get(`${API_URL}/api/educational_assessment/?patient_id=` + this.state.patient_id, this.config)
      .then(response => {
        //console.log('Response Status', response.status);
        if (response.status === 200) {
          if (response.data.data !== null) {
              this.setState({ anyActive: true}) ;
          }
          else
            this.setState({ anyActive: false}) ;
        }
      });
  }

  render() {
      const isLogged = !!localStorage.getItem('token');
		if (!isLogged) {
			return <Redirect to="/login" />;
		}
    return (
        <>
        
          <div id="archives" className="container-fluid tab-pane fade">
            
                    <div className="table-responsive">
                      <table className="table">
                        <thead>
                          <tr>
                            <th style={{ width: '100px' }}> Active</th>
                            <th className="wdt125">Assessment ID</th>
                            <th className="wdt100">Date</th>
                            <th className="">Diagnosis Description</th>
                            <th className="wdt120">Patient Time</th>
                            <th className="wdt120">Last Updated</th>
                            <th className="wdt120">Updated By</th>
                            <th style={{ width: '100px' }}></th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.archivesInputList.length > 0 ? this.state.archivesInputList.map((x, i) => {
                            return (
                              <tr key={i}>
                                <td className="text-left">
                                  <div className="custom-control custom-checkbox">
                                    <input type="checkbox" className="custom-control-input" id={"archiveActive_" + i} checked={this.state.archivesInputList[i].archiveActive}
                                      name="archiveActive" value={this.state.archivesInputList[i].archiveActive} onChange={(e) => this.handleArchivesInputChange(e, i)} />
                                    <label className="custom-control-label font-weight-normal" htmlFor={"archiveActive_" + i}></label>
                                  </div>
                                </td>
                                <td>
                                  <input type="text" className="form-control" readOnly
                                    name="archiveAssessmentId" value={this.state.archivesInputList[i].archiveAssessmentId} onChange={e => this.handleArchivesInputChange(e, i)} />
                                </td>
                                <td>
                                  <input type="date" className="form-control w-145" readOnly
                                    name="archiveDate" value={this.state.archivesInputList[i].archiveDate} onChange={e => this.handleArchivesInputChange(e, i)} />
                                </td>
                                <td style={{ width: '30%' }}>
                                  <textarea rows="1" className="form-control" readOnly name="archiveDiagnosis" defaultValue={this.state.archivesInputList[i].archiveDiagnosis}
                                    onChange={e => this.handleArchivesInputChange(e, i)}>
                                  </textarea>
                                </td>
                                <td>
                                  <input type="text" className="form-control" readOnly
                                    name="archivePatientTime" value={this.state.archivesInputList[i].archivePatientTime} onChange={e => this.handleArchivesInputChange(e, i)} />
                                </td>
                                <td>
                                  <input type="date" className="form-control w-145" readOnly
                                    name="archiveLastUpdated" value={this.state.archivesInputList[i].archiveLastUpdated} onChange={e => this.handleArchivesInputChange(e, i)} />
                                </td>
                                <td>
                                  <input type="text" className="form-control" readOnly
                                    name="archiveUpdatedBy" value={this.state.archivesInputList[i].archiveUpdatedBy} onChange={e => this.handleArchivesInputChange(e, i)} />
                                </td>
                              </tr>
                            );
                          }) : (
                            <tr>
                              <td colSpan="6">No records found</td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                                                          
      </>
    );

  }
}