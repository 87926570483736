import React from 'react';

import LoginPage from '../containers/LoginPage/index.js';
import HomePage from '../containers/HomePage/index.js';

import TasksPage from '../containers/TasksPage/index.js';
import NotFoundPage from '../containers/NotFoundPage/index.js';

import PatientClinicalProfile from '../containers/PatientClinicalProfile/index.js';
import ScheduleDelivery from '../containers/ScheduleDelivery/index.js';

import RefillRemiderCall from '../containers/RefillReminderCall/index.js';
import LogIntervention from '../containers/LogIntervention/index.js';

import PastMedicalHistory from '../containers/PastMedicalHistory/index.js';
import CurrentRxOtcMedications from '../containers/CurrentRxOtcMedications/index.js';
import DayFollowUp from '../containers/DayFollowUp/index.js';

import PatientsProfile from '../containers/PatientsProfile/index.js';
import PatientsLists from '../containers/PatientsLists/index.js';

import ResetPage from '../containers/ResetPage/index.js';
import ForgotPage from '../containers/ForgotPasswordPage/index.js';
import Reports from '../containers/Reports/index.js';

import Outcomes from '../containers/Outcomes/index.js';
import OutComesReport from '../containers/OutComesReports/index.js';
import AdverseEvents from '../containers/AdverseEvents/index.js';

import AverageInitialKarnofsky from '../containers/AverageInitialKarnofsky/index.js';
import DurRates from '../containers/DurRates/index.js';
import ClientLogo from '../containers/ClientLogo/index.js';
import Users from '../containers/Users/index.js';

import { Route, Switch } from 'react-router-dom';
import { BrowserRouter } from 'react-router-dom';

const routes = () => {
	return (
		<>
		  <BrowserRouter>
			  <Switch>
			    <Route exact path='/login' component={LoginPage} />
				<Route exact path='/' component={HomePage} />
				<Route exact path='/tasks' component={TasksPage} />
				<Route exact path='/patientcliprofile' component={PatientClinicalProfile} />
				<Route exact path='/scheduledelivery' component={ScheduleDelivery} />
				<Route exact path='/refillremindercall' component={RefillRemiderCall} />
				<Route exact path='/logintervention' component={LogIntervention} />
				<Route exact path='/pastmedicalhistory' component={PastMedicalHistory} />
				<Route exact path='/currentrxotcmedications' component={CurrentRxOtcMedications} />
				<Route exact path='/dayfollowup' component={DayFollowUp} />
				<Route exact path='/patientsprofile' component={PatientsProfile} />
				<Route exact path='/patientslists' component={PatientsLists} />
				<Route exact path='/forgot-password' component={ForgotPage} />
				<Route exact path='/password_reset' component={ResetPage} />
				<Route exact path='/reports' component={Reports} />
				<Route exact path='/outcomes' component={Outcomes} />
				<Route exact path='/outcomesreport' component={OutComesReport} />
				<Route exact path='/adverseevents' component={AdverseEvents} />
				<Route exact path='/advinitialkarnofsky' component={AverageInitialKarnofsky} />
				<Route exact path='/durrates' component={DurRates} />
				<Route exact path='/clientlogo' component={ClientLogo} />
				<Route exact path='/users' component={Users} />
				<Route component={NotFoundPage} />
			  </Switch>
		  </BrowserRouter>
		</>
	);
}

export default routes;