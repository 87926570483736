import React from 'react';
import axios from 'axios';
import { Redirect, Link } from 'react-router-dom';
import { Accordion, Card, Button, Modal } from 'react-bootstrap';
import dateFormat from 'dateformat';
import { ToastContainer, toast } from 'react-toastify';
import {API_URL,} from '../../config';
import Moment from 'moment';
import Assessment from './AssessmentLst';
import { blockInvalidChar } from "./utils";
import $ from 'jquery';

import {
  OncoError,
  OncoInitialCondition,
  ParticipationCondition,
  VALID_DATA,
  SUCCESS_DATA
} from './constants';

import 'react-toastify/dist/ReactToastify.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'bootstrap/dist/css/bootstrap.css';
import '../../containers/css/style_semantic.css';
import PlusIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import './style.css';
import 'react-confirm-alert/src/react-confirm-alert.css';

export default class Assessments extends React.Component {
  constructor(props) {
    super(props);
    this.Assessment = React.createRef();
    this.state = {
      patient_id: this.props !== undefined ? this.props.patient_id : null,    
      // Onco States
      onco_configuration: true, // Get Onco Configuration from backend to show button or not 
      assessment_id : '', //Set Assessment ID of Education Assessment which is complete and Active
      participation_status: '', // Set Participation Status of Education Assessment
      active_medications: [],
      show_dose_rounding: true,
      show_pain_medication: true,
      show_nausea_medication:true,
      show_therapy_before: true,
      show_allergies:true,
      show_careplan:true,
      show_goaltherapy:false,
      activeOncoAddAssessment: true,
      activeOncoAssPlus: false,
      activeOncoAssMinus: true,
      OncoAssessmentData: {
        dose_rounding: '',
        dose_rounding_comment: '',
        pain_medication: '',
        pain_medication_comment: '',
        nausea_medication: '',
        nausea_medication_comment: '',
        therapy_before: '',
        therapy_before_comment: '',
        months:'',
        allergies: '',
        allergies_comments:'',
        current_symptoms:'',
        care_plan: '',
        care_plan_comments:'',
        understand_plan_care:'',
        goal_therapy_established:'',
        additional_comments:'',
        is_assessment_completed: false,
        completed_on:'',
        completed_by:'',
        created_on: '',
        created_by: '',
        updated_on:'',
        updated_by:'',    
        is_active: true,
      },
      is_draft: true,
      completed_on:'',
      completed_by:'',
      isclicked: false //Only allow one Save button click at a time
    }
  }
  // get token from localstorage
  token = localStorage.getItem('token');
  config = {
    headers: { Authorization: `Bearer ${this.token}` },
  };

  componentDidMount() {
    // Get Configuration to Set Assessments for different clients 
    this.getConfiguration();
    this.getactiveMed();  
  } 

  clearInput = () => {
    this.setState({ 
      OncoAssessmentData: {
      dose_rounding: '',
      dose_roundig_comment: '',
      pain_medication: '',
      pain_medication_comment: '',
      nausea_medication: '',
      nausea_medication_comment: '',
      therapy_before: '',
      therapy_before_comment: '',
      months:'',
      allergies: '',
      allergies_comments:'',
      current_symptoms:'',
      care_plan: '',
      care_plan_comments:'',
      understand_plan_care:'',
      goal_therapy_established:'',
      additional_comments:'',
      is_assessment_completed: false,
      completed_on:'',
      completed_by:'',
      created_on: '',
      created_by: '',
      updated_on:'',
      updated_by:'',    
      is_active: true,
    },show_dose_rounding: true,
    show_pain_medication: true,
    show_nausea_medication:true,
    show_therapy_before: true,
    show_allergies:true,
    show_careplan:true,
    show_goaltherapy:false,
    activeOncoAddAssessment: true,
    activeOncoAssPlus: false,
    activeOncoAssMinus: true, 
  })
  }

  getConfiguration = () => {
    axios
    .get(`${API_URL}/api/configurations/`, this.config)
    .then(response => {
      let configuration = response.data
      this.setState({ 'onco_configuration': configuration['Onco']});
    })
    .catch(error => {
      console.log(error);
    });
  }

  // Assessment Tab click refresh
  getAllAssessmentLst = () => {
   
    if (localStorage.getItem('patAllFillsHisChanged') || localStorage.getItem('isPatientAddDelChanged') ||
      localStorage.getItem('patRefillCallNotesChanged') || localStorage.getItem('patCommunicationsChanged')
      || localStorage.getItem('isPatientEduAssChanged') || localStorage.getItem('patTherapyInputChanged')) {
      const confirmBox = window.confirm("You have unsaved changes! Do you really want to move to another tab?");
      if (confirmBox === true) {
        localStorage.setItem('prevTab', localStorage.getItem('currTab'));
        localStorage.setItem('currTab', 'assessments');

        localStorage.removeItem('isPatientAddDelChanged');
        localStorage.removeItem('patAllFillsHisChanged');
        localStorage.removeItem('patRefillCallNotesChanged');
        localStorage.removeItem('patCommunicationsChanged');
        localStorage.removeItem('isPatientEduAssChanged');
        localStorage.removeItem('patTherapyInputChanged');

        $("#oncoAssessments").attr('href','#assessments');
        $("#medTherapy").removeAttr('href');
        $("#assCommunications").removeAttr('href');
        $("#allFilesHis").removeAttr('href');
        $("#schDelivery").removeAttr('href');
        $("#refCallNotes").removeAttr('href');
        $("#eduAssessment").removeAttr('href');
        $("#archivesAss").removeAttr('href');
      } else {
        setTimeout(() => this.props.setTab(), 250);
      }
    } else {
      localStorage.setItem('prevTab', localStorage.getItem('currTab'));
      localStorage.setItem('currTab', 'assessments');

      $("#oncoAssessments").attr('href','#assessments');
      $("#medTherapy").removeAttr('href');
      $("#assCommunications").removeAttr('href');
      $("#allFilesHis").removeAttr('href');
      $("#schDelivery").removeAttr('href');
      $("#refCallNotes").removeAttr('href');
      $("#eduAssessment").removeAttr('href');
      $("#archivesAss").removeAttr('href');
    }
    this.clearInput();
    this.getConfiguration();
    this.Assessment.current.getOncologyAssessment();
    this.getactiveMed();
    this.setState({ 'activeOncoAssPlus': false });
    this.setState({ 'activeOncoAddAssessment': true });
    this.setState({ 'activeOncoAssMinus': true });
  }

  // Get active Medication and OPT out status
  getactiveMed = () => {
    axios
    .get(`${API_URL}/api/educational_assessment/?patient_id=` + this.state.patient_id, this.config)
    .then(response => {
      if (response.status === 200) {
        if (response.data.status === 200) {
          if (response.data.data.length > 0) {
            // Get medication of Active Education Assessment
            const active_medications = [];
            for (var index = 0; index < response.data.data.length; index++) {
              if (response.data.data[index]['is_active'] == true && response.data.data[index]['is_completed'] && response.data.data[index]['medications'] != null) {
                this.setState({
                  assessment_id: response.data.data[index]['id'],
                  participation_status: response.data.data[index]['participation_status']
                });
                for (var i = 0; i < response.data.data[index].medications.length; i++) {
                  if(i == Number(response.data.data[index].medications.length)-1){
                    active_medications.push(response.data.data[index].medications[i].medication_name + " ");
                  }
                  else{
                    active_medications.push(response.data.data[index].medications[i].medication_name + " | ");
                  }
                }
              }  
              this.setState({
                active_medications: active_medications,
              });
            }
          }
        }
      }
    })
    .catch(error => {
      console.log(error);
    });
  }

  /* Toggle for Onco Assessment */
  plusMinusOncoAssessmentToggle = event => {
    if (event === 'plus') {
      if(this.state.active_medications.length == 0 || this.state.assessment_id == ''){
        toast.error(OncoInitialCondition, {
          position: 'top-right',
          autoClose: 3500,
        });
      }
      else if(this.state.participation_status == 1){
        toast.error(ParticipationCondition, {
          position: 'top-right',
          autoClose: 3500,
        });
      }
      else{
        this.setState({ 'activeOncoAssPlus': true });
        this.setState({ 'activeOncoAddAssessment': false });
        this.setState({ 'activeOncoAssMinus': false });  
      }
    } else {
      this.setState({ 'activeOncoAssPlus': false });
      this.setState({ 'activeOncoAddAssessment': true });
      this.setState({ 'activeOncoAssMinus': true });
    }
  };

  /* Close Modal Pop Up Of Goal Therapy in Onco Assessment */
  handle_goal_Close = () => {
    this.setState({ show_goaltherapy: false});
  };

  // Checkbox for Completed
  handleOncoCheckbox = (event) => {
    const value = event.target.checked ? true : false;
    const name = event.target.name;
   
    let list = { ...this.state.OncoAssessmentData };
    
    if(value==true){
      list['is_draft'] = false;
      list['completed_on']= dateFormat(new Date().toISOString().slice(0,10), "mm/dd/yyyy");
    } else if(value==false){
      list['is_draft'] = true;
      list['completed_on']= '';
    }
    list[name]= value
    this.setState({
      OncoAssessmentData: list,
    });
  };

  // Methods For Handling Oncology Change And Validations Events For Education Form
  handleOncoFieldsChanges = (event) => {
    const value = event.target.value;
    const name = event.target.name;
    //console.log('Event', name, value);

    let list = { ...this.state.OncoAssessmentData };
    
    // Handle Activating comment sections
    if (name == 'dose_rounding' && value == 2) {
      this.setState({ 'show_dose_rounding': false });
    } else if (name == 'dose_rounding' && value != 2) {
      this.setState({ 'show_dose_rounding': true });
    } else if (name == 'pain_medication' && value == 1 || name == 'pain_medication' && value == 2) {
      this.setState({ 'show_pain_medication': false });
    } else if (name == 'pain_medication' && value != 1 || name == 'pain_medication' && value != 2) {
    this.setState({ 'show_pain_medication': true });
    } else if (name == 'nausea_medication' && value == 1 || name == 'nausea_medication' && value == 2) {
      this.setState({ 'show_nausea_medication': false });
    } else if (name == 'nausea_medication' && value != 1 || name == 'nausea_medication' && value != 2) {
    this.setState({ 'show_nausea_medication': true });
    } else if (name == 'therapy_before' && value == 2) {
      this.setState({ 'show_therapy_before': false });
    } else if (name == 'therapy_before' && value != 2) {
    this.setState({ 'show_therapy_before': true });
    } else if (name == 'allergies' && value == 2) {
      this.setState({ 'show_allergies': false });
    } else if (name == 'allergies' && value != 2) {
    this.setState({ 'show_allergies': true });
    } else if (name == 'care_plan' && value == 2) {
    this.setState({ 'show_careplan': false });
    } else if (name == 'care_plan' && value != 2) {
    this.setState({ 'show_careplan': true });
    } else if (name == 'goal_therapy_established' && value == 2) {
    this.setState({ 'show_goaltherapy': false });
    } else if (name == 'goal_therapy_established' && value == 1) {
    this.setState({ 'show_goaltherapy': true });
    } 
    
    list[name]= value
    this.setState({ OncoAssessmentData: list, });
    localStorage.setItem('isAssChanged', true);
  };  

  // Create Onco Form
  createOncoForm = e => {
    let list = { ...this.state.OncoAssessmentData };
    // Months Length & Default Value
    if((list['therapy_before'] == 2 && list['months'].length > 3)){
      toast.error('Number entered for Months field exceeds the limit', {
        position: 'top-right',
        autoClose: 2500,
      });
      return false;
    }
    if (list['therapy_before'] == 1){
      list['months'] = 0
    }
    // Dose rounding condition
    if(list['is_assessment_completed']==true){
      if(list['dose_rounding'] == 0 || (list['dose_rounding'] == 2 && list['dose_rounding_comment'] == '')){
        toast.error(OncoError, {
          position: 'top-right',
          autoClose: 2500,
        });
        return false;
      }
      // Pain Medication condition
      if(list['pain_medication'] == 0 || (list['pain_medication'] == 2 || list['pain_medication'] == 1) && list['pain_medication_comment'] == ''){
        toast.error(OncoError, {
          position: 'top-right',
          autoClose: 2500,
        });
        return false;
      }
      // Nausea condition
      if(list['nausea_medication'] == 0 || ((list['nausea_medication'] == 2 || list['nausea_medication'] == 1) && list['nausea_medication_comment'] == '')){
        toast.error(OncoError, {
          position: 'top-right',
          autoClose: 2500,
        });
        return false;
      }
      // Therapy before condition
      if(list['therapy_before'] == 0){
        toast.error(OncoError, {
          position: 'top-right',
          autoClose: 2500,
        });
        return false;
      }
      // Allergies condition
      if(list['allergies'] == 0 || (list['allergies'] == 2 && list['allergies_comments'] == '')){
        toast.error(OncoError, {
          position: 'top-right',
          autoClose: 2500,
        });
        return false;
      }
      // Current symptoms condition
      if(list['current_symptoms'] == ''){
        toast.error(OncoError, {
          position: 'top-right',
          autoClose: 2500,
        });
        return false;
      }
      // Care plan
      if(list['care_plan'] == 0 || (list['care_plan'] == 2 && list['understand_plan_care'] == 0) || (list['care_plan'] == 2 && list['care_plan_comments'] == '')){
        toast.error(OncoError, {
          position: 'top-right',
          autoClose: 2500,
        });
        return false;
      }
      // Goal Therapy
      if(list['goal_therapy_established'] == 0){
        toast.error(OncoError, {
          position: 'top-right',
          autoClose: 2500,
        });
        return false;
      }
    }
    this.setState({ isclicked : true})
    if(!this.state.isclicked){
      let createOncoFormData = {
        patient: this.state.patient_id,
        assessment_id: this.state.assessment_id,
        // medication: this.state.active_medications,
        dose_rounding: Number(list['dose_rounding']),
        dose_rounding_comment: list['dose_rounding_comment'],
        pain_medication: list['pain_medication'],
        pain_medication_comment: list['pain_medication_comment'],
        nausea_medication: Number(list['nausea_medication']),
        nausea_medication_comment: list['nausea_medication_comment'],
        therapy_before:  Number(list['therapy_before']),
        therapy_before_comment:list['therapy_before_comment'],
        months: Number(list['months']),
        allergies: Number(list['allergies']),
        allergies_comments: list['allergies_comments'],
        current_symptoms: list['current_symptoms'],
        care_plan:  Number(list['care_plan']),
        care_plan_comments:list['care_plan_comments'],
        understand_plan_care:Number(list['understand_plan_care']),
        goal_therapy_established:Number(list['goal_therapy_established']),
        additional_comments:list['additional_comments'],
        is_assessment_completed: Boolean(list['is_assessment_completed']),
        //is_draft: Boolean(list['is_draft']),
        //is_active: Boolean(list['is_active']),
        completed_on: list['completed_on'],
        completed_by: list['completed_by'],
        updated_on:list['updated_on'],
        updated_by:list['updated_by']
      }
      axios.post(`${API_URL}/api/oncology_assessment/` , createOncoFormData, this.config)
      .then((response) => {
        if(response.status === 200)
        {
          this.setState({ isclicked: false})
          toast.success(SUCCESS_DATA, {
            position: 'top-right',
            autoClose: 3000,
          });
          this.setState({ createOncoFormData: [] });
          this.setState({
            'activeOncoAddAssessment': true,
            'activeOncoAssPlus': false,
            'activeOncoAssMinus': true,
          });
          localStorage.removeItem('isAssChanged');
          this.Assessment.current.getOncologyAssessment();
        }
        else if (response.data.status === 400) {
          toast.error(VALID_DATA, {
            position: 'top-right',
            autoClose: 3000,
          });
        }
      })
      .catch((error) => {
        toast.error(VALID_DATA, {
          position: 'top-right',
          autoClose: 3500,
        });
        return false;
      });
    } 
  }


  render() {
    const isLogged = !!localStorage.getItem('token');
		if (!isLogged) {
			return <Redirect to="/login" />;
		}
    return (
      <>
        <div id="assessments" className="container-fluid tab-pane fade">
          <div hidden={this.state.onco_configuration}>
            <br/>
            <h3>Oncology</h3>
            <a hidden={this.state.activeOncoAssPlus} className="btn btn-primary" onClick={() => this.plusMinusOncoAssessmentToggle('plus')} href="javascript:void(0)">
              (<PlusIcon />) Oncology Initial Assessment 
              </a>
            <a hidden={this.state.activeOncoAssMinus} className="btn btn-primary" onClick={() => this.plusMinusOncoAssessmentToggle('minus')} href="javascript:void(0)">
              (<RemoveIcon />) Oncology Initial Assessment
            </a>  
            <div class="mt-3">
              <Accordion defaultActiveKey="0" hidden={this.state.activeOncoAddAssessment}>
                <Card>
                  <Card.Header className="align-items-center d-flex justify-content-between text-white">
                    <span className="px-2"> Oncology Initial Assessment </span>
                    <Accordion.Toggle as={Button} variant="link" eventKey="0" className="float-right">
                      <i class="fa fa-arrow-circle-down text-white" aria-hidden="true"></i>
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="0">
                    <Card.Body>
                    <form>
                        <div class="onco">
                        <div class="row mt-4">
                        <div class="col-md-12 mb-3">
                            <div class="mb-2">
                              <div class="custom-control custom-checkbox pt-2">
                                <label class="font-weight-bold" id="medication_label" for="">
                                  {this.state.active_medications}
                                </label>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-5 mb-3">
                            <div class="mb-2">
                              <div class="custom-control custom-checkbox pt-2">
                                <label class="font-weight-normal" for="">
                                  Will dose rounding be used for this therapy? <span className='error'>*</span>
                                </label>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-2 mb-3">
                            <select value={this.state.OncoAssessmentData.dose_rounding} onChange={this.handleOncoFieldsChanges}
                              id="doserounding" name="dose_rounding" class="form-control">
                              <option value="">Please Choose</option>
                              <option value="2">Yes</option>
                              <option value="1">No</option>
                            </select>
                          </div>
                          <div class="col-md-5 text-right mb-2" >
                            <input type="text" hidden={this.state.show_dose_rounding} value={this.state.OncoAssessmentData.dose_rounding_comment} onChange={this.handleOncoFieldsChanges}
                              id="doseroundingComments" name="dose_rounding_comment" class="form-control" placeholder="Add your comments"/>
                          </div>
                          <div class="col-md-5  mb-2">
                            <div class="mb-3">
                              <div class="custom-control custom-checkbox pt-2">
                                <label class="font-weight-normal" for="">
                                  Is the patient taking any pain medications?<span className='error'>*</span>
                                </label>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-2 mb-3">
                            <select value={this.state.OncoAssessmentData.pain_medication} onChange={this.handleOncoFieldsChanges}
                              id="pain_medication" name="pain_medication" class="form-control">
                              <option value="">Please Choose</option>
                              <option value="1">Scheduled</option>
                              <option value="2">PRN</option>
                              <option value="3">None</option>
                            </select>
                          </div>
                          <div class="col-md-5 text-right  mb-2">
                            <input type="text" hidden={this.state.show_pain_medication} value={this.state.OncoAssessmentData.pain_medication_comment} id="pain_medication_comment"
                              name="pain_medication_comment" onChange={this.handleOncoFieldsChanges} class="form-control" placeholder="Add your comments"/>
                          </div>
                          <div class="col-md-5  mb-2">
                            <div class="mb-3">
                              <div class="custom-control custom-checkbox pt-2">
                                <label class="font-weight-normal" for="">
                                  Is the patient taking any nausea medications?<span className='error'>*</span>
                                </label>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-2 mb-3">
                            <select value={this.state.OncoAssessmentData.nausea_medication} onChange={this.handleOncoFieldsChanges}
                              id="nausea_medication" name="nausea_medication" class="form-control">
                              <option value="">Please Choose</option>
                              <option value="1">Scheduled</option>
                              <option value="2">PRN</option>
                              <option value="3">None</option>
                            </select>
                          </div>
                          <div class="col-md-5 text-right mb-2">
                            <input type="text" hidden={this.state.show_nausea_medication} value={this.state.OncoAssessmentData.nausea_medication_comment} onChange={this.handleOncoFieldsChanges}
                              id="nausea_medication_comment" name="nausea_medication_comment" class="form-control" placeholder="Add your comments" />
                          </div>

                          <div class="col-md-5 mb-2">
                            <div class="mb-3">
                              <div class="custom-control custom-checkbox pt-2">
                                <label class="font-weight-normal" for="">
                                  Has the patient been on this therapy before?<span className='error'>*</span>
                                </label>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-2 mb-3">
                            <select value={this.state.OncoAssessmentData.therapy_before} onChange={this.handleOncoFieldsChanges} name="therapy_before"
                              id="therapy_before" class="form-control">
                              <option value="">Please Choose</option>
                              <option value="1">Naive</option>
                              <option value="2">Experienced</option>
                            </select>
                          </div>
                          <div class="col-md-5 mb-2"></div>
                          <div class="col-md-5 mb-2" hidden={this.state.show_therapy_before}>
                            <div class="mb-3 ml-4">
                              <div class="custom-control custom-checkbox pt-2">
                                <label class="font-weight-normal" for="">
                                  How long in months has the patient been on this therapy?<span className='error'>*</span>
                                </label>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-3 text-right mb-2" hidden={this.state.show_therapy_before}>
                            <input type="number" min="0" value={this.state.OncoAssessmentData.months} onKeyDown={blockInvalidChar} onChange={this.handleOncoFieldsChanges}
                            id="months" name="months" class="form-control" placeholder="Enter a Number (Default value is 0)"/>
                          </div>
                         <div class="col-md-5 mb-2">
                            <div class="mb-3">
                              <div class="custom-control custom-checkbox pt-2">
                                <label class="font-weight-normal" for="">
                                  Does the patient have any allergies that could prevent them from taking this medication?<span className='error'>*</span>
                                </label>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-2 mb-3">
                            <select value={this.state.OncoAssessmentData.allergies} onChange={this.handleOncoFieldsChanges}
                              id="allergies" name="allergies" class="form-control">
                              <option value="">Please Choose</option>
                              <option value="2">Yes</option>
                              <option value="1">No</option>
                            </select>
                          </div>
                          <div class="col-md-5 text-right mb-2">
                            <input type="text" hidden={this.state.show_allergies} value={this.state.OncoAssessmentData.allergies_comments} onChange={this.handleOncoFieldsChanges}
                              id="allergies_comments" name="allergies_comments" class="form-control" placeholder="Add your comments"/>
                          </div>
                          <div class="col-md-5 mb-2">
                            <div class="mb-3">
                              <div class="custom-control custom-checkbox pt-2">
                                <label class="font-weight-normal" for="">
                                  What are your current symptoms?<span className='error'>*</span>
                                </label>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-5 text-right mb-2">
                            <input type="text" value={this.state.OncoAssessmentData.current_symptoms} onChange={this.handleOncoFieldsChanges}
                              id="current_symptoms" name="current_symptoms" class="form-control" placeholder="Add your comments"/>
                          </div>
                          <div class="col-md-5 mb-2">
                            <div class="mb-3">
                              <div class="custom-control custom-checkbox pt-2">
                                <label class="font-weight-normal" for="">
                                  Is a Care Plan required?<span className='error'>*</span>
                                </label>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-2 mb-3">
                            <select value={this.state.OncoAssessmentData.care_plan} onChange={this.handleOncoFieldsChanges} name="care_plan"
                              id="care_plan" class="form-control">
                              <option value="">Please Choose</option>
                              <option value="2">Yes</option>
                              <option value="1">No</option>
                            </select>
                          </div>
                          <div class="col-md-5 text-right mb-2">
                            <input type="text" hidden={this.state.show_careplan} value={this.state.OncoAssessmentData.care_plan_comments} onChange={this.handleOncoFieldsChanges}
                              id="care_plan_comments" name="care_plan_comments" class="form-control" placeholder="Add your comments"/>
                          </div>
                          <div class="col-md-5 mb-2">
                            <div class="mb-3 ml-4" hidden={this.state.show_careplan}>
                              <div class="custom-control custom-checkbox pt-2">
                                <label class="font-weight-normal" for="">
                                  Does the patient understand the plan of care?
                                </label>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-2 mb-3" hidden={this.state.show_careplan}>
                            <select value={this.state.OncoAssessmentData.understand_plan_care} onChange={this.handleOncoFieldsChanges} name="understand_plan_care"
                              id="understand_plan_care" class="form-control">
                              <option value="">Please Select</option>
                              <option value="2">Yes</option>
                              <option value="1">No</option>
                            </select>
                          </div>
                          <div class="col-md-5 mb-2"></div>
                          <div class="col-md-5 mb-2">
                            <div class="mb-3">
                              <div class="custom-control custom-checkbox pt-2">
                                <label class="font-weight-normal" for="">
                                  Have Goal(s) of Therapy been established?<span className='error'>*</span>
                                </label>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-2 mb-3">
                            <select value={this.state.OncoAssessmentData.goal_therapy_established} onChange={this.handleOncoFieldsChanges} name="goal_therapy_established"
                              id="goal_therapy_established" class="form-control">
                              <option value="">Please Select</option>
                              <option value="2">Yes</option>
                              <option value="1">No</option>
                            </select>
                          </div>
                          <div class="col-md-5 mb-2"></div>
                          <div class="col-md-5 mb-2">
                            <div class="mb-3">
                              <div class="custom-control custom-checkbox pt-2">
                                <label class="font-weight-normal" for="">
                                  Additional Comments
                                </label>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-5 text-right mb-2">
                            <input type="text" value={this.state.OncoAssessmentData.additional_comments} onChange={this.handleOncoFieldsChanges}
                              id="additional_comments" name="additional_comments" class="form-control" placeholder="Add your comments"/>
                          </div>
                          <div class="col-md-5 mb-2">
                            <div class="mb-3">
                              <div class="custom-control custom-checkbox pt-2">
                                <label class="font-weight-normal" for="">
                                  Is Assessment Complete?
                                </label>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-5 mb-2 mt-1 ml-3">
                            <input type="checkbox" value={this.state.OncoAssessmentData.is_assessment_completed} onChange={this.handleOncoCheckbox} id="is_assessment_completed" name="is_assessment_completed"/>
                          </div>
               
                          <div class="col-md-12 text-right">
                            <Link className="btn btn-primary" to="#" onClick={this.createOncoForm}>Save</Link>
                          </div>
                          </div>
                        </div>
                      </form>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>
              <Modal id="customStyles" show={this.state.show_goaltherapy} onHide={this.handle_goal_Close} backdrop="static">
                <Modal.Header closeButton>
                  <Modal.Title></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <p>Goal(s) of Therapy are required for all patients. PLEASE CREATE.</p>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={this.handle_goal_Close}>Close</Button>
                </Modal.Footer>
              </Modal>
            </div>

            <Assessment ref={this.Assessment} patient_id = {this.state.patient_id}/> 
          </div>
        </div>                                               
      </>

    );
  }
}   