/*
 * Schedule Delivery
 *
 *
 */

import React from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import IconWithTooltip from 'icon-with-tooltip';
import CommonHeader from '../../components/CommonHeader';
import CommonSidebar from '../../components/CommonSidebar';
import CommonFooter from '../../components/CommonFooter';

import { Redirect, Link, Prompt } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { MDBDataTable } from 'mdbreact';
import Moment from 'moment';
import Button from 'react-bootstrap/Button';
import axios from 'axios';
import {
  API_URL,
} from '../../config';

import {
  SUCCESS_SCHDEULE_DELIVERY, TOKEN_EXPIRED,
  ERROR_DATA_SAVE, INTERNAL_SERVER_ERROR, UPDATE_SCHDEULE_DELIVERY,
} from './constants';
import Select from 'react-select';

import 'react-toastify/dist/ReactToastify.css';
import 'mdbreact/dist/css/mdb.css';
import 'bootstrap/dist/css/bootstrap.css';
import '../../containers/css/style_semantic.css';
import './style.css';

export default class ScheduleDelivery extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      refreshing :false,
      scheduleDeliveryData: false,
      show: false,
      scheduleDeliveryLists: [],
      trackScheduleDelivery: [],
      patient_name: this.props.location.state !== undefined ? this.props.location.state.patient_name : null,
      transaction_id: this.props.location.state !== undefined ? this.props.location.state.transaction_id : null,
      patientid : this.props.location.state !== undefined ? this.props.location.state.patient_id : null,
      data_added : this.props.location.state !== undefined ? this.props.location.state.data_added : null,
      scheduleDelivery: {     
        patient_id: this.props.location.state !== undefined ? this.props.location.state.patient_id : null,
        id: '',
        patient_shipping_notes:'',
        shipping_date: '',
        delivery_date: '',
        delivery_method: '',
        shipping_method: '',
        shipping_location: '',
        new_patient: false,
        delivery_notes_shipment: '',
        
      },
      plainArray: [],
      selectedValue: [],
      medicationIdsArr: [],
      shippingLocation: [],
      errors: {
        patient_id: '',
        patient_shipping_notes:'',
        shipping_date: '',
        delivery_date: '',
        delivery_method: '',
        shipping_method: '',
        shipping_location: '',
        new_patient: false,
        medications: true,
        delivery_notes_shipment: '',
        
      },
      columns: [
        {
          label: 'Shipping Date',
          field: 'ShippingDate',
          sort: 'asc',
          width: 150,
        },
        {
          label: 'Delivery Date',
          field: 'DeliveryDate',
          sort: 'asc',
          width: 270,
        },
        {
          label: 'Delivery Method',
          field: 'DeliveryMethod',
          sort: 'asc',
          width: 200,
        },
        {
          label: 'Shipping Method',
          field: 'ShippingMethod',
          sort: 'asc',
          width: 200,
        },
        {
          label: 'New Patient',
          field: 'NewPatient',
          sort: 'asc',
          width: 100,
        },
        {
          label: 'Shipping Notes',
          field: 'ShippingNotes',
          sort: 'asc',
          width: 150,
        },
        {
          label: 'Shipping Location',
          field: 'ShippingLocation',
          sort: 'asc',
          width: 150,
        },
        {
          label: 'Action',
          field: 'Action',
          sort: 'asc',
          width: 100,
        }
      ],
      rowData: [],
    };
  }
  // today date
  todayDate = new Date().toISOString().slice(0,10)
  
  async handleScheDelDataChanges(event) {
    let list = { ...this.state.scheduleDelivery };
    const name = event.target.name;
    const value = event.target.value;
    this.setState({
      [name]: value    
    });
    let errors = this.state.errors;

    switch (name) {
      case 'shipping_date':
        errors.shipping_date =
          value == '' ? 'Please Enter The Shipping Date.' : '';
        break;
      case 'delivery_date':
        errors.delivery_date =
          value == '' ? 'Please Enter The Delivery Date.' : '';
        break;
      case 'delivery_method':
        errors.delivery_method =
          value == '' ? 'Please Enter Delivery Method.' : '';
        break;
      case 'shipping_method':
        errors.shipping_method =
          value == '' ? 'Please Enter Shipping Method.' : '';
        break;
      
      
    }

    if (name === 'new_patient') {
      list[event.target.name] = !list[event.target.name];
    } else {
      list[name] = value;
    }

    if (list[event.target.name] !== '') {
      await this.setState({ 'scheduleDeliveryData': true, });
    } else {
      await this.setState({ 'scheduleDeliveryData': false, });
    }
    //console.log('Sch Delivery Data', this.state.scheduleDeliveryData);
    await this.setState({ scheduleDelivery: list, });
  };
  async onSelectedItemsChange(e) {
    
    this.setState({ selectedValue: e });
    const arr = [];
    
    for (var i = 0; i < e.length; i++) {
      arr.push(e[i].value);
    }
    
    await this.setState(() => ({ medicationIdsArr: arr }));
  };
  
  handleScheduleDelivery(event) {
    event.preventDefault();
    let errors = { ...this.state.errors };
    let list = { ...this.state.scheduleDelivery };
    
    if (this.state.medicationIdsArr.length == 0) {
      errors.medications = 'Please Enter The Medications.';
    } else {
      errors.medications = '';
    }
    

    if (list.shipping_date == '') {
      errors.shipping_date = 'Please Enter The Shipping Date.';
    } else {
      errors.shipping_date = '';
    }

    if (list.delivery_date == '') {
      errors.delivery_date = 'Please Enter Delivery Date.';
    } else {
      errors.delivery_date = '';
    }

    if (list.delivery_method == '') {
      errors.delivery_method = 'Please Select The Delivery Method.';
    } else {
      errors.delivery_method = '';
    }

    if (list.shipping_method == '') {
      errors.shipping_method = 'Please Select The Shipping Method.';
    } else {
      errors.shipping_method = '';
    }

    if (errors.shipping_date != '' || errors.delivery_date != '' || errors.delivery_method || errors.shipping_method ||  errors.medications != '') {
      this.setState({ errors: errors });
      return false;
    }

    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const formData = {
      patient: this.state.scheduleDelivery.patient_id,
      patient_shipping_notes: this.state.scheduleDelivery.patient_shipping_notes,
      shipping_date: this.state.scheduleDelivery.shipping_date,
      delivery_date: this.state.scheduleDelivery.delivery_date,
      delivery_method: this.state.scheduleDelivery.delivery_method,
      shipping_method: this.state.scheduleDelivery.shipping_method,
      shipping_location: this.state.scheduleDelivery.shipping_location,
      new_patient: this.state.scheduleDelivery.new_patient,
      medications: this.state.medicationIdsArr,
      delivery_notes_shipment: this.state.scheduleDelivery.delivery_notes_shipment,
      
    }

    axios
      
      .post(`${API_URL}/api/schedule_delivery/`, formData, config)
      .then(response => {
        if (response.status == 200) {
          this.state.refreshing = true
          toast.success(SUCCESS_SCHDEULE_DELIVERY, {
            position: 'top-right',
            autoClose: 3000,
          });
          this.timeout = setTimeout(() => {
            this.setState({ refreshing: false });
          }, 3000);
          window.setTimeout(function () {
            window.location.reload();
          }, 3000);
          axios
            .get(`${API_URL}/api/schedule_delivery/?patient_id=${this.state.scheduleDelivery.patient_id}`, config)
            .then(response => {
              if (response.status == 200) {
                if (response.data.status == 200) {
                  let responseData = response.data.data;
                  for (var i = 0; i < responseData.length; i++) {

                    if (responseData[i].delivery_method == 1) {
                      responseData[i].delivery_method = 'Pickup';
                    } else if (responseData[i].delivery_method == 5) {
                      responseData[i].delivery_method = 'Delivery';
                    } else {
                      responseData[i].delivery_method = 'Same Day Delivery';
                    }

                    if (responseData[i].shipping_method == 10) {
                      responseData[i].shipping_method = 'Standard Shipping';
                    } else if (responseData[i].shipping_method == 20) {
                      responseData[i].shipping_method = 'Saturday';
                    } else {
                      responseData[i].shipping_method = 'Priority Overnight';
                    }

                    // if (responseData[i].shipping_location == 1) {
                    //   responseData[i].shipping_location = 'Home';
                    // } else if (responseData[i].shipping_location == 5) {
                    //   responseData[i].shipping_location = 'Work';
                    // } else if (responseData[i].shipping_location == 10) {
                    //   responseData[i].shipping_location = 'Caregiver';
                    // } else {
                    //   responseData[i].shipping_location = 'Other123';
                    // }
                  }

                  let res = responseData.filter(past_delivery_date => new Date(past_delivery_date.delivery_date).setHours(0, 0, 0, 0) >= new Date().setHours(0, 0, 0, 0)).map((data, index) => ({
                    Sno: index + 1,

                    ShippingDate: Moment(data.shipping_date).format('MM/DD/YYYY'),
                    DeliveryDate: Moment(data.delivery_date).format('MM/DD/YYYY'),
                    ShippingNotes: data.delivery_notes_shipment,
                    DeliveryMethod: data.delivery_method,
                    ShippingMethod: data.shipping_method,
                    ShippingLocation: data.shipping_location,
                    NewPatient: data.new_patient == true ? 'Yes' : 'No',
                    Action: <Button className="f-12 nowrap"> <i onClick={this.editScheDelivery(data.id)} className="fa fa-edit" aria-hidden="true"></i></Button>,
                  }));

                  this.setState({ rowData: res });
                } else if (response.data.status_code == 406) {
                  localStorage.removeItem('token');
                  localStorage.removeItem('user_name');

                  localStorage.removeItem('adminCheck');
                  localStorage.setItem('tokenExpired', true);

                  const tokenStatus = localStorage.getItem('tokenExpired');
                  if (tokenStatus) {
                    localStorage.removeItem('tokenExpired');
                    toast.error(TOKEN_EXPIRED, {
                      position: 'top-right',
                      autoClose: 2500,
                    });
                  }

                  const isLogged = !!localStorage.getItem('token');
                  if (!isLogged) {
                    setTimeout(() => {
                      this.props.history.push("/login");
                    }, 2500);
                  }
                }
              } else {
                toast.error(ERROR_DATA_SAVE, {
                  position: 'top-right',
                  autoClose: 3000,
                });
                return false;
              }
            })
            .catch(error => {
              console.log(error);
            });

          this.setState({
            scheduleDelivery: {
              patient_shipping_notes:'',
              shipping_date: '',
              delivery_date: '',
              delivery_method: '',
              shipping_method: '',
              shipping_location: '',
              new_patient: false,
              delivery_notes_shipment: '',
              
            }
          });

          this.setState({ selectedValue: null });
        } else {
          toast.error(ERROR_DATA_SAVE, {
            position: 'top-right',
            autoClose: 3000,
          });
          return false;
        }
      })
      .catch(error => {
        console.log(error);
      });
      // window.setTimeout(function () {
      //   window.location.reload();
      //   }, 3500);
	  
  };
  editScheDelivery(id) {
    this.setState({ show: true });
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    // this.setState({ selectedValue: null }); 
    
    axios
      .get(`${API_URL}/api/schedule_delivery_get_update/${id}`, config)
      .then(response => {
        if (response.status == 200) {
          if (response.data.status == 200) {
            this.setState({ scheduleDelivery: response.data.data[0] });

            const arr = [];
            const arryId = [];
            if (response.data.data[0].medications != null) {
              for (var i = 0; i < response.data.data[0].medications.length; i++) {
                arr.push({ label: response.data.data[0].medications[i].medication_name, value: response.data.data[0].medications[i].id });
                arryId.push(response.data.data[0].medications[i].id)
              }
            }
           
            this.setState({medicationIdsArr :arryId})
            this.setState({ selectedValue: arr });
            
          } else if (response.data.status_code == 406) {
            localStorage.removeItem('token');
            localStorage.removeItem('user_name');

            localStorage.removeItem('adminCheck');
            localStorage.setItem('tokenExpired', true);

            const tokenStatus = localStorage.getItem('tokenExpired');
            if (tokenStatus) {
              localStorage.removeItem('tokenExpired');
              toast.error(TOKEN_EXPIRED, {
                position: 'top-right',
                autoClose: 2500,
              });
            }
            
            const isLogged = !!localStorage.getItem('token');
            if (!isLogged) {
              setTimeout(() => {
                this.props.history.push("/login");
              }, 2500);
            }
          }
        } else {
          toast.error(INTERNAL_SERVER_ERROR, {
            position: 'top-right',
            autoClose: 2500,
          });
        }
      })
     
      .catch(error => {
        console.log(error);
      });
      
  };

  componentDidMount() {
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    axios
      .get(`${API_URL}/api/active_delivery_address/?patient_id=${this.state.scheduleDelivery.patient_id}`, config)
      .then(response => {
        if (response.data.data !== null) {
          this.setState({ 'shippingLocation': response.data.data });
        } else {
          this.setState({ 'shippingLocation': [] });
        }
      })
      .catch(error => {
        console.log(error);
      });

    axios
      .get(`${API_URL}/api/get_all_fills_history/?patient_id=${this.state.scheduleDelivery.patient_id}` + '&all_fills_medication=all', config)
      .then(response => {
        const arr = [];
        const arrIds = [];

        if (response.status == 200) {
          for (var i = 0; i < response.data.data.length; i++) {
            arr.push({ label: response.data.data[i].name, value: response.data.data[i].trans_med_id });
            arrIds.push(response.data.data[i].trans_med_id);
          }
          this.setState({ plainArray: arr });
          //this.setState({ selectedValue:arr });
          
          // this.setState({ medicationIdsArr: arr });
          if (this.state.scheduleDelivery.id >0 && this.state.scheduleDelivery.id != undefined)
             this.setState({ medicationIdsArr: arrIds });
          //this.setState({selectedValue:null});
          
        }
          
      })
      .catch(error => {
        console.log(error);
      });
    
    axios
      .get(`${API_URL}/api/schedule_delivery/?patient_id=${this.state.scheduleDelivery.patient_id}`, config)
      .then(response => {
        if (response.status == 200) {
          if (response.data.status == 200) {
            let responseData = response.data.data;
            for (var i = 0; i < responseData.length; i++) {
              if (responseData[i].delivery_method == 1) {
                responseData[i].delivery_method = 'Pickup';
              } else if (responseData[i].delivery_method == 5) {
                responseData[i].delivery_method = 'Delivery';
              } else {
                responseData[i].delivery_method = 'Same Day Delivery';
              }

              if (responseData[i].shipping_method == 10) {
                responseData[i].shipping_method = 'Standard Shipping';
              } else if (responseData[i].shipping_method == 20) {
                responseData[i].shipping_method = 'Saturday';
              } else {
                responseData[i].shipping_method = 'Priority Overnight';
              }

              // if (responseData[i].shipping_location == 1) {
              //   responseData[i].shipping_location = 'Home';
              // } else if (responseData[i].shipping_location == 5) {
              //   responseData[i].shipping_location = 'Work';
              // } else if (responseData[i].shipping_location == 10) {
              //   responseData[i].shipping_location = 'Caregiver';
              // } else {
              //   responseData[i].shipping_location = 'Other';
              // }
            }

            this.setState({ trackScheduleDelivery: responseData });
            //console.log('Track Schedule Delivery', this.state.trackScheduleDelivery);
            let res = responseData.filter(past_delivery_date => new Date(past_delivery_date.delivery_date).setHours(0, 0, 0, 0) >= new Date().setHours(0, 0, 0, 0)).map((data, index) => (
              {
                Sno: index + 1,
                ShippingDate: Moment(data.shipping_date).format('MM/DD/YYYY'),
                DeliveryDate: Moment(data.delivery_date).format('MM/DD/YYYY'),
                ShippingNotes: data.delivery_notes_shipment,
                DeliveryMethod: data.delivery_method,
                ShippingMethod: data.shipping_method,
                ShippingLocation: data.shipping_location,
                NewPatient: data.new_patient == true ? 'Yes' : 'No',
                Action: 
                <div><Button onClick={() => {this.editScheDelivery(data.id) }} className="f-12 nowrap">
                  <i className="fa fa-edit" aria-hidden="true"></i>
                </Button>{localStorage.getItem('adminCheck') === "true" ? (
                  <Button style={{ marginLeft: "15px" }} onClick={() => { this.deleteScheDelivery(data.id) }} className="btn btn-primary mt-1 btnControl btndel f-12 nowrap">
                    <IconWithTooltip Icon={DeleteIcon} text="Remove" />
                  </Button>) : ''}
                </div>,
              }));
            this.setState({ rowData: res });
          } else if (response.data.status_code == 406) {
            localStorage.removeItem('token');
            localStorage.removeItem('user_name');

            localStorage.removeItem('adminCheck');
            localStorage.setItem('tokenExpired', true);

            const tokenStatus = localStorage.getItem('tokenExpired');
            if (tokenStatus) {
              localStorage.removeItem('tokenExpired');
              toast.error(TOKEN_EXPIRED, {
                position: 'top-right',
                autoClose: 2500,
              });
            }

            const isLogged = !!localStorage.getItem('token');
            if (!isLogged) {
              setTimeout(() => {
                this.props.history.push("/login");
              }, 2500);
            }
          }
        } else {
          toast.error(INTERNAL_SERVER_ERROR, {
            position: 'top-right',
            autoClose: 2500,
          });
        }
      })
      .catch(error => {
        console.log(error);
      });
  }

  deleteScheDelivery = (id) => {
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    axios
      .delete(`${API_URL}/api/schedule_delivery_get_update/${id}`, config)
      .then(response => {
        if (response.status === 200) {
          window.onload = this.componentDidMount()
          toast.error(response.data.message, {
            position: 'top-right',
            autoClose: 3000,
          });
        }
        else if (response.status !== 200) {
          toast.error(response.data.message, {
            position: 'top-right',
            autoClose: 3500,
          });
          return false;
        }
      })
      .catch(error => {
        console.log(error);
      });
      this.setState({ selectedValue: null });  
      this.state.refreshing = true
      window.setTimeout(function () {
        window.location.reload();
      }, 3000);
      this.timeout = setTimeout(() => {
        this.setState({ refreshing: false });
      }, 3000);
  } 
  updScheduleDelivery = (id) => {
    let errors = { ...this.state.errors };
    let list = { ...this.state.scheduleDelivery };
    this.state.scheduleDelivery.medications = this.state.medicationIdsArr;
    //this.state.scheduleDelivery.medications;
    if (this.state.medicationIdsArr.length == 0) {
      errors.medications = 'Please Enter The Medications.';
    } else {
      errors.medications = '';
    }
    
    
    if (list.shipping_date == '') {
      errors.shipping_date = 'Please Enter The Shipping Date.';
    } else {
      errors.shipping_date = '';
    }

    if (list.delivery_date == '') {
      errors.delivery_date = 'Please Enter Delivery Date.';
    } else {
      errors.delivery_date = '';
    }

    if (list.delivery_method == '') {
      errors.delivery_method = 'Please Select The Delivery Method.';
    } else {
      errors.delivery_method = '';
    }

    if (list.shipping_method == '') {
      errors.shipping_method = 'Please Select The Shipping Method.';
    } else {
      errors.shipping_method = '';
    }

    if (errors.medications != '' || errors.shipping_date != '' || errors.delivery_date != '' || errors.delivery_method
      || errors.shipping_method) {
      this.setState({ errors: errors });
      return false;
    }
    
    this.setState({ show: false });
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const formData = {
      patient: this.state.scheduleDelivery.patient_id,
      patient_shipping_notes: this.state.scheduleDelivery.patient_shipping_notes,
      shipping_date: this.state.scheduleDelivery.shipping_date,
      delivery_date: this.state.scheduleDelivery.delivery_date,
      delivery_method: this.state.scheduleDelivery.delivery_method,
      shipping_method: this.state.scheduleDelivery.shipping_method,
      shipping_location: this.state.scheduleDelivery.shipping_location,
      new_patient: this.state.scheduleDelivery.new_patient,
      medications: this.state.medicationIdsArr,
      
      delivery_notes_shipment: this.state.scheduleDelivery.delivery_notes_shipment,
      
    }
    axios
      .put(`${API_URL}/api/schedule_delivery_get_update/${id}`, formData, config)
      .then(res => {
        if (res.status == 200) {
          this.state.refreshing = true
          toast.success(UPDATE_SCHDEULE_DELIVERY, {
            position: 'top-right',
            autoClose: 2500,
          });
          this.timeout = setTimeout(() => {
            this.setState({ refreshing: false });
          }, 2500);
          axios
            .get(`${API_URL}/api/schedule_delivery/?patient_id=${this.state.scheduleDelivery.patient}`, config)
            .then(response => {
              if (response.status == 200) {
                if (response.data.status == 200) {
                  let responseData = response.data.data;
                  for (var i = 0; i < responseData.length; i++) {
                    if (responseData[i].delivery_method == 1) {
                      responseData[i].delivery_method = 'Pickup';
                    } else if (responseData[i].delivery_method == 5) {
                      responseData[i].delivery_method = 'Delivery';
                    } else {
                      responseData[i].delivery_method = 'Same Day Delivery';
                    }

                    if (responseData[i].shipping_method == 10) {
                      responseData[i].shipping_method = 'Standard Shipping';
                    } else if (responseData[i].shipping_method == 20) {
                      responseData[i].shipping_method = 'Saturday';
                    } else {
                      responseData[i].shipping_method = 'Priority Overnight';
                    }

                    // if (responseData[i].shipping_location == 1) {
                    //   responseData[i].shipping_location = 'Home';
                    // } else if (responseData[i].shipping_location == 5) {
                    //   responseData[i].shipping_location = 'Work';
                    // } else if (responseData[i].shipping_location == 10) {
                    //   responseData[i].shipping_location = 'Caregiver';
                    // } else {
                    //   responseData[i].shipping_location = 'Other';
                    // }
                  }

                  let res = responseData.filter(past_delivery_date => new Date(past_delivery_date.delivery_date).setHours(0, 0, 0, 0) >= new Date().setHours(0, 0, 0, 0)).map((data, index) => (
                    {
                      Sno: index + 1,
                      ShippingDate: Moment(data.shipping_date).format('MM/DD/YYYY'),
                      DeliveryDate: Moment(data.delivery_date).format('MM/DD/YYYY'),
                      ShippingNotes: data.delivery_notes_shipment,
                      DeliveryMethod: data.delivery_method,
                      ShippingMethod: data.shipping_method,
                      ShippingLocation: data.shipping_location,
                      NewPatient: data.new_patient == true ? 'Yes' : 'No',
                      Action: 
                      <div><Button onClick={() => { this.editScheDelivery(data.id) }} className="f-12 nowrap">
                        <i className="fa fa-edit" aria-hidden="true"></i>
                     </Button>{localStorage.getItem('adminCheck') === "true" ? (
                      <Button style={{ marginLeft: "15px" }} onClick={() => { this.deleteScheDelivery(data.id) }} className="btn btn-primary mt-1 btnControl btndel f-12 nowrap">
                    <IconWithTooltip Icon={DeleteIcon} text="Remove" />
                    </Button>) : ''}
                    </div>,
                    
                    }));
                  this.setState({ rowData: res });
                } else if (response.data.status_code == 406) {
                  localStorage.removeItem('token');
                  localStorage.removeItem('user_name');

                  localStorage.removeItem('adminCheck');
                  localStorage.setItem('tokenExpired', true);

                  const tokenStatus = localStorage.getItem('tokenExpired');
                  if (tokenStatus) {
                    localStorage.removeItem('tokenExpired');
                    toast.error(TOKEN_EXPIRED, {
                      position: 'top-right',
                      autoClose: 2500,
                    });
                  }

                  const isLogged = !!localStorage.getItem('token');
                  if (!isLogged) {
                    setTimeout(() => {
                      this.props.history.push("/login");
                    }, 2500);
                  }
                }
              } else {
                toast.error(INTERNAL_SERVER_ERROR, {
                  position: 'top-right',
                  autoClose: 2500,
                });
              }
            })
            .catch(error => {
              console.log(error);
            });
          
          this.setState({
            scheduleDelivery: {
              id: '',
              patient_shipping_notes:'',
              shipping_date: '',
              delivery_date: '',
              delivery_method: '',
              shipping_method: '',
              shipping_location: '',
              new_patient: false,
              
              delivery_notes_shipment: '',
              
            }
          });
          this.setState({ selectedValue: null }); 
          this.setState({
            errors: {
              patient_shipping_notes:'',
              shipping_date: '',
              delivery_date: '',
              delivery_method: '',
              shipping_method: '',
              shipping_location: '',
              new_patient: false,
              medications: '',
              delivery_notes_shipment: '',
            }
          });
        } else {
          toast.error(INTERNAL_SERVER_ERROR, {
            position: 'top-right',
            autoClose: 2500,
          });
        }
      })
      .catch(error => {
        console.log(error);
      });
  };
   cancelUpdScheduleDelivery = () => {
    this.state.refreshing = true
    this.timeout = setTimeout(() => {
      this.setState({ refreshing: false });
    }, 1000); 
    this.setState({ show: false });
    this.setState({
      scheduleDelivery: {
        id: '',
        patient_shipping_notes:'',
        shipping_date: '',
        delivery_date: '',
        delivery_method: '',
        shipping_method: '',
        shipping_location: '',
        new_patient: false,
        delivery_notes_shipment: '',
      }
    });
    this.setState({ selectedValue: null });  
    this.setState({ medicationIdsArr :[] });
    this.setState({
      errors: {
        patient_shipping_notes:'',
        shipping_date: '',
        delivery_date: '',
        delivery_method: '',
        shipping_method: '',
        shipping_location: '',
        new_patient: false,
        medications: '',
        delivery_notes_shipment: '',
      }
    });
  };
  render() {
    const isLogged = !!localStorage.getItem('token');
    if (!isLogged) {
      return <Redirect to="/login" />;
    }

    let mbdDatas = {
      columns: this.state.columns,
      rows: this.state.rowData
    }

    const { errors } = this.state;
    return (
      <>
        <Prompt when={this.state.scheduleDeliveryData} message="You have unsaved changes! Save changes before leaving ?" />
                
        <ToastContainer />
        <CommonHeader />
        <section id="wrapper">
          <CommonSidebar />
          <div className="queue-block patientPageContainer mt-0">
            
            <div className="row mb-4">
              <div className="col-md-12 mb-2 border-bottom-custom">
            <p className="mb-2">
                  Form – Schedule Delivery – This is feature is optional, the client may or may not choose to utilize this tool.
                </p>
              </div>
              <div className="col-md-12">
                <small>Form - Schedule a new delivery</small>
              </div>
            </div>

            <div className="row mb-4">
              <div className="col-sm-12">
                <h3 className="text-blue mb-1">
                  <i className="fa fa-user fa-fw user-icon mr-2"></i>
                  {this.state.refreshing == false ?
                    <Link to={{
                      'pathname': '/patientcliprofile', 'state': {
                        'transaction_id': this.state.transaction_id,
                        'patient_id': this.state.patientid, 'patient_name': this.state.patient_name,'data_added' : this.state.data_added 
                      }
                    }}>
                    {this.state.patient_name}
                    </Link>
                    : 
                    <Link className="disabledCursor" to={{
                      'pathname': '/patientcliprofile', 'state': {
                        'transaction_id': this.state.transaction_id,
                        'patient_id': this.state.patientid, 'patient_name': this.state.patient_name,'data_added' : this.state.data_added 
                      }}}>
                    {this.state.patient_name}
                    </Link>
                  }
                 </h3>
                 <hr />  
              </div>
              <div className="col-md-12 text-right">
                <div className="form-groupp row patientHeaderButton">
                  <Link className="btn btn-primary" to="#">Export To Word</Link>
                </div>
              </div>
            </div>

            <div className="row mb-4">
              <div className="col-md-2">
                <label htmlFor="Patient" className="col-form-label"> Patient Shipping Notes </label>
              </div>
              <div className="col-sm-10">
                <textarea className="textareaf form-control" id="w3review" name="w3review" placeholder="Patient Shipping Notes"></textarea>
              </div>
            </div>

            <div className="row mb-4">
              <div className="col-lg-12">
                <div className="tabsCustom p-4 schedulartab">
                  <form>
                    <div className="row">
                      <div className="col-lg-6 paddingzero">
                        <div className="form-group  form-inline">
                          <label htmlFor="shippingDate" className="col-md-3"> Shipping Date :<span className='error'>*</span></label>
                          <input type="date"  onChange={(event) => { this.handleScheDelDataChanges(event); }} min = {this.todayDate} onKeyDown={(event)=>{event.preventDefault()}}
                            className="form-control" value={this.state.scheduleDelivery.shipping_date} name="shipping_date" id="shipping_date" />
                          {errors.shipping_date.length > 0 && <span className='error' style={{ color: 'red' }}>{errors.shipping_date}</span>}
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group  form-inline">
                          <label htmlFor="deliveryDate" className="col-md-3"> Delivery Date :<span className='error'>*</span></label>
                          <input type="date" onChange={this.handleScheDelDataChanges.bind(this)} value={this.state.scheduleDelivery.delivery_date}
                           min = {this.todayDate} onKeyDown={(event)=>{event.preventDefault()}} className="form-control" name="delivery_date" id="delivery_date" />
                          {errors.delivery_date.length > 0 && <span className='error' style={{ color: 'red' }}>{errors.delivery_date}</span>}
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6 paddingzero">
                        <div className="form-group  form-inline">
                          <label htmlFor="deliveryMethod" className="col-md-3">Method of Delivery :<span className='error'>*</span></label>
                          <select onChange={(event) => { this.handleScheDelDataChanges(event); }} value={this.state.scheduleDelivery.delivery_method} className="form-control col-md-8" name="delivery_method">
                            <option value="">Please Choose</option>
                            <option value="1">Pickup</option>
                            <option value="5">Delivery</option>
                            <option value="10">Same Day Delivery</option>
                          </select>
                          {errors.delivery_method.length > 0 && <span className='error' style={{ color: 'red' }}>{errors.delivery_method}</span>}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group  form-inline">
                          <label htmlFor="shippingMethod" className="col-md-3">Shipping Method :<span className='error'>*</span></label>
                          <select onChange={(event) => { this.handleScheDelDataChanges(event); }} value={this.state.scheduleDelivery.shipping_method} className="form-control col-md-8" name="shipping_method">
                            <option value="">Please Choose</option>
                            <option value="10">Standard Shipping</option>
                            <option value="20">Saturday</option>
                            <option value="30">Priority Overnight</option>
                          </select>
                          {errors.shipping_method.length > 0 && <span className='error' style={{ color: 'red' }}>{errors.shipping_method}</span>}
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6 paddingzero">
                        <div className="form-group  form-inline">
                          <label htmlFor="Medications" className="col-md-3 text-left">Medications :<span className='error'>*</span></label>
                          <div className="col-md-8 paddingzero">
                            <Select
                              className="dropdown"
                              placeholder="Please Choose Medications"
                              options={this.state.plainArray} // set list of the data 
                              onChange={this.onSelectedItemsChange.bind(this)} // assign onChange function
                              value={this.state.selectedValue}
                              isMulti
                            />
                          </div>
                          {errors.medications.length > 0 && <span className='error' style={{ color: 'red' }}>{errors.medications}</span>}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div class="custom-control custom-checkbox">
                          <input type="checkbox" onChange={(event) => { this.handleScheDelDataChanges(event); }} checked={this.state.scheduleDelivery.new_patient} name="new_patient" id="newPatient" className="custom-control-input" />
                          <label className="custom-control-label" htmlFor="newPatient">
                            New Patient<span className='error'></span>
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6 paddingzero">
                        <div className="form-group  form-inline">
                          <label htmlFor="shippingLocation" className="col-md-3 text-left">Shipping Location :</label>
                          <select name="shipping_location" onChange={(event) => { this.handleScheDelDataChanges(event); }} value={this.state.scheduleDelivery.shipping_location} className="form-control col-md-8">
                            <option value="">Please Select</option>
                            {this.state.shippingLocation != null && this.state.shippingLocation.map(location => (
                              <option value={location.street}> {location.street} </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-6">
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-2">
                        <label htmlFor="Patient" className="col-form-label"> Delivery Notes This Shipment :</label>
                      </div>
                      <div className="col-sm-8">
                        <textarea className="textareaf form-control" id="deliveryNotesShipment" name="delivery_notes_shipment"
                          placeholder="delivery Shipping Notes" onChange={(event) => { this.handleScheDelDataChanges(event); }} value={this.state.scheduleDelivery.delivery_notes_shipment}></textarea>
                      </div>
                    </div>
                    <div className="row mb-4">
                      <div className="col-md-12 mb-2 mt-4 text-right">
                        <button type="button" hidden={this.state.show} class="btn btn-primary" onClick={(event) => { this.handleScheduleDelivery(event); }}>Save Change</button>
                        <button type="button" hidden={!this.state.show} class="btn btn-primary" onClick={() => { this.cancelUpdScheduleDelivery(); }}>Cancel</button>
                        <button type="button" hidden={!this.state.show} class="btn btn-primary" onClick={(event) => { this.updScheduleDelivery(this.state.scheduleDelivery.id); }}>Save Changes</button>
                        {/* <button hidden={!this.state.show} className="btn btn-primary mb-4" onClick={e => this.updScheduleDelivery(e, this.state.id)}>Save Changes</button> */}

                      </div>
                    </div>
                  </form>

                  <hr />

                  <div className="tab-content">
                    <div id="Archives" className="container-fluid tab-pane fade active in px-0">
                      <div className="table-responsive">
                        <MDBDataTable
                          striped
                          bordered
                          small
                          hover
                          searching={true}
                          data={mbdDatas}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <CommonFooter />
      </>
    );
  }
}
