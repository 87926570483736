export const RESET_PASSWORD = 'Reset/RESET_PASSWORD';
export const SUCCESS_FORGOT_PASSWORD = 'Reset/SUCCESS_FORGOT_PASSWORD';
export const SUCCESS_SET_PASSWORD = 'Reset/SUCCESS_SET_PASSWORD';
export const ERROR_SET_PASSWORD = 'Reset/ERROR_SET_PASSWORD';
export const ERROR_FORGOT_PASSWORD = 'Reset/ERROR_FORGOT_PASSWORD';
export const SUCCESS_FORGOT_MESSAGE = 'Check your email for a link to reset your password. If it doesn’t appear within a few minutes, check your spam folder.';
export const ERROR_FORGOT_MESSAGE = 'Something went wrong! Please try again.';
export const PASSWORD_VALIDATION = 'Password and confirm password should be same.';
export const SET_PASSWORD_MESSAGE_ERROR = 'Something went wrong! Please try again.';
export const SET_PASSWORD_MESSAGE_SUCCESS = 'Your password changed successfully.';
export const VALID_PASSWORD = "Password should not be empty!";
export const VALID_CONFIRM_PASSWORD = "Confirm Password should not be empty!";
export const INTERNAL_FAILURE = "Internal Failure";
export const API_URL = 'http://172.16.9.117:8001';
export const TOKEN_EXPIRED = 'Password reset link has been expired.';
export const TOKEN_EXPIRED_MSG_DB = 'Signature has expired';