/**
 * AdverseEvents
 *
 * This is the page we show when the user click on Adverse Events 
 */

 import React from 'react';
 import './style.css';
 
 export default function AdverseEvents() {
   return (
     <div id="adverseevents col-md-12">
         <div className="col-md-12 paddingzero divspace">
             <h4>Definitions</h4>
             <label>A brief definition is provided to clarify the meaning of each AE term.</label>
         </div>
         <div className="col-md-12 paddingzero divspace">
             <h4>Grades</h4>
             <label className="paddingzero">Grade refers to the severity of the AE. The CTCAE displays Grades 1 through 5 with unique clinical 
             descriptions of severity for each AE based on this general guideline:</label>
            <div className="col-md-12"> 
                <span className="col-md-1 paddingzero"> Grade 1 - </span>
                <label className="col-md-11">Mild; asymptomatic or mild symptoms: Clinical or diagnostic observations only; intervention
not indicated</label>
            </div>
            <div className="col-md-12"> 
                <span className="col-md-1 paddingzero"> Grade 2 - </span>
                <label className="col-md-11">Moderate; minimal, local or noninvasive intervention indicated; limiting age-appropriate
instrumental ADL*</label>
            </div>
            <div className="col-md-12"> 
                <span className="col-md-1 paddingzero" style={{ verticalAlign: 'top' }} > Grade 3 - </span>
                <label className="col-md-11">Severe or medically significant but not immediately life-threatening; hospitalization or prolongation
of hospitalization indicated; disabling; self care ADL*</label>
            </div>
            <div className="col-md-12"> 
                <span className="col-md-1 paddingzero"> Grade 4 - </span>
                <label className="col-md-11">Life-threatening consequences: Urgent intervention indicated.</label>
            </div>
            <div className="col-md-12"> 
                <span className="col-md-1 paddingzero"> Grade 5 - </span><label className="col-md-11">Death related to AE.</label>
                
            </div>
        </div>
        <div className="col-md-12 paddingzero divspace">
            <label className="col-md-12 paddingzero"> A semi-colon indicates "or" within the description of the Grade </label>
            <label className="col-md-12 paddingzero">A single dash (-) indicates a Grade in not available.</label>
            <label className="col-md-12 paddingzero">Not all Grades are appropriate for all AEs. Therefore, some AEs are listed with less than five options for Grade selection.</label>
        </div>
        <div className="col-md-12 paddingzero divspace">
             <h4>Grade 5</h4>
             <label className="col-md-12 paddingzero">Grade 5 (Death) is not appropriate for some AEs and therefore is not an option.</label>
             <h4 className="col-md-12 paddingzero divspace">Activities of Daily Living (ADL)</h4>
             <label className="col-md-12 paddingzero">* Instrumental ADL refers to preparing meals, shopping for groceries or clothes, using the telephone, managing money, etc.</label>
             <label className="col-md-12 paddingzero">* * Self-care ADL refers to bathing, dressing and undressing, feeding self, using the toilet, taking medications and not
bedridden.</label>
         </div>

     </div>
   );
 }
 