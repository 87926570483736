/*
 * Patient Demographics Page
 */

import React from 'react';

import axios from 'axios';

import 'bootstrap/dist/css/bootstrap.css';
import '../../containers/css/style_semantic.css';
import './style.css';

import dateFormat from 'dateformat';

import { ToastContainer, toast } from 'react-toastify';

import {
  VALID_EDUCATION_DATA,
  UPDATE_PATIENT_DATA,
} from './constants';

import {
  API_URL,
} from '../../config';

export default class PatientDemographics extends React.Component {
 
  token = localStorage.getItem('token');
  config = {
      headers: { Authorization: `Bearer ${this.token}` },
  };  

  constructor(props) {
    super(props);
    this.state = {
      org_high_risk: [{
        id: null,
        modified: '',
        value: '',
        notes: '',
        active: true,
      }],
      high_risk_condition: [],
      original_allergies_condition: [{
        id: null,
        modified: '',
        value: '',
        notes: '',
        active: true,
      }],
      allergies_condition: [],
      original_comborbid_condition: [{
        id: null,
        modified: '',
        value: '',
        notes: '',
        active: true,
      }],
      comborbid_condition: [],
    }
  }

  componentDidMount(){
    const patient_id = this.props.patient_id
    axios
      .get(`${API_URL}/api/api-patient/${patient_id}`, this.config)
      .then(response => {
        if (response.data.status === 200 && response.status === 200) {
          if (response.data.data.high_risk_condition.length > 0) {
            this.setState({ high_risk_condition: response.data.data.high_risk_condition, });
          }

          if (response.data.data.allergies_condition.length > 0) {
            this.setState({ allergies_condition: response.data.data.allergies_condition, });
          }

          if (response.data.data.comborbid_condition.length > 0) {
            this.setState({ comborbid_condition: response.data.data.comborbid_condition, });
          }
        } else if (response.status !== 200) {
          toast.error(VALID_EDUCATION_DATA, {
            position: 'top-right',
            autoClose: 3500,
          });
          return false;
        }
      })
      .catch(error => {
        console.log(error);
      });
  }  

  /******* High Risk Functions *******/
  /* Handle the High risk condition */
  async handleOrgHigRisConChanges(e) {
    const { name, value } = e.target;
    const list = [...this.state.org_high_risk];
    const list2 = [...this.state.high_risk_condition];
    const orgList = [{
      id: null,
      notes: '',
      value: '',
      modified: '',
      active: true,
    }];
    if (name == 'active') {
      list[0][name] = !this.state.org_high_risk[0].active;
    } else {
      list[0][name] = value;
    }
    if (name == 'value') {
      list2.unshift(this.state.org_high_risk[0]);
    }
    await this.setState({ org_high_risk: list, });
    await this.setState({ high_risk_condition: list2, });
    await this.setState({ org_high_risk: orgList, });
    /* Used For Chnage focus on dropdown */
    document.getElementById('high_risk_0').focus();
  }

  /* Handle Patient Clinical Details Conditions Changes */
  async handleHigRisConChanges(e, index) {
    const { name, value } = e.target;
    const list = [...this.state.high_risk_condition];
    if (name == 'active') {
      list[index][name] = !this.state.high_risk_condition[index].active;
    } else {
      list[index][name] = value;
    }
    await this.setState({ high_risk_condition: list, });
  }
  
  /******* Allergy Functions *******/
  /* Handle Patient Clinical Details For Allergies Conditions On Click */
  async handleOrgAllergiesConOnChanges(e) {
    const { name, value } = e.target;
    const list = [...this.state.original_allergies_condition];
    const list2 = [...this.state.allergies_condition];
    const orgList = [{
      id: null,
      notes: '',
      value: '',
      modified: '',
      active: true,
    }];
    if (name == 'active') {
      list[0][name] = !this.state.original_allergies_condition[0].active;
    } else {
      list[0][name] = value;
    }
    if (name == 'value') {
      list2.unshift(this.state.original_allergies_condition[0]);
    }
    await this.setState({ original_allergies_condition: list, });
    await this.setState({ allergies_condition: list2, });
    await this.setState({ original_allergies_condition: orgList, });
    /* Used For Chnage focus on dropdown */
    document.getElementById('allergy_0').focus();
  }
  /* Handle Patient Clinical Details For Allergies Conditions Changes */
  async handleAllergiesConChanges(e, index) {
    const { name, value } = e.target;
    const list = [...this.state.allergies_condition];

    if (name == 'active') {
      list[index][name] = !this.state.allergies_condition[index].active;
    } else {
      list[index][name] = value;
    }
    await this.setState({ allergies_condition: list, });
  }

  /******* Combroid Functions *******/  
  /* Handle Patient Clinical Details For Comborbid Conditions Changes */
  async handleComborbidConChanges(e, index) {
    const { name, value } = e.target;
    const list = [...this.state.comborbid_condition];

    if (name == 'active') {
      list[index][name] = !this.state.comborbid_condition[index].active;
    } else {
      list[index][name] = value;
    }
    await this.setState({ comborbid_condition: list, }); 
  }
  /* Handle Patient Clinical Details For Comborbid Conditions On Changes */
  async handleOrgComborbidConChanges(e) {
    const { name, value } = e.target;
    const list = [...this.state.original_comborbid_condition];
    const list2 = [...this.state.comborbid_condition];
    const orgList = [{
      id: null,
      notes: '',
      value: '',
      modified: '',
      active: true,
    }];
    if (name == 'active') {
      list[0][name] = !this.state.original_comborbid_condition[0].active;
    } else {
      list[0][name] = value;
    }
    if (name == 'value') {
      list2.unshift(this.state.original_comborbid_condition[0]);
    }
    await this.setState({ original_comborbid_condition: list, });
    await this.setState({ comborbid_condition: list2, });
    await this.setState({ original_comborbid_condition: orgList, });
    /* Used For Chnage focus on dropdown */
    document.getElementById('comborbit_0').focus();
  }

  /* Handling Patient Data Submit */
  handlePatientDataSubmit = (event) => {
    event.preventDefault();
    const {
      gender,
      patient_phone,
      patient_cell,
      patient_height,
      patient_weight,
      dob,
      mrn,
      pregnancy_comments,
      patient_email,
    } = this.props.patientProData;

    let highRiskConData = [];
    for (var i = 0; i < this.state.high_risk_condition.length; i++) {
      if (this.state.high_risk_condition[i].value !== '') {
        highRiskConData.push(this.state.high_risk_condition[i]);
      }
    }

    let allergiesConData = [];
    for (var i = 0; i < this.state.allergies_condition.length; i++) {
      if (this.state.allergies_condition[i].value !== '') {
        allergiesConData.push(this.state.allergies_condition[i]);
      }
    }

    let comborbidConData = [];
    for (var i = 0; i < this.state.comborbid_condition.length; i++) {
      if (this.state.comborbid_condition[i].value !== '') {
        comborbidConData.push(this.state.comborbid_condition[i]);
      }
    }

    const patient_id = this.props.patient_id

    const patientFormData = {
      patient_email: patient_email,
      gender: gender,
      patient_phone: patient_phone,
      patient_cell: patient_cell,
      patient_height: patient_height,
      patient_weight: patient_weight,
      dob: dob,
      mrn: mrn,
      pregnancy_comments: pregnancy_comments,
      high_risk_condition: highRiskConData,
      allergies_condition: allergiesConData,
      comborbid_condition: comborbidConData,
    }

    axios.put(`${API_URL}/api/api-patient/${patient_id}`, patientFormData, this.config)
      .then((response) => {
        if (response.status === 200) {
          window.onload = this.componentDidMount()
          if (response.data.data.high_risk_condition.length > 0) {
            this.setState({ high_risk_condition: response.data.data.high_risk_condition, });
          }

          if (response.data.data.allergies_condition.length > 0) {
            this.setState({ allergies_condition: response.data.data.allergies_condition, });
          }

          if (response.data.data.comborbid_condition.length > 0) {
            this.setState({ comborbid_condition: response.data.data.comborbid_condition, });
          }
          toast.success(UPDATE_PATIENT_DATA, {
            position: 'top-right',
            autoClose: 3000,
          });
          return false;
        }
      })
      .catch((error) => {
        toast.error(VALID_EDUCATION_DATA, {
          position: 'top-right',
          autoClose: 3500,
        });
        return false;
      });
  };



  render() {

    return (
      <>
      <div className="row" style={{ padding: "15px" }}>
  
        {/* Patient Name */}
        <div className="col-lg-4">
          <div className="form-group row">
            <label htmlFor="Age" className="col-sm-3">Patient</label>
            <div className="col-sm-9">
              <input type="text" name="patient_name" className="form-control" value={this.props.patientProData.patient_name}
                disabled={true} />
            </div>
          </div>
        </div>

        {/* MRN */}
        <div className="col-lg-4">
          <div className="form-group row">
            <label htmlFor="MRN" className="col-sm-3">MRN</label>
            <div className="col-sm-9">
              <input type="text" name="mrn" className="form-control" value={this.props.patientProData.mrn} disabled={true} />
            </div>
          </div>
        </div>

        {/* AGE */}
        <div className="col-lg-4">
          <div className="form-group row">
            <label htmlFor="Age" className="col-sm-3">Age</label>
            <div className="col-sm-9">
              <input type="text" name="age" className="form-control" value={this.props.patientProData.age} disabled={true} />
            </div>
          </div>
        </div>

        {/* Gender */}
        <div className="col-lg-4">
          <div className="form-group row">
            <label htmlFor="Age" className="col-sm-3">Gender</label>
            <div className="col-sm-9">
              <div className="form-check-inline">
                <label htmlFor="Gender" className="form-check-label">
                  <input type="radio" className="form-check-input" name="gender" disabled={true}
                    checked={this.props.patientProData.gender === "M"} />
                  Male
                </label>
              </div>
              <div className="form-check-inline">
                <label htmlFor="Gender" className="form-check-label">
                  <input type="radio" className="form-check-input" disabled={true} name="gender"
                    checked={this.props.patientProData.gender === "F"} />
                  Female
                </label>
              </div>
            </div>
          </div>
        </div>

        {/* Deceased */}
        <div className="col-lg-4">
          <div className="form-check-inline">
            <label htmlFor="Deceased" className="form-check-label">
              <input type="radio" className="form-check-input" name="deceased"
                checked={this.props.patientProData.deceased} disabled={true} />
              Deceased
            </label>
          </div>
        </div>
        
        {/* DOB */}
        <div className="col-lg-4">
          <div className="form-group row">
            <label htmlFor="Age" className="col-sm-3">DOB</label>
            <div className="col-sm-9">
              <input type="date" onChange={this.props.handlePatientDataChanges} name="dob" className="form-control" value={this.props.patientProData.dob} />
            </div>
          </div>
        </div>
        
        {/* Phone */}
        <div className="col-lg-4">
          <div className="form-group row">
            <label htmlFor="Phone" className="col-sm-3">Phone</label>
            <div className="col-sm-9">
              <input type="text" noValidate name="patient_phone" className="form-control" onChange={this.props.handlePatientDataChanges}
                value={this.props.patientProData.patient_phone} />
            </div>
          </div>
        </div>

        {/* Mobile */}
        <div className="col-lg-4">
          <div className="form-group row">
            <label htmlFor="Phone" className="col-sm-3">Mobile</label>
            <div className="col-sm-9">
              <input type="text" noValidate name="patient_cell" className="form-control" onChange={this.props.handlePatientDataChanges}
                value={this.props.patientProData.patient_cell} />
            </div>
          </div>
        </div>

        {/* Email */}
        <div className="col-md-4">
          <div className="form-group row">
            <label htmlFor="Patient" className="col-sm-3 col-form-label">Email </label>
            <div className="col-sm-9">
              <input type="text" onChange={this.props.handlePatientDataChanges} name="patient_email" className="form-control" placeholder="Enter Email"
                value={this.props.patientProData.patient_email} />
            </div>
          </div>
        </div>
        
        {/* General Notes */}
        <div className="col-md-4">
          <div className="form-group">
            <label htmlFor="Patient" className="col-sm-12 col-form-label">General Notes</label>
            <div className="col-sm-10">
              <textarea className="textareaf" id="w3review" name="pregnancy_comments"
                placeholder="General Notes" value={this.props.patientProData.pregnancy_comments}
                onChange={this.props.handlePatientDataChanges}>
              </textarea>
            </div>
          </div>
        </div>

        {/* Height */}
        <div className="col-lg-4">
          <div className="form-group row">
            <label htmlFor="Height" className="col-sm-3">Height</label>
            <div className="col-sm-9">
              <input type="text" noValidate name="patient_height" onChange={this.props.handlePatientDataChanges} className="form-control wdt100 float-left w-100" value={this.props.patientProData.patient_height} />
              <label htmlFor="Height" className="unit-label">Inches</label>
            </div>
          </div>
        </div>

        {/* Weight */}
        <div className="col-lg-4">
          <div className="form-group row">
            <label htmlFor="Weight" className="col-sm-3">Weight</label>
            <div className="col-sm-9">
              <input type="text" name="patient_weight" onChange={this.props.handlePatientDataChanges} className="form-control wdt100 float-left w-100" value={this.props.patientProData.patient_weight} />
              <label htmlFor="Weight" className="unit-label">lbs </label>
            </div>
          </div>
        </div>
      
      </div> 
      
      {/* High Risk Conditions */}  
      <div className="col-sm-12 mt-4 section-sepration">
        <div class="table-responsive">
          <table class="table RX-table">
            <thead>
              <tr>
                <th>Active</th>
                <th>High Risk Conditions</th>
                <th>Notes</th>
                <th>Last Updated</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                {/* Active Checkbox */}
                <td>
                  <input type="checkbox" checked={this.state.org_high_risk[0].active} name="active" onChange={(e) => this.handleOrgHigRisConChanges(e)} />
                </td>
                {/* High risk condition dropdown */}
                <td style={{ "width": "250px" }}>
                  <select onChange={(e) => this.handleOrgHigRisConChanges(e)} class="form-control"
                    name="value" value={this.state.org_high_risk[0].value}>
                    <option value="">Please Select Conditions</option>
                    <option value="age">Age</option>
                    <option value="pregnancy">Pregnancy</option>
                    <option value="other">Other</option>
                  </select>
                </td>
                {/* Note */}
                <td>
                  <input type="text" class="form-control" name="notes" value={this.state.org_high_risk[0].notes} />
                </td>
                {/* Last Updated */}
                <td style={{ "width": "200px" }}>
                  <input type="text" disabled class="form-control" name="modified" />
                </td>
              </tr>

              {/* All Saved Records */}
              {this.state.high_risk_condition.length > 0 && this.state.high_risk_condition.map((x, i) => {
                return (
                  <tr>
                    <td>
                      <input type="checkbox" checked={this.state.high_risk_condition[i].active} name="active"
                        onChange={(e) => this.handleHigRisConChanges(e, i)} />
                    </td>
                    <td style={{ "width": "250px" }}>
                      <select onChange={(e) => this.handleHigRisConChanges(e, i)} class="form-control"
                        name="value" id={'high_risk_' + i} value={this.state.high_risk_condition[i].value}>
                        <option value="">Please Select Conditions</option>
                        <option value="age">Age</option>
                        <option value="pregnancy">Pregnancy</option>
                        <option value="other">Other</option>
                      </select>
                    </td>
                    <td>
                      <input type="text" class="form-control" name="notes" value={this.state.high_risk_condition[i].notes}
                        onChange={(e) => this.handleHigRisConChanges(e, i)} />
                    </td>
                    <td style={{ "width": "200px" }}>
                      <input type="text" disabled class="form-control" name="modified"
                        value={this.state.high_risk_condition[i].modified == '' ? '' : dateFormat(this.state.high_risk_condition[i].modified, "mm-dd-yyyy  HH:MM:ss")} />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      <hr/>
      
      {/* Allergies Drug / Food */}
      <div className="col-sm-12 mt-4 section-sepration">
        <div class="table-responsive">
          <table class="table RX-table">
            <thead>
              <tr>
                <th>Active</th>
                <th>Allergies Drug/Food</th>
                <th>Notes</th>
                <th>Last Updated</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                {/* Active Checkbox */}
                <td>
                  <input type="checkbox" checked={this.state.original_allergies_condition[0].active} name="active" onClick={(e) => this.handleOrgAllergiesConOnChanges(e)} />
                </td>
                {/* Allergy dropdown */}
                <td style={{ "width": "250px" }}>
                  <input type="text" class="form-control" name="value" value={this.state.original_allergies_condition[0].value} onClick={(e) => this.handleOrgAllergiesConOnChanges(e)}/>
                </td>
                  {/* Note */}
                <td>
                  <input type="text" class="form-control" name="notes" value={this.state.original_allergies_condition[0].notes} />
                </td>
                  {/* Last Updated */}
                <td style={{ "width": "200px" }}>
                  <input type="text" disabled class="form-control" name="modified" />
                </td>
              </tr>

              {/* All Saved Records */}
              {this.state.allergies_condition.length > 0 && this.state.allergies_condition.map((x, i) => {
                return (
                  <tr>
                    <td>
                      <input type="checkbox" checked={this.state.allergies_condition[i].active} name="active" onChange={(e) => this.handleAllergiesConChanges(e, i)} />
                    </td>
                    <td style={{ "width": "250px" }}>
                      <input type="text" class="form-control" name="value" id={'allergy_' + i} value={this.state.allergies_condition[i].value} onChange={(e) => this.handleAllergiesConChanges(e, i)} />
                    </td>
                    <td>
                      <input type="text" class="form-control" name="notes" value={this.state.allergies_condition[i].notes} onChange={(e) => this.handleAllergiesConChanges(e, i)} />
                    </td>
                    <td style={{ "width": "200px" }}>
                      <input type="text" disabled class="form-control" name="modified"
                      value={this.state.allergies_condition[i].modified == '' ? '' : dateFormat(this.state.allergies_condition[i].modified, "mm-dd-yyyy  HH:MM:ss")} />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      <hr/>

      {/* Comborbid Condition */} 
      <div className="col-sm-12 mt-4 section-sepration">
        <div class="table-responsive">
          <table class="table RX-table">
            <thead>
              <tr>
                <th>Active</th>
                <th>Comorbid Condition</th>
                <th>Notes</th>
                <th>Last Updated</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                {/* Active Checkbox */}
                <td>
                  <input type="checkbox" checked={this.state.original_comborbid_condition[0].active} name="active"
                    onChange={(e) => this.handleOrgComborbidConChanges(e)} />
                </td>
                {/* Combroid dropdown */}
                <td style={{ "width": "200px" }}>
                  <select class="form-control" name="value" value={this.state.original_comborbid_condition[0].value}
                    onChange={(e) => this.handleOrgComborbidConChanges(e)}>
                    <option value="">Please Select Status</option>
                    <option value="hiv">HIV</option>
                    <option value="diabetes">Diabetes</option>
                    <option value="heartdisease">Heart Disease</option>
                    <option value="cerebrovasculardisease">Cerebrovascular Disease</option>
                    <option value="hypertension">Hypertension</option>
                    <option value="copd">COPD</option>
                    <option value="liverdisease">Liver Disease</option>
                    <option value="kidneydisease">Kidney Disease</option>
                    <option value="other">Other</option>
                  </select>
                </td>
                {/* Notes */}
                <td>
                  <input type="text" class="form-control" name="notes" value={this.state.original_comborbid_condition[0].notes}/>
                </td>
                {/* Last Updated */}
                <td style={{ "width": "200px" }}>
                  <input type="text" disabled class="form-control" name="modified" />
                </td>
              </tr>
              {/* All saved records */}
              {this.state.comborbid_condition.length > 0 && this.state.comborbid_condition.map((x, i) => {
                return (
                  <tr>
                    <td>
                      <input type="checkbox" checked={this.state.comborbid_condition[i].active} name="active"
                        onChange={(e) => this.handleComborbidConChanges(e, i)} />
                    </td>
                    <td style={{ "width": "200px" }}>
                      <select class="form-control" id={'comborbit_' + i} name="value" value={this.state.comborbid_condition[i].value}
                        onChange={(e) => this.handleComborbidConChanges(e, i)}>
                        <option value="">Please Select Status</option>
                        <option value="hiv">HIV</option>
                        <option value="diabetes">Diabetes</option>
                        <option value="heartdisease">Heart Disease</option>
                        <option value="cerebrovasculardisease">Cerebrovascular Disease</option>
                        <option value="hypertension">Hypertension</option>
                        <option value="copd">COPD</option>
                        <option value="liverdisease">Liver Disease</option>
                        <option value="kidneydisease">Kidney Disease</option>
                        <option value="other">Other</option>
                      </select>
                    </td>
                    <td>
                      <input type="text" class="form-control" name="notes" value={this.state.comborbid_condition[i].notes}
                        onChange={(e) => this.handleComborbidConChanges(e, i)} />
                    </td>
                    <td style={{ "width": "200px" }}>
                      <input type="text" disabled class="form-control" name="modified"
                        value={this.state.comborbid_condition[i].modified == '' ? '' : dateFormat(this.state.comborbid_condition[i].modified, "mm-dd-yyyy  HH:MM:ss")} />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      
      {/* save changes button */}
      <div className="row">
        <div className="col-md-6"></div>
        <div className="col-md-6" style={{ float: "right", textAlign: "right" }}>
          <button className="btn btn-primary" type="button" onClick={evt => this.handlePatientDataSubmit(evt)}>Save Changes</button>
        </div>
        <div className="col-md-6"></div>
      </div>
      
      </>
    );
  }
}
