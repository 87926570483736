/*
 * TaskPage
 *
 *
 */

import React from 'react';

import CommonHeader from '../../components/CommonHeader';
import CommonSidebar from '../../components/CommonSidebar';
import CommonFooter from '../../components/CommonFooter';
import dateFormat from 'dateformat';

import { Redirect, Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';

import PlusIcon from '@material-ui/icons/Add';

import DeleteIcon from '@material-ui/icons/Delete';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';

import IconWithTooltip from 'icon-with-tooltip';
import CheckIcon from '@material-ui/icons/Check';

import {VALID_EDUCATION_DATA} from './constants';
import { Modal, Button, } from 'react-bootstrap';

import { API_URL, } from '../../config';
import axios from 'axios';

import 'bootstrap/dist/css/bootstrap.css';
import '../../containers/css/style_semantic.css';
import './style.css';
import insertionSort from './utils'
import AdversePopup from './AdverseEvents';

export default class LogIntervention extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentDate : new Date(),
      show: false,
      checked: false,
      goalChecked: false,
      InActive: true,
      goal_achieved:false,
      goal_not_achieved:false,
      patient_id : this.props.location.state !== undefined ? this.props.location.state.patient_id: null,
      patient_name : this.props.location.state !== undefined ? this.props.location.state.patient_name: null,
      transaction_id : this.props.location.state !== undefined ? this.props.location.state.transaction_id: null,
      data_added : this.props.location.state !== undefined ? this.props.location.state.data_added : null,
      origCareList: [],
      careList:[],
      updatecareList:[],
      careInputList: [
        {
          therapyCare: "",
          therapyStrategy: "",
          therapyGoal: "",
          therapyTiFrToMeetGoal: "",
          therapyOthers: "",
        },
      ],
      barrierTherapy:[],
      categoryToTherapy: [],
      idToTherapy: {},
      categories:[],
      commuInputList: [],
      aeGrade:[],
      plainArray:[],
      medication: '00',
      barrier_therapy: '',
      category: '',
      ae_grade: '',
      identified_date:'',
      achieved_date: null,
      fu_date: '',
      strategy_and_resource: '',
      goal: '',
      status: 'PATIENT_ACCEPT',
      timeframe_to_goal: '',
      assessment_id: '',
      
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleCategoryChange = this.handleCategoryChange.bind(this);
    this.onRadioChange = this.onRadioChange.bind(this);
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    this.handleAchievedDateChange=this.handleAchievedDateChange.bind(this);
  }

  // today date
  todayDate = new Date().toISOString().slice(0,10)
  
  /* get token from localStorage */
  token = localStorage.getItem('token');
  is_superAdmin = localStorage.getItem('adminCheck');

  config = {
      headers: { Authorization: `Bearer ${this.token}` },
  };

  onRadioChange = (e) => {
    this.setState({
      status: e.target.value
    });
  }

  // implemented only for goal
  changeGoalAchievedState = (e) =>{
    if (e.target.name ==="goal_achieved"){
      this.setState({goal_achieved: !this.state.goal_achieved})
    }
    else if (e.target.name ==="goal_not_achieved"){
      this.setState({goal_not_achieved: !this.state.goal_not_achieved})
    }
  }

  //sort carelist
  sortCareLists = (list) => {
    let uneditAble = [];
    let editAble = [];
    list.map((eachGoal) => {
      eachGoal.achieved_date === null ? editAble.push(eachGoal) : uneditAble.push(eachGoal)
    })
    uneditAble = insertionSort(uneditAble)
    editAble = insertionSort(editAble)
    let arraySorted = editAble.concat(uneditAble)
    // console.log(arraySorted)
    return arraySorted
  }

/* get All Education Assessment */
getEducationAssessment=()=> {
  axios
    .get(`${API_URL}/api/educational_assessment/?patient_id=`+this.state.patient_id, this.config)
    .then(response => {
      if (response.data.status === 200 && response.status === 200) {
        response.data.data.map((x) =>{
          // console.log(x)
          if (x.is_active == true){
            // console.log(x)
            this.setState({
              assessment_id: x.id
            });
            // console.log(this.state.assessment_id)
          }
        });
      }
    })
    .catch(error => {
      console.log(error);
  });
}

/* get All care plan*/
  getCarePlan=()=> {
    axios
      .get(`${API_URL}/api/log_intervention/?patient_id=`+this.state.patient_id, this.config)
      .then(response => {
        if (response.data.status === 200 && response.status === 200) {
          this.setState({
            careList: this.sortCareLists(response.data.data),
            origCareList: this.sortCareLists(JSON.parse(JSON.stringify(response.data.data))),
          });
          this.getCategories();
        }     
        else if(response.status !== 200) {
          toast.error(VALID_EDUCATION_DATA, {
            position: 'top-right',
            autoClose: 3500,
          });

          return false;
        }
      })
      .catch(error => {
        console.log(error);
      });
  }

// Get All medication Details
  getMedication = () => {
    axios
      .get(`${API_URL}/api/get_therapy_info/?patient_id=${this.state.patient_id}`, this.config)
      .then(response => {
        if (response.status === 200) {

          let newArray = response.data.data.map((planet) =>
              <option value={planet.trans_med_id}>{planet.name}</option>
            );
          this.setState({ plainArray:newArray });

        }
      })
      .catch(err => console.log(err))
    }

  componentDidMount() {
    axios
      .get(`${API_URL}/api/log_intervention/?patient_id=`+this.state.patient_id, this.config)
      .then(response => {
        if (response.data.status === 200 && response.status === 200) {
          this.setState({
            careList: response.data.data.medical_history,
            origCareList: JSON.parse(JSON.stringify(response.data.data.medical_history)),
          });
        }

        else if(response.status !== 200) {
          toast.error(VALID_EDUCATION_DATA, {
            position: 'top-right',
            autoClose: 3500,
          });
          return false;
        }
      })
      .catch(error => {
        console.log(error);
      });
      this.getMedication();
  }

  // Get All Categories Therapy
  getCategories = () => {
    axios
      .get(`${API_URL}/api/get_all_categories/`, this.config)
      .then(response => {
        if (response.status === 200) {
          let plainArray = response.data.data.map((planet) =>
            <option value={planet.id}>{planet.category}</option>
          );
          let mappedCats = {}
          response.data.data.map((eachCategory) =>{
            // if allowed id then create option and store in array for common
            let common = eachCategory.barrier_therapies.map((each)=>{
              return <option value= {each}>{this.state.idToTherapy[each]}</option>
            })
              // filter common for any undefined, empty, null values
              common = common.filter(function(value, index, arr){
                return value != undefined && value != '' && value != null;
              });
              common.sort(function(a,b){return (a.props.children < b.props.children) ? -1 : 1;})
              mappedCats[eachCategory.id]=common
            }
          );
          this.setState({categoryToTherapy: mappedCats})
          this.setState({ categories:plainArray });
        }
      })
      .catch(err => console.log(err))
  };

  // Get All Barriers Therapy
  getBarriersTherapy = () => {
    axios
      .get(`${API_URL}/api/get_all_barriers_to_therapy/`, this.config)
      .then(response => {
        if (response.status === 200) {
          let plainArray = response.data.data.map((planet) =>
            <option value={planet.id}>{planet.therapy_problem}</option>
          );
          this.setState({ barrierTherapy:plainArray });
          let mappedDict= {}
          response.data.data.map((planet) =>
            mappedDict[planet.id] = planet.therapy_problem,
          );
          this.setState({idToTherapy:mappedDict});
        }
      })
      .catch(err => console.log(err))
  };

  // Get All AE Grade
  getAllAEGrade = () => {
    axios
      .get(`${API_URL}/api/get_all_ae_grades/`, this.config)
      .then(response => {
        if (response.status === 200) {
          let plainArray = response.data.data.map((planet) =>
            <option value={planet.id}>{planet.name}</option>
          );
          this.setState({ aeGrade:plainArray });
        }
      })
      .catch(err => console.log(err))
  };

  componentDidMount(){
    this.getMedication();
    this.getBarriersTherapy();
    this.getCategories();
    this.getAllAEGrade();
    this.getCarePlan();
    this.getEducationAssessment();
  }

  handleCheckboxChange(event) {
    if(event.target.value==='false'){
      this.setState({
        achieved_date: dateFormat(new Date().toISOString().slice(0,10), "yyyy-mm-dd"),
      });
      if (!this.state.goal_achieved != this.state.goal_not_achieved){
        this.changeGoalAchievedState(event)
      }
    }
     else {
      this.setState({
        achieved_date: '',
      });
      this.changeGoalAchievedState(event)
    }
  }

  // Handle Click Event Of The Add Button Of Care Log Intervention
  handleCareAddClick = () => {
    const list = [...this.state.careInputList];
    list.push({
      therapyCare: "",
          therapyStrategy: "",
          therapyGoal: "",
          therapyTiFrToMeetGoal: "",
          therapyOthers: "",
    });
    this.setState({
      careInputList: list,
    });
  };

  // Handle Click event Of The Remove Button Of Care Log Intervention
  handleCareRemoveClick = index => {
    const list = [...this.state.careInputList];
    list.splice(index, 1);
    this.setState({
      careInputList: list,
    });
  };

  // Handle Input Change Of Care Log Intervention
  handleCareInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...this.state.careInputList];
    list[index][name] = value;
    this.setState({
      careInputList: list,
    });
  };

  handleAchievedDateChange(event) {
    if(event.target.value!=null || event.target.value!=''){
      this.setState({
        [event.target.name]: event.target.value,
        goal_achieved: true
      })

    }else{
      this.setState({[event.target.name]: event.target.value})
    }

  }

  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  // for change in category
  handleCategoryChange(event){
    this.setState({
      [event.target.name]: event.target.value,
      barrier_therapy: '',
      barrierTherapy: this.state.categoryToTherapy[event.target.value],
    });
  }

  // Create Care Plan
  createCarePlanForm = e => {

 let carePlanFormData = {
   patient: this.state.patient_id,
   education_assessment: this.state.assessment_id,
   medication: Number(this.state.medication),
   goal_acheived:Boolean(this.state.goal_achieved),
   goal_not_acheived:Boolean(this.state.goal_not_achieved),
   barrier_therapy: Number(this.state.barrier_therapy),
   category: Number(this.state.category),
   ae_grade: Number(this.state.ae_grade),
   identified_date:this.state.identified_date,
   achieved_date:this.state.achieved_date,
   fu_date: this.state.fu_date,
   strategy_and_resource: this.state.strategy_and_resource,
   goal: this.state.goal,
   status: this.state.status,
   timeframe_to_goal:this.state.timeframe_to_goal
 }
 if(carePlanFormData.education_assessment==='' || carePlanFormData.education_assessment===undefined || carePlanFormData.education_assessment===0 ){
  toast.error('Cannot add without active education assessment', {
    position: 'top-right',
    autoClose: 2500,
  });
  return false;
 }
 if(carePlanFormData.medication==='' || carePlanFormData.medication===undefined || carePlanFormData.medication===0 ){
  toast.error('Please Select Medication', {
    position: 'top-right',
    autoClose: 2500,
  });
  return false;
 }
 if(carePlanFormData.category==='' || carePlanFormData.category===undefined || carePlanFormData.category===0){
  toast.error('Please Select Category', {
    position: 'top-right',
    autoClose: 2500,
  });
  return false;
 }
 if(carePlanFormData.barrier_therapy==='' || carePlanFormData.barrier_therapy===undefined || carePlanFormData.barrier_therapy===0){
  toast.error('Please Select Barrier', {
    position: 'top-right',
    autoClose: 2500,
  });
  return false;
 }
 if(carePlanFormData.identified_date==='' || carePlanFormData.identified_date===undefined){
  toast.error('Please Select Identified Date', {
    position: 'top-right',
    autoClose: 2500,
  });
  return false;
 }
 if(carePlanFormData.goal==='' || carePlanFormData.goal===undefined){
  toast.error('Please Enter Goal Of Therapy / Intervention', {
    position: 'top-right',
    autoClose: 2500,
  });
  return false;
 }
 if(carePlanFormData.strategy_and_resource==='' || carePlanFormData.strategy_and_resource===undefined){
  toast.error('Please Enter Strategy And Resource To Achieve Goal', {
    position: 'top-right',
    autoClose: 2500,
  });
  return false;
 }
 if(carePlanFormData.timeframe_to_goal==='' || carePlanFormData.timeframe_to_goal===undefined){
  toast.error('Please Enter Timeframe To Meet Goal', {
    position: 'top-right',
    autoClose: 2500,
  });
  return false;
 }
 if(carePlanFormData.ae_grade==='' || carePlanFormData.ae_grade===undefined || carePlanFormData.ae_grade===0){
  toast.error('Please Select AE Grade', {
    position: 'top-right',
    autoClose: 2500,
  });
  return false;
 }
 if(carePlanFormData.fu_date==='' || carePlanFormData.fu_date===undefined || carePlanFormData.fu_date===null ){
  carePlanFormData.fu_date = null;
 }
 axios
    .post(`${API_URL}/api/log_intervention/`,carePlanFormData, this.config)
    .then(response => {
      if (response.data.status === 201 && response.status === 200) {
        toast.success('Successfully Created',{
          position:'top-right',
          autoClose:3500,
        });
        window.setTimeout(function(){
          window.location.reload();
        }, 3500);
      } else  {
        toast.error(VALID_EDUCATION_DATA, {
          position: 'top-right',
          autoClose: 3500,
        });
        return false;
      }
    })
    .catch(error => {
      console.log(error);
    });

  }
  /* update  Care Plan method*/
  updateCarePlan = (evt, index, id) => {
    axios
      .put(`${API_URL}/api/log_intervention/${id}/`,this.state.careList[index], this.config)
      .then(response => {
        if (response.data.status === 200 && response.status === 200) {
          toast.success('Successfully Updated', {
            position: 'top-right',
            autoClose: 3000,
          });
          window.setTimeout(function(){
            window.location.reload();
          }, 3000);

        } else if(response.status !== 200) {
          toast.error(VALID_EDUCATION_DATA, {
            position: 'top-right',
            autoClose: 3000,
          });
          return false;
        }
      })
      .catch(error => {
        console.log(error);
      });
  }

  updateCarePlanChanges = (event, index, id) => {
    const name = event.target.name;
    const value = event.target.value;
    let list = [ ...this.state.careList ];
    list[index][event.target.name] = event.target.value;
    this.setState({
      careList: list,
    });
  }

  updateCarePlanCheckboxes = (event, index, id) => {
    const name = event.target.name;
    const value = event.target.checked ? true : false;
    let list = [...this.state.careList];
    let origAchieved = this.state.origCareList[index].achieved_date
    if (origAchieved !== null){
      return
    }
    else{
      if(value){
        list[index]['achieved_date']=  dateFormat(new Date().toISOString().slice(0,10), "yyyy-mm-dd");
        if (!list[index]["goal_acheived"] !== list[index]["goal_not_acheived"]){
          list[index][name] = !list[index][name]
        }
      }
      else {
        list[index]['achieved_date']= null;
        list[index][name] = !list[index][name]
      }
    }
    this.setState({
      careList: list,
    });
    //console.log(this.state.careList[index])
  }

  // Handle Click Event Of The Add Button Of Communication Log Intervention
  handleCommuAddClick = () => {
    const list = [...this.state.commuInputList];
    list.push({
      followUpRequired: false,
      commBarriers: "",
      commuDate: this.todayDate,
      commuMethod: "phone_call",
      commuNotes: "",
      commuStatus: "in_process",
      commuFollowUpDate: this.todayDate,
      commuId: ""
    });
    this.setState({
      commuInputList: list,
    });
    // console.log(this.state.commuInputList)
  };

  // Handle Communication Cancel Changes
  handleCancelCommChanges = (evt, index, id) => {
    const list = [...this.state.commuInputList];

    list[index].commuDate = this.todayDate;
    list[index].commuMethod = "phone_call";

    list[index].commuNotes = "";
    list[index].commuStatus = "in_process";

    list[index].commuFollowUpDate = this.todayDate;
    list[index].commuId = "";

    this.setState({ commuInputList: list, });
  };

  // Handle Click Event Of The Create or Update Communication Log Intervention
  handleCommUpdateOrCreate = (evt, index, id) => {
      const commData = {
        follow_up: this.state.commuInputList[index].followUpRequired,
        issue_type: this.state.commuInputList[index].commBarriers,
        patient: this.state.patient_id,
        method:  this.state.commuInputList[index].commuMethod,
        status: this.state.commuInputList[index].commuStatus,
        followup_date: dateFormat(this.state.commuInputList[index].commuFollowUpDate, "yyyy-mm-dd"),
        notes: this.state.commuInputList[index].commuNotes,
      }
      if (id) {
        axios
            .put(`${API_URL}/api/assessment_communication/${id}`, commData, this.config)
            .then(response => {
              if (response.data.status === 200 && response.status === 200) {
                toast.success(`Successfully ${response.data.message}`, {
                  position: 'top-right',
                  autoClose: 3500,
                })
              } else {
                toast.error(`${response.data.message}`, {
                  position: 'top-right',
                  autoClose: 3500,
                });
                return false;
              }
            })
            .catch(err => {
              toast.error(`${err.data.message}`, {
                position: 'top-right',
                autoClose: 3500,
            })
          })
      }else{
        axios
            .post(`${API_URL}/api/assessment_communication/`, commData, this.config)
            .then(response => {
              if (response.data.status === 201) {
                toast.success(`Successfully ${response.data.message}`, {
                  position: 'top-right',
                  autoClose: 3500,
                })
              } else {
                toast.error(response.data.message, {
                  position: 'top-right',
                  autoClose: 3500,
                });
                return false;
              }
            })
            .catch(err => {
              toast.error(err.data.message, {
                position: 'top-right',
                autoClose: 3500,
            })
          })
      }
  };

  // Handle Click event Of The Remove Button Of Communication Log Intervention
  handleCommuRemoveClick = (index, id) => {
    const list = [...this.state.commuInputList];
    if (id) {
      axios
          .delete(`${API_URL}/api/assessment_communication/${id}`, this.config)
          .then(resp => {
            if (resp.data.status === 200) {
            list.splice(index, 1);
            this.setState({
              commuInputList: list,
            });
            toast.success('Successfully Deleted', {
              position: 'top-right',
              autoClose: 3500,
            });
          }else{
            toast.error(VALID_EDUCATION_DATA, {
            position: 'top-right',
            autoClose: 3500,
            })
          }
          })
          .catch(err => {
            console.log(err)
            toast.error(VALID_EDUCATION_DATA, {
              position: 'top-right',
              autoClose: 3500,
            });
            return false;
            console.log(err)
          })
    }else{
      list.splice(index, 1);
      this.setState({
        commuInputList: list,
      });
    }

  };
  // Handle Checkbox Change Of Communication Log Intervention
  handleCommuCheckboxChange = (e, index) => {
    const name = e.target.name;
    const value = e.target.checked ? true : false;
    const list = [...this.state.commuInputList];
    list[index][name] = value;
    this.setState({
      commuInputList: list,
    });
  };

  // Handle Input Change Of Communication Log Intervention
  handleCommuInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...this.state.commuInputList];
    list[index][name] = value;
    this.setState({
      commuInputList: list,
    });
  };

  // Handle Assessment Communication Listing For Log Intervention
  getAssessComm = () => {
    axios
      .get(`${API_URL}/api/assessment_communication/?patient_id=${this.state.patient_id}`, this.config)
      .then(response => {
        if (response.data.status === 200 && response.status === 200) {
          let allCommData;
          if (response.data.data.length) {
            allCommData = response.data.data.map((data, index)=> ({
                              followUpRequired: data.follow_up,
                              commBarriers: data.issue_type,
                              last_updated_by:data.updated_by_user,
                              last_modified:data.modified,
                              commuMethod: data.method,
                              commuDate: data.created,
                              commuNotes: data.notes,
                              commuStatus: data.status,
                              commuId: data.id,
                              commuFollowUpDate: data.followup_date,
                            })
                          );
          } else {
            allCommData = [{
              commuId: "",
              commuDate: this.todayDate,
              commuMethod: "phone_call",
              commuNotes: "",
              commuStatus: "in_process",
              commuFollowUpDate: this.todayDate,
            }]
          }
          this.setState({ commuInputList: allCommData, });
        } else if(response.status !== 200) {
          toast.error(VALID_EDUCATION_DATA, {
            position: 'top-right',
            autoClose: 3500,
          });
          return false;
        }
      })
      .catch(error => {
        console.log(error);
      });
  }

  // Handle On Change Event For Care And Communication Section Under Log Intervention
  doSomeCare = event => {
    this.getAssessComm();
    if (event === 'care') {
      this.setState({ 'active': false });
      this.setState({ 'InActive': true });
    } else {
      this.setState({ 'InActive': false });
      this.setState({ 'active': true });
    }
  };


  /* get communications listing common method*/

  // componentDidMount() {
    // this.getAssessComm()
  // }
  
  handlePopup = (e) => {
    this.setState({ show: true })
  }

  closeModal = event => {
    this.setState({
      show: false
    })
  }

  render() {
    const isLogged = !!localStorage.getItem('token');
    const is_superAdmin = localStorage.getItem('adminCheck');

    if (!isLogged) {
      return <Redirect to="/login" />;
    }
    
    return (
    <>
      <ToastContainer />
      <CommonHeader />
      <section id="wrapper">
          <CommonSidebar />
          <div className="row" id="one">
            <div className="col-sm-12 mb-4">
              <h3 className="text-uppercase font-weight-bold">
                Goals Of Therapy / Intervention / Care Planning
              </h3>
            </div>
            <div className="col-sm-12">
              <h3 className="text-blue mb-1">
              <i className="fa fa-user fa-fw user-icon mr-2"></i>
                <Link to={{
                  'pathname': '/patientcliprofile','state':{
                    'transaction_id': this.state.transaction_id,
                    'patient_id': this.state.patient_id, 'patient_name': this.state.patient_name,'data_added' : this.state.data_added
                    }
                    }}>
                    {this.state.patient_name}

                </Link>

              </h3>
              <hr/>
            </div>
                {/*****  Button Created */}
            <div className="col-sm-6 mt-4 mb-4">
              <button className="btn btn-primary mr-2" onClick={() => this.doSomeCare('care')}> Care Plan </button>
              <button className="btn btn-primary" onClick={() => this.doSomeCare('commu')}> Communication Archives </button>
            </div>
            <div className="col-sm-6 mt-4 mb-4 text-right">
              <button className="btn btn-primary">Add New Therapy Goal / Intervention</button>
            </div>
            {/* end the button */}
            <div className="col-sm-12" hidden={this.state.active}>
              <div className="table-responsive">
               <table className="table RX-table">
                 <thead>
                    <tr>
                      <th>Medication / Category / Barrier</th>
                      <th>Goals Of Therapy / Intervention</th>
                      <th>Strategy And Resources To Achieve Goal</th>
                      <th>Timeframe To Meet Goal</th>
                      <th  style={{ width: '50px' }}></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="text-left">
                          {/*********** starting the medication/cat/barrier*/}
                        <div className="custom-control pl-0">
                          <select value={this.state.medication} className="form-control" id="medication" name='medication'
                          onChange={this.handleChange} onLoad={this.handleChange}>
                            <option value="">Please Select Medication</option>
                            {this.state.plainArray}
                          </select>
                        </div>
                        <div className="custom-control mt-2  pl-0">
                          <select className="form-control" name="category"  id="category"
                          value={this.state.category} onChange={this.handleCategoryChange}>
                            <option value="">Please Select Category</option>
                            {this.state.categories}
                          </select>
                        </div>
                        <div className="custom-control mt-2  pl-0">
                          <select className="form-control" name="barrier_therapy"  id="barrier_therapy"
                          value={this.state.barrier_therapy} onChange={this.handleChange}>
                            <option value="">Please Select Barrier</option>
                            {this.state.barrierTherapy}
                          </select>
                        </div>
                        <div className="custom-control mt-2  pl-0 d-flex align-items-center">
                          <label className="mr-1 mb-0 font-weight-bold">Identified</label>
                          <input name="identified_date" id="identified_date" type="date" className="form-control"
                          value={this.state.identified_date} max={this.todayDate} onKeyDown={(event)=>{event.preventDefault()}} onChange={this.handleChange} />
                        </div>
                        <div className="custom-control mt-2 pl-0 d-flex align-items-center">
                          <label className="mr-1 mb-0 font-weight-bold">Closed</label>
                          <input name="achieved_date" id="achieved_date"  type="date" className="form-control" value={this.state.achieved_date} onChange={event => this.handleAchievedDateChange(event)} readOnly/>
                        </div>
                      </td>
                        {/* end of the  medication/cat/barrier*/}
                        {/* handle the goals of therapy,strategy,timeframe */}
                      <td>
                        <textarea value={this.state.goal} name="goal" id="goal" placeholder="Goal" cols="75" rows="10" className="form-control" onChange={this.handleChange}></textarea>
                      </td>
                      <td>
                        <textarea value={this.state.strategy_and_resource} onChange={this.handleChange}
                        name="strategy_and_resource" id="strategy_and_resource" placeholder="Strategy And Resources To Achieve Goal" cols="75" rows="10" className="form-control"></textarea>
                      </td>
                      <td>
                        <textarea value={this.state.timeframe_to_goal} onChange={this.handleChange} name="timeframe_to_goal" id="timeframe_to_goal" placeholder="Timeframe To Meet Goal" cols="75" rows="10" className="form-control"></textarea>
                      </td>
                        {/* end point */}
                        <td className="text-left">
                          <div className="custom-control pl-0 d-flex align-items-center">
                            {/* start link AE Grade */}
                            <label className="mr-1 mb-0 font-weight-bold" onClick={this.handlePopup} title="AE Grade Description">
                              <u style={{ cursor : "pointer", color: "blue"}}>AE Grade</u>
                            </label>

                            <select className="form-control" value={this.state.ae_grade}
                            onChange={this.handleChange} name="ae_grade" id="ae_grade">
                              <option value="">Please Select</option>
                              {this.state.aeGrade}
                            </select>
                          </div>
                          {/* start the patient accepts Goal */}
                          <div className="custom-control mt-2  pl-0">
                            <div className="form-check-inline">
                              <label  className="form-check-label" >
                                <input type="checkbox" className="form-check-input" value='PATIENT_ACCEPT' name='create_status'  defaultChecked
                                />Patient Accepts Goal
                              </label>
                            </div>
                          </div>
                          {/* start the Goal Achieved */}
                          <div className="custom-control mt-2  pl-0">
                            <div className="form-check-inline">
                              <label  className="form-check-label">
                                <input type="checkbox" className="form-check-input"  name='goal_achieved'
                                value={this.state.goal_achieved} checked={this.state.goal_achieved===true}
                                  onChange={event => this.handleCheckboxChange(event)}
                                  />Goal Achieved
                                </label>
                            </div>
                          </div>
                          {/* start the Goal not Achieved */}
                          <div className="custom-control mt-2  pl-0">
                            <div className="form-check-inline">
                              <label  className="form-check-label">
                                <input type="checkbox" className="form-check-input"  name='goal_not_achieved'
                                value={this.state.goal_not_achieved} checked={this.state.goal_not_achieved}
                                  onChange={event => this.handleCheckboxChange(event)}
                                  />Goal Not Achieved
                                </label>
                            </div>
                          </div>
                          {/* start with F/U date */}
                          <div className="custom-control mt-2  pl-0 d-flex align-items-center">
                            <label className="mr-1 mb-0 font-weight-bold">Follow-up</label>
                            
                            <input type="date" value={this.state.fu_date} 
                            min = {this.todayDate} onKeyDown={(event)=>{event.preventDefault()}}
                            onChange={this.handleChange} name="fu_date" id="fu_date" className="form-control"/>
                          </div>
                          {/* button Add New Communication*/}
                          <div className="d-flex mt-3 justify-content-end">
                                <button className="btn btn-primary" onClick={() => this.doSomeCare('commu')}>Add New Communication</button>
                          </div>
                        </td>
                        <td className="text-left">
                          <div class="d-flex flex-column bd-highlight mb-3">
                            <div className="d-flex justify-content-end">
                              <button type="button" onClick={this.createCarePlanForm} className="btn btn-primary mt-1 btnControl">
                                <IconWithTooltip Icon={PlusIcon} text="Add" />
                              </button>

                            </div>
                          </div>
                        </td>
                      </tr>
                  </tbody>  
                  {this.state.careList.map((x, i) => {
                      let origList = this.state.origCareList[i]
                      let disable = origList.achieved_date !== null;
                      return (
                        <tbody>
                          <tr style={disable? { backgroundColor: "#F0F0F0" } : {}}>
                         <td className="text-left">
                            <div className="custom-control pl-0">
                            <select className="form-control"  defaultValue={x.medication}
                            name="medication" onChange={(e) => this.updateCarePlanChanges(e, i)} disabled ={disable}>
                              {this.state.plainArray}
                            </select>
                            </div>
                            <div className="custom-control mt-2  pl-0">
                              <select className="form-control" value={x.category}
                              name="category" onChange={(e) => this.updateCarePlanChanges(e, i)} disabled ={disable}>
                                {this.state.categories}
                              </select>
                            </div>
                            <div className="custom-control mt-2  pl-0">
                              <select className="form-control"  value={x.barrier_therapy}
                              name="barrier_therapy" onChange={(e) => this.updateCarePlanChanges(e, i)} disabled ={disable}>
                                <option value={this.state.idToTherapy[x.barrier_therapy]}>Please Select a Barrier</option>
                                {this.state.categoryToTherapy[x.category]}
                                {/* {this.state.barrierTherapy} */}
                                {/* <option value={this.state.idToTherapy[x.barrierTherapy]}>Please Select a Barrier</option>
                                {this.state.categoryToTherapy[x.category]} */}
                              </select>
                            </div>
                            <div className="custom-control mt-2  pl-0 d-flex align-items-center">
                              <label className="mr-1 mb-0 font-weight-bold" name="identified_date">Identified</label>
                              <input type="date" defaultValue={x.identified_date} onKeyDown={(event)=>{event.preventDefault()}} name="identified_date" onChange={(e) => this.updateCarePlanChanges(e, i)}
                              className="form-control" readOnly ={disable}/>
                              
                            </div>
                            <div className="custom-control mt-2 pl-0 d-flex align-items-center">
                              <label className="mr-1 mb-0 font-weight-bold" name='achieved_date'>Closed</label>
                              <input type="date" defaultValue={x.achieved_date} onChange={(e) => this.handleAchievedDateChange(e, i)} className="form-control" readOnly/>
                            </div>
                            <div className="custom-control pl-0 mt-2">
                              <p>{dateFormat(x.created , "mm-dd-yyyy  HH:MM:ss ") } {x.created_by_user} </p>
                            </div>
                          </td>
                          <td className="text-left">
                            <textarea defaultValue={x.goal} onChange={(e) => this.updateCarePlanChanges(e, i)} name="goal" placeholder="Goal" cols="75" rows="10" className="form-control" readOnly ={disable}></textarea>
                          </td>
                          <td>
                          <textarea defaultValue={x.strategy_and_resource} onChange={(e) => this.updateCarePlanChanges(e, i)} name="strategy_and_resource" placeholder="Strategy And Resources" cols="75" rows="10" className="form-control" readOnly ={disable}></textarea>
                          </td>
                          <td>
                          <textarea defaultValue={x.timeframe_to_goal} onChange={(e) => this.updateCarePlanChanges(e, i)} name="timeframe_to_goal" placeholder="Timeframe To Meet Goal" cols="75" rows="10" className="form-control" readOnly ={disable}></textarea>
                          </td>
                          <td className="text-left">
                            <div className="custom-control pl-0 d-flex align-items-center">
                            <label className="mr-1 mb-0 font-weight-bold" onClick={this.handlePopup} title="AE Grade Description"><u style={{ cursor : "pointer", color: "blue"}}> AE Grade </u></label>
                            <select className="form-control" defaultValue={x.ae_grade}  name="ae_grade" 
                            onChange={(e) => this.updateCarePlanChanges(e, i)} id="ae_grade" disabled ={disable}>
                              {this.state.aeGrade}
                            </select>
                            </div>
                            <div className="custom-control mt-2  pl-0">
                              <div className="form-check-inline">
                                <label className="form-check-label">
                                  <input type="checkbox" onChange={(e) => this.updateCarePlanChanges(e, i)} className="form-check-input" checked={x.status==="PATIENT_ACCEPT"} name="pateient_accept" />Patient Accepts Goal
                                </label>
                              </div>
                            </div>
                            <div className="custom-control mt-2  pl-0">
                              <div className="form-check-inline">
                                <label className="form-check-label">
                                  <input type="checkbox" onChange={(e) => this.updateCarePlanCheckboxes(e, i)} className="form-check-input" checked={x.goal_acheived === true}  name="goal_acheived" />Goal Achieved
                                </label>
                              </div>
                            </div>
                            <div className="custom-control mt-2  pl-0">
                              <div className="form-check-inline">
                                <label className="form-check-label">
                                  <input type="checkbox" onChange={(e) => this.updateCarePlanCheckboxes(e, i)} className="form-check-input" checked={x.goal_not_acheived === true}  name="goal_not_acheived" />Goal Not Achieved
                                </label>
                              </div>
                            </div>
                            <div className="custom-control mt-2  pl-0 d-flex align-items-center">
                              <label className="mr-1 mb-0 font-weight-bold">Follow-up</label>
                              <input type="date" defaultValue={x.fu_date} name="fu_date" min = {this.todayDate} onKeyDown={(event)=>{event.preventDefault()}}
                              className="form-control" onChange={(e) => this.updateCarePlanChanges(e, i)} readOnly ={disable}/>
                              
                            </div>
                            <div className="d-flex mt-3 justify-content-end">
                                <button className="btn btn-primary" onClick={() => this.doSomeCare('commu')}>Add New Communication</button>
                            </div>
                          </td>
                          <td className="text-left">
                            <div class="d-flex flex-column bd-highlight mb-3">
                              <div className="d-flex justify-content-end">
                              {disable ? (<span/>) : (
                                <button type="button" onClick={(evt) => this.updateCarePlan(evt, i, x.id)} className="btn btn-primary mt-1 btnControl btnsv">
                                  <IconWithTooltip Icon={CheckIcon} text="Save Changes" />
                                </button>
                               )}
                              </div>
                            </div>
                          </td>
                        </tr>
                       </tbody>
                       );
                      })}
                </table>
              </div>
            </div>
            <div className="col-sm-12" hidden={this.state.InActive}>
              <div className="table-responsive">
               <table className="table RX-table gridTable">
                 <thead>
                    <tr>
                      <th>Follow Up Required</th>
                      <th>Issue Type</th>
                      <th>Date</th>
                      <th>Method</th>
                      <th>Communication Notes</th>
                      <th>Status</th>
                      <th>Follow Up Date</th>
                      <th>Last Updated</th>
                      <th>Last Updated By</th>
                      <th  style={{ width: '50px' }}></th>
                    </tr>
                  </thead>
                  <tbody>
                  {this.state.commuInputList.map((x, i) => {
                  // {console.log(x)}
                    return (
                      <tr>
                         <td>
                           <input type="checkbox" name="followUpRequired" checked={x.followUpRequired} onChange={(evt) => this.handleCommuCheckboxChange(evt, i)}  />
                         </td>
                         <td>
                            <select className="form-control" name="commBarriers" value={x.commBarriers}
                            onChange={(evt) => this.handleCommuInputChange(evt, i)}>
                              <option value="">Please Select</option>
                              {this.state.barrierTherapy}
                            </select>
                          </td>
                         <td>
                            <div className="custom-control pl-0">
                              <input type="date" value={dateFormat(x.commuDate, "yyyy-mm-dd")} name="commuDate"
                              className="form-control" onChange={(evt) => this.handleCommuInputChange(evt, i)} readOnly/>
                            </div>
                          </td>
                          <td style={{width: "140px"}}>
                            <select className="form-control" name="commuMethod" value={x.commuMethod}
                            onChange={(evt) => this.handleCommuInputChange(evt, i)}>
                              <option value="phone_call">Phone Call</option>
                              <option value="email">Email</option>
                              <option value="other">Other</option>
                            </select>
                          </td>
                          <td>
                            <textarea value={x.commuNotes} name="commuNotes" cols="75" rows="1" className="form-control" onChange={(evt) => this.handleCommuInputChange(evt, i)}></textarea>
                          </td>
                          <td>
                            <select value={x.commuStatus} className="form-control" name="commuStatus" onChange={(evt) => this.handleCommuInputChange(evt, i)}>
                                <option value="in_process">In Process</option>
                                <option value="closed">Closed</option>
                            </select>
                          </td>
                          <td>
                            <input value={dateFormat(x.commuFollowUpDate, "yyyy-mm-dd")} type="date"
                            name="commuFollowUpDate" min = {this.todayDate} onKeyDown={(event)=>{event.preventDefault()}} className="form-control" onChange={(evt) => this.handleCommuInputChange(evt, i)} />
                          </td>
                          <td>
                            <input type="text" className="form-control gridTableLastUpdated" name="commLastUpdatedBy" value={dateFormat(x.last_modified , "mm-dd-yyyy  HH:MM:ss TT")} readOnly />
                          </td>
                          <td>
                            <input type="text" className="form-control gridTableLastUpdated" name="commLastUpdated" value={x.last_updated_by} readOnly />
                          </td>
                          <td className="d-flex justify-content-end">
                            <button type="button" className="btn btn-primary mt-1 btnControl btnsv" onClick={(evt) => this.handleCommUpdateOrCreate(evt, i, x.commuId)}>
                              <IconWithTooltip Icon={CheckIcon} text="Save Changes" />
                            </button>
                            <button type="button" onClick={(evt) => this.handleCancelCommChanges(evt, i, x.commuId)} className="btn btn-primary mt-1 btnControl">
                              <IconWithTooltip Icon={DeleteOutlinedIcon} text="Cancel Changes" />
                            </button>
                            <button className="btn btn-primary mt-1 btnControl btnsv"> <i className="fa fa-user" aria-hidden="true"></i></button>
                            {this.state.commuInputList.length - 1 === i && <button type="button" onClick={this.handleCommuAddClick} className="btn btn-primary mt-1 btnControl">
                                <IconWithTooltip Icon={PlusIcon} text="Add" />
                              </button> }
                            {this.state.commuInputList.length !==  1 && is_superAdmin == 'true' && <button type="button" onClick={evt => this.handleCommuRemoveClick(i, x.commuId)} className="btn btn-primary mt-1 btnControl btndel">
                                <IconWithTooltip Icon={DeleteIcon} text="Remove"/>
                              </button> }
                          </td>
                        </tr>
                       );
                      })
                      }
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <Modal show={this.state.show} onHide={this.closeModal} backdrop="static">
            <Modal.Header closeButton>
              <Modal.Title className="text-center col-md-11 paddingzero"> Common Terminology Criteria for Adverse Events v4.0 (CTCAE) </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <AdversePopup closeModal={this.closeModal} />
            </Modal.Body>
          </Modal>
        </section>
        <CommonFooter />
    </>
  );
  }
}
